//--<< IMPORTS DE COMPONENTES >>--\\

import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import $ from 'jquery'
import 'jquery-mask-plugin'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {Badge, CircularProgress, IconButton, InputAdornment, LinearProgress, Theme, ThemeProvider, Tooltip, Typography} from '@mui/material'
import {MuiTelInput} from 'mui-tel-input'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import WarningIcon from '@mui/icons-material/Warning'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import RecommendIcon from '@mui/icons-material/Recommend'
import LockResetIcon from '@mui/icons-material/LockReset'
import BoltIcon from '@mui/icons-material/Bolt'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import EditNoteIcon from '@mui/icons-material/EditNote'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'

import UploadCollection from '../upload/UploadCollection'

import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider, DesktopDatePicker, DateTimePicker} from '@mui/x-date-pickers'
import {GetAccessToken} from '../../../../modules/api/Api'

import {Id, ToastContent, ToastOptions, toast} from 'react-toastify'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import MenuAppResourceSelect from '../utility/menuAppResourceSelect'
import Chat from '../chat/chat'
import {GeocodeFormat, NumericFormatWithDecimal, NumericFormatWithoutDecimal} from '../utility/numericFormatDecimal'
import {CCalcFieldRule} from '../../../../models/classes/CCalcFieldRule'
import DynamicList from '../tabs/components/DynamicList'
import EmailSideMenu from '../email/EmailSideMenu'
import StyledDialog from '../styled/StyledDialog'
import {CRuleActions} from '../../../../models/classes/CRuleActions'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {useMsal} from '@azure/msal-react'
import CodeMirror, {Extension} from '@uiw/react-codemirror'
import {json} from '@codemirror/lang-json'
import {basicLight} from '@uiw/codemirror-theme-basic'
import {MenuAppActionResult} from '../../../../modules/api/models/Result/MenuAppActionResult'
import ModalPerformingAction from './modalPerformingAction'

import ReactMapGL, {Marker} from 'react-map-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {ElapsedFollowUpTimerResult} from '../../../../modules/api/models/Result/ElapsedFollowUpTimerResult'
import Timer from '../utility/Timer'
import {NavigateFunction, useNavigate} from 'react-router-dom'
import {CampoEstruturadoResult} from '../../../../modules/api/models/Result/CampoEstruturadoResult'
import {MenuAppRoleSimpleResult} from '../../../../modules/api/models/Result/MenuAppRoleSimpleResult'
import useStateRef from 'react-usestateref'
import {SingleSelectResult} from '../../../../modules/api/models/Result/SingleSelectResult'
import {SafeClamp} from '../../../../models/wrappers/math'
import {handleApplyValueToArrayedState} from '../../../../models/wrappers/handlers'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {TranslateLocale} from '../../../../models/wrappers/translator'
import axios from 'axios'
import uuid from 'react-uuid'
import QRCode from 'qrcode.react'
import {CKEditorZIndexFix} from '../utility/CKEditorZIndexFix'
import ProjectInfoDisplay from '../utility/ProjectInfoDisplay'

const Editor: any = ClassicEditor

//--<< END IMPORTS DE COMPONENTES >>--\\

// Interface de tipagem do objeto de funções passado no APP_Start
interface IFunctions {
  jQuery: any
  navigate: NavigateFunction
  closeModal: (data?: any) => void
  successMSG: <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions<{}> | undefined) => Id
  errorMSG: <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions<{}> | undefined) => Id
  warnMSG: <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions<{}> | undefined) => Id
  hideButtons: (hide: {enviarPorEmail: boolean; excluir: boolean; salvar: boolean; salvarEFechar: boolean}) => void
  /*
    Ocultar botões do modal:
    - enviarPorEmail: Ocultar botão de enviar por email
    - excluir: Ocultar botão de excluir
    - salvar: Ocultar botão de salvar
    - salvarEFechar: Ocultar botão de salvar e fechar

    Exemplo no JS:
      hideButtons({
        enviarPorEmail: true,
        excluir: true,
        salvar: true,
        salvarEFechar: true
      })
  */
}

// Declaramos funções que ficarão disponíveis no javascript da página
declare global {
  interface Window {
    /**
     * Função de inicio do custom page. NOTA: Todas as custom pages devem ter essa função declarada!
     * @param AccessCode Código de acesso do contexto atual
     * @param ID Código do item aberto no modal
     * @param URLAPI URL da api escolhida pela aplicação
     * @param Token Função de recuperação do token. NOTA: Essa função é assíncrona, o que quer dizer que a mesma retorna uma promisse para o token ao invés do token diretamente!
     * @param Functions Objeto com as funções mais básicas presentes no TypeScript da aplicação
     * @returns void
     */
    APP_Start(AccessCode: string, ID: number, URLAPI: any, Token: () => Promise<string | undefined>, Functions: IFunctions): void
    /**
     * Função de fechamento do modal sem salvamento. NOTA: Todas as custom pages devem ter essa função declarada!
     * @returns void
     */
    APP_Cancel(): void
    /**
     * Função de salvamento. NOTA: Todas as custom pages devem ter essa função declarada!
     * @returns Promise<number>
     */
    APP_Save(): Promise<number>
    /**
     * Função de fechamento do modal com salvamento. NOTA: Todas as custom pages devem ter essa função declarada!
     * @returns Promise<number>
     */
    APP_SaveAndClose(): Promise<number>
  }
}

interface IDynamicModal {
  disableSendEmail?: boolean
  readonly?: boolean
  showClose?: boolean
  acessoExterno?: boolean
  accessCode?: string
  accessCodeMaster?: string
  id: number
  ids?: Array<number>
  acaoEmMassa?: boolean
  relationItem?: any
  relationItemID?: number
  disableSave?: boolean
  callBack?: any
  deleteFunc?: any
  actions?: any
  setActions?: any
}

const DynamicModal = ({
  disableSendEmail,
  readonly,
  showClose,
  acessoExterno,
  accessCode,
  accessCodeMaster,
  id,
  ids,
  acaoEmMassa,
  relationItem,
  relationItemID,
  disableSave,
  callBack,
  deleteFunc,
  actions,
  setActions,
}: IDynamicModal) => {
  // Variaveis de estado
  const [AccessCode, setAccessCode] = useState<string | undefined>('')
  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Saving, setSaving] = useState<boolean>(false)
  const [Validating, setValidating] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [DataAccordions, setDataAccordions] = useState<Array<string>>([])
  const [Data, setData, DataRef] = useStateRef<Array<CampoEstruturadoResult>>([])
  const [DataID, setDataID] = useState<number>(0)
  const [DataIDs, setDataIDs] = useState<Array<number>>([])
  const [Relations, setRelations] = useState<any>([])
  const [TabIndex, setTabIndex] = useState<number>(0)
  const [CustomPageFile, setCustomPageFile] = useState<any>(null)
  const [permissions, setPermissions] = useState<MenuAppRoleSimpleResult | undefined>()
  const [localActions, setLocalActions] = useState<Array<MenuAppActionResult>>(actions ?? [])
  const [elapsedFollowUpTimer, setElapsedFollowUpTimer] = useState<ElapsedFollowUpTimerResult | undefined>(undefined)
  const [fieldLock, setFieldLock] = useState<boolean>(false)
  const [appInfo, setAppInfo] = useState<any>({})

  const [hideButtons, setHiddenButtons] = useState<{
    enviarPorEmail: boolean
    excluir: boolean
    salvar: boolean
    salvarEFechar: boolean
  }>({
    enviarPorEmail: false,
    excluir: false,
    salvar: false,
    salvarEFechar: false,
  })

  const [sending, setSending] = useState<boolean>(false)
  const [emailProps, setEmailProps] = useState<any>({})

  const [emailGrid, setEmailGrid] = useState<boolean>(false)
  const [emailGridIntegration, setEmailGridIntegration] = useState<number>(0)
  const [emailGridFilters, setEmailGridFilters] = useState<Array<any>>([
    {
      columnField: 'Folder',
      operatorValue: 'equals',
      value: ['INBOX'],
    },
  ])
  const [emailGridTitle, setEmailGridTitle] = useState<string>('Caixa de Entrada')
  const [emailGridNaoLidos, setEmailGridNaoLidos] = useState<Array<any>>([])
  const [appGeneralRules, setAppGeneralRules, appGeneralRulesRef] = useStateRef<Array<any>>([])
  const [codeEditorTheme, setCodeEditorTheme] = useState<Extension>(basicLight)
  const [regraConfirmacao, setRegraConfirmacao] = useState<any>(null)
  const [geocodeIntervalID, setGeocodeIntervalID] = useState<NodeJS.Timer | undefined>(undefined)

  const [MAPTOKEN, setMAPTOKEN] = useState<string>('')
  const MAPDEFAULTCOORDINATES = '{"longitude": -49.2733, "latitude": -25.4284, "zoom": 10, "address": ""}'

  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const locale = useLang()
  const navigate = useNavigate()

  /**
   * Verifica se a função de callback é existente e se for chama a mesma passando o retorno para o componente que esta renderizando este.
   * @param data (Opcional) Objeto de retorno para o componente "Pai"
   * @returns void
   */
  const handleCallBack = (data: any = null) => {
    // Limpamos o objeto Interval do geocoder para evitar azamentos de memória
    if (geocodeIntervalID != null) {
      clearInterval(geocodeIntervalID)
    }

    if (callBack != null) {
      callBack(data)
    }
  }

  const handleLoadActions = (ignorarValidacoes: boolean = false) => {
    apiWrapper.get(`/api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${accessCode}`).then(async (response) => {
      let _actions: Array<MenuAppActionResult> = response.data.data

      // VALIDAMOS AS EXIBIÇÕES DAS AÇÕES APÓS EXECUÇÃO INICIAL DE REGRAS CASO TENHAM
      // Fazemos isso no back pois o valor que impera para a execução de uma ação É O QUE ESTÁ SALVO NA BASE e não o que está no front sendo renderizado e alterado no modal
      if (!ignorarValidacoes) {
        for (const _action of _actions) {
          if (_action.menuAppFieldsRule?.atributo08 == null || _action.menuAppFieldsRule?.atributo08 == '') {
            continue
          }

          let obj = {
            item: {
              ID: id,
              AccessCode: accessCode,
              Campos: Data,
              EventoRegra: 'Ao carregar app',
              PreValidacaoExibicao: true,
            },
          }

          let ret = await apiWrapper.put('api/v1/Dynamic/executarPreValidacoes', obj)
          let results = ret.data.data as Array<string>

          let result = results.find((x) => x.startsWith(`${_action.menuAppFieldsRule?.id} -`))
          if (!result?.includes('SUCESSO')) {
            _action.biVisible = false
          }
        }
      }

      setActions(_actions)
    })
  }

  const handleLoadTarefaAprovacao = async () => {
    let tarefaRequest = await apiWrapper.get(`api/v1/Tarefa/existeTarefaAprovacaoItemDinamico?item.AccessCode=${accessCode}&item.ID=${id}`)

    if (tarefaRequest.data.data != null) {
      handleOpenModalAvisoTarefa(tarefaRequest.data.data.ID)
    }
  }

  /**
   * Verifica se todos os campos estão válidos, campos não válidps são informados com um erro na tentativa de salvamento.
   * @returns void
   */
  const handleValidate = () => {
    let isValid = true
    let message: Array<string> = []

    for (let index = 0; index < Data.length; index++) {
      let item = Data[index]

      if (
        !acaoEmMassa &&
        item.obrigatorio &&
        ((item.tipo == 'upload' && item.arquivos.length == 0) || (item.tipo != 'upload' && (item.valor == null || item.valor == '')))
      ) {
        isValid = false
        message.push(`O campo ${item.label} é obrigatório`)

        setData((prev) => {
          prev[index].isValid = false
          prev[index].invalidMessage = 'Campo obrigatório'
          return [...prev]
        })
      }
    }

    if (acaoEmMassa && Data.find((x) => x.salvarAlteracoes) == null) {
      isValid = false
      message.push(`Você precisa marcar ao menos um campo para ser salvo.`)
    }

    if (!isValid) {
      message.forEach((item: any) => {
        toast.error(item)
      })
    }

    return isValid
  }

  const execProc = async (field: any): Promise<string> => {
    let request = {
      item: {
        AccessCode: accessCode,
        ID: DataID,
        IDMenuAppField: field.id,
        Campos: Array(field),
      },
    }

    let ret = await apiWrapper.post('api/v1/Dynamic/executarRegrasDeCampo', request)

    if (ret.data.mensagens.length > 0) {
      for (const msg of ret.data.mensagens) {
        if (msg == 'SUCESSO' || (ret.data.data != null && ret.data.data[0] != null && msg == ret.data.data[0].valor)) {
          return ret.data.data[0].valor
        }
        toast.error(msg)
      }
    }
    return ''
  }

  /**
   * Função de inicialização do componente, geralmente chamada no primeiro useEffect.
   * @returns void
   */
  const handleOpen = async (accessCode: string | undefined, id: number) => {
    setOpened(true)
    setAccessCode(accessCode)
    setDataID(id)
    setDataIDs(ids ?? [])

    handleClosePerformingAction()
    setLoading(true)

    if (readonly) {
      setFieldLock(true)
    }

    handleLoadTarefaAprovacao()

    // Carregamos os items extras para as propriedades
    const integrationRequest = await apiWrapper.get('api/v1/MenuAppIntegration/CarregarPorIdentificador?item.Identificador=MAPBOX')
    if (integrationRequest.data.data != null) {
      setMAPTOKEN(integrationRequest.data.data.token)
    }

    if (!acessoExterno) {
      if (readonly) {
        setPermissions({biSelect: true, biInsert: false, biManage: false, biDelete: false, biUpdate: false})
      } else {
        await apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=' + accessCode).then((response) => {
          setPermissions(response.data.data)
        })
      }

      await apiWrapper.get(`/api/v1/ElapsedFollowUpTimer/carregarAtivoPorMenuAppGenericValue?item.IDMenuAppGenericValue=${id}`).then((response) => {
        setElapsedFollowUpTimer(response.data.data)
      })
    } else {
      // Acesso externo tem permissões pré definidas
      setPermissions({biSelect: true, biInsert: true, biManage: false, biDelete: false, biUpdate: true})
    }

    // Carregamos o objeto do app da rota
    apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${accessCode}`).then(async (response) => {
      const baseMenuApp = response.data.data
      setAppInfo((prevAppInfo) => ({
        ...prevAppInfo,
        icon: baseMenuApp.icone,
      }))
    })

    // Carregamos os campos estruturados
    apiWrapper
      .get(
        `api/v1/Dynamic/carregarCamposEstruturados${acessoExterno ? 'Publico' : ''}?item.AccessCode=${accessCode}&item.ID=${id}&item.AcaoEmMassa=${
          acaoEmMassa ? 'true' : 'false'
        }`
      )
      .then(async (response) => {
        let campos: Array<CampoEstruturadoResult> = response.data.data

        const isParsableAsInt = (value) => {
          return /^-?\d+$/.test(value) // Strict check for integer
        }

        const getFieldValue = (campo) => {
          const valor = campo?.valor
          return !valor || isParsableAsInt(valor) ? campo?.opcoes[0]?.label ?? valor : valor
        }

        setAppInfo((prevAppInfo) => ({
          ...prevAppInfo,
          field1: getFieldValue(campos[0]),
          field2: getFieldValue(campos[1]),
          field3: getFieldValue(campos[2]),
        }))

        // INICAMOS WORKFLOW DIFERENTE APENAS PARA CUSTOM PAGE
        if (response.data.customPage) {
          setCustomPageFile(response.data.data)

          $('#contentbind').load(response.data.data.urlDownload, () => {
            window.APP_Start(accessCode as string, id, process.env.REACT_APP_BST_API_URL, GetAccessToken, {
              jQuery: $,
              navigate: navigate,
              closeModal: handleCallBack,
              successMSG: toast.success,
              errorMSG: toast.error,
              warnMSG: toast.warning,
              hideButtons: setHiddenButtons,
            })
          })

          handleLoadActions(true)
          setLoading(false)

          return
        }

        //MONTAMOS OS ACORDEONS
        let accordions: Array<string> = []

        for (const field of campos) {
          if (field.agrupador != null && field.agrupador != '' && accordions.find((x) => x == field.agrupador) == null) {
            accordions.push(field.agrupador)
          }

          // Lockamos o modal em caso de campos de sistema de items protegidos
          if (
            accessCode == 'SYSROLE' &&
            field.campo.toLowerCase() == 'identificador' &&
            (field.valor == 'SADMIN' || field.valor == 'ADMIN' || field.valor == 'USERALL')
          ) {
            setFieldLock(true)
          }

          //DISPARAMOS AS MENSAGENS DE ERRO QUE ACONTECERAM NA HORA DE CARREGAR OS CAMPOS NO BACK (GERALMENTE ERROS DE REGRAS QUE SÃO EXECUTADAS SOMENTE PELO BACKEND)
          if (field.errosInternos.length > 0) {
            for (const error of field.errosInternos) {
              toast.error(error.response?.data?.mensagem ?? error.message)
            }
          }
        }

        setDataAccordions(accordions.sort())

        if (relationItem != null) {
          let campoDeRelacao = campos.find((x) => x.campo == relationItem.menuAppField.campo)

          if (campoDeRelacao != null) {
            campoDeRelacao.valor = relationItemID?.toString() ?? ''
          }
        }

        //REGRAS DO APP
        let appRulesReq = await apiWrapper.get(
          `api/v1/MenuAppFieldsRule/listarPorMenuApp${acessoExterno ? 'Publico' : ''}?item.accessCode=${accessCode}`
        )

        let appRules = appRulesReq.data.data

        // Executamos as regras de app
        for (const rule of appRules) {
          if (rule.evento == 'Ao abrir formulario') {
            if (rule.acao == 'Bloquear Campos') {
              if (rule.atributo01 == 'ALL') {
                setFieldLock(true)
              } else if (rule.atributo02 != '') {
                let camposABloquear = JSON.parse(rule.atributo02) as Array<number>

                for (let item of camposABloquear) {
                  let campo = campos.find((x) => x.id == item)

                  if (campo != null) {
                    campo.somenteLeitura = true
                  }
                }
              }
            } else if (rule.acao == 'Ocultar Campos') {
              let aoCriar = rule.atributo02 === 'true'
              let aoEditar = rule.atributo03 === 'true'

              if (rule.atributo01 != '' && ((aoCriar && id == 0) || (aoEditar && id != 0))) {
                let camposAOcultar = JSON.parse(rule.atributo01) as Array<number>

                for (let item of camposAOcultar) {
                  let campo = campos.find((x) => x.id == item)

                  if (campo != null) {
                    campo.exibir = false
                  }
                }
              }
            } else if (rule.acao == 'Preencher Valor' || rule.acao == 'Preencher um campo do formulário') {
              let aoCriar = rule.atributo02 === 'true'
              let aoEditar = rule.atributo03 === 'true'

              let campo = campos.find((x) => x.id == rule.idmenuAppField)
              if (campo && rule.atributo01 != '' && ((aoCriar && id == 0) || (aoEditar && id != 0))) {
                switch (rule.atributo01) {
                  case 'Específico':
                    campo.valor = rule.valor
                    break
                  case 'Função':
                    break
                  case 'Procedimento':
                    execProc(campo).then((value: string) => {
                      if (campo) campo.valor = value || ''
                    })
                    break

                  default:
                    break
                }
              }
            } else if (rule.acao == 'Executar ação de estrutura') {
              if (rule.atributo04 == '') {
                continue
              }

              let campo: any = null
              let result = campos
              let fields = JSON.parse(rule.atributo04)

              if (fields.length > 1) {
                let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/retornarObjetoRecursivo`, {
                  item: {ID: rule.id, Campos: campos},
                })

                if (ret.data.mensagem != 'SUCCESS') {
                  continue
                }

                result = ret.data.data
                campo = result.find((x) => x.campo.toLowerCase() == ret.data.campo.toLowerCase())
              } else {
                campo = result.find((x) => x.id == fields[0])
              }

              if (result == null || campo == null) {
                continue
              }

              if (rule.atributo05 == 'ACAO') {
                if (
                  (rule.atributo06 == 'Igual' && campo.valor == rule.valor) ||
                  (rule.atributo06 == 'Diferente' && campo.valor != rule.valor) ||
                  (rule.atributo06 == 'Não está preenchido' && campo.valor == '') ||
                  (rule.atributo06 == 'Está preenchido' && campo.valor != '') ||
                  (rule.atributo06 == 'Contém' && campo.valor.contains(rule.valor)) ||
                  (rule.atributo06 == 'Não contém' && !campo.valor.contains(rule.valor)) ||
                  (rule.atributo06 == 'Maior que' && parseFloat(campo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo06 == 'Menor que' && parseFloat(campo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo06 == 'Maior ou igual a' && parseFloat(campo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo06 == 'Menor ou igual a' && parseFloat(campo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo07 == 'OCULTAR') {
                    if (rule.atributo08 != '') {
                      let camposAOcultar = JSON.parse(rule.atributo08) as Array<number>

                      for (let item of camposAOcultar) {
                        let campo = campos.find((x) => x.id == item)

                        if (campo != null) {
                          campo.exibir = false
                        }
                      }
                    }
                  } else if (rule.atributo07 == 'BLOQUEAR') {
                    let camposABloquear = JSON.parse(rule.atributo08) as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.somenteLeitura = true
                      }
                    }
                  } else if (rule.atributo07 == 'LIMPAR') {
                    let camposABloquear = JSON.parse(rule.atributo08) as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.valor = ''
                      }
                    }
                  }
                } else {
                  if (rule.atributo07 == 'OCULTAR') {
                    if (rule.atributo08 != '') {
                      let camposAOcultar = JSON.parse(rule.atributo08) as Array<number>

                      for (let item of camposAOcultar) {
                        let campo = campos.find((x) => x.id == item)

                        if (campo != null) {
                          campo.exibir = true
                        }
                      }
                    }
                  } else if (rule.atributo07 == 'BLOQUEAR') {
                    let camposABloquear = JSON.parse(rule.atributo08) as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.somenteLeitura = false
                      }
                    }
                  }
                }
              } else if (rule.atributo05 == 'APLICAR') {
                let campoParaAplicar = campos.find((x) => x.id == rule.atributo06)

                if (campoParaAplicar != null) {
                  campoParaAplicar.valor = campo.valor
                }
              }
            }
          }

          if (rule.acao == 'Exibir caixa de emails') {
            setEmailGrid(true)
            setEmailGridIntegration(parseInt(rule.atributo01))
          }
        }

        // Executamos regras de campos do inicio do modal
        for (const field of campos) {
          let toConcat = appRules.filter((x) => x.acao == 'Executar ação de estrutura' && x.atributo05 == 'FILTRAR' && x.atributo06 == field.id)
          if (toConcat.length > 0) {
            field.regras = field.regras.concat(toConcat)
          }

          for (const rule of field.regras) {
            if (rule.evento == 'Campo possui valor' && rule.acao == 'Exibir Campos') {
              let campoAlvo = campos.find((item: any) => item.id == (rule.idmenuAppField as number))

              if (campoAlvo != null && campoAlvo.valor != '') {
                if (
                  (rule.atributo01 == 'Igual' && campoAlvo.valor == rule.valor) ||
                  (rule.atributo01 == 'Diferente' && campoAlvo.valor != rule.valor) ||
                  (rule.atributo01 == 'Não está preenchido' && campoAlvo.valor == '') ||
                  (rule.atributo01 == 'Está preenchido' && campoAlvo.valor != '') ||
                  (rule.atributo01 == 'Contém' && campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Não contém' && !campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Maior que' && parseFloat(campoAlvo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor que' && parseFloat(campoAlvo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Maior ou igual a' && parseFloat(campoAlvo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor ou igual a' && parseFloat(campoAlvo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo02 != '') {
                    let camposAExibir = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    for (let item of camposAExibir) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.exibir = true
                      }
                    }
                  }
                }
              }
            }

            if (rule.evento == 'Campo possui valor' && rule.acao == 'Ocultar Campos') {
              let campoAlvo = campos.find((item: any) => item.id == (rule.idmenuAppField as number))

              if (campoAlvo != null && campoAlvo.valor != '') {
                if (
                  (rule.atributo01 == 'Igual' && campoAlvo.valor == rule.valor) ||
                  (rule.atributo01 == 'Diferente' && campoAlvo.valor != rule.valor) ||
                  (rule.atributo01 == 'Não está preenchido' && campoAlvo.valor == '') ||
                  (rule.atributo01 == 'Está preenchido' && campoAlvo.valor != '') ||
                  (rule.atributo01 == 'Contém' && campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Não contém' && !campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Maior que' && parseFloat(campoAlvo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor que' && parseFloat(campoAlvo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Maior ou igual a' && parseFloat(campoAlvo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor ou igual a' && parseFloat(campoAlvo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo02 != '') {
                    let camposAOcultar = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    for (let item of camposAOcultar) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.exibir = false
                      }
                    }
                  }
                }
              }
            }

            if (rule.evento == 'Campo possui valor' && id != 0) {
              if (rule.acao == 'Bloquear Campos') {
                if (
                  field.valor != '' &&
                  ((rule.atributo03 == 'Igual' && field.valor == rule.valor) ||
                    (rule.atributo03 == 'Diferente' && field.valor != rule.valor) ||
                    (rule.atributo03 == 'Não está preenchido' && field.valor == '') ||
                    (rule.atributo03 == 'Está preenchido' && field.valor != '') ||
                    (rule.atributo03 == 'Contém' && field.valor.includes(rule.valor)) ||
                    (rule.atributo03 == 'Não contém' && !field.valor.includes(rule.valor)) ||
                    (rule.atributo03 == 'Maior que' && parseFloat(field.valor) > parseFloat(rule.valor)) ||
                    (rule.atributo03 == 'Menor que' && parseFloat(field.valor) < parseFloat(rule.valor)) ||
                    (rule.atributo03 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(rule.valor)) ||
                    (rule.atributo03 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(rule.valor)))
                ) {
                  if (rule.atributo01 == 'ALL') {
                    setFieldLock(true)
                  } else if (rule.atributo02 != '') {
                    let camposABloquear = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.somenteLeitura = true
                      }
                    }
                  }
                }
              }
            }

            if (rule.evento == 'Campo possui valor' && rule.acao == 'Ocultar custom action') {
              let campoAlvo = campos.find((item: any) => item.id == (rule.idmenuAppField as number))
              if (campoAlvo != null) {
                if (
                  (rule.atributo01 == 'Igual' && campoAlvo.valor == rule.valor) ||
                  (rule.atributo01 == 'Diferente' && campoAlvo.valor != rule.valor) ||
                  (rule.atributo01 == 'Não está preenchido' && campoAlvo.valor == '') ||
                  (rule.atributo01 == 'Está preenchido' && campoAlvo.valor != '') ||
                  (rule.atributo01 == 'Contém' && campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Não contém' && !campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Maior que' && parseFloat(campoAlvo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor que' && parseFloat(campoAlvo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Maior ou igual a' && parseFloat(campoAlvo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor ou igual a' && parseFloat(campoAlvo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo02 != '') {
                    let actionsParaOcultar = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    setActions((prev) => {
                      for (let item of actionsParaOcultar) {
                        let itemAlvo = prev.find((x) => x.id == item)
                        if (itemAlvo != null) {
                          itemAlvo.biVisible = false
                        }
                      }

                      return [...prev]
                    })
                  }
                } else {
                  if (rule.atributo02 != '') {
                    let actionsParaExibir = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    setActions((prev) => {
                      for (let item of actionsParaExibir) {
                        let itemAlvo = prev.find((x) => x.id == item)
                        if (itemAlvo != null) {
                          itemAlvo.biVisible = true
                        }
                      }

                      return [...prev]
                    })
                  }
                }
              }
            }
          }

          //VARIAVEIS DO FRONT
          field.showDecrypted = false
          field.loading = false
          field.isValid = true
          field.invalidMessage = ''
        }

        setAppGeneralRules(appRules)
        setData(campos)

        if (!acessoExterno) {
          handleLoadActions()
        }

        let tipoIntegracao = campos.find((x) => x.campo.toLowerCase() == 'idtipointegration')
        if (accessCode == 'SYSINTEGRATION' && tipoIntegracao != null && tipoIntegracao.valor == '7') {
          verifyWAInstance(true)
        }

        setTimeout(() => {
          handlePopulateGeolocation(campos)
        }, 500)

        if (relationItem != null) {
          let pos = -1

          campos.map((item: any, index: number) => {
            if (item.campo == relationItem.menuAppField.campo) {
              pos = index
            }
          })

          let ids: Array<number> = []

          if (relationItemID != null) {
            ids.push(relationItemID)
          }

          handleOnInputChangeAutoComplete(pos, '', ids, true)
        }

        setTimeout(() => {
          //INCLUSÃO DE FORMATAÇÕES
          //PROCURAMOS TODOS OS COMPONENTES COM BASE NO ID PARA A FORMATAÇÃO

          //CEP
          let cep_element: any = $('input[class*=" cep-"]')
          cep_element.mask('00.000-000')

          //CPF
          let cpf_elements: any = $('input[class*=" cpf-"]')
          cpf_elements.mask('000.000.000-00')

          //CNPJ
          let cnpj_element: any = $('input[class*=" cnpj-"]')
          cnpj_element.mask('00.000.000/0000-00')
        }, 250)
      })
      .finally(() => {
        setLoading(false)
      })

    apiWrapper.get(`api/v1/MenuApp/carregarTituloPorCodigoDeAcesso?item.AccessCode=${accessCode}`).then((response) => {
      if (id == 0) {
        setTitle(response.data.titulo + (acaoEmMassa ? ' - Edição em massa' : ' - Adicionar'))
      } else {
        setTitle(response.data.titulo + (readonly ? ' - Visualizar' : ' - Editar'))
      }
    })

    if (id != 0) {
      apiWrapper.get('api/v1/MenuAppRelacao/listarParaExibicao?item.AccessCode=' + accessCode).then((response) => {
        setRelations(response.data.data)
      })
    }
  }

  /**
   * Função de fechamento de modal, aqui gerenciamos todos os tratamentos de pré-salvamento e o salvamento em si
   * @param save Salvar dados
   * @param keepOpen Manter o modal aberto após salvar os dados
   * @param eventoDeAcoes (Opcional) Nome do evento de ação customizada
   * @returns void
   */
  const handleClose = async (save: boolean, keepOpen: boolean, eventoDeAcoes?: string) => {
    if (CustomPageFile != null) {
      if (save) {
        setSaving(true)
        let saved = keepOpen ? await window.APP_Save() : await window.APP_SaveAndClose()
        setSaving(false)

        if (saved > 0) {
          handleCallBack({id: saved, keepOpen: keepOpen, customPage: true})
        }
      } else {
        window.APP_Cancel()
        handleCallBack()
      }

      return
    } else if (!save) {
      handleDeleteAllUploads()

      if (!keepOpen) {
        setOpened(false)
        setData([])
      } else {
        setData([])
        handleOpen(accessCode, DataID)
        return
      }

      handleCallBack()
      return
    }

    if (Data == null || !handleValidate()) {
      return
    }

    let regrasConfirmacao = appGeneralRules.filter(
      (x) =>
        ((x.evento == 'Ao criar registro' && DataID == 0) || (x.evento == 'Ao atualizar registro' && DataID != 0)) && x.acao == 'Exibir confirmacao'
    )

    let _regrasConfirmacao: any = null

    if (regrasConfirmacao.length > 0 && !acaoEmMassa) {
      handleOpenPerformingAction('Executando regras e salvando registro, aguarde...')

      if (eventoDeAcoes == null) {
        setValidating(true)

        let obj = {
          item: {
            ID: DataID,
            AccessCode: accessCode,
            Campos: Data,
          },
        }

        let ret = await apiWrapper.put('api/v1/Dynamic/executarPreValidacoes', obj)
        let results = ret.data.data as Array<string>

        setValidating(false)

        for (let regra of regrasConfirmacao) {
          let result = results.find((x) => x.startsWith(`${regra.id} -`))

          if (result?.includes('SUCESS')) {
            _regrasConfirmacao = regra
            setRegraConfirmacao(regra)
            break
          }
        }

        if (_regrasConfirmacao != null) {
          handleOpenModalConfirmacao(keepOpen, _regrasConfirmacao.atributo01)
          return
        }
      }

      if (eventoDeAcoes != null && eventoDeAcoes != '' && regraConfirmacao != null) {
        let acoes = JSON.parse(regraConfirmacao.atributo10) as Array<CRuleActions>

        for (const acao of acoes.filter((x) => x.event == eventoDeAcoes)) {
          if (acao.action != 'Atualizar Campo') {
            continue
          }

          let campo = Data.find((x) => x.id == acao.campo)
          if (campo == null) {
            continue
          }

          campo.valor = acao.valor
        }
      }
    }

    setSaving(true)

    let obj: any = {
      item: {
        AccessCode: AccessCode,
        Campos: Data,
        AcaoEmMassa: acaoEmMassa,
        IDMenuAppFieldRule: regraConfirmacao?.id,
        EventoDeAcoes: eventoDeAcoes ?? '',
      },
    }

    if (acaoEmMassa) {
      obj.item.IDs = DataIDs
    } else {
      obj.item.IDs = [DataID]
    }

    if (WAInstanceRef.current != null) {
      updateWAInstanceWebhook()
    }

    apiWrapper
      .put(`api/v1/Dynamic/salvarCamposModal${acessoExterno ? 'Publico' : ''}`, obj)
      .then((response) => {
        setSaving(false)

        let erro = false

        if (DataID == 0) {
          for (const item of response.data.resultados) {
            if (item.item2 != 'SUCESSO') {
              toast.error(item.item2)
              erro = true
            } else {
              toast.success('Registro salvo com sucesso!')
            }
          }
        } else {
          for (const item of response.data.resultados) {
            if (item.item2 != 'SUCESSO') {
              toast.error(`Item ${item.item1}: ${item.item2}`)
              erro = true
            } else {
              if (acaoEmMassa) {
                toast.success(`Item ${item.item1}: Atualizado com sucesso!`)
              } else {
                toast.success('Registro atualizado com sucesso!')
              }
            }
          }
        }

        if (!acaoEmMassa && erro) {
          handleClosePerformingAction()
          return
        }

        if (!keepOpen) {
          setOpened(false)
          setData([])
        } else {
          setData([])
          setDataID(response.data.id)
          handleOpen(accessCode, response.data.id)
        }

        handleCallBack({id: response.data.id, keepOpen: keepOpen})
      })
      .catch((error) => {
        setSaving(false)
        toast.error(error.response.data.mensagem)
      })
  }

  /**
   * Função de inicialização do geocode.
   * @returns void
   */
  const handlePopulateGeolocation = (data: any, ignoreTimer?: boolean) => {
    for (const field of data) {
      if (field.tipo != 'geolocation') {
        continue
      }

      navigator.geolocation.getCurrentPosition(
        function (position) {
          setData((prev) => {
            let campo = prev.find((x) => x.id == field.id)

            if (campo != null) {
              campo.valor = JSON.stringify({latitude: position.coords.latitude, longitude: position.coords.longitude})
            }

            return [...prev]
          })

          if (geocodeIntervalID != null) {
            clearInterval(geocodeIntervalID)
          }

          handleClosePerformingAction()
        },
        function (positionError) {
          if (!field.obrigatorio || ignoreTimer) {
            return
          }

          toast.error('É necessário permitir a localização para este app!')

          let id = setInterval(() => {
            handlePopulateGeolocation(data, true)
          }, 500)

          setGeocodeIntervalID(id)
          handleOpenPerformingAction('Aguardando permissão de localização')
        }
      )
    }
  }

  /**
   * Função que controla o estado das seleções das tabs
   * @param event (Não utilizado) Evento do controle que muda as tabs
   * @param value Valor da tab a ser selecionada
   * @returns void
   */
  const handleTabIndexChange = (event: any, value: number) => {
    setTabIndex(value)

    switch (value) {
      case -1:
        break
      case -2:
        let element = document.getElementById('htmlContentBind')
        if (element != null) {
          element.innerHTML = emailProps.content
        }
        break
    }
  }

  /**
   * Função que chama a função call back de exclusão de items
   * @returns void
   */
  const handleOpenExcluir = () => {
    deleteFunc(DataID, AccessCode)
  }

  /**
   * Função simples para travar todos os floats do modal.
   * @param value Input para travar
   * @returns number
   */
  const handleClampFloat = (value: number, min: number = -9999999.99, max: number = 9999999.99) => {
    return SafeClamp(value, min, max)
  }

  /**
   * Função simples para travar todos os numeros do modal.
   * @param value Input para travar
   * @returns number
   */
  const handleClampNumber = (value: number) => {
    return SafeClamp(value, -2147483647, 2147483647)
  }

  /**
   * Função geral que aplica um valor final na propriedade do campo estruturado no index informado e logo após executa as regras do campo.
   * @param index Posição do campo estruturado na variável de estado
   * @param value Valor a ser aplicado
   * @returns void
   */
  const handleOnChange = (index: number, value: string) => {
    // Atualizamos o estado com o novo valor
    setData((prev) => {
      let field = prev[index]

      if (DataID != 0 && field.valorAnterior == '') {
        field.valorAnterior = field.valor
      }

      // Aplicamos o valor de latitude e longitude no campo de mapa caso o mesmo existe e esteja com o campo do contexto vinculado
      if (field.tipo == 'geocode') {
        let linkedMapFields = prev.filter((x) => x.configuracao.idmenuAppFieldLat == field.id || x.configuracao.idmenuAppFieldLon == field.id)

        for (const item of linkedMapFields) {
          if (item.configuracao.idmenuAppFieldLat == field.id) {
            let mapJson = JSON.parse(item.valor != '' ? item.valor : MAPDEFAULTCOORDINATES)
            mapJson.latitude = parseFloat(value)
            item.valor = JSON.stringify(mapJson)
          }

          if (item.configuracao.idmenuAppFieldLon == field.id) {
            let mapJson = JSON.parse(item.valor != '' ? item.valor : MAPDEFAULTCOORDINATES)
            mapJson.longitude = parseFloat(value)
            item.valor = JSON.stringify(mapJson)
          }
        }
      }

      field.valor = value

      field.isValid = true
      field.invalidMessage = ''

      return [...prev]
    })

    // Executamos as regras do campo
    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo possui valor')
    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo mudar de valor')
  }

  /**
   * Função que muda o valor de um auto complete e escolhe se é necessário abrir um segundo modal caso o usuário clicou em "Adicionar registro" no drop down.
   * @param index Posição do campo estruturado na variável de estado
   * @param value Valor a ser aplicado
   * @returns void
   */
  const handleOnChangeAutoComplete = (index: any, value: any) => {
    if (value == null || value.id != -1) {
      handleOnChange(index, value != null ? value.id.toString() : '')
    }

    if (value != null && value.id == -1) {
      handleOpenModalSingleSelect(index, DataRef.current[index].loopBackAccessCode as string, 0)
    }
  }
  const mapRefs = useRef({})

  const fetchAddress = (index, lng, lat) => {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAPTOKEN}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.features && data.features.length > 0) {
          updateMapData(index, {address: data.features[0].place_name})
        }
      })
      .catch((error) => console.error('Error fetching address:', error))
  }

  const handleMapClick = (index, lngLat) => {
    const {lng, lat} = lngLat
    updateMapData(index, {longitude: lng, latitude: lat})
    updateRelatedFields(index, 'latitude', lat)
    updateRelatedFields(index, 'longitude', lng)
    fetchAddress(index, lng, lat)
    handleFieldRule(Data, Data[index], 'Campo possui valor')
    handleFieldRule(Data, Data[index], 'Campo mudar de valor')
  }

  const handleOnMoveMap = (index, viewState) => {
    updateMapData(index, {mapCenter: viewState})
  }

  const updateMapData = (index, newData) => {
    setData((prev) => {
      const updatedData = [...prev]
      const currentData = JSON.parse(updatedData[index].valor || MAPDEFAULTCOORDINATES)
      updatedData[index].valor = JSON.stringify({...currentData, ...newData})
      return updatedData
    })
  }

  const updateRelatedFields = (index, key, value) => {
    setData((prev) => {
      const updatedData = [...prev]

      if (key === 'latitude' && prev[index].configuracao.idmenuAppFieldLat) {
        const latField = updatedData.find((x) => x.id === prev[index].configuracao.idmenuAppFieldLat)
        if (latField) latField.valor = value.toString()
      }
      if (key === 'longitude' && prev[index].configuracao.idmenuAppFieldLon) {
        const lonField = updatedData.find((x) => x.id === prev[index].configuracao.idmenuAppFieldLon)
        if (lonField) lonField.valor = value.toString()
      }

      return updatedData
    })
  }

  /**
   * Função de update de JSON de campo de mapa.
   * @param index Posição do campo estruturado na variável de estado
   * @param key Propriedade do JSON
   * @param value valor para aplicar ao JSON
   * @returns void
   */
  const handleChangeMapJSONValue = (index: number, key: string, value: any) => {
    setData((prev) => {
      let obj = JSON.parse(prev[index].valor != '' ? prev[index].valor : MAPDEFAULTCOORDINATES)

      if (key === 'mapCenter') {
        obj.mapCenter = value
      } else {
        obj[key] = value
      }

      if (key === 'latitude' || key === 'longitude') {
        // Update related fields if they exist
        if (obj.configuracao) {
          if (key === 'latitude' && obj.configuracao.idmenuAppFieldLat) {
            const latField = prev.find((x) => x.id === obj.configuracao.idmenuAppFieldLat)
            if (latField) {
              latField.valor = value.toString()
            }
          }
          if (key === 'longitude' && obj.configuracao.idmenuAppFieldLon) {
            const lonField = prev.find((x) => x.id === obj.configuracao.idmenuAppFieldLon)
            if (lonField) {
              lonField.valor = value.toString()
            }
          }
        }
      }

      prev[index].valor = JSON.stringify(obj)
      return [...prev]
    })

    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo possui valor')
    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo mudar de valor')
  }

  /**
   * Executa as regras do campo de acordo com o contexto solicitado. NOTA: Essa função deve ser chamada dentro de um setData para evitar enfileiramentos desnecessários de edição de estado.
   * @param fields Array com os campos do estado atual na hora da edição
   * @param field Objeto do campo estruturado que terá as regras executadas
   * @param event Evento da regra a ser executada
   * @returns void
   */
  const handleFieldRule = async (fields: Array<CampoEstruturadoResult>, field: CampoEstruturadoResult, event: string) => {
    let regras = field.regras

    if (regras.length == 0) {
      return
    }

    regras = regras.filter((x) => x.evento == event)

    for (const regra of regras) {
      if (regra.acao == 'Exibir Campos') {
        if (
          regra.atributo02 != '' &&
          ((regra.atributo01 == 'Igual' && field.valor == regra.valor) ||
            (regra.atributo01 == 'Diferente' && field.valor != regra.valor) ||
            (regra.atributo01 == 'Não está preenchido' && field.valor == '') ||
            (regra.atributo01 == 'Está preenchido' && field.valor != '') ||
            (regra.atributo01 == 'Contém' && field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Não contém' && !field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor)))
        ) {
          let camposAExibir = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

          for (let item of camposAExibir) {
            let campo = fields.find((x) => x.id == item)

            if (campo != null) {
              campo.exibir = true
            }
          }
        }
      }

      if (regra.acao == 'Ocultar Campos') {
        if (
          regra.atributo02 != '' &&
          ((regra.atributo01 == 'Igual' && field.valor == regra.valor) ||
            (regra.atributo01 == 'Diferente' && field.valor != regra.valor) ||
            (regra.atributo01 == 'Não está preenchido' && field.valor == '') ||
            (regra.atributo01 == 'Está preenchido' && field.valor != '') ||
            (regra.atributo01 == 'Contém' && field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Não contém' && !field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor)))
        ) {
          let camposAOcultar = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

          for (let item of camposAOcultar) {
            let campo = fields.find((x) => x.id == item)

            if (campo != null) {
              campo.exibir = false
            }
          }
        }
      }

      if (regra.acao == 'Ocultar custom action') {
        if (
          (regra.atributo01 == 'Igual' && field.valor == regra.valor) ||
          (regra.atributo01 == 'Diferente' && field.valor != regra.valor) ||
          (regra.atributo01 == 'Não está preenchido' && field.valor == '') ||
          (regra.atributo01 == 'Está preenchido' && field.valor != '') ||
          (regra.atributo01 == 'Contém' && field.valor.includes(regra.valor)) ||
          (regra.atributo01 == 'Não contém' && !field.valor.includes(regra.valor)) ||
          (regra.atributo01 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
          (regra.atributo01 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
          (regra.atributo01 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
          (regra.atributo01 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor))
        ) {
          if (regra.atributo02 != '') {
            let actionsParaOcultar = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            setActions((prev) => {
              for (let item of actionsParaOcultar) {
                let itemAlvo = prev.find((x) => x.id == item)
                if (itemAlvo != null) {
                  itemAlvo.biVisible = false
                }
              }

              return [...prev]
            })
          }
        } else {
          if (regra.atributo02 != '') {
            let actionsParaExibir = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            setActions((prev) => {
              for (let item of actionsParaExibir) {
                let itemAlvo = prev.find((x) => x.id == item)
                if (itemAlvo != null) {
                  itemAlvo.biVisible = true
                }
              }

              return [...prev]
            })
          }
        }
      }

      if (regra.acao == 'Buscar Valor') {
        let campoAlvo = fields.find((item: any) => item.id == parseInt(regra.atributo03 as string))
        if (campoAlvo == null) {
          continue
        }

        if (field.valor == '') {
          campoAlvo.valor = ''
          continue
        }

        let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/montarRetornoValor`, {
          item: {ID: regra.id, Campos: fields, IDMenuAppGenericItem: DataID},
        })

        let valores = ret.data.data as Array<any>

        if (valores.length > 0) {
          let cIndex = 0
          let cValor = ''

          if (campoAlvo.tipo == 'singleSelect') {
            if (valores.length == 1) {
              campoAlvo.valor = valores[0]
            }

            let parsed: Array<number> = []

            for (const item of valores) {
              parsed.push(parseInt(item))
            }

            handleOnInputChangeAutoComplete(fields.indexOf(campoAlvo), '', parsed, true, true)
          } else if (campoAlvo.tipo == 'decimal') {
            let newValue = parseFloat(valores[0].replace(',', '.'))
            campoAlvo.valor = handleClampFloat(newValue).toFixed(2)
          } else if (campoAlvo.tipo == 'number') {
            let newValue = parseInt(valores[0])
            campoAlvo.valor = isNaN(newValue) ? '' : newValue.toString()
          } else if (campoAlvo.tipo == 'boolean') {
            campoAlvo.valor = valores[0].toLowerCase()
            cValor = campoAlvo.valor
            cIndex = fields.indexOf(campoAlvo)
          } else {
            campoAlvo.valor = valores[0]
          }
          if (cValor != '') {
            handleOnChange(cIndex, cValor == 'true' ? 'true' : 'false')
          }
        }
      }

      if (regra.acao == 'Calcular Campos') {
        let operations: Array<CCalcFieldRule> = JSON.parse(regra.formula)
        let toEval: string = ''

        for (const operation of operations) {
          let campoAlvo = fields.find((item: any) => item.id == operation.campo)
          if (campoAlvo == null || campoAlvo.valor == '') continue

          if (operation.operador == '#') {
            operation.operador = ''
          }

          toEval = `${toEval} ${operation.operador} ${campoAlvo.valor}`
        }

        let result: any = Function(`'use strict'; return (${toEval})`)()

        let campoParaAplicar = fields.find((item: any) => item.id == parseInt(regra.atributo01 as string))

        if (campoParaAplicar != null) {
          if (campoParaAplicar.tipo == 'number') {
            campoParaAplicar.valor = result.toString()
          } else if (campoParaAplicar.tipo == 'decimal') {
            campoParaAplicar.valor = handleClampFloat(result).toFixed(2)
          }
        }
      }

      if (regra.acao == 'Bloquear Campos') {
        if (
          (regra.atributo03 == 'Igual' && field.valor == regra.valor) ||
          (regra.atributo03 == 'Diferente' && field.valor != regra.valor) ||
          (regra.atributo03 == 'Não está preenchido' && field.valor == '') ||
          (regra.atributo03 == 'Está preenchido' && field.valor != '') ||
          (regra.atributo03 == 'Contém' && field.valor.includes(regra.valor)) ||
          (regra.atributo03 == 'Não contém' && !field.valor.includes(regra.valor)) ||
          (regra.atributo03 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
          (regra.atributo03 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
          (regra.atributo03 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
          (regra.atributo03 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor))
        ) {
          if (regra.atributo01 == 'ALL') {
            setFieldLock(true)
          } else if (regra.atributo02 != '') {
            let camposABloquear = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            for (let item of camposABloquear) {
              let campo = fields.find((x) => x.id == item)

              if (campo != null) {
                campo.somenteLeitura = true
              }
            }
          }
        } else {
          if (regra.atributo01 == 'ALL') {
            setFieldLock(false)
          } else if (regra.atributo02 != '') {
            let camposABloquear = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            for (let item of camposABloquear) {
              let campo = fields.find((x) => x.id == item)

              if (campo != null) {
                campo.somenteLeitura = false
              }
            }
          }
        }
      }

      if (regra.acao == 'Executar ação de estrutura') {
        if (regra.atributo04 == '') {
          continue
        }

        let campo: any = null
        let result = fields
        let ids = JSON.parse(regra.atributo04 ?? '[]')

        if (ids.length > 1) {
          let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/retornarObjetoRecursivo`, {
            item: {ID: regra.id, Campos: result},
          })

          if (ret.data.mensagem != 'SUCCESS') {
            continue
          }

          result = ret.data.data
          campo = result.find((x) => x.campo.toLowerCase() == ret.data.campo.toLowerCase())
        } else {
          campo = result.find((x) => x.id == ids[0])
        }

        if (result == null || campo == null) {
          continue
        }

        if (regra.atributo05 == 'ACAO') {
          if (
            (regra.atributo06 == 'Igual' && campo.valor == regra.valor) ||
            (regra.atributo06 == 'Diferente' && campo.valor != regra.valor) ||
            (regra.atributo06 == 'Não está preenchido' && campo.valor == '') ||
            (regra.atributo06 == 'Está preenchido' && campo.valor != '') ||
            (regra.atributo06 == 'Contém' && campo.valor.contains(regra.valor)) ||
            (regra.atributo06 == 'Não contém' && !campo.valor.contains(regra.valor)) ||
            (regra.atributo06 == 'Maior que' && parseFloat(campo.valor) > parseFloat(regra.valor)) ||
            (regra.atributo06 == 'Menor que' && parseFloat(campo.valor) < parseFloat(regra.valor)) ||
            (regra.atributo06 == 'Maior ou igual a' && parseFloat(campo.valor) >= parseFloat(regra.valor)) ||
            (regra.atributo06 == 'Menor ou igual a' && parseFloat(campo.valor) <= parseFloat(regra.valor))
          ) {
            if (regra.atributo07 == 'OCULTAR') {
              if (regra.atributo08 != '') {
                let camposAOcultar = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

                for (let item of camposAOcultar) {
                  let campo = fields.find((x) => x.id == item)

                  if (campo != null) {
                    campo.exibir = false
                  }
                }
              }
            } else if (regra.atributo07 == 'BLOQUEAR') {
              let camposABloquear = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

              for (let item of camposABloquear) {
                let campo = fields.find((x) => x.id == item)

                if (campo != null) {
                  campo.somenteLeitura = true
                }
              }
            } else if (regra.atributo07 == 'LIMPAR') {
              let camposABloquear = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

              for (let item of camposABloquear) {
                let campo = fields.find((x) => x.id == item)

                if (campo != null) {
                  campo.valor = ''
                }
              }
            }
          } else {
            if (regra.atributo07 == 'OCULTAR') {
              if (regra.atributo08 != '') {
                let camposAOcultar = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

                for (let item of camposAOcultar) {
                  let campo = fields.find((x) => x.id == item)

                  if (campo != null) {
                    campo.exibir = true
                  }
                }
              }
            } else if (regra.atributo07 == 'BLOQUEAR') {
              let camposABloquear = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

              for (let item of camposABloquear) {
                let campo = fields.find((x) => x.id == item)

                if (campo != null) {
                  campo.somenteLeitura = false
                }
              }
            }
          }
        } else if (regra.atributo05 == 'APLICAR') {
          let campoParaAplicar = fields.find((x) => x.id == parseInt(regra.atributo06 as string))

          if (campoParaAplicar != null) {
            campoParaAplicar.valor = campo.valor
          }
        }
      }

      if (regra.acao == 'Executar procedure validacao') {
        let request = {
          item: {
            AccessCode: accessCode,
            ID: DataID,
            IDMenuAppField: field.id,
            Campos: fields,
          },
        }

        let ret = await apiWrapper.post('api/v1/Dynamic/executarRegrasDeCampo', request)

        if (ret.data.mensagens.length > 0) {
          for (const msg of ret.data.mensagens) {
            if (msg == 'SUCESSO') {
              continue
            }

            toast.error(msg)
          }
        }

        fields = ret.data.data
      }
    }

    setData(fields)
  }

  /**
   * Carrega os items do auto complete baseado nos dados informados
   * @param fields Array com os campos do estado atual na hora da edição
   * @param field Objeto do campo estruturado que terá as regras executadas
   * @param event Evento da regra a ser executada
   * @returns void
   */
  const handleOnInputChangeAutoComplete = async (
    index: number,
    value: any,
    ids: Array<number> = [],
    applySingleValue: boolean = false,
    ignoreFilter: boolean = false,
    ignoreLoading: boolean = false
  ) => {
    // Jogamos o campo num estado de carregamento assim o componente trava e mostra o loading
    if (!ignoreLoading) {
      setData((prev) => {
        prev[index].loading = true
        return [...prev]
      })
    }

    let filter = ''
    let _ids: string = ''

    //TODO: Checar se ainda precisamos desse filtro
    if (!ignoreFilter) {
      filter = (await getFilter(DataRef.current[index].campo)) ?? ''
    }

    if (ids.length > 0) {
      for (const item of ids) {
        if (isNaN(item)) {
          continue
        }

        _ids = `${_ids != '' ? `${_ids},` : ''}${item}`
      }
    }

    let obj: any = {
      item: {
        AccessCode: accessCode,
        IDMenuApp: 0,
        IDMenuAppField: DataRef.current[index].id,
        Campo: DataRef.current[index].campo,
        Query: value,
        Filtro: filter,
        IDs: _ids,
        Campos: DataRef.current,
      },
      PaginaAtual: 0,
      RegistroPorPagina: 15,
    }

    apiWrapper
      .post(`api/v1/Dynamic/listarSingleSelectCampoFormulario${acessoExterno ? 'Publico' : ''}`, obj)
      .then((response) => {
        let opcoes: Array<SingleSelectResult> = response.data.data

        setData((prev) => {
          prev[index].loading = false

          // Caso o valor ja esteja lá então não repopulamos as opções
          let valueFromString = prev[index].opcoes.find((item: any) => item.label == value)
          if (valueFromString != null && prev[index].valor == valueFromString.id.toString()) {
            return [...prev]
          }

          prev[index].opcoes = []

          // Caso tenhamos opções então populamos o estado com elas senão caso o usuário tenha permissão adicionamos a opção de criar um registro
          if (opcoes.length > 0) {
            opcoes.map((item: any) => {
              prev[index].opcoes.push({id: item.id, label: item.label})
            })
          } else if (prev[index].permissoes.biInsert) {
            prev[index].opcoes.push({id: -1, label: 'Adicionar Registro'})
          }

          if (prev[index].opcoes.filter((item: any) => item.id == prev[index].valor).length == 0) {
            if (prev[index].valorAnterior == '') {
              prev[index].valorAnterior = prev[index].valor
            }

            prev[index].valor = ''
          }

          return [...prev]
        })

        if (opcoes.length == 1 && applySingleValue) {
          handleOnChangeAutoComplete(index, opcoes[0])
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })
  }

  /**
   * Função de abertura do auto complete.
   * @param index Index do campo estruturado que o auto-complete está vinculado
   * @param value Valor para verificar se é necessário chamar a função de onInputChange
   * @returns void
   */
  const handleOnOpenAutoComplete = (index: number, event: any) => {
    if (event == null || event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete(index, '')
    }
  }

  /**
   * Define o estado inicial do editor de templates
   * @returns void
   */
  const handleOnEditorStartup = (editor: any, disabled?: boolean, initialData: any = null) => {
    editor.editing.view.change((writer) => {
      writer.setStyle('minHeight', '400px', editor.editing.view.document.getRoot() as any)
      writer.setStyle('width', '684px', editor.editing.view.document.getRoot() as any)
    })

    const toolbarElement = editor.ui.view.toolbar.element as HTMLElement

    if (disabled) {
      toolbarElement.style.display = 'none'
    }

    if (initialData != null) {
      editor.setData(initialData)
    }
  }

  /**
   * Função handler chamada quando o componente de upload recebe o arquivo selecionado
   * @param index Posição do campo estruturado no estado atual
   * @param newAzFile Arquivo que acabou de ser enviado para a Azure
   * @returns void
   */
  const handleOnUpload = (index: number, newAzFile: any) => {
    setData((prev) => {
      prev[index].arquivos = [...prev[index].arquivos, newAzFile]
      return [...prev]
    })
  }

  /**
   * Função handler que delete o arquivo no index informado
   * @param index Posição do campo estruturado no estado atual
   * @param AzFile Arquivo existente para ser apagado
   * @returns void
   */
  const handleOnDeleteUpload = (index: number, AzFile: any) => {
    let obj: any = {
      item: {
        Nome: AzFile.nome,
      },
    }

    apiWrapper.delete(`api/v1/Storage/ApagarArquivoAz${acessoExterno ? 'Publico' : ''}`, {data: obj, headers: {}})

    setData((prev) => {
      prev[index].arquivos = prev[index].arquivos.filter((item: any) => item != AzFile)
      return [...prev]
    })
  }

  /**
   * Função handler que deleta todos os arquivos de um campo de upload
   * @returns void
   */
  const handleDeleteAllUploads = () => {
    DataRef.current.map((item: any, index: number) => {
      if (item.tipo != 'upload') {
        return
      }

      item.arquivos.forEach((arquivo) => {
        if (arquivo.id != 0) {
          return
        }

        handleOnDeleteUpload(index, arquivo)
      })
    })
  }

  /**
   * Monta um filtro para listagem de campos singleSelect
   * @param campo Nome do campo de contexto
   * @returns string
   */
  const getFilter = async (campo: string) => {
    let filter = ''

    const contextField = DataRef.current.find((item: any) => item.campo == campo)

    if (contextField == null) {
      return filter
    }

    const fieldsComRegra = DataRef.current.filter((item: any) => item.regras.length > 0)

    for (const field of fieldsComRegra) {
      let regras = field.regras

      for (const regra of regras) {
        if (regra.acao == 'Executar ação de estrutura' && regra.atributo05 == 'FILTRAR' && regra.atributo06 == contextField.id.toString()) {
          if (regra.atributo04 == '') {
            continue
          }

          let campo: any = null
          let result = DataRef.current
          let fields = JSON.parse(regra.atributo04 ?? '[]')

          if (fields.length > 1) {
            let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/retornarObjetoRecursivo`, {
              item: {ID: regra.id, Campos: DataRef.current},
            })

            if (ret.data.mensagem != 'SUCCESS') {
              continue
            }

            result = ret.data.data
            campo = result.find((x) => x.campo.toLowerCase() == ret.data.campo.toLowerCase())
          } else {
            campo = result.find((x) => x.id == fields[0])
          }

          if (result == null || campo == null) {
            continue
          }

          let ret = await apiWrapper.get(`api/v1/MenuAppFields/carregar?item.ID=${regra.atributo07}`)
          return `${ret.data.data.campo} = ${campo.valor}`
        }

        if (regra.acao != 'Buscar Valor' || regra.atributo03 != contextField.id.toString() || field.valor == '') {
          continue
        }

        let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/montarRetornoValor`, {
          item: {ID: regra.id, Campos: Data, IDMenuAppGenericItem: DataID},
        })

        let IDs = ret.data.data as Array<any>

        if (IDs.length > 0) {
          let _ids: string = ''

          for (let _id of IDs) {
            if (_id == '') {
              continue
            }

            _ids = `${_ids != '' ? `${_ids},` : ''}${_id}`
          }

          return _ids != '' ? `ID IN (${_ids})` : ''
        }
      }
    }

    return filter
  }

  /**
   * Verifica se algum campo ou o contexto geral do modal está editável
   * @param field (Opcional) Campo estruturado para verificar as propriedades
   * @returns boolean
   */
  const isEditable = (field?: CampoEstruturadoResult) => {
    if (field != null) {
      if (fieldLock) {
        return false
      }

      if (field.somenteLeitura) {
        return false
      }

      if (!field.permissoesCampo.biUpdate) {
        return false
      }

      if (acaoEmMassa && !field.salvarAlteracoes) {
        return false
      }

      if (relationItem != null && !Saving) {
        return field.campo != relationItem.menuAppField.campo
      }
    }

    return !Saving && !loading
  }

  /* EVOLUTION API */

  const [WAInstance, setWAInstance, WAInstanceRef] = useStateRef<any>(null)
  const [WAInstanceLoading, setWAInstanceLoading] = useState<boolean>(false)
  const [WAInstanceInterval, setWAInstanceInterval, WAInstanceIntervalRef] = useStateRef<any>(null)

  const createWAInstanceInterval = () => {
    let id = setInterval(async () => {
      if (WAInstanceRef.current == null) {
        return
      }

      await verifyWAInstance()
    }, 1500)

    setWAInstanceInterval(id)
  }

  const clearWAInstanceInterval = () => {
    if (WAInstanceIntervalRef.current != null) {
      clearInterval(WAInstanceIntervalRef.current)
      setWAInstanceInterval(null)
    }
  }

  const verifyWAInstance = async (useLoading: boolean = false) => {
    if (useLoading) {
      setWAInstanceLoading(true)
    }

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    let tokenField = DataRef.current.find((x) => x.campo.toLowerCase() == 'token')
    if (tokenField != null) {
      let instances = await axios.get('https://evolutionapi.gym360.com.br/instance/fetchInstances', {headers})
      let instance = (instances.data as Array<any>).find((x) => x.instance.instanceName == tokenField?.valor)

      if (instance != null) {
        setWAInstance((prev) => {
          if (prev == null) {
            prev = instance
          } else {
            prev.instance = instance.instance
          }

          return {...prev}
        })

        if (
          (instance.instance.status == 'connecting' || instance.instance.status == 'close') &&
          WAInstanceRef.current != null &&
          WAInstanceRef.current.qrcode == null
        ) {
          let instanceConnect = await axios.get(`https://evolutionapi.gym360.com.br/instance/connect/${instance.instance.instanceName}`, {headers})

          setWAInstance((prev) => {
            prev.qrcode = instanceConnect.data
            return {...prev}
          })
        } else if (
          instance.instance.status != 'connecting' &&
          instance.instance.status != 'close' &&
          WAInstanceRef.current != null &&
          WAInstanceRef.current.qrcode != null
        ) {
          setWAInstance((prev) => {
            prev.qrcode = undefined
            return {...prev}
          })
        }
      } else {
        setWAInstance(instance)

        if (id != 0) {
          setData((prev) => {
            let tokenField = prev.find((x) => x.campo.toLowerCase() == 'token')
            if (tokenField != null) {
              tokenField.valor = ''
            }

            return [...prev]
          })
        }
      }

      if (WAInstanceIntervalRef.current == null) {
        createWAInstanceInterval()
      }
    }

    if (useLoading) {
      setWAInstanceLoading(false)
    }
  }

  const startNewWAInstance = async () => {
    setWAInstanceLoading(true)

    const instanceToken = uuid()
    const instanceName = instanceToken

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    let obj = {
      instanceName: instanceName,
      token: instanceToken,
      qrcode: true,
    }

    let instance = await axios.post('https://evolutionapi.gym360.com.br/instance/create', obj, {headers})
    setWAInstance(instance.data)

    createWAInstanceInterval()

    setData((prev) => {
      let tokenField = prev.find((x) => x.campo.toLowerCase() == 'token')
      if (tokenField != null) {
        tokenField.valor = instanceToken
      }

      return [...prev]
    })

    setWAInstanceLoading(false)
  }

  const closeWAInstance = async () => {
    let tokenField = DataRef.current.find((x) => x.campo.toLowerCase() == 'token')
    if (tokenField == null) {
      return
    }

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    setWAInstanceLoading(true)

    await axios.delete(`https://evolutionapi.gym360.com.br/instance/logout/${tokenField.valor}`, {headers})
    await axios.delete(`https://evolutionapi.gym360.com.br/instance/delete/${tokenField.valor}`, {headers})

    setWAInstance(null)

    clearWAInstanceInterval()

    setData((prev) => {
      let tokenField = prev.find((x) => x.campo.toLowerCase() == 'token')
      if (tokenField != null) {
        tokenField.valor = ''
      }

      return [...prev]
    })

    setWAInstanceLoading(false)
  }

  const updateWAInstanceWebhook = async () => {
    let tokenField = DataRef.current.find((x) => x.campo.toLowerCase() == 'token')
    if (tokenField == null) {
      return
    }

    let urlField = DataRef.current.find((x) => x.campo.toLowerCase() == 'url')
    if (urlField == null) {
      return
    }

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    let obj = {
      url: urlField.valor,
      webhook_by_events: false,
      webhook_base64: false,
      events: ['QRCODE_UPDATED', 'MESSAGES_UPSERT', 'MESSAGES_UPDATE', 'MESSAGES_DELETE', 'SEND_MESSAGE', 'CONNECTION_UPDATE', 'CALL'],
    }

    await axios.post(`https://evolutionapi.gym360.com.br/webhook/set/${tokenField.valor}`, obj, {headers})
  }

  /* END EVOLUTION API */

  /**
   * Escolhe o componente e seus derivados baseado no tipo e formatação do campo
   * @param item Campo estruturado que receberá o componente
   * @param index Posição do campo estruturado no estado atual
   * @returns O componente escolhido
   */
  const getComponent = (item: CampoEstruturadoResult, index: number) => {
    let control: any = <></>

    switch (accessCode) {
      case 'SYSMEN':
      case 'SYSMENSUB':
        if (item.campo == 'Icone') {
          control = (
            <MenuAppResourceSelect
              id='icone-select'
              label='Ícone'
              labelId='icone-select-label'
              type='duotune'
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
            />
          )

          return control
        }
        break
      case 'SYSTASK':
        if (item.campo.toLowerCase() == 'descricao') {
          let disabled = !isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)

          if (disabled) {
            control = (
              <Box
                component='div'
                className='border border-secondary'
                sx={{width: '100%', px: 2, py: 1}}
                dangerouslySetInnerHTML={{__html: item.valor}}
              ></Box>
            )
          } else {
            control = (
              <>
                {CKEditorZIndexFix}
                <CKEditor
                  editor={Editor}
                  data={item.valor}
                  disabled={disabled}
                  onReady={(editor: any) => {
                    handleOnEditorStartup(editor, disabled)
                  }}
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData()
                    handleOnChange(index, data)
                  }}
                  onBlur={(event: any, editor: any) => {}}
                  onFocus={(event: any, editor: any) => {}}
                />
              </>
            )
          }

          return control
        }
        break
      case 'SYSINTEGRATION':
        let tipoIntegracao = DataRef.current.find((x) => x.campo.toLowerCase() == 'idtipointegration')
        if (tipoIntegracao != null && tipoIntegracao.valor == '7' && item.campo.toLowerCase() == 'url') {
          return (
            <Box component='div' sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
              <Box component='div' className='alert alert-dark d-flex align-items-center'>
                <div className='d-flex flex-column'>
                  <h1>Conecte o WhatsApp da sua empresa</h1>
                  <br />
                  <span>1 - Abre o WhatsApp no seu celular</span>
                  <span>
                    2 - Toque em <strong>Mais opções</strong> ou <strong>Ajustes</strong> e selecione <strong>WhatsApp Web</strong>
                  </span>
                  <span>3 - Gere o QR Code e aponte o celular para essa tela para capturar o código</span>
                  <br />
                  {WAInstanceLoading && (
                    <Box component='div' sx={{alignSelf: 'center'}}>
                      <CircularProgress />
                    </Box>
                  )}
                  {!WAInstanceLoading && WAInstance == null && (
                    <Button variant='contained' startIcon={<FullscreenIcon />} color='secondary' onClick={() => startNewWAInstance()}>
                      Gerar QR Code
                    </Button>
                  )}
                  {!WAInstanceLoading && WAInstance != null && WAInstance.qrcode && <QRCode size={256} value={WAInstance.qrcode.code} />}
                  {!WAInstanceLoading && WAInstance != null && WAInstance.qrcode == null && (
                    <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => closeWAInstance()}>
                      Fechar Conexão
                    </Button>
                  )}
                </div>
              </Box>
              <Box component='div'>
                <TextField
                  required={item.obrigatorio}
                  error={!item.isValid}
                  helperText={item.invalidMessage}
                  sx={{width: '100%'}}
                  disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
                  size='small'
                  label='URL Webhook'
                  value={item.valor}
                  onChange={(event) => handleOnChange(index, event.target.value)}
                />
              </Box>
            </Box>
          )
        }

        break
    }

    const exibicao = item.jsonformatacao != null ? JSON.parse(item.jsonformatacao) : null
    let startAdornment: any = undefined
    let endAdornment: any = undefined

    if (exibicao != null) {
      if (exibicao.formatacao == 'Moeda (R$)') {
        startAdornment = <InputAdornment position='start'>R$</InputAdornment>
      } else if (exibicao.formatacao == 'Moeda ($)') {
        startAdornment = <InputAdornment position='start'>$</InputAdornment>
      } else if (exibicao.formatacao == 'Percentual (%)' || exibicao.formatacao == 'Progresso (%)') {
        endAdornment = <InputAdornment position='end'>%</InputAdornment>
      }
    }

    switch (item.tipo) {
      case 'number':
        if (item.obrigatorio && item.valor == '') {
          item.valor = '0'
        }

        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{width: '100%'}}
            disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            InputLabelProps={{shrink: true}}
            value={item.valor}
            onChange={(event) => {
              let clamped = handleClampNumber(parseInt(event.target.value ?? '0'))
              handleOnChange(index, clamped.toString())
            }}
            InputProps={{
              inputComponent: NumericFormatWithoutDecimal as any,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
            }}
          />
        )
        break
      case 'decimal':
        if (item.obrigatorio && item.valor == '') {
          item.valor = '0'
        }

        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{width: '100%'}}
            disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            InputLabelProps={{shrink: true}}
            value={item.valor}
            onChange={(event) => {
              let valueString = event.target.value
              if (valueString == '') {
                return
              }

              let clamped = handleClampFloat(parseFloat(valueString))
              handleOnChange(index, clamped.toFixed(2))
            }}
            InputProps={{
              inputComponent: NumericFormatWithDecimal as any,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
            }}
          />
        )
        break
      case 'geocode':
        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{width: '100%'}}
            disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            InputLabelProps={{shrink: true}}
            value={item.valor}
            onChange={(event) => {
              let valueString = event.target.value
              if (valueString == '') {
                return
              }

              let clamped = handleClampFloat(parseFloat(valueString), -999.999999, 999.999999)
              handleOnChange(index, clamped.toFixed(6))
            }}
            InputProps={{
              inputComponent: GeocodeFormat as any,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
            }}
          />
        )
        break
      case 'string':
      case 'geolocation':
        if (item.criptografar) {
          control = (
            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{width: '100%'}}
              disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              type={item.showDecrypted ? 'text' : 'password'}
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={(event) => handleApplyValueToArrayedState(setData, index, 'showDecrypted', !item.showDecrypted)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge='end'
                    >
                      {item.showDecrypted ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )
        } else {
          control = (
            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{width: '100%'}}
              disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
            />
          )
        }
        break
      case 'map':
        let isJson = item.valor.startsWith('{') && item.valor.endsWith('}')

        if (!isJson && item.valor != '') {
          if (item.valor != '') {
            let request = new XMLHttpRequest()
            request.open('GET', `https://api.mapbox.com/search/geocode/v6/forward?q=${item.valor}&access_token=${MAPTOKEN}`)
            request.send()
            request.onload = () => {
              if (request.status == 200) {
                let result = JSON.parse(request.response)

                setData((prev) => {
                  let obj = JSON.parse(MAPDEFAULTCOORDINATES)
                  obj.longitude = result.features[0].properties.coordinates.longitude
                  obj.latitude = result.features[0].properties.coordinates.latitude
                  obj.address = prev[index].valor
                  obj.zoom = 17

                  let contextField = prev[index]

                  // Atualizamos o campo selecionado com a latitude
                  if (contextField.configuracao.idmenuAppFieldLat != null) {
                    let latField = prev.find((x) => x.id == contextField.configuracao.idmenuAppFieldLat)
                    if (latField != null) {
                      latField.valorAnterior = latField.valorAnterior == '' ? latField.valor : latField.valorAnterior
                      latField.valor = obj.latitude.toFixed(6)
                    }
                  }

                  // Atualizamos o campo selecionado com a longitude
                  if (contextField.configuracao.idmenuAppFieldLon != null) {
                    let longField = prev.find((x) => x.id == contextField.configuracao.idmenuAppFieldLon)
                    if (longField != null) {
                      longField.valorAnterior = longField.valorAnterior == '' ? longField.valor : longField.valorAnterior
                      longField.valor = obj.longitude.toFixed(6)
                    }
                  }

                  contextField.valor = JSON.stringify(obj)
                  return [...prev]
                })
              } else {
                toast.error(`error ${request.status}: ${request.statusText}`)
              }
            }
          }

          return <></>
        }

        let coordinates = JSON.parse(item.valor != '' ? item.valor : MAPDEFAULTCOORDINATES)

        control = (
          <Box component='div' sx={{width: '100%'}} className='d-flex flex-column'>
            <Box component='div' sx={{py: 1, width: '100%'}}>
              <ReactMapGL
                key={item.id}
                ref={(el) => (mapRefs.current[`map-${item.id}`] = el)}
                mapboxAccessToken={MAPTOKEN}
                initialViewState={{
                  longitude: coordinates.mapCenter ? coordinates.mapCenter.longitude : coordinates.longitude,
                  latitude: coordinates.mapCenter ? coordinates.mapCenter.latitude : coordinates.latitude,
                  zoom: coordinates.mapCenter ? coordinates.mapCenter.zoom : coordinates.zoom,
                }}
                onMove={(evt) => handleOnMoveMap(index, evt.viewState)}
                onClick={(evt) => handleMapClick(index, evt.lngLat)}
                dragPan={isEditable(item)}
                scrollZoom={isEditable(item)}
                onLoad={(event) => {
                  if (!isEditable(item)) return handleMapClick(index, {lng: coordinates.longitude, lat: coordinates.latitude})

                  const geocoder = new MapboxGeocoder({
                    accessToken: MAPTOKEN,
                    placeholder: 'Buscar',
                    mapboxgl: ReactMapGL,
                    countries: 'BR',
                    language: 'pt-BR',
                  })

                  geocoder.on('results', (event) => {
                    setTimeout(() => {
                      const suggestionElements = document.querySelectorAll('.mapboxgl-ctrl-geocoder--suggestion')

                      if (suggestionElements.length === event.features.length) {
                        suggestionElements.forEach((element, i) => {
                          element.addEventListener('mouseup', () => {
                            const selectedFeature = event.features[i]
                            if (selectedFeature) {
                              handleMapClick(index, {lng: selectedFeature.center[0], lat: selectedFeature.center[1]})
                            }
                          })
                        })
                      }
                    }, 100)
                  })

                  event.target.addControl(geocoder)
                }}
                style={{width: '100%', height: 400}}
                mapStyle='mapbox://styles/mapbox/streets-v9'
              >
                <Marker color='red' longitude={coordinates.longitude} latitude={coordinates.latitude} />
              </ReactMapGL>
            </Box>
            <Box component='div' sx={{py: 1, width: '100%'}}>
              <TextField
                disabled
                required={item.obrigatorio}
                error={!item.isValid}
                helperText={item.invalidMessage}
                fullWidth
                size='small'
                label={item.label}
                value={item.valor != '' ? JSON.parse(item.valor).address : ''}
                onChange={(event) => handleChangeMapJSONValue(index, 'address', event.target.value)}
              />
            </Box>
          </Box>
        )

        break
      case 'textArea':
        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{width: '100%'}}
            disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            value={item.valor}
            onChange={(event) => handleOnChange(index, event.target.value)}
            multiline
            maxRows={6}
            minRows={6}
          />
        )
        break
      case 'special':
        if (exibicao != null && exibicao.formatacao == 'Telefone') {
          control = (
            <MuiTelInput
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{width: '100%'}}
              disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(value) => handleOnChange(index, value)}
            />
          )
        } else if (exibicao != null && exibicao.formatacao == 'JSON') {
          control = (
            <CodeMirror
              readOnly={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              theme={codeEditorTheme}
              value={item.valor}
              height='150px'
              width='683px'
              placeholder={item.label}
              extensions={[json()]}
              onChange={(value, viewUpdate) => handleOnChange(index, value)}
            />
          )
        } else if (exibicao != null && exibicao.formatacao != 'Telefone' && exibicao.formatacao != '') {
          control = (
            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{width: '100%'}}
              disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
              inputProps={{
                className: `${exibicao.formatacao.toLowerCase()}-${index}`,
              }}
            />
          )
        } else {
          control = (
            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{width: '100%'}}
              disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
            />
          )
        }
        break
      case 'singleSelect':
        if (item.valor != '' && item.opcoes.find((x) => x.id == parseInt(item.valor)) == null) {
          handleOnInputChangeAutoComplete(index, '', [parseInt(item.valor)], true, false, true)
          return <></>
        }

        control = (
          <Box component='div' sx={{display: 'flex', width: '100%'}}>
            <Autocomplete
              disablePortal
              disabled={!isEditable(item) || !item.permissoes.biSelect || (acaoEmMassa && !item.salvarAlteracoes)}
              options={item.opcoes != null ? item.opcoes : []}
              sx={{width: '100%'}}
              size='small'
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={item.obrigatorio}
                  error={!item.isValid}
                  helperText={item.invalidMessage}
                  label={item.label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {item.loading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              value={item.valor != null && item.valor != '' ? item.opcoes.find((op: any) => op.id == item.valor) ?? null : null}
              onOpen={(event) => handleOnOpenAutoComplete(index, event)}
              onChange={(event, value, reason) => handleOnChangeAutoComplete(index, value)}
              onInputChange={(event, value) => handleOnInputChangeAutoComplete(index, value)}
              filterOptions={(x) => x}
              loading={item.loading}
              loadingText='Buscando opções...'
              noOptionsText='Nenhuma opção encontrada, digite para buscar'
            />
            {item.loopBackAccessCode != null && (
              <IconButton
                disabled={item.valor == null || item.valor == '' || item.somenteLeitura || !item.permissoes.biUpdate}
                aria-label='Edit single select option'
                size='small'
                title='Editar Registro'
                sx={{pl: 1}}
                onClick={() => {
                  let valor = item.valor != null && item.valor != '' ? parseInt(item.valor) : 0
                  handleOpenModalSingleSelect(index, item.loopBackAccessCode as string, valor)
                }}
              >
                <EditNoteIcon />
              </IconButton>
            )}
          </Box>
        )
        break
      case 'dateTime':
        control = (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={TranslateLocale(locale)}>
            <DateTimePicker
              slotProps={{textField: {size: 'small', error: !item.isValid, helperText: item.invalidMessage, required: item.obrigatorio}}}
              label={item.label}
              value={item.valor != '' ? new Date(item.valor) : null}
              onChange={(date) => {
                let parsed = moment(date).format('YYYY-MM-DDTHH:mm:ss').toString()

                if (parsed == 'Invalid date') {
                  parsed = ''
                }

                handleOnChange(index, parsed)
              }}
              disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              sx={{width: '100%'}}
            />
          </LocalizationProvider>
        )
        break
      case 'date':
        control = (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={TranslateLocale(locale)}>
            <DesktopDatePicker
              slotProps={{textField: {size: 'small', error: !item.isValid, helperText: item.invalidMessage, required: item.obrigatorio}}}
              label={item.label}
              value={item.valor != '' ? new Date(item.valor) : null}
              onChange={(date) => {
                let parsed = moment(date).format('YYYY-MM-DDTHH:mm:ss').toString()

                if (parsed == 'Invalid date') {
                  parsed = ''
                }

                handleOnChange(index, parsed)
              }}
              disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
              sx={{width: '100%'}}
            />
          </LocalizationProvider>
        )
        break
      case 'boolean':
        control = (
          <FormControlLabel
            label={item.label}
            control={
              <Checkbox
                disabled={!isEditable(item) || (acaoEmMassa && !item.salvarAlteracoes)}
                checked={item.valor != '' ? item.valor == 'true' : false}
                onChange={(event, checked) => handleOnChange(index, event.target.checked ? 'true' : 'false')}
              />
            }
          />
        )
        break
      case 'upload':
        control = (
          <UploadCollection
            label={item.label}
            readonly={!isEditable(item) || readonly}
            azFiles={item.arquivos}
            onDelete={(azFile) => handleOnDeleteUpload(index, azFile)}
            onUpload={(newAzFile) => handleOnUpload(index, newAzFile)}
          />
        )
        break
    }

    return control
  }

  /**
   * Função que monta a primeira tab onde vem os campos do formulário
   * @returns void
   */
  const getFirstTab = () => {
    return (
      <>
        {loading && (
          <Box component='div' sx={{alignItems: 'center', justifyContent: 'center'}}>
            {acessoExterno && <CircularProgress />}
            {!acessoExterno && <LinearProgress />}
          </Box>
        )}
        {Data && Data.length > 0 && (
          <>
            {/*MAPEAMOS OS CAMPOS COM AGRUPADORES*/}
            {DataAccordions.map((accordion: any, index: number) => {
              return (
                <Accordion sx={{border: 0}} defaultExpanded={index == 0} variant='outlined'>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`accordion-content-${accordion}-${index}`}
                    id={`accordion-${accordion}-${index}`}
                  >
                    {accordion}
                  </AccordionSummary>
                  <AccordionDetails>
                    {Data.map((item: any, index: number) => {
                      if (item.agrupador != accordion) {
                        return <></>
                      }

                      let control: any = getComponent(item, index)

                      return (
                        <Box
                          hidden={!item.exibir}
                          component='div'
                          sx={{p: 1, display: 'flex', width: '95%', maxWidth: '700px'}}
                          key={`box-accordion-${index}`}
                          title={item.info ?? undefined}
                        >
                          {acaoEmMassa && (
                            <Checkbox
                              //disabled={!isEditable(item)}
                              checked={item.salvarAlteracoes}
                              onChange={(event, checked) => handleApplyValueToArrayedState(setData, index, 'salvarAlteracoes', checked)}
                            />
                          )}
                          {control}
                          {item.tipo == 'singleSelect' && !item.permissoes.biSelect && (
                            <Box
                              component='div'
                              sx={{
                                height: '100%',
                                justifyContent: 'center',
                                paddingTop: '7px',
                                paddingLeft: '5px',
                              }}
                            >
                              <Tooltip title='Você não tem permissão para editar este campo.'>
                                <WarningIcon color='disabled' />
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}

            <br></br>

            {/*MAPEAMOS OS CAMPOS SEM AGRUPADORES*/}
            {Data.map((item: any, index: number) => {
              if (item.agrupador != null && item.agrupador != '') {
                return <></>
              }

              let control: any = getComponent(item, index)

              return (
                <Box
                  hidden={!item.exibir}
                  component='div'
                  sx={{p: 1, display: 'flex', width: '95%', maxWidth: '700px'}}
                  key={`box-noaccordion-${index}`}
                  title={item.info ?? undefined}
                >
                  {acaoEmMassa && (
                    <Checkbox
                      //disabled={!isEditable(item)}
                      checked={item.salvarAlteracoes}
                      onChange={(event, checked) => handleApplyValueToArrayedState(setData, index, 'salvarAlteracoes', checked)}
                    />
                  )}
                  {control}
                  {item.tipo == 'singleSelect' && !item.permissoes.biSelect && (
                    <Box
                      component='div'
                      sx={{
                        height: '100%',
                        justifyContent: 'center',
                        paddingTop: '7px',
                        paddingLeft: '5px',
                      }}
                    >
                      <Tooltip title='Você não tem permissão para editar este campo.'>
                        <WarningIcon color='disabled' />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              )
            })}
          </>
        )}

        {acessoExterno && !loading && (
          <Box component='div' sx={{p: 1}}>
            <Button disabled={Saving} variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, true)}>
              Salvar
            </Button>
          </Box>
        )}
      </>
    )
  }

  /**
   * Função que identifica campos protegidos de edição de certos Apps TODO: Passar tudo isso para as regras
   * @returns void
   */
  const isProtectedItem = () => {
    if (DataRef.current != null && accessCode == 'SYSROLE') {
      if (
        DataRef.current.find(
          (x) =>
            x.campo.toLowerCase() == 'identificador' &&
            (x.valor.toLowerCase() == 'admin' || x.valor.toLowerCase() == 'sadmin' || x.valor.toLowerCase() == 'userall')
        )
      ) {
        return true
      }
    }

    return false
  }

  /**
   * Função call back para o menu lateral do painel de emails
   * @param selectedTab Nome da tab selecionada
   * @returns void
   */
  const emailSideMenuCallBack = (selectedTab: string) => {
    switch (selectedTab) {
      case 'INBOX':
        setEmailGridTitle('Caixa de Entrada')
        break
      case 'SEND':
        setEmailGridTitle('Caixa de Saída')
        break
      case 'SENT':
        setEmailGridTitle('Items Enviados')
        break
      case 'DRAFT':
        setEmailGridTitle('Rascunhos')
        break
    }

    let filter: Array<any> = []
    filter.push({
      columnField: 'Folder',
      operatorValue: 'equals',
      value: [selectedTab],
    })

    setEmailGridFilters(filter)
  }

  /**
   * Função que atualiza a badge de emails não lidos
   * @returns void
   */
  const handleUpdateBadge = () => {
    apiWrapper.get(`api/v1/Email/listarNaoLidos?item.AccessCode=${accessCode}&item.IDMenuAppGenericItem=${id}`).then((response) => {
      setEmailGridNaoLidos(response.data.data)
    })
  }

  /**
   * Função de execução de ação customizada
   * @param item Objeto da ação customizada
   * @param validate (Opcional) Necessário validação?
   * @param acaoConfirmacao (Opcional) Nome da ação de confirmação
   * @param preConfirmacao (Opcional) É uma pré confirmação?
   * @returns void
   */
  const handleExecuteCustomAction = async (item: MenuAppActionResult, validate?: boolean, acaoConfirmacao?: string, preConfirmacao?: boolean) => {
    setValidating(true)

    if (validate) {
      handleOpenPerformingAction('Executando validações, aguarde...')

      let obj = {
        item: {
          ID: DataID,
          AccessCode: accessCode,
          Campos: Data,
          EventoRegra: 'Ao carregar app',
        },
      }

      let ret = await apiWrapper.put('api/v1/Dynamic/executarPreValidacoes', obj)
      let results = ret.data.data as Array<string>

      let result = results.find((x) => x.startsWith(`${item.menuAppFieldsRule?.id} -`))
      if (result?.includes('SUCESSO')) {
        handleOpenModalConfirmacao(true, item.menuAppFieldsRule?.atributo01 ?? '', item)
      } else if (result?.includes('CONFIRMACAO')) {
        let split = result.split(':')
        handleOpenModalConfirmacao(true, split[1], item, true)
      } else {
        toast.error('Os critérios de execução para esta ação não foram atingidos!')
      }

      handleClosePerformingAction()
      setValidating(false)
      return
    }

    if (preConfirmacao) {
      handleOpenModalConfirmacao(true, item.menuAppFieldsRule?.atributo01 ?? '', item)
      return
    }

    handleOpenPerformingAction('Executando ação customizada, aguarde...')

    let acoes = JSON.parse(item.menuAppFieldsRule?.atributo10 ?? '[]') as Array<CRuleActions>
    let camposAtualizados = 0

    for (const acao of acoes.filter((x) => x.event == acaoConfirmacao)) {
      if (acao.action != 'Atualizar Campo') {
        continue
      }

      let campo = Data.find((x) => x.id == acao.campo)
      if (campo == null) {
        continue
      }

      campo.valorAnterior = campo.valor
      campo.valor = acao.valor

      camposAtualizados++
    }

    let obj = {
      item: {
        ID: DataID,
        AccessCode: accessCode,
        Campos: Data,
        IDMenuAppFieldRule: item.menuAppFieldsRule?.id,
        EventoDeAcoes: acaoConfirmacao,
      },
    }

    await apiWrapper.put('api/v1/Dynamic/executarCustomAction', obj)
    toast.success('Ação customizada executada com sucesso!')
    handleClosePerformingAction()

    setValidating(false)

    if (camposAtualizados > 0) {
      handleClose(true, true)
    } else {
      handleClose(false, true)
    }
  }

  // --<< MODAIS >>-- \\

  /* SingleSelect (ou secundario dependendo do caso) */

  const [modalDynamicSingleSelect, setModalDynamicSingleSelect] = useState<boolean>(false)
  const [modalDynamicSingleSelectFieldIndex, setModalDynamicSingleSelectFieldIndex] = useState<number>(0)
  const [modalDynamicSingleSelectAccessCode, setModalDynamicSingleSelectAccessCode] = useState<string>('')
  const [modalDynamicSingleSelectID, setModalDynamicSingleSelectID] = useState<number>(0)

  const handleOpenModalSingleSelect = (fieldIndex: number, AccessCode: string, Id: number) => {
    setModalDynamicSingleSelect(true)
    setModalDynamicSingleSelectFieldIndex(fieldIndex)
    setModalDynamicSingleSelectAccessCode(AccessCode)
    setModalDynamicSingleSelectID(Id)
  }

  const handleModalSingleSelectCallback = (response: any = null) => {
    setModalDynamicSingleSelect(false)

    // Recarregamos as opções existentes do campo singleSelect
    if (response != null) {
      handleOnInputChangeAutoComplete(modalDynamicSingleSelectFieldIndex, '', [response.id ?? modalDynamicSingleSelectID], true)
    }

    setModalDynamicSingleSelectFieldIndex(0)
    setModalDynamicSingleSelectAccessCode('')
    setModalDynamicSingleSelectID(0)
  }

  /* AVISO TAREFA */

  const [modalAvisoTarefa, setModalAvisoTarefa] = useState<boolean>(false)
  const [modalAvisoTarefaIDTarefa, setModalAvisoTarefaIDTarefa] = useState<number>(0)

  const handleOpenModalAvisoTarefa = (idTarefa: number) => {
    setModalAvisoTarefa(true)
    setModalAvisoTarefaIDTarefa(idTarefa)
  }

  const handleCloseModalAvisoTarefa = (redirect: boolean = false) => {
    setModalAvisoTarefa(false)
    setModalAvisoTarefaIDTarefa(0)

    if (redirect) {
      window.open(`/pages/_dynamicTabs/SYSTASK/${modalAvisoTarefaIDTarefa}`, '_blank')
    }
  }

  /* SYSTASK */

  const [modalObservacaoTarefa, setModalObservacaoTarefa] = useState<boolean>(false)
  const [modalObservacaoTarefaAcao, setModalObservacaoTarefaAcao] = useState<string>('')
  const [observacaoTarefa, setObservacaoTarefa] = useState<string>('')

  /**
   * Função que checa se estamos num ambiente de uma tarefa de aprovação
   * @returns boolean
   */
  const getTarefaAprovacao = () => {
    if (accessCode != 'SYSTASK' || Data == null || DataID == 0) {
      return false
    }

    let campoIdTipoTarefa = DataRef.current.find((x) => x.campo.toLowerCase() == 'idtipotarefa')
    if (campoIdTipoTarefa == null) {
      return false
    }

    let campoIdTipoStatus = DataRef.current.find((x) => x.campo.toLowerCase() == 'idtipostatus')
    if (campoIdTipoTarefa == null) {
      return false
    }

    return campoIdTipoTarefa.valor == '2' && (campoIdTipoStatus?.valor == '1' || campoIdTipoStatus?.valor == '2')
  }

  /**
   * Função que executa uma tarefa, aprovar, reprovar ou aprovar com ressalva
   * @param evento O evento da tarefa
   * @param observacao Observação a ser salva na tarefa ao realizar a execução
   * @returns void
   */
  const handleExecutarTarefa = (evento: string, observacao: string, necessitaObservacao: boolean = false) => {
    if (necessitaObservacao && observacao == '') {
      toast.error('É necessário informar a observação!')
      return
    }

    setModalObservacaoTarefa(false)

    let campoStatusTarefa = Data.find((x) => x.campo.toLowerCase() == 'idtipostatus')
    if (campoStatusTarefa == null) {
      toast.error('Campo de status de tarefa não foi encontrado no formulário, verifique o mapeamento do app.')
      return
    }

    handleOpenPerformingAction('Executando ações e salvando tarefa, aguarde...')

    let novoStatus = campoStatusTarefa.valor
    let mensagem = ''

    switch (evento) {
      case 'APROVACAO':
        novoStatus = '12'
        mensagem = 'Tarefa aprovada!'
        break
      case 'REPROVACAO':
        novoStatus = '13'
        mensagem = 'Tarefa reprovada!'
        break
      case 'APROVACAORESSALVA':
        novoStatus = '14'
        mensagem = 'Tarefa aprovada com ressalva!'
        break
    }

    campoStatusTarefa.valor = novoStatus //SANITY CHECK

    setData(Data)

    // LOCKAMOS O MODAL PARA EXECUÇÃO E SALVAMENTO DA TAREFA
    setFieldLock(true)
    setSaving(true)

    apiWrapper
      .post('api/v1/Tarefa/executarTarefa', {item: {IDTarefa: DataID, Evento: evento, Observacao: observacao, Campos: Data}})
      .then((response) => {
        for (const message of response.data.data) {
          if (message != 'SUCESSO') {
            toast.error(message)
          }
        }
      })
      .catch((error) => {
        alert(error)
      })
      .finally(() => {
        setFieldLock(false)
        setSaving(false)
        handleClosePerformingAction()
        handleClose(false, false)
        handleCallBack({id: DataID, keepOpen: false})
      })
  }

  /* CONFIRMAÇÃO */

  const [modalConfirmacao, setModalConfirmacao] = useState<boolean>(false)
  const [modalConfirmacaoPreConfirmacao, setModalConfirmacaoPreConfirmacao] = useState<boolean>(false)
  const [modalConfirmacaoCustomAction, setModalConfirmacaoCustomAction] = useState<MenuAppActionResult | undefined>(undefined)
  const [modalConfirmacaoKeepOpen, setModalConfirmacaoKeepOpen] = useState<boolean>(false)
  const [modalConfirmacaoMensagem, setModalConfirmacaoMensagem] = useState<string>('')

  /**
   * Função que abre o modal de confirmação da regra de validação
   * @param keepOpen Mander modal aberto?
   * @param mensagem Mensagem para mostrar no modal de confirmação
   * @param customAction (Opcional) Objeto da ação customizada
   * @param preConfirmacao (Opcional) É uma pré confirmação?
   * @returns void
   */
  const handleOpenModalConfirmacao = (keepOpen: boolean, mensagem: string, customAction?: MenuAppActionResult, preConfirmacao?: boolean) => {
    setModalConfirmacao(true)
    setModalConfirmacaoCustomAction(customAction)
    setModalConfirmacaoKeepOpen(keepOpen)
    setModalConfirmacaoMensagem(mensagem)
    setModalConfirmacaoPreConfirmacao(preConfirmacao ?? false)
  }

  /**
   * Função que gerencia o que acontece quando o modal de confirmaçào fecha dependendo do que foi pressionado
   * @param evento Nome do evento
   * @param customAction (Opcional) Objeto da ação customizada
   * @param preConfirmacao (Opcional) É uma pré confirmação?
   * @returns void
   */
  const handleCloseModalConfirmacao = (evento: string, customAction?: MenuAppActionResult, preConfirmacao?: boolean) => {
    if (evento != '' && (evento == 'SIM' || evento == 'NAO')) {
      if (customAction == null) {
        handleClose(true, modalConfirmacaoKeepOpen, evento)
      } else if (preConfirmacao) {
        handleExecuteCustomAction(customAction, false, evento, true)
      } else {
        handleExecuteCustomAction(customAction, false, evento)
      }
    }

    handleClosePerformingAction()
    setModalConfirmacao(false)
  }

  /* AGUARDE */

  const [modalPerformingAction, setModalPerformingAction] = useState<boolean>(false)
  const [modalPerformingActionMessage, setModalPerformingActionMessage] = useState<string>('')

  /**
   * Função que abre o modal de aguarde
   * @param message Mensagem para mostrar no modal de aguarde
   * @returns void
   */
  const handleOpenPerformingAction = (message: string) => {
    setModalPerformingAction(true)
    setModalPerformingActionMessage(message)
  }

  /**
   * Função que fecha o modal de aguarde
   * @returns void
   */
  const handleClosePerformingAction = () => {
    setModalPerformingAction(false)
    setModalPerformingActionMessage('')
  }

  // Use Effect do grid de emails para mudar as badges
  useEffect(() => {
    if (acessoExterno) {
      return
    }

    handleUpdateBadge()
  }, [emailGrid, emailGridFilters])

  // Use Effect padrão, ele é chamado sempre que o modal é instanciado
  useEffect(() => {
    if (DataID == 0) {
      handleOpen(accessCode, id)
    }

    return () => {
      clearWAInstanceInterval()
    }
  }, [])

  //in case actions are null
  useEffect(() => {
    if (!Array.isArray(localActions) || localActions.length === 0) {
      apiWrapper.get(`/api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${accessCode}`).then((data) => setLocalActions(data.data.data))
    }
  }, [localActions, accessCode, setActions, apiWrapper])

  return (
    <>
      {acessoExterno && <>{getFirstTab()}</>}
      {!acessoExterno && (
        <>
          <StyledDialog open={Opened} fullScreen={true}>
            <DialogTitle>
              {Title}
              <Box component='div' sx={{float: 'inline-end'}}>
                {readonly && <span className='badge bg-secondary mx-1'>Somente Leitura</span>}
                {elapsedFollowUpTimer != null && (
                  <span className='badge badge-light-success'>
                    <Timer initialDate={new Date(elapsedFollowUpTimer.inicio)} />
                  </span>
                )}
              </Box>
            </DialogTitle>
            <DialogContent
              dividers={true}
              sx={{py: 0, px: 1, overflow: 'hidden', display: 'flex', height: '100%', flexDirection: 'column'}}
            >
              {Data && (
                <ProjectInfoDisplay
                  text1={appInfo.field1}
                  text2={appInfo.field2}
                  text3={appInfo.field3}
                  icon={appInfo.icon}
                  visible={!(appInfo.field2 == undefined || appInfo.field2 == '')}
                />
              )}
              {CustomPageFile == null && (
                <>
                  <Tabs value={TabIndex} onChange={handleTabIndexChange}>
                    <Tab label='Dados Iniciais' value={0} />
                    {Relations.length > 0 &&
                      Relations.map((item: any, index: number) => {
                        return <Tab key={`tab-relation-${index}`} label={item.nome} value={index + 1} />
                      })}
                    <Tab
                      hidden={DataID == 0 || readonly || !emailGrid}
                      label={
                        <Badge variant='dot' color='info' badgeContent={emailGridNaoLidos.length} sx={{p: 0.5}}>
                          Email
                        </Badge>
                      }
                      value={Relations.length + 1}
                    />
                    <Tab hidden={DataID == 0 || readonly} label='Logs' value={Relations.length + 2} />
                  </Tabs>
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      height: 'calc(100% - 100px)',
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      component='div'
                      hidden={TabIndex != 0}
                      sx={{display: 'flex', height: 'auto', flexGrow: 1, maxHeight: `calc(100% - ${loading ? 0 : 43}px)`, minHeight: '100px'}}
                    >
                      <Box
                        component='div'
                        sx={{height: '100%', px: 0, overflowY: 'auto'}}
                        className={`${DataID != 0 ? 'col-6 border' : 'col-md-12 border'} overflow-auto`}
                      >
                        <FormControl
                          hidden={TabIndex != 0}
                          sx={{display: 'flex', width: '100%', height: 'calc(100vh - 18%)', px: 0, overflowY: 'auto'}}
                          component='fieldset'
                          variant='standard'  
                        >
                          {getFirstTab()}
                        </FormControl>
                      </Box>
                      {DataID != 0 && (
                        <Box component='div' sx={{height: '100%', px: 0}} className='col-md-6 border d-flex'>
                          <Chat isDrawer={true} accessCode={accessCode as string} idMenuAppGenericValue={DataID} />
                        </Box>
                      )}
                    </Box>
                    {Relations.length > 0 &&
                      Relations.map((item: any, index: number) => (
                        <FormControl
                          key={`fc-relation-${index}`}
                          hidden={TabIndex != index + 1}
                          sx={{height: 'calc(100% - 104px)', width: '100%'}}
                          component='fieldset'
                          variant='standard'
                        >
                          <DynamicList
                            readonly={readonly}
                            isDisplayed={TabIndex == index + 1}
                            accessCode={item.menuAppRelacao.accessCode}
                            sysParam=''
                            title={item.nome}
                            subtitle={item.descricao}
                            displayHeader={true}
                            relationItem={item}
                            requestProps={{
                              useRelationFilters: true,
                              idMenuAppRelation: item.id,
                              idRelationItem: DataID,
                            }}
                          />
                        </FormControl>
                      ))}
                    <FormControl
                      hidden={TabIndex != Relations.length + 1}
                      sx={{height: 'calc(100% - 104px)', width: '100%'}}
                      component='fieldset'
                      variant='standard'
                    >
                      {DataID != 0 && emailGrid && (
                        <Box component='div' className='d-flex flex-row' sx={{height: 'inherit'}}>
                          <Box component='div'>
                            <EmailSideMenu naoLidos={emailGridNaoLidos} callBack={emailSideMenuCallBack} />
                          </Box>
                          <Box component='div' sx={{width: '-webkit-fill-available'}}>
                            <DynamicList
                              isDisplayed={TabIndex == Relations.length + 1}
                              accessCode='ZPLUGMAILBOX'
                              sysParam={accessCode}
                              IDMenuAppGenericItem={id.toString()}
                              IDIntegration={emailGridIntegration}
                              filters={emailGridFilters}
                              title={emailGridTitle}
                              subtitle=''
                              displayHeader={true}
                              getDataCallBack={handleUpdateBadge}
                            />
                          </Box>
                        </Box>
                      )}
                    </FormControl>
                    <FormControl
                      hidden={TabIndex != Relations.length + 2}
                      sx={{height: 'calc(100% - 48px)', width: '100%'}}
                      component='fieldset'
                      variant='standard'
                    >
                      {Data != null && DataID != 0 && (
                        <DynamicList
                          isDisplayed={TabIndex == Relations.length + 2}
                          accessCode='SYSMAA'
                          sysParam={accessCode as string}
                          IDMenuAppGenericItem={DataID.toString()}
                          title=''
                          subtitle=''
                          displayHeader={false}
                        />
                      )}
                    </FormControl>
                  </Box>
                </>
              )}
              <Box hidden={CustomPageFile == null} component='div' id='contentbind'></Box>
            </DialogContent>
            <DialogActions>
              {!getTarefaAprovacao() && (
                <>
                  {!Validating && (
                    <>
                      {/*disableSendEmail != true && isEditable() && DataID != 0 && !hideButtons.enviarPorEmail && (
                        <Button variant='contained' startIcon={<EmailIcon />} color='info' onClick={() => reopenAsEmailSender()}>
                          Enviar por Email
                        </Button>
                      )*/}
                      {/* BOTÃO EXCLUIR */}
                      {!fieldLock &&
                        isEditable() &&
                        localActions &&
                        ((item, index) => {
                          if (item.acao != 'Delete' || hideButtons.excluir) {
                            return
                          }

                          return (
                            <>
                              {deleteFunc != null && !readonly && isEditable() && !isProtectedItem() && DataID != 0 && (
                                <Button
                                  hidden={item.biVisible === false}
                                  disabled={permissions == null || !permissions.biDelete}
                                  variant='contained'
                                  startIcon={<DeleteIcon />}
                                  color='error'
                                  title={permissions == null || !permissions.biDelete ? 'Você não possui permissão' : item.descricao}
                                  onClick={() => handleOpenExcluir()}
                                >
                                  {item.nome}
                                </Button>
                              )}
                            </>
                          )
                        })}
                      <div style={{flex: '1 0 0'}} />
                      {/* BOTÃO FECHAR */}
                      {localActions &&
                        localActions.map((item, index) => {
                          if (item.acao != 'Close') {
                            return
                          }

                          return (
                            <>
                              <Button
                                hidden={item.biVisible === false}
                                disabled={!isEditable() || (showClose != null && showClose == false)}
                                variant='contained'
                                startIcon={<CancelIcon />}
                                color='inherit'
                                title={item.descricao}
                                onClick={() => handleClose(false, false)}
                              >
                                {item.nome}
                              </Button>
                            </>
                          )
                        })}
                      {/* BOTÕES CUSTOM */}
                      {/*!fieldLock && --> Removido devido necessidade de exibir em modal somente Leitura*/
                        isEditable() &&
                        DataID != 0 &&
                        localActions &&
                        localActions.map((item, index) => {
                          if (item.acao != 'Custom' || item.tipo.trim() != 'Custom') {
                            return
                          }

                          return (
                            <Button
                              hidden={item.biVisible === false}
                              variant='contained'
                              startIcon={<BoltIcon />}
                              color='warning'
                              title={item.descricao}
                              onClick={() => handleExecuteCustomAction(item, true)}
                            >
                              {item.nome}
                            </Button>
                          )
                        })}
                      {/* BOTÃO SALVAR */}
                      {!isProtectedItem() &&
                        localActions &&
                        localActions.map((item, index) => {
                          if (item.acao != 'Save' || hideButtons.salvar || disableSave) {
                            return
                          }

                          return (
                            <Button
                              hidden={item.biVisible === false || acaoEmMassa}
                              disabled={fieldLock || !isEditable() || permissions == null || (!permissions.biUpdate && !permissions.biInsert)}
                              variant='contained'
                              startIcon={<SaveIcon />}
                              color='success'
                              title={permissions == null || !permissions.biUpdate ? 'Você não possui permissão' : item.descricao}
                              onClick={() => handleClose(true, true)}
                            >
                              {item.nome}
                            </Button>
                          )
                        })}
                      {/* BOTÃO SALVAR E FECHAR */}
                      {!isProtectedItem() &&
                        localActions &&
                        localActions.map((item, index) => {
                          if (item.acao != 'SaveClose' || hideButtons.salvarEFechar) {
                            return
                          }

                          return (
                            <Button
                              hidden={item.biVisible === false}
                              disabled={fieldLock || !isEditable() || permissions == null || (!permissions.biUpdate && !permissions.biInsert)}
                              variant='contained'
                              startIcon={<SaveIcon />}
                              color='success'
                              title={permissions == null || !permissions.biUpdate ? 'Você não possui permissão' : item.descricao}
                              onClick={() => handleClose(true, false)}
                            >
                              {item.nome}
                            </Button>
                          )
                        })}
                      {Saving && (
                        <Button disabled variant='contained' startIcon={<SaveIcon />} color='success'>
                          Salvando...
                        </Button>
                      )}
                      {loading && (
                        <Button disabled variant='contained' startIcon={<HourglassBottomIcon />} color='success'>
                          Carregando...
                        </Button>
                      )}
                    </>
                  )}
                  {Validating && (
                    <>
                      <Button disabled variant='contained' startIcon={<LockResetIcon />} color='success'>
                        Validando...
                      </Button>
                    </>
                  )}
                </>
              )}
              {getTarefaAprovacao() && (
                <>
                  {!fieldLock && !Saving && !loading && (
                    <>
                      <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false, false)}>
                        Fechar
                      </Button>
                      <Button
                        disabled={permissions == null || !permissions.biUpdate}
                        variant='contained'
                        startIcon={<ThumbDownAltIcon />}
                        color='error'
                        title={permissions == null || !permissions.biUpdate ? 'Você não possui permissão' : undefined}
                        onClick={() => {
                          setModalObservacaoTarefa(true)
                          setModalObservacaoTarefaAcao('REPROVACAO')
                        }}
                      >
                        Reprovar
                      </Button>
                      <Button
                        disabled={permissions == null || !permissions.biUpdate}
                        hidden={CustomPageFile != null || acaoEmMassa}
                        variant='contained'
                        startIcon={<ThumbUpIcon />}
                        color='success'
                        title={permissions == null || !permissions.biUpdate ? 'Você não possui permissão' : undefined}
                        onClick={() => handleExecutarTarefa('APROVACAO', '')}
                      >
                        Aprovar
                      </Button>
                      <Button
                        disabled={permissions == null || !permissions.biUpdate}
                        variant='contained'
                        startIcon={<RecommendIcon />}
                        color='success'
                        title={permissions == null || !permissions.biUpdate ? 'Você não possui permissão' : undefined}
                        onClick={() => {
                          setModalObservacaoTarefa(true)
                          setModalObservacaoTarefaAcao('APROVACAORESSALVA')
                        }}
                      >
                        Aprovar Com Ressalva
                      </Button>
                    </>
                  )}
                  {fieldLock && Saving && (
                    <Button disabled variant='contained' startIcon={<SaveIcon />} color='success'>
                      Salvando tarefa...
                    </Button>
                  )}
                  {fieldLock && !Saving && (
                    <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false, false)}>
                      Fechar
                    </Button>
                  )}
                  {loading && (
                    <Button disabled variant='contained' startIcon={<HourglassBottomIcon />} color='success'>
                      Carregando...
                    </Button>
                  )}
                </>
              )}
            </DialogActions>
          </StyledDialog>

          {/* MODAL DINÂMICO DE GERENCIAMENTO SINGLE SELECT */}
          {modalDynamicSingleSelect && (
            <DynamicModal
              accessCode={modalDynamicSingleSelectAccessCode}
              accessCodeMaster={modalDynamicSingleSelectAccessCode}
              id={modalDynamicSingleSelectID}
              disableSave={true}
              callBack={handleModalSingleSelectCallback}
            />
          )}

          {/* MODAL OBSERVAÇÃO TAREFA */}
          {modalObservacaoTarefa && (
            <StyledDialog open={modalObservacaoTarefa} fullWidth={true} maxWidth='sm'>
              <DialogTitle>Observação</DialogTitle>
              <DialogContent dividers={true}>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: '100%'}}
                    size='small'
                    value={observacaoTarefa}
                    onChange={(event) => setObservacaoTarefa(event.target.value)}
                    multiline
                    maxRows={6}
                    minRows={6}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => setModalObservacaoTarefa(false)}>
                  Fechar
                </Button>
                <Button
                  variant='contained'
                  startIcon={<RecommendIcon />}
                  color='success'
                  onClick={() => {
                    handleExecutarTarefa(modalObservacaoTarefaAcao, observacaoTarefa, true)
                  }}
                >
                  Confirmar
                </Button>
              </DialogActions>
            </StyledDialog>
          )}

          {/* MODAL AVISO TAREFA */}
          {modalAvisoTarefa && (
            <StyledDialog open={modalAvisoTarefa} fullWidth={true} maxWidth='sm'>
              <DialogTitle>Tarefas encontradas</DialogTitle>
              <DialogContent dividers={true}>
                <Box component='div' sx={{p: 1}}>
                  <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                    <strong>Existe tarefas de aprovação pendentes referentes a esse registro, gostaria de visualizá-las?</strong>
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant='contained' color='inherit' onClick={() => handleCloseModalAvisoTarefa()}>
                  Não
                </Button>
                <Button variant='contained' color='success' onClick={() => handleCloseModalAvisoTarefa(true)}>
                  Sim
                </Button>
              </DialogActions>
            </StyledDialog>
          )}

          {/* MODAL CONFIRMACAO */}
          {modalConfirmacao && (
            <StyledDialog open={modalConfirmacao} fullWidth={true} maxWidth='sm'>
              <DialogTitle>Confirmação</DialogTitle>
              <DialogContent dividers={true}>
                <Box component='div' sx={{p: 1}}>
                  <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                    <strong>{modalConfirmacaoMensagem}</strong>
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleCloseModalConfirmacao('')}>
                  Cancelar
                </Button>
                <Button
                  variant='contained'
                  startIcon={<ThumbDownAltIcon />}
                  color='error'
                  onClick={() => handleCloseModalConfirmacao('NAO', modalConfirmacaoCustomAction)}
                >
                  Não
                </Button>
                <Button
                  variant='contained'
                  startIcon={<RecommendIcon />}
                  color='success'
                  onClick={() => handleCloseModalConfirmacao('SIM', modalConfirmacaoCustomAction)}
                >
                  Sim
                </Button>
              </DialogActions>
            </StyledDialog>
          )}

          {/* MODAL AGUARDE */}
          {modalPerformingAction && <ModalPerformingAction message={modalPerformingActionMessage} />}
        </>
      )}
    </>
  )
}

export default DynamicModal
