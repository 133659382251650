import React, {useEffect, useState} from 'react'
import clsx from 'clsx'

import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Avatar from '@mui/material/Avatar'

import SendIcon from '@mui/icons-material/Send'
import Button from '@mui/material/Button'
import {useGlobalAuthentication} from '../../../../modules/authentication/Authentication'
import {useThemeMode} from '../../../../../_metronic/partials'
import {Box} from '@mui/material'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

interface IChat {
  isDrawer: boolean
  accessCode: string
  idMenuAppGenericValue: number
}

/**
 * Retorna cor radomica que ficaram de backgroundo para o Avatar
 * @param string
 * @returns
 */
const stringToColor = (string: string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

/**
 * Retorna o Avatar formatado somente com as 2 primeiras iniciais do nome
 * @param name
 * @returns
 */
const stringAvatar = (tipo: number, name: string) => {
  if (tipo == 998) {
    return
  }

  let split = name.split(' ')

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: split.length > 1 ? `${split[0][0]}${split[1][0]}` : `${split[0][0]}`,
    title: name,
  }
}

export const Chat = ({isDrawer, accessCode, idMenuAppGenericValue}: IChat) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const {loggedUser} = useGlobalAuthentication()

  const [mensagens, setMensagens] = useState<Array<any>>([])
  const [texto, setTexto] = useState<string>('')
  const [Tag, setTag] = useState<any>(null)

  const [userNames, setUserNames] = useState<Array<any>>([])

  const handleInit = () => {
    apiWrapper
      .get(`api/v1/MenuAppFieldTag/carregar?item.AccessCode=${accessCode}&item.IDMenuAppGenericValue=${idMenuAppGenericValue}`)
      .then((response) => {
        setTag(response.data.data)
      })

    apiWrapper
      .get(`api/v1/MenuAppFieldComment/ListarPorMenuAppItem?item.AccessCode=${accessCode}&item.IDMenuAppGenericValue=${idMenuAppGenericValue}`)
      .then(async (response) => {
        let names: Array<any> = []

        for (const item of response.data.data) {
          if (names.find((x) => x.id == item.idpessoaCreated) != null) {
            continue
          }

          let ret = await GetPessoa(item.idpessoaCreated)
          names.push({id: item.idpessoaCreated, name: ret.data.data.nome, tipo: ret.data.data.idtipoPessoa})
        }

        setUserNames(names)
        setMensagens(response.data.data)
      })
  }

  const handleOnChangeTags = (value: any) => {
    setTag((prev: any) => {
      prev.tag = JSON.stringify(value)

      let obj: any = {
        item: {
          AccessCode: accessCode,
          MenuAppFieldTag: prev,
        },
      }

      apiWrapper.put('api/v1/MenuAppFieldTag/salvar', obj).then((response) => {
        setTag((prev: any) => {
          prev.id = response.data.id
          return {...prev}
        })
      })

      return {...prev}
    })
  }

  const enviarMensagem = () => {
    if (texto == '') {
      return
    }

    let obj: any = {
      item: {
        AccessCode: accessCode,
        Comment: texto,
        IDMenuAppGenericValue: idMenuAppGenericValue.toString(),
      },
    }

    apiWrapper.put('api/v1/MenuAppFieldComment/salvar', obj).then(async (response) => {
      handleInit()
    })

    setTexto('')
  }

  const onEnterPress = (event: any) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault()
      enviarMensagem()
    }
  }

  function GetPessoa(IdPessoa: number) {
    return apiWrapper.get(`api/v1/User/carregarPessoa?IDPessoa=${IdPessoa}`)
  }

  const {mode} = useThemeMode()

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <Box className="d-flex flex-column h-100 w-100">
      <Box className="d-flex flex-column flex-grow-1">
        <Box
          className="card d-flex flex-column h-100"
          id="kt_chat_messenger"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Box className="input-group">
            <Autocomplete
              sx={{ pt: 1, border: 0 }}
              className="form-control"
              multiple
              options={[]}
              size="small"
              value={Tag != null ? JSON.parse(Tag.tag) : []}
              freeSolo
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => <TextField {...params} label="Tags" />}
              onChange={(event, value, reason) => handleOnChangeTags(value)}
            />
          </Box>

          {/* Messages Box with Dynamic Height */}
          <Box
            className="card-body flex-grow-1 overflow-auto"
            sx={{
              maxHeight: 'calc(100vh - 385px)',
              padding: '5px 0',
              background: mode == 'light' ? 'white' : '#151521',
            }}
            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
          >
            <div data-kt-element="messages">
              {mensagens.map((item: any, index: number) => {
                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                  item.idpessoaCreated != loggedUser?.id ? 'start' : 'end'
                } mb-10`;
                const state = item.idpessoaCreated != loggedUser?.id ? 'info' : 'primary';
                const userName = userNames.find((x) => x.id == item.idpessoaCreated);

                if (!userName) return null;

                return (
                  <Box
                    key={`message${index}`}
                    className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': item.template })}
                  >
                    <Box
                      className={clsx(
                        'd-flex flex-column align-items',
                        `align-items-${item.idpessoaCreated != loggedUser?.id ? 'start' : 'end'}`
                      )}
                    >
                      <Box className="d-flex align-items-center mb-2">
                        {item.idpessoaCreated != loggedUser?.id ? (
                          <>
                            <Box className="symbol symbol-35px symbol-circle">
                              <Avatar
                                src={
                                  userName.tipo == 998
                                    ? 'https://media.discordapp.net/attachments/1034584824364679329/1243011942306549861/ico-system.png?ex=664fec95&is=664e9b15&hm=46d01553168c372716470fe2fa9ca33f65e6a8889ee3fe8d2a25962a7a95fb61&=&format=webp&quality=lossless'
                                    : undefined
                                }
                                {...stringAvatar(userName.tipo, userName.name)}
                              />
                            </Box>
                            <Box className="ms-3">
                              <a href="#" className="fs-7 fw-bolder text-gray-900 text-hover-primary me-1">
                                {userName?.name}
                              </a>
                              <span className="text-muted fs-9 mb-1">
                                {`${new Date(item.dtCreated).toLocaleDateString()} ${new Date(
                                  item.dtCreated
                                ).toLocaleTimeString()}`}
                              </span>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box className="me-3">
                              <span className="text-muted fs-9 mb-1">
                                {`${new Date(item.dtCreated).toLocaleDateString()} ${new Date(
                                  item.dtCreated
                                ).toLocaleTimeString()}`}
                              </span>
                              <a href="#" className="fs-7 fw-bolder text-gray-900 text-hover-primary ms-1">
                                Você
                              </a>
                            </Box>
                            <Box className="symbol symbol-35px symbol-circle">
                              <Avatar
                                src={
                                  userName.tipo == 998
                                    ? 'https://media.discordapp.net/attachments/1034584824364679329/1243011942306549861/ico-system.png?ex=664fec95&is=664e9b15&hm=46d01553168c372716470fe2fa9ca33f65e6a8889ee3fe8d2a25962a7a95fb61&=&format=webp&quality=lossless'
                                    : undefined
                                }
                                {...stringAvatar(userName.tipo, userName.name)}
                              />
                            </Box>
                          </>
                        )}
                      </Box>

                      <Box
                        className={clsx(
                          'p-5 rounded',
                          `bg-light-${state}`,
                          'text-dark fw-bold mw-400px',
                          `text-${item.idpessoaCreated != loggedUser?.id ? 'start' : 'end'}`
                        )}
                        data-kt-element="message-text"
                        dangerouslySetInnerHTML={{ __html: item.comment }}
                      ></Box>
                    </Box>
                  </Box>
                );
              })}
            </div>
          </Box>

          {/* Footer with Input */}
          <Box
            className="card-footer d-flex flex-row align-items-center"
            sx={{ padding: '0', background: mode == 'light' ? 'white' : '#151521' }}
            id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
          >
            <TextField
              className="flex-grow-1 form-control"
              size="small"
              label="Escreva um comentário"
              value={texto}
              onChange={(event) => setTexto(event.target.value)}
              onKeyUp={(event) => onEnterPress(event)}
            />
            <Button variant="contained" onClick={enviarMensagem} startIcon={<SendIcon />} color="info">
              Enviar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat
