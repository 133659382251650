import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

interface ProjectInfoDisplayProps {
  text1: string;
  text2: string;
  text3: string;
  icon: any;
  visible: boolean;
}

const ProjectInfoDisplay: React.FC<ProjectInfoDisplayProps> = ({
  text1,
  text2,
  text3,
  icon,
  visible
}) => {

   /* Utilizado para previnir a abertura antecipada do elemento */
   const [mostrarElemento, setMostrarElemento] = useState(!visible);
   useEffect(() => {
    setMostrarElemento(visible);
  }, [visible]); // Certifique-se de adicionar 'visible' como dependência

   // Retornar null se não estiver visível
   if (!visible) {
    return null;
  }
 

  if (!visible) 
    return (<></>);
  else
    return (
      <div>
        { mostrarElemento ?
          <Box display="flex" alignItems="center" p={2} borderRadius={1}>
            <Box sx={{ fontSize: 64, marginRight: 2, width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <i className={icon} aria-hidden="true" style={{ fontSize: '70%', width: '70%', height: '70%' }}></i>
            </Box>
            <Box>
              <Typography variant="body1" fontWeight="bold">{text1}</Typography>
              <Typography variant="body2">{text2}</Typography>
              <Typography variant="body2">{text3}</Typography>
            </Box>
          </Box>
          : null
        }
      </div>
    );
};

export default ProjectInfoDisplay;