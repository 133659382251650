import {useState, useEffect} from 'react'

import ReactFlow, {Edge, Node} from 'reactflow'
import 'reactflow/dist/style.css'
import 'reactflow/dist/base.css'

import DialogTitle from '@mui/material/DialogTitle'
import StyledDialog from '../styled/StyledDialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {MenuAppActionResult} from '../../../../modules/api/models/Result/MenuAppActionResult'
import {MenuAppFieldsRuleResult} from '../../../../modules/api/models/Result/MenuAppFieldsRuleResult'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import ModalForm from './modalForm'
import CustomNode from '../flowchart/customNode'

import BoltIcon from '@mui/icons-material/Bolt'
import HelpIcon from '@mui/icons-material/Help'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import AttractionsIcon from '@mui/icons-material/Attractions'
import MessageIcon from '@mui/icons-material/Message'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'

import {CGlobalFilterStructure} from '../../../../models/classes/CMenuAppHistoryStructure'
import {CRuleActions} from '../../../../models/classes/CRuleActions'
import FieldRuleFilter from '../field-rules/fieldRuleFilter'
import FieldRuleActions from '../field-rules/fieldRuleActions'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import ModalModeloSQL from './modalModeloSQL'
import {toast} from 'react-toastify'
import StyledButton from '../styled/StyledButton'
import { Autocomplete } from '@mui/material'

const nodeTypes = {
  custom: CustomNode,
}

interface ISYSACTModal {
  id: number
  accessCode: string
  callBack: (response: any) => void
}

const SYSACTModal = ({id, accessCode, callBack}: ISYSACTModal) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  //* Variáveis de estado do componente
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [menuAppAction, setMenuAppAction] = useState<MenuAppActionResult | undefined>(undefined)
  const [menuAppFieldRule, setMenuAppFieldRule] = useState<MenuAppFieldsRuleResult | undefined>(undefined)
  const [maxWidth, setMaxWidth] = useState<string>('md')

  const menuAppOptions = ['Custom', 'PadraoModalCustom'];

  //* callback para o componente pai deste
  const handleCallBack = (response: any = null) => {
    if (callBack != null) {
      callBack(response)
    }
  }

  //* Carregamos os dados e deixamos os componentes pronto para a edição dos mesmos
  const handleOpen = async () => {
    setLoading(true)

    let menuAppActionRequest = await apiWrapper.get(`api/v1/MenuAppAction/carregar?item.AccessCode=${accessCode}&item.ID=${id}`)
    let _menuAppAction = menuAppActionRequest.data.data_a as MenuAppActionResult | undefined
    let _menuAppFieldRule = menuAppActionRequest.data.data_b as MenuAppFieldsRuleResult | undefined

    setMenuAppAction(_menuAppAction)
    setMenuAppFieldRule(_menuAppFieldRule)

    if (_menuAppFieldRule == null) {
      setMaxWidth('sm')
    } else {
      if (_menuAppFieldRule.atributo08 != null && _menuAppFieldRule.atributo08 != '') {
        setFiltersExibicao(JSON.parse(_menuAppFieldRule.atributo08))
      }

      if (_menuAppFieldRule.atributo09 != null && _menuAppFieldRule.atributo09 != '') {
        setFilters(JSON.parse(_menuAppFieldRule.atributo09))
      }

      if (_menuAppFieldRule.atributo10 != null && _menuAppFieldRule.atributo10 != '') {
        setAcoesDeRegra(JSON.parse(_menuAppFieldRule.atributo10))
      }
    }

    setLoading(false)
  }

  //* Salvamento e fechamento do modal
  const handleClose = async (save: boolean) => {
    if (save) {
      setSaving(true)

      if (menuAppFieldRule != null) {
        if (menuAppFieldRule.atributo01 == null || menuAppFieldRule.atributo01 == '') {
          toast.error('É necessário informar a mensagem de confirmação!')
          setSaving(false)
          return
        }

        menuAppFieldRule.atributo08 = JSON.stringify(filtersExibicao)
        menuAppFieldRule.atributo09 = JSON.stringify(filters)
        menuAppFieldRule.atributo10 = JSON.stringify(acoesDeRegra)

        await apiWrapper.put('api/v1/MenuAppFieldsRule/salvar', {item: {MenuAppFieldsRule: menuAppFieldRule}}).catch((error) => {
          toast.error(error.response?.data?.mensagem ?? error.message)
        })
      }

      await apiWrapper.put('api/v1/MenuAppAction/salvar', {item: {MenuAppAction: menuAppAction}}).catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })

      setSaving(false)
    }

    handleCallBack({save: save || id == 0})
  }

  //* OnChange para o menuAppAction
  const handleOnChangeMenuAppAction = (key: string, value: any) => {
    setMenuAppAction((prev) => {
      if (prev == null) {
        return
      }

      prev[key] = value

      return {...prev}
    })
  }

  //* OnChange para o menuAppFieldRule
  const handleOnChangeMenuAppFieldRule = (key: string, value: any) => {
    setMenuAppFieldRule((prev) => {
      if (prev == null) {
        return
      }

      prev[key] = value

      return {...prev}
    })
  }

  //* Função que retorna quando os controles devem ser bloquados
  const isEditable = () => {
    return !loading && !saving
  }

  /* MODAL FORM */

  const [modalForm, setModalForm] = useState<boolean>(false)
  const [modalFormComponentMode, setModalFormComponentMode] = useState<string>()

  const handleOpenModalForm = (componentMode: string) => {
    setModalForm(true)
    setModalFormComponentMode(componentMode)
  }

  const handleModalFormCallBack = (data?: any) => {
    setModalForm(false)
    setModalFormComponentMode('')
  }

  /* MODAL FILTERS */

  const [filters, setFilters] = useState<Array<CGlobalFilterStructure>>([])
  const [modalFilter, setModalFilter] = useState<boolean>(false)

  const filterCallBack = (response: any = null) => {
    if (response?.apply) {
    }

    setModalFilter(false)
  }

  const handleOpenModalFilters = () => {
    setModalFilter(true)
  }

  /* MODAL FILTERS EXIBICAO */

  const [filtersExibicao, setFiltersExibicao] = useState<Array<CGlobalFilterStructure>>([])
  const [modalFilterExibicao, setModalFilterExibicao] = useState<boolean>(false)

  const filterExibicaoCallBack = (response: any = null) => {
    if (response?.apply) {
    }

    setModalFilterExibicao(false)
  }

  const handleOpenModalFiltersExibicao = () => {
    setModalFilterExibicao(true)
  }

  /* MODAL AÇÕES */

  const [acoesDeRegra, setAcoesDeRegra] = useState<Array<CRuleActions>>([])
  const [modalAcoesDeRegra, setModalAcoesDeRegra] = useState<boolean>(false)

  const handleOpenModalAcoesDeRegra = () => {
    setModalAcoesDeRegra(true)
  }

  const handleCallBackModalAcoesDeRegra = (response: any = null) => {
    setModalAcoesDeRegra(false)
  }

  /* MODAL MODELO SQL */

  const [modalModeloSQL, setModalModeloSQL] = useState<boolean>(false)
  const [modalModeloSQLTipo, setModalModeloSQLTipo] = useState<string>('')

  const handleOpenModalModeloSQL = (tipo: string) => {
    setModalModeloSQL(true)
    setModalModeloSQLTipo(tipo)
  }

  const modalModeloCallback = (data?: any) => {
    setModalModeloSQL(false)
  }

  /* NODES */

  const [selectedFlowNode, setSelectedFlowNode] = useState<Node | undefined>(undefined)

  const handleOnNodeClick = (event: React.MouseEvent, node: Node) => {
    setSelectedFlowNode(node)

    if (node.data.onClick == null) {
      return
    }

    node.data.onClick(event)
  }

  //* Use effect inicial que chama o handleOpen quando o componente renderiza pela primeira vez
  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <StyledDialog open={true} fullWidth={true} maxWidth={maxWidth}>
      <DialogTitle>Ação de App</DialogTitle>
      <DialogContent dividers={true}>
        {loading && (
          <Box component='div' className='d-flex justify-content-center'>
            <CircularProgress />
          </Box>
        )}

        {!loading && menuAppAction != null && menuAppFieldRule != null && (
          <Box component='div' className='d-flex flex-row' sx={{width: '100%', maxHeight: '400px'}}>
            <Box component='div' className='d-flex flex-column' sx={{width: '50%'}}><Box component='div' sx={{ p: 1, width: '100%' }}>
              <Autocomplete
                disablePortal
                options={menuAppOptions}
                sx={{ width: '100%' }}
                size='small'
                value={menuAppAction.tipo}
                onChange={(event, value) => handleOnChangeMenuAppAction('tipo', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    disabled={!isEditable()}
                    label='Tipo'
                  />
                )}
              />
            </Box>
              <Box component='div' sx={{p: 1, width: '100%'}}>
                <TextField
                  fullWidth
                  disabled={!isEditable()}
                  size='small'
                  label='Nome'
                  value={menuAppAction.nome}
                  onChange={(event) => handleOnChangeMenuAppAction('nome', event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1, width: '100%'}}>
                <TextField
                  fullWidth
                  disabled={!isEditable()}
                  size='small'
                  label='Descrição'
                  value={menuAppAction.descricao}
                  onChange={(event) => handleOnChangeMenuAppAction('descricao', event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1, width: '100%'}}>
                <TextField
                  fullWidth
                  multiline
                  maxRows={11}
                  minRows={11}
                  disabled={!isEditable()}
                  size='small'
                  label='Comentário'
                  value={menuAppFieldRule.comentario}
                  onChange={(event) => handleOnChangeMenuAppFieldRule('comentario', event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: '100%'}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => handleOnChangeMenuAppAction('biVisible', event.target.checked)}
                        checked={menuAppAction.biVisible}
                      />
                    }
                    label='Visível'
                  />
                </FormGroup>
                <FormGroup sx={{width: '100%'}}>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={(event) => handleOnChangeMenuAppAction('biAtivo', event.target.checked)} checked={menuAppAction.biAtivo} />
                    }
                    label='Ativo'
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box component='div' sx={{width: '50%', height: '400px'}}>
              <ReactFlow
                nodes={[
                  {
                    id: '1',
                    position: {x: 220, y: 0},
                    type: 'custom',
                    data: {
                      label: 'Critérios de exibição',
                      icon: <DisplaySettingsIcon />,
                      badgeContent: filtersExibicao.length,
                      onClick: (event: React.MouseEvent) => {
                        handleOpenModalFiltersExibicao()
                      },
                    },
                  },
                  {
                    id: '2',
                    position: {x: 220, y: 100},
                    type: 'custom',
                    data: {
                      label: 'Critérios de execução',
                      icon: <DoneAllIcon />,
                      badgeContent: filters.length,
                      onClick: (event: React.MouseEvent) => {
                        handleOpenModalFilters()
                      },
                    },
                  },
                  {
                    id: '3',
                    position: {x: 191, y: 200},
                    type: 'custom',
                    data: {
                      label: 'Procedimento de pré-validação',
                      badgeContent:
                        menuAppFieldRule.atributo02 != null && menuAppFieldRule.atributo02 != '' && menuAppFieldRule.atributo02 == 'true'
                          ? 1
                          : undefined,
                      icon: <AttractionsIcon />,
                      onClick: (event: React.MouseEvent) => {
                        handleOpenModalForm('PROCEDIMENTO')
                      },
                    },
                  },
                  {
                    id: '4',
                    position: {x: 204, y: 300},
                    type: 'custom',
                    data: {
                      label: 'Mensagem de confirmação',
                      icon: <MessageIcon />,
                      badgeContent: menuAppFieldRule.atributo01 != null && menuAppFieldRule.atributo01 != '' ? 1 : undefined,
                      onClick: (event: React.MouseEvent) => {
                        handleOpenModalForm('MENSAGEM')
                      },
                    },
                  },
                  {
                    id: '5',
                    position: {x: 228, y: 400},
                    type: 'custom',
                    data: {
                      label: 'Execução de ações',
                      icon: <BoltIcon />,
                      badgeContent: acoesDeRegra.length,
                      onClick: (event: React.MouseEvent) => {
                        handleOpenModalAcoesDeRegra()
                      },
                    },
                  },
                ]}
                edges={[
                  {id: 'e1-2', source: '1', target: '2', animated: true},
                  {id: 'e2-3', source: '2', target: '3', animated: true},
                  {id: 'e3-4', source: '3', target: '4', animated: true},
                  {id: 'e4-5', source: '4', target: '5', animated: true},
                ]}
                nodeTypes={nodeTypes}
                onNodeClick={handleOnNodeClick}
                panOnDrag={false}
                maxZoom={0.75}
                minZoom={0.75}
                fitView
              />
            </Box>
          </Box>
        )}
        {!loading && menuAppAction != null && menuAppFieldRule == null && (
          <>
            <Box component='div' sx={{p: 1, width: '100%'}}>
              <TextField
                fullWidth
                disabled={!isEditable()}
                size='small'
                label='Nome'
                value={menuAppAction.nome}
                onChange={(event) => handleOnChangeMenuAppAction('nome', event.target.value)}
              />
            </Box>
            <Box component='div' sx={{p: 1, width: '100%'}}>
              <TextField
                fullWidth
                disabled={!isEditable()}
                size='small'
                label='Descrição'
                value={menuAppAction.descricao}
                onChange={(event) => handleOnChangeMenuAppAction('descricao', event.target.value)}
              />
            </Box>
            <Box component='div' sx={{p: 1}}>
              <FormGroup sx={{width: '100%'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => handleOnChangeMenuAppAction('biVisible', event.target.checked)}
                      checked={menuAppAction.biVisible}
                    />
                  }
                  label='Visível'
                />
              </FormGroup>
            </Box>
          </>
        )}

        {/* MODAL FORM */}
        {modalForm && menuAppFieldRule != null && (
          <ModalForm
            title={selectedFlowNode?.data?.label}
            fullWidth={true}
            maxWidth='sm'
            element={
              <>
                {modalFormComponentMode == 'PROCEDIMENTO' && (
                  <Box component='div' sx={{minHeight: '230px'}}>
                    <Box component='div' sx={{p: 1}}>
                      <FormGroup sx={{width: '100%'}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event) => handleOnChangeMenuAppFieldRule('atributo02', event.target.checked ? 'true' : 'false')}
                              checked={
                                menuAppFieldRule.atributo02 != null && menuAppFieldRule.atributo02 != '' && menuAppFieldRule.atributo02 == 'true'
                              }
                            />
                          }
                          label='Executar um procedimento de pré-validação'
                        />
                      </FormGroup>
                    </Box>
                    {menuAppFieldRule.atributo02 == 'true' && (
                      <>
                        <Box component='div' sx={{mb: 0, p: 1, display: 'flex', width: '100%'}}>
                          <TextField
                            fullWidth
                            disabled={!isEditable()}
                            size='small'
                            label='Nome do procedimento'
                            value={menuAppFieldRule.formula}
                            onChange={(event) => handleOnChangeMenuAppFieldRule('formula', event.target.value)}
                          />
                          <Box component='div'>
                            <IconButton aria-label='question' color='inherit' onClick={() => handleOpenModalModeloSQL('Validação')}>
                              <HelpIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box component='div' sx={{p: 1}}>
                          <FormGroup sx={{width: '100%', alignContent: 'center'}}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(event) => handleOnChangeMenuAppFieldRule('atributo03', event.target.checked ? 'true' : 'false')}
                                  checked={
                                    menuAppFieldRule.atributo03 != null && menuAppFieldRule.atributo03 != '' && menuAppFieldRule.atributo03 == 'true'
                                  }
                                />
                              }
                              label='Pré-validação pode conter confirmação?'
                            />
                          </FormGroup>
                        </Box>
                        <Box component='div' className='alert alert-primary d-flex align-items-center' sx={{m: 1}}>
                          <div className='d-flex flex-column'>
                            <h4 className='mb-1 text-dark'>Atenção</h4>
                            Este procedimento de validação será executado antes da tela de Confirmação. Somente se a pre-validação retornar "SUCESSO",
                            a tela de confirmação será aberta.
                            {menuAppFieldRule.atributo03 == 'true' && (
                              <>
                                <br />
                                <br />
                                Caso o procedimento retorne o padrão "CONFIRMACAO:TEXTOCONFIRMACAO", a área de retorno "TEXTOCONFIRMACAO" será
                                utilizada em uma pré confirmação para seguimento do fluxo.
                              </>
                            )}
                          </div>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
                {modalFormComponentMode == 'MENSAGEM' && (
                  <Box component='div' sx={{p: 1, width: '100%'}}>
                    <TextField
                      fullWidth
                      disabled={!isEditable()}
                      size='small'
                      label='Pergunta a ser realizada na confirmação:'
                      value={menuAppFieldRule.atributo01}
                      onChange={(event) => handleOnChangeMenuAppFieldRule('atributo01', event.target.value)}
                      multiline
                      maxRows={6}
                      minRows={6}
                    />
                  </Box>
                )}
              </>
            }
            callBack={handleModalFormCallBack}
          />
        )}

        {/* MODAL FILTER */}
        {modalFilter && (
          <FieldRuleFilter accessCode={accessCode} accessCodeExtra={accessCode} filters={filters} setFilters={setFilters} callBack={filterCallBack} />
        )}

        {/* MODAL FILTER EXIBICAO */}
        {modalFilterExibicao && (
          <FieldRuleFilter
            accessCode={accessCode}
            accessCodeExtra={accessCode}
            filters={filtersExibicao}
            setFilters={setFiltersExibicao}
            callBack={filterExibicaoCallBack}
          />
        )}

        {/* MODAL MODELO SQL */}
        {modalModeloSQL && <ModalModeloSQL tipoModelo={modalModeloSQLTipo} callBack={modalModeloCallback} />}

        {/* MODAL AÇÕES TAREFA */}
        {modalAcoesDeRegra && (
          <FieldRuleActions
            tipo='CONFIRMATION'
            accessCode={accessCode}
            actions={acoesDeRegra}
            setActions={setAcoesDeRegra}
            callBack={handleCallBackModalAcoesDeRegra}
          />
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton disabled={!isEditable()} variant='contained' color='inherit' onClick={() => handleClose(false)}>
          Fechar
        </StyledButton>
        <StyledButton disabled={!isEditable()} variant='contained' color='success' onClick={() => handleClose(true)}>
          Salvar
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default SYSACTModal
