import {useEffect, useState} from 'react'

import {Theme, styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Checkbox from '@mui/material/Checkbox'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import LinearProgress from '@mui/material/LinearProgress'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import HelpIcon from '@mui/icons-material/Help'

import {toast} from 'react-toastify'
import {GridActionsCellItem, GridRowSelectionModel, useGridApiRef, ptBR} from '@mui/x-data-grid-pro'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {NoRowsOverlay} from '../utility/NoRowsOverlay'
import {StyledDataGrid} from '../grid/StyledDataGrid'
import FieldRule from '../field-rules/fieldRule'
import UploadCollection from '../upload/UploadCollection'
import MenuAppResourceSelect from '../utility/menuAppResourceSelect'
import {CMenuAppJsonFields} from '../../../../models/classes/CMenuAppJsonField'
import {useThemeMode} from '../../../../../_metronic/partials'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'
import DynamicList from '../tabs/components/DynamicList'
import {StyledRadioGroup} from '../styled/StyledRadioButton'
import FieldRuleModalV2 from '../field-rules/fieldRuleModalV2'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import IconButton from '@mui/material/IconButton'
import ModalModeloSQL from './modalModeloSQL'
import { Typography } from '@mui/material'
import ProjectInfoDisplay from '../utility/ProjectInfoDisplay'

const muiTheme = createTheme({
  typography: {
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: 15,
  },
})

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface IModalSYSMAPProps {
  id: number
  idmenuAppPai?: number
  callBack?: any
  deleteFunc?: any
}

const SYSMAPModal = ({id, idmenuAppPai, callBack, deleteFunc}: IModalSYSMAPProps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Saving, setSaving] = useState<boolean>(false)
  const [Data, setData] = useState<any>(null)
  const [DataID, setDataID] = useState<number>(0)
  const [DataTables, setDataTables] = useState<any>([])
  const [DataTablesFields, setDataTablesFields] = useState<any>([])
  const [TabIndex, setTabIndex] = useState<number>(0)
  const [Roles, setRoles] = useState<any>([])
  const [RoleNames, setRoleNames] = useState<any>([])
  const [jsonField, setJsonField] = useState<any>(null)
  const [menuAppFields, setMenuAppFields] = useState<any>([])
  const [Files, setFiles] = useState<any>([])
  const [Origens, setOrigens] = useState<any>([])
  const [order, setOrder] = useState<string>('DESC')
  const [ruleCount, setRuleCount] = useState<number>(0)

  const MenuAppTypes = [
    {id: 'SQL', label: 'SQL Customizado'},
    {id: 'TABLE', label: 'Tabela'},
    {id: 'VIEW', label: 'View'},
  ]

  const [Validation, setValidation] = useState<any>({
    accessCode: {
      isValid: true,
      message: undefined,
    },
    titulo: {
      isValid: true,
      message: undefined,
    },
  })

  const [TipoMenuApp, setTipoMenuApp] = useState<string>('1')

  const handleValidateField = (event: any, field: string) => {
    switch (field) {
      case 'titulo':
        if (event.target.value == '') {
          setValidation({
            ...Validation,
            [field]: {
              isValid: false,
              message: 'O título é obrigatório.',
            },
          })
        } else {
          apiWrapper.get(`api/v1/System/verificarExistenciaTabela?item.Query=${event.target.value}`).then((response) => {
            if (response.data.data) {
              setValidation({
                ...Validation,
                [field]: {
                  isValid: false,
                  message: 'Já existe uma tabela com este nome.',
                },
              })
            }
          })
        }
        break
      case 'accessCode':
        if (event.target.value.toLowerCase().startsWith('sys')) {
          setValidation({
            ...Validation,
            [field]: {
              isValid: true,
              message: 'Não é permitido usar o prefixo "SYS" para o código de acesso.',
            },
          })
        } else if (event.target.value.toLowerCase().startsWith('zplug')) {
          setValidation({
            ...Validation,
            [field]: {
              isValid: true,
              message: 'Não é permitido usar o prefixo "ZPLUG" para o código de acesso, somente para plugins.',
            },
          })
        } else {
          apiWrapper
            .get(`api/v1/MenuApp/verificarExistenciaDeAccessCode?item.IDMenuApp=${DataID}&item.AccessCode=${event.target.value}`)
            .then((response) => {
              if (response.data.data) {
                setValidation({
                  ...Validation,
                  [field]: {
                    isValid: false,
                    message: 'Já existe um app com este código de acesso.',
                  },
                })
              } else {
                setValidation({
                  ...Validation,
                  [field]: {
                    isValid: true,
                    message: undefined,
                  },
                })
              }
            })
        }
        break
    }
  }

  const handleValidateAll = () => {
    let isValid = true

    if (Data.accessCode == null || Data.accessCode == '') {
      isValid = false
    }

    if (!Validation.accessCode.isValid) {
      toast.error(Validation.accessCode.message)
      isValid = false
    }

    if (!Validation.titulo.isValid) {
      toast.error(Validation.titulo.message)
      isValid = false
    }

    return isValid
  }

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = async (id: number) => {
    setDataID(id)
    setOpened(true)

    if (id == 0) {
      setTitle('Adicionar')
    } else {
      setTitle('Editar')
      setTipoMenuApp('2')
    }

    let url = 'api/v1/MenuApp/carregar?item.ID=' + id

    let ret = await apiWrapper.get(url)
    let data = ret.data

    let jsonFieldConfig = new CMenuAppJsonFields()

    if (data.data.jsonField != '' && data.data.jsonField != '{}') {
      jsonFieldConfig = JSON.parse(data.data.jsonField) as CMenuAppJsonFields
    }

    if (data.data.sqlFieldOrder != null && data.data.sqlFieldOrder != '') {
      let split = data.data.sqlFieldOrder.split(' ')
      data.data.sqlFieldOrder = split[0]
      setOrder(split[1] ?? 'DESC')
    }

    setData(data.data)
    setJsonField(jsonFieldConfig)

    if (data.data.sqlObject != '') {
      apiWrapper.get('api/v1/System/carregarCamposTabela?item.Tabela=' + data.data.sqlObject).then((response) => {
        setDataTablesFields(response.data.data)
      })
    }

    apiWrapper.get('api/v1/MenuAppFactory/listar?item.Query=').then((response) => {
      setOrigens(response.data.data)
    })

    if (data.data.id != 0) {
      handleLoadFieldRules(data.data.id)

      apiWrapper.get('api/v1/MenuAppRole/carregarPorMenuApp?item.IDMenuApp=' + data.data.id).then((response) => {
        setRoles(response.data.data)
        setRoleNames(response.data.names)
      })

      apiWrapper.get('api/v1/Arquivo/listarPorMenuApp?item.IDMenuApp=' + data.data.id).then((response) => {
        setFiles(response.data.data)
      })

      apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${data.data.accessCode}&item.CamposDeSistema=true`).then((response) => {
        setMenuAppFields(
          response.data.data.filter((item: any) => {
            return item.tipo != 'upload' && item.tipo != 'tag' && item.tipo != 'formulaSQL' && item.campo.toLowerCase() != 'id'
          })
        )
      })
    }
  }

  const handleClose = (save: boolean, keepOpen: boolean) => {
    if (!save) {
      setOpened(false)
      setData(null)
      setDataTables([])
      setDataTablesFields([])
      setTabIndex(0)
      handleCallBack()
      return
    }

    if (!handleValidateAll()) {
      toast.error('Preencha todos os campos obrigatórios.')
      return
    }

    setSaving(true)

    Data.jsonField = JSON.stringify(jsonField)
    Data.sqlFieldOrder = `${Data.sqlFieldOrder} ${order}`
    Data.idmenuAppPai = idmenuAppPai != null ? idmenuAppPai : Data.idmenuAppPai

    let obj: any = {
      item: {
        MenuApp: Data,
        CriarTabela: TipoMenuApp == '1',
      },
    }

    apiWrapper.put('api/v1/MenuApp/salvar', obj).then(async (response) => {
      await apiWrapper.put('api/v1/MenuAppRole/salvar', {
        item: {Roles: Roles, IDMenuApp: response.data.id},
      })

      setData(null)
      setDataTables([])
      setDataTablesFields([])
      setTabIndex(0)

      if (!keepOpen) {
        setOpened(false)
      } else {
        setSaving(false)
        handleOpen(response.data.id)
      }

      let res: any = {
        id: response.data.id,
        keepOpen: keepOpen,
      }

      handleCallBack(res)
      toast.success('Registro salvo com sucesso!')
    })
  }

  const handleOpenExcluir = () => {
    if (deleteFunc != null) {
      deleteFunc(DataID, 'SYSMAP')
      handleClose(false, false)
    }
  }

  const handleTabIndexChange = (event: any, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleOnChangeAutoComplete = (key: string, value: any) => {
    setData((prev: any) => {
      if (key == 'sqlObject') {
        prev['sqlFieldOrder'] = value != null ? 'ID' : ''

        apiWrapper.get('api/v1/System/carregarCamposTabela?item.Tabela=' + value).then((response) => {
          setDataTablesFields(response.data.data)
        })
      }

      if (key == 'idmenuAppFactory') {
        prev['sqlFieldOrder'] = ''
        prev['sqlObject'] = ''
        prev['idmenuAppFactory'] = value?.id ?? ''
        return {...prev}
      }

      if (key == 'sqlType') {
        prev['sqlType'] = value?.id ?? ''
        return {...prev}
      }

      prev[key] = value
      return {...prev}
    })
  }

  const handleOnChangeJsonFieldAutoComplete = (key: string, value: any) => {
    setJsonField((prev: CMenuAppJsonFields) => {
      prev.kanban[key] = value?.id ?? 0
      return {...prev}
    })
  }

  const handleOnInputChangeAutoComplete = (key: string, value: any) => {
    switch (key) {
      case 'sqlObject':
        apiWrapper.get('api/v1/System/carregarTabelas?item.Query=' + value + '&item.IDMenuAppFactory=' + Data.idmenuAppFactory).then((response) => {
          setDataTables(response.data.data)
        })
        break
      case 'idmenuAppFactory':
        apiWrapper.get('api/v1/MenuAppFactory/listar?item.Query=' + value).then((response) => {
          setOrigens(response.data.data)
        })
        break
    }
  }

  const handleOnOpenAutoComplete = (key: string, event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete(key, '')
    }
  }

  const handleOnChangeInput = (key: string, event: any) => {
    switch (key) {
      case 'titulo':
      case 'accessCode':
        setValidation((prev: any) => {
          prev[key]['isValid'] = true
          prev[key]['message'] = undefined
          return {...prev}
        })
        break
    }

    setData((prev: any) => {
      prev[key] = event.target.value
      return {...prev}
    })
  }

  const handleOnChangeBoolean = (key: string, event: any) => {
    setData((prev: any) => {
      prev[key] = event.target.checked
      return {...prev}
    })
  }

  const handleOnChangeTipoMenuApp = (event: any) => {
    setTipoMenuApp(event.target.value)

    setData((prev: any) => {
      prev['sqlObject'] = ''
      prev['sqlFieldOrder'] = ''
      return {...prev}
    })
  }

  const handleChangeRoleBoolean = (index: number, key: string, event: any) => {
    setRoles((prev: any) => {
      prev[index][key] = event.target.checked
      return [...prev]
    })
  }

  const handleOnChangeOrder = (event: any) => {
    setOrder(event.target.value)
  }

  const handleOnUpload = (newAzFile: any) => {
    setFiles((prev: any) => {
      return [...prev, newAzFile]
    })
  }

  const handleOnDeleteUpload = (AzFile: any) => {
    let obj: any = {
      item: {
        Nome: AzFile.nome,
      },
    }

    apiWrapper.delete('api/v1/Storage/ApagarArquivoAz', {data: obj, headers: {}})

    setFiles((prev: any) => {
      prev = prev.filter((item: any) => item.nome != AzFile.nome)
      return [...prev]
    })
  }

  const handleDeleteAllUploads = () => {
    Files.map((item: any, index: number) => {
      if (item.id != 0) {
        return
      }

      handleOnDeleteUpload(item)
    })
  }

  const isEditable = () => {
    return !Saving
  }

  function LoadRoleName(index: number) {
    return RoleNames[index]
  }

  /* GRID FILTRO */

  const apiRef_Filtros = useGridApiRef()
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

  /* FILTRO AVANCADO */

  const [fieldRules, setFieldRules] = useState<Array<any>>([])
  const [modalFieldRule, setModalFieldRule] = useState<boolean>(false)
  const [modalFieldRuleItemID, setModalFieldRuleItemID] = useState<number>(-1)
  const [modalFieldRuleLogicalOperator, setModalFieldRuleLogicalOperator] = useState<string>('')

  function BuildGridColumnFieldRule() {
    let columns: Array<any> = [
      {
        field: 'actions',
        width: 25,
        type: 'actions',
        editable: false,
        hideable: false,
        getActions: (params: any) => BuildActionsFieldRule(params),
      },
      {
        field: 'atributo10',
        type: 'number',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        width: 75,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'atributo03',
        type: 'string',
        headerName: 'Campo',
        headerAlign: 'left',
        align: 'left',
        width: 300,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'atributo06',
        type: 'string',
        headerName: 'Operador',
        headerAlign: 'left',
        align: 'left',
        width: 175,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'atributo07',
        type: 'string',
        headerName: 'Tipo Valor',
        headerAlign: 'left',
        align: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'valor',
        type: 'number',
        headerName: 'Valor',
        headerAlign: 'left',
        align: 'left',
        width: 400,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
    ]

    columns[2].valueGetter = ({value}) => {
      if (value == null || value == '') {
        return ''
      } else if (value == -1) {
        return 'Em grupo'
      }

      return value
    }

    columns[5].valueGetter = ({value}) => {
      if (value != null && typeof value == 'string' && value.startsWith('{') && value.endsWith('}')) {
        let parsed = JSON.parse(value)
        return parsed?.label ?? 'N/A'
      } else {
        if (value === 'true') {
          return 'Verdadeiro'
        } else if (value === 'false') {
          return 'Falso'
        }
      }

      return value
    }

    return columns
  }

  function BuildActionsFieldRule(params: any) {
    let actions: Array<JSX.Element> = [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Editar'
        onClick={() => {
          handleOpenModalFieldRule(params.row.id)
        }}
        showInMenu
      />,
      <GridActionsCellItem icon={<DeleteIcon />} label='Excluir' onClick={() => handleDeleteFieldRule(params.row.id)} showInMenu />,
    ]

    return actions
  }

  const handleLoadFieldRules = (idMenuApp: number) => {
    apiWrapper.get(`api/v1/MenuAppFieldsRule/listarFiltroAvancadoPorMenuApp?item.IDMenuApp=${idMenuApp}`).then((response) => {
      setFieldRules(response.data.data)
    })
  }

  const handleOpenModalFieldRule = (id: number) => {
    let logicalOperator = ''
    let index = 0

    let item = fieldRules.find((x) => x.id == id)
    if (item != null) {
      index = Math.max(fieldRules.indexOf(item), 0)
    } else {
      index = fieldRules.length
    }

    if (index < 1) {
      logicalOperator = '-'
    } else if (index == 1) {
      logicalOperator = ''
    } else {
      //logicalOperator = fieldRules[1].atributo10
    }

    setModalFieldRule(true)
    setModalFieldRuleItemID(id)
    setModalFieldRuleLogicalOperator(logicalOperator)
  }

  const modalFieldRuleCallback = (response: any) => {
    setModalFieldRule(false)
    setModalFieldRuleItemID(0)
    handleLoadFieldRules(DataID)
  }

  const handleDeleteFieldRule = (id: any) => {
    let obj: any = {
      item: {
        ID: id,
      },
    }

    apiWrapper.delete('api/v1/MenuAppFieldsRule/excluir', {data: obj, headers: {}}).then((response) => {
      handleLoadFieldRules(DataID)
      toast.success('Registro excluído com sucesso!')
    })
  }

  const handleDeleteFieldRules = () => {
    for (const item of selectionModel) {
      handleDeleteFieldRule(item)
    }
  }

  /* MODAL MODELO SQL */

  const [modalModeloSQL, setModalModeloSQL] = useState<boolean>(false)
  const [modalModeloSQLTipo, setModalModeloSQLTipo] = useState<string>('')

  const handleOpenModalModeloSQL = (tipo: string) => {
    setModalModeloSQL(true)
    setModalModeloSQLTipo(tipo)
  }

  const modalModeloCallback = (data?: any) => {
    setModalModeloSQL(false)
  }

  /* THEME */

  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  /* END THEME */

  useEffect(() => {
    handleOpen(id)
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog fullScreen open={Opened}>
        <DialogTitle>
          {Title}
          {Data && <> - {Data.titulo}</>}
          <Box component='div' sx={{float: 'inline-end'}}>
            {Data != null && Data.idmenuAppPai != null && <span className='badge bg-primary text-light m-1'>Visão</span>}
          </Box>
        </DialogTitle>
        <DialogContent dividers={true} sx={{py: 0, px: 1}}>
          
        {Data && (
          <ProjectInfoDisplay
            text1={Data.titulo}
            text2={Data.descricao}
            text3={Data.sqlType != null && Data.sqlType != '' ? MenuAppTypes.find((op: any) => op.id == Data.sqlType)?.label ?? '' : ''}
            icon={Data.icone}
            visible={Data ? true : false}
          />
        )}
          <Tabs value={TabIndex} onChange={handleTabIndexChange} aria-label='SYSMAP Tabs'>
            <Tab label={Data != null && Data.idmenuAppPai == null ? 'App' : 'Visão'} value={0} />
            {Data != null && Data.idmenuAppPai == null && <Tab hidden={DataID == 0} label='Visões' value={1} />}
            <Tab hidden={DataID == 0} label='Campos' value={2} />
            <Tab hidden={DataID == 0 || (Data != null && Data.idmenuAppPai != null)} label='Relações' value={3} />
            <Tab hidden={DataID == 0 || (Data != null && Data.idmenuAppPai != null)} label='Regras' value={4} />
            <Tab hidden={DataID == 0} label='Filtros' value={5} />
            <Tab hidden={DataID == 0} label='Permissões' value={6} />
            <Tab hidden={DataID == 0 || (Data != null && Data.idmenuAppPai != null)} label='Kanban' value={7} />
            <Tab hidden={DataID == 0} label='Açoes' value={8} />
            <Tab hidden={DataID == 0} label='Logs' value={9} />
          </Tabs>
          <FormControl hidden={TabIndex != 0} sx={{py: 1}} component='fieldset' variant='standard'>
            {Opened && Data && (
              <>
                {DataID == 0 && (
                  <Box component='div' sx={{p: 1}}>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>Tipo de Menu App</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={TipoMenuApp}
                        onChange={(event) => handleOnChangeTipoMenuApp(event)}
                      >
                        <FormControlLabel value='1' control={<Radio />} label='Nova Tabela' />
                        <FormControlLabel value='2' control={<Radio />} label='Tabela Existente' />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    required
                    error={!Validation.titulo.isValid}
                    helperText={Validation.titulo.message}
                    onBlur={(event) => handleValidateField(event, 'titulo')}
                    sx={{width: 487}}
                    disabled={!isEditable()}
                    size='small'
                    label='Título'
                    value={Data.titulo}
                    onChange={(event) => handleOnChangeInput('titulo', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 487}}
                    disabled={!isEditable()}
                    size='small'
                    label='Descrição'
                    value={Data.descricao}
                    onChange={(event) => handleOnChangeInput('descricao', event)}
                  />
                </Box>
                {TipoMenuApp == '2' && (
                  <>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={MenuAppTypes}
                        sx={{width: 487}}
                        size='small'
                        renderInput={(params) => <TextField {...params} disabled={!isEditable()} label='Tipo' />}
                        value={Data.sqlType != null && Data.sqlType != '' ? MenuAppTypes.find((op: any) => op.id == Data.sqlType) ?? null : null}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('sqlType', value)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        id='ddlOrigem'
                        disablePortal
                        disabled={!isEditable() || DataID != 0 || Data.idmenuAppPai != null}
                        options={Origens}
                        getOptionLabel={(option) => option.nome}
                        sx={{width: 487}}
                        size='small'
                        renderInput={(params) => <TextField {...params} label='Origem' />}
                        value={
                          Data.idmenuAppFactory != null && Data.idmenuAppFactory != ''
                            ? Origens.filter((op: any) => op.id == Data.idmenuAppFactory)[0] ?? null
                            : null
                        }
                        onOpen={(event) => handleOnOpenAutoComplete('idmenuAppFactory', event)}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('idmenuAppFactory', value)}
                        onInputChange={(event, value) => handleOnInputChangeAutoComplete('idmenuAppFactory', value)}
                      />
                    </Box>
                    {Data.idmenuAppFactory != null && Data.idmenuAppFactory != 0 && (
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable() || DataID != 0 || Data.idmenuAppPai != null}
                          options={DataTables}
                          sx={{width: 487}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Tabela do Banco de Dados' />}
                          value={Data.sqlObject}
                          onOpen={(event) => handleOnOpenAutoComplete('sqlObject', event)}
                          onChange={(event, value, reason) => handleOnChangeAutoComplete('sqlObject', value)}
                          onInputChange={(event, value) => handleOnInputChangeAutoComplete('sqlObject', value)}
                        />
                      </Box>
                    )}
                    <Box component='div' sx={{p: 1, display: 'flex'}}>
                      <Autocomplete
                        disablePortal
                        disabled={!isEditable()}
                        options={DataTablesFields}
                        sx={{width: 300}}
                        size='small'
                        renderInput={(params) => <TextField {...params} label='Campo de Ordenação' />}
                        value={Data.sqlFieldOrder != null && Data.sqlFieldOrder != '' ? Data.sqlFieldOrder.split(' ')[0] : null}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('sqlFieldOrder', value)}
                      />
                      <FormControl sx={{px: 2}}>
                        <StyledRadioGroup value={order} onChange={handleOnChangeOrder} row>
                          <FormControlLabel value='ASC' control={<Radio />} label='ASC' />
                          <FormControlLabel value='DESC' control={<Radio />} label='DESC' />
                        </StyledRadioGroup>
                      </FormControl>
                    </Box>
                  </>
                )}
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    error={!Validation.accessCode.isValid}
                    helperText={
                      fieldRules.length > 0 || ruleCount > 0
                        ? 'Por questões de integridade, esse campo não pode ser alterado devido a existência de regras e/ou filtros.'
                        : Validation.accessCode.message
                    }
                    onBlur={(event) => handleValidateField(event, 'accessCode')}
                    sx={{width: 487}}
                    disabled={!isEditable() || fieldRules.length > 0 || ruleCount > 0}
                    size='small'
                    label='Código de Acesso'
                    value={Data.accessCode}
                    onChange={(event) => handleOnChangeInput('accessCode', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}} hidden={true}>
                  <TextField
                    sx={{width: 487}}
                    disabled={!isEditable()}
                    size='small'
                    label='Filtro SQL'
                    value={Data.sqlFiltroInicial}
                    onChange={(event) => handleOnChangeInput('sqlFiltroInicial', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <MenuAppResourceSelect
                    id='icone-select'
                    label='Ícone'
                    labelId='icone-select-label'
                    type='fontawesome'
                    value={Data.icone}
                    onChange={(event) => handleOnChangeInput('icone', event)}
                  />
                </Box>
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Ativo'
                    control={
                      <Checkbox disabled={!isEditable()} checked={Data.ativo} onChange={(event, checked) => handleOnChangeBoolean('ativo', event)} />
                    }
                  />
                </Box>
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Custom Page'
                    control={
                      <Checkbox
                        disabled={!isEditable()}
                        checked={Data.biCustomPage}
                        onChange={(event, checked) => handleOnChangeBoolean('biCustomPage', event)}
                      />
                    }
                  />
                </Box>
                {DataID != 0 && Data.biCustomPage && (
                  <Box component='div' sx={{p: 1}}>
                    <UploadCollection
                      customPage={true}
                      idMenuApp={DataID}
                      azFiles={Files}
                      onDelete={(azFile) => handleOnDeleteUpload(azFile)}
                      onUpload={(newAzFile) => handleOnUpload(newAzFile)}
                    />
                  </Box>
                )}
                <Box component='div' sx={{pl: 1, display: 'flex'}}>
                  <FormControlLabel
                    label='SQL Customizado'
                    control={
                      <Checkbox
                        disabled={!isEditable()}
                        checked={Data.biCustomSql}
                        onChange={(event, checked) => handleOnChangeBoolean('biCustomSql', event)}
                      />
                    }
                  />
                  <Box component='div'>
                    <IconButton aria-label='question' color='inherit' onClick={() => handleOpenModalModeloSQL('Listagem')}>
                      <HelpIcon />
                    </IconButton>
                  </Box>
                </Box>
                {Data.biCustomSql && (
                  <Box component='div' sx={{p: 1}}>
                    <TextField
                      sx={{width: 487}}
                      disabled={!isEditable()}
                      size='small'
                      label='SQL Customizado'
                      value={Data.sqlcustom}
                      onChange={(event) => handleOnChangeInput('sqlcustom', event)}
                      multiline
                      maxRows={6}
                      minRows={6}
                    />
                  </Box>
                )}
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Público'
                    control={
                      <Checkbox
                        disabled={!isEditable()}
                        checked={Data.biPublicAccess}
                        onChange={(event, checked) => handleOnChangeBoolean('biPublicAccess', event)}
                      />
                    }
                  />
                </Box>
                {Data.biPublicAccess && (
                  <>
                    <Box component='div' sx={{p: 1}}>
                      <TextField
                        sx={{width: 300}}
                        disabled={true}
                        size='small'
                        label='URL'
                        value={`${window.location.origin}/public/app/${Data.accessCode}`}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <img
                        src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${window.location.origin}/public/app/${Data.accessCode}`}
                      />
                    </Box>
                  </>
                )}
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Compartilhamento de Mensagens'
                    control={
                      <Checkbox
                        disabled={!isEditable()}
                        checked={Data.biShareMessage}
                        onChange={(event, checked) => handleOnChangeBoolean('biShareMessage', event)}
                      />
                    }
                  />
                </Box>
              </>
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 1} sx={{height: 'calc(100% - 104px)', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && Data && DataID != 0 && (
              <DynamicList
                isDisplayed={true}
                IDMenuAppGenericItem={Data.id.toString()}
                accessCode='SYSMAPVISOES'
                title='Visões'
                subtitle='Visões do app'
                displayHeader={true}
                filters={[
                  {
                    columnField: 'IDMenuAppPai',
                    operatorValue: '=',
                    value: [Data.id.toString()],
                  },
                ]}
              />
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 2} sx={{height: 'calc(100% - 104px)', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && Data && DataID != 0 && (
              <DynamicList
                isDisplayed={true}
                accessCode='SYSMAF'
                sysParam={Data.accessCode}
                title='Campos'
                subtitle='Gerenciamento de Campos de Exibição'
                displayHeader={true}
              />
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 3} sx={{height: 'calc(100% - 104px)', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && Data && DataID != 0 && (
              <DynamicList
                isDisplayed={true}
                accessCode='SYSMAR'
                sysParam={Data.accessCode}
                title='Relacionamentos'
                subtitle='Gerenciamento de Relações'
                displayHeader={true}
              />
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 4} sx={{height: '100%', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && Data != null && DataID > 0 && <FieldRule accessCode={Data.accessCode} idMenuApp={DataID} setRuleCount={setRuleCount} />}
          </FormControl>
          <FormControl hidden={TabIndex != 5} sx={{height: 'calc(100% - 104px)', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && DataID != 0 && DataID != 0 && (
              <ThemeProvider theme={muiTheme}>
                <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
                  <Box component='div' className='page-title d-flex'>
                    <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                      Filtros
                    </Box>
                    <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                    <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                      <Box component='li' className='breadcrumb-item text-muted'>
                        Gerenciamento
                      </Box>
                    </Box>
                  </Box>
                  <Box component='div' className='d-flex align-items-center py-1'>
                    {selectionModel.length > 0 && (
                      <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                        <Button variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleDeleteFieldRules()}>
                          Excluir
                        </Button>
                      </Box>
                    )}
                    <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                      <Button variant='contained' startIcon={<AddIcon />} color='info' onClick={() => handleOpenModalFieldRule(0)}>
                        Adicionar
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box component='div' sx={{display: 'flex', height: '100%', width: '100%'}}>
                  <StyledDataGrid
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    apiRef={apiRef_Filtros}
                    components={{
                      NoRowsOverlay: NoRowsOverlay,
                      NoResultsOverlay: NoRowsOverlay,
                      LoadingOverlay: LinearProgress,
                    }}
                    checkboxSelection
                    rows={fieldRules}
                    columns={BuildGridColumnFieldRule()}
                    rowHeight={38}
                    columnHeaderHeight={38}
                    getRowId={(row: any) => row.id}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel)
                    }}
                  />
                </Box>
              </ThemeProvider>
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 6} sx={{height: '100%', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && DataID != 0 && (
              <TableContainer sx={{width: '100%', height: '100%'}}>
                <Table stickyHeader size='small' aria-label='role table'>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Grupo</StyledTableCell>
                      <StyledTableCell
                        align='center'
                        sx={{width: '25px'}}
                        title='Permissão para realizar qualquer alteração de campos, regras, filtros e configurações gerais do App'
                      >
                        Gerenciar
                      </StyledTableCell>
                      <StyledTableCell align='center' sx={{width: '25px'}} title='Permite incluir novos registros'>
                        Inserção
                      </StyledTableCell>
                      <StyledTableCell align='center' sx={{width: '25px'}} title='Permite alterar registros'>
                        Alteração
                      </StyledTableCell>
                      <StyledTableCell align='center' sx={{width: '25px'}} title='Permite excluir registros'>
                        Remoção
                      </StyledTableCell>
                      <StyledTableCell align='center' sx={{width: '25px'}} title='Permite visualizar dados de registros'>
                        Visualização
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {Roles.map((item: any, index: number) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{LoadRoleName(index)}</StyledTableCell>
                          <StyledTableCell align='center'>
                            <Checkbox checked={item.biManage} onChange={(event, checked) => handleChangeRoleBoolean(index, 'biManage', event)} />
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <Checkbox checked={item.biInsert} onChange={(event, checked) => handleChangeRoleBoolean(index, 'biInsert', event)} />
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <Checkbox checked={item.biUpdate} onChange={(event, checked) => handleChangeRoleBoolean(index, 'biUpdate', event)} />
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <Checkbox checked={item.biDelete} onChange={(event, checked) => handleChangeRoleBoolean(index, 'biDelete', event)} />
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <Checkbox checked={item.biSelect} onChange={(event, checked) => handleChangeRoleBoolean(index, 'biSelect', event)} />
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 7} component='fieldset' variant='standard'>
            {Opened && DataID != 0 && jsonField != null && (
              <>
                <Box component='div' className='container'>
                  <Box component='div' className='row align-items-center'>
                    <Box component='div' className='col'>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={menuAppFields}
                          sx={{width: 300}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Area 1' />}
                          value={
                            jsonField?.kanban?.area1field != null && jsonField?.kanban?.area1field != 0
                              ? menuAppFields.filter((op: any) => op.id == jsonField?.kanban?.area1field)[0] ?? null
                              : null
                          }
                          onChange={(event, value, reason) => handleOnChangeJsonFieldAutoComplete('area1field', value)}
                        />
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={menuAppFields}
                          sx={{width: 300}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Area 2' />}
                          value={
                            jsonField?.kanban?.area2field != null && jsonField?.kanban?.area2field != 0
                              ? menuAppFields.filter((op: any) => op.id == jsonField?.kanban?.area2field)[0] ?? null
                              : null
                          }
                          onChange={(event, value, reason) => handleOnChangeJsonFieldAutoComplete('area2field', value)}
                        />
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={menuAppFields}
                          sx={{width: 300}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Area 3' />}
                          value={
                            jsonField?.kanban?.area3field != null && jsonField?.kanban?.area3field != 0
                              ? menuAppFields.filter((op: any) => op.id == jsonField?.kanban?.area3field)[0] ?? null
                              : null
                          }
                          onChange={(event, value, reason) => handleOnChangeJsonFieldAutoComplete('area3field', value)}
                        />
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={menuAppFields}
                          sx={{width: 300}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Area 4' />}
                          value={
                            jsonField?.kanban?.area4field != null && jsonField?.kanban?.area4field != 0
                              ? menuAppFields.filter((op: any) => op.id == jsonField?.kanban?.area4field)[0] ?? null
                              : null
                          }
                          onChange={(event, value, reason) => handleOnChangeJsonFieldAutoComplete('area4field', value)}
                        />
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={menuAppFields}
                          sx={{width: 300}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Area 5' />}
                          value={
                            jsonField?.kanban?.area5field != null && jsonField?.kanban?.area5field != 0
                              ? menuAppFields.filter((op: any) => op.id == jsonField?.kanban?.area5field)[0] ?? null
                              : null
                          }
                          onChange={(event, value, reason) => handleOnChangeJsonFieldAutoComplete('area5field', value)}
                        />
                      </Box>
                    </Box>
                    <Box component='div' className='col my-1'>
                      <img
                        src='https://cdn.discordapp.com/attachments/1034584824364679329/1080993396430221393/image.png'
                        alt='preview'
                        width='808'
                        height='410'
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 8} sx={{height: 'calc(100% - 104px)', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && Data && DataID != 0 && (
              <DynamicList
                isDisplayed={true}
                accessCode='SYSACT'
                sysParam={Data.accessCode}
                title='Ações'
                subtitle='Ações do app'
                displayHeader={true}
              />
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 9} sx={{height: 'calc(100% - 48px)', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && DataID != 0 && (
              <DynamicList
                isDisplayed={TabIndex == 8}
                accessCode='SYSMAA'
                sysParam='SYSMAP'
                IDMenuAppGenericItem={DataID.toString()}
                title=''
                subtitle=''
                displayHeader={false}
              />
            )}
          </FormControl>

          {/* MODAL FILTRO AVANCADO */}
          {modalFieldRule && (
            <FieldRuleModalV2
              id={modalFieldRuleItemID}
              accessCode={Data.accessCode}
              idMenuApp={Data.id}
              StartEvent='Ao carregar app'
              startAction='Aplicar Filtro Avançado'
              hideDescription={true}
              hideBackButton={true}
              logicalOperator={modalFieldRuleLogicalOperator}
              callBack={modalFieldRuleCallback}
            />
          )}

          {modalModeloSQL && <ModalModeloSQL tipoModelo={modalModeloSQLTipo} callBack={modalModeloCallback} />}
        </DialogContent>
        <DialogActions>
          {isEditable() && (
            <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false, false)}>
              Fechar
            </Button>
          )}
          {deleteFunc != null && isEditable() && DataID != 0 && (
            <Button variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluir()}>
              Excluir
            </Button>
          )}
          {isEditable() && (
            <>
              <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, true)}>
                Salvar
              </Button>
              {DataID != 0 && (
                <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, false)}>
                  Salvar e Fechar
                </Button>
              )}
            </>
          )}
          {!isEditable() && (
            <Button variant='contained' startIcon={<SaveIcon />} color='success'>
              Salvando...
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default SYSMAPModal
