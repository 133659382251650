import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'

import CancelIcon from '@mui/icons-material/Cancel'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Addchart from '@mui/icons-material/Addchart'
import FolderShared from '@mui/icons-material/FolderShared'
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual'
import AddLink from '@mui/icons-material/AddLink'

import {GridActionsCellItem, GridRowSelectionModel, ptBR} from '@mui/x-data-grid'
import {useGridApiRef} from '@mui/x-data-grid-pro'

import UploadCollection from '../upload/UploadCollection'

import {CChartDatasetStructure, CElementStructure, CGlobalFilterStructure} from '../../../../models/classes/CMenuAppHistoryStructure'
import CardItem from '../utility/CardItem'
import {StyledDataGrid} from '../grid/StyledDataGrid'
import {NoRowsOverlay} from '../utility/NoRowsOverlay'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import MenuAppResourceSelect from '../utility/menuAppResourceSelect'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {MenuAppDesktopsChartResult} from '../../../../modules/api/models/Result/MenuAppDesktopsChart'
import StyledDialog from '../styled/StyledDialog'
import StyledButton from '../styled/StyledButton'
import SYSCHFModal from '../modals/modalSYSCHF'
import {handleApplyValueToState} from '../../../../models/wrappers/handlers'

interface IChartModal {
  accessCode: string
  idMenuAppDesktop: number
  idMenuAppDesktopChart: number
  initialEditMode?: string
  callBack(data: any): void
}

const ChartModal = ({accessCode, idMenuAppDesktop, idMenuAppDesktopChart, initialEditMode, callBack}: IChartModal) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  //* VARIAVEIS DO COMPONENTE
  const [title, setTitle] = useState<string>('Escolher Modo')
  const [mode, setMode] = useState<string>(initialEditMode ?? '')
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [desktopChart, setDesktopChart] = useState<MenuAppDesktopsChartResult | undefined>(undefined)
  const [chart, setChart] = useState<CElementStructure>(new CElementStructure())

  /* CHARTS */

  const [chartOperationFields, setChartOperationFields] = useState<Array<any>>([])

  const chartOptions = [
    {id: 'Bar', label: 'Barra'},
    {id: 'Pie', label: 'Pizza'},
    {id: 'Line', label: 'Linha'},
    {id: 'Radar', label: 'Radar'},
    {id: 'PolarArea', label: 'Área Polar'},
    {id: 'Number', label: 'Número'},
    {id: 'Table', label: 'Tabela'},
  ]

  const chartOperators = [
    {id: 'count', label: 'Contagem'},
    {id: 'sum', label: 'Soma'},
    {id: 'avg', label: 'Média'},
  ]

  const chartOrdering = [
    {id: 'ASC', label: 'Crescente'},
    {id: 'DESC', label: 'Decrescente'},
  ]

  const chartTop = [
    {id: '3', label: '3 Primeiros'},
    {id: '5', label: '5 Primeiros'},
    {id: '10', label: '10 Primeiros'},
    {id: '15', label: '15 Primeiros'},
    {id: '20', label: '20 Primeiros'},
  ]

  const [chartApp, setChartApp] = useState<any>(null)
  const [chartApps, setChartApps] = useState<Array<any>>([])
  const [chartFields, setChartFields] = useState<Array<any>>([])

  /* LINK */

  const [linkApps, setLinkApps] = useState<Array<any>>([])
  const linkTypes = [
    {id: 'menuapp', label: 'Menu App'},
    {id: 'externo', label: 'Externo'},
  ]

  /* FILE */

  const [fileApp, setFileApp] = useState<any>(null)
  const [fileApps, setFileApps] = useState<Array<any>>([])
  const [fileFields, setFileFields] = useState<Array<any>>([])

  /* IMAGE */

  const [azImages, setAzImages] = useState<Array<any>>([])

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = async () => {
    setLoading(true)

    //* Carregamos o chart da base, caso seja nulo instanciamos um novo
    let desktopChartRequest = await apiWrapper.get(`api/v1/MenuAppDesktopsChart/carregar?item.ID=${idMenuAppDesktopChart}`)
    let desktopChart = desktopChartRequest.data.data as MenuAppDesktopsChartResult | undefined
    let _chart = chart

    //* Caso o desktopChart venha nulo da base criamos um novo
    //* Caso o desktopChart venha existente da base então tratamos apenas o chart em si
    if (desktopChart == null) {
      desktopChart = new MenuAppDesktopsChartResult(idMenuAppDesktop)
    } else if (desktopChart.json != null && desktopChart.json != '') {
      _chart = JSON.parse(desktopChart.json) as CElementStructure
    }

    if (initialEditMode != null) {
      let tipo: string = handleChangeMode(initialEditMode, false)
      _chart.tipo = tipo
    } else {
      switch (_chart.tipo) {
        case 'chart':
          handleChangeMode('EDIT-CHART', false)
          break
        case 'link':
          handleChangeMode('EDIT-LINK', false)
          break
        case 'file':
          handleChangeMode('EDIT-FILE', false)
          break
        case 'image':
          handleChangeMode('EDIT-IMAGE', false)
          break
        default:
          handleChangeMode('SELECT', false)
          break
      }
    }

    setDesktopChart(desktopChart)
    setChart(_chart)

    /* CARREGAR O APP DO VALOR DO CHART */

    let menuAppChart: any = undefined
    let campo: number = 0

    if (_chart.chart.datasets.length > 0) {
      if (_chart.chart.datasets[0].campo != 0) {
        campo = _chart.chart.datasets[0].campo
      }
    }

    if (accessCode == 'SYSDASHBOARD') {
      if (campo != 0) {
        let menuAppRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorMenuAppField?item.IDMenuAppField=${campo}`)
        menuAppChart = menuAppRequest.data.data

        if (menuAppChart != null) {
          handleLoadChartFields(menuAppChart.accessCode)
        }
      }
    } else {
      let menuAppRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${accessCode}`)
      menuAppChart = menuAppRequest.data.data
      handleLoadChartFields(accessCode as string)
    }

    if (menuAppChart != null) {
      let array: Array<any> = [menuAppChart]
      setChartApp(array[0])
      setChartApps(array)
    }

    /* CARREGAR O APP DO VALOR DO LINK */

    if (_chart.link.tipo == 'menuapp' && _chart.link.valor != '') {
      handleOnInputChangeAutoComplete('link', '', _chart.link.valor)
    }

    /* CARREGAR O APP DO VALOR DO FILE */

    let _fileApps = [{id: -1, titulo: 'Todos'}]

    if (_chart.file.menuapp != 0) {
      let menuAppRequest = await apiWrapper.get(`api/v1/MenuApp/carregar?item.ID=${_chart.file.menuapp}`)
      let menuAppFile = menuAppRequest.data.data

      if (menuAppFile != null) {
        handleLoadFileFields(menuAppFile.accessCode)

        let array: Array<any> = _fileApps.concat([menuAppFile])
        setFileApp(array.find((x) => x.id == _chart.file.menuapp) ?? null)
        setFileApps(array)
      } else if (_chart.file.menuapp == -1) {
        setFileApp(_fileApps[0])
        setFileApps(_fileApps)
      }
    } else {
      setFileApps(_fileApps)
    }

    /* CARREGAR O ARQUIVO DE IMAGEM */

    if (_chart.image.idArquivo != 0) {
      apiWrapper.get(`api/v1/Arquivo/carregar?item.ID=${_chart.image.idArquivo}`).then((response) => {
        setAzImages([response.data.data])
      })
    }

    setLoading(false)
  }

  const handleClose = async (save: boolean) => {
    if (!save || desktopChart == null) {
      handleCallBack()
      return
    }

    setSaving(true)

    desktopChart.json = JSON.stringify(chart)
    desktopChart.idmenuAppDesktops = idMenuAppDesktop

    let obj: any = {
      item: {MenuAppDesktopsChart: desktopChart},
    }

    apiWrapper.put('api/v1/MenuAppDesktopsChart/salvar', obj).then((response) => {
      handleCallBack({refresh: true})
      //toast.success('Registro salvo com sucesso!')
    })
  }

  const handleTabIndexChange = (value: number) => {
    setTabIndex(value)
  }

  const handleChangeMode = (mode: string, changeType: boolean) => {
    setMode(mode)

    let tipo: string = ''
    let titulo: string = 'Escolher Modo'

    switch (mode) {
      case 'EDIT-CHART':
        tipo = 'chart'
        titulo = 'Editar Elemento - Gráfico'
        break
      case 'EDIT-LINK':
        tipo = 'link'
        titulo = 'Editar Elemento - Link'
        break
      case 'EDIT-FILE':
        tipo = 'file'
        titulo = 'Editar Elemento - Arquivos'
        break
      case 'EDIT-IMAGE':
        tipo = 'image'
        titulo = 'Editar Elemento - Imagem'
        break
    }

    setTitle(titulo)

    if (!changeType) {
      return tipo
    }

    setChart((prev: CElementStructure) => {
      prev.tipo = tipo
      prev.layout = undefined
      return {...prev}
    })

    return tipo
  }

  const handleLoadChartFields = async (accessCode: string) => {
    let menuAppFieldsRequest = await apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${accessCode}`)

    let menuAppFields = menuAppFieldsRequest.data.data
    let dimensions = menuAppFields.filter((x: any) => x.tipo != 'upload')
    let operationFields = menuAppFields.filter((x: any) => x.tipo == 'number' || x.tipo == 'decimal' || x.tipo == 'singleSelect')

    setChartFields(dimensions)
    setChartOperationFields(operationFields)
  }

  const handleLoadFileFields = async (accessCode: string) => {
    let menuAppFieldsRequest = await apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${accessCode}`)

    let menuAppFields = menuAppFieldsRequest.data.data
    let dimensions = menuAppFields.filter((x: any) => x.tipo != 'upload')

    setFileFields(dimensions)
  }

  const handleOnInputChangeAutoComplete = (mode: string, value: any, id: any = null) => {
    if (mode == 'file') {
      apiWrapper
        .get(`api/v1/MenuApp/listarComUploadPorTitulo?item.Query=${value}&item.ReturnObject=true${id != null ? '&item.IDMenuApp=' + id : ''}`)
        .then((response) => {
          let array = [{id: -1, titulo: 'Todos'}].concat(response.data.data)
          setFileApps(array)
        })
    } else {
      apiWrapper
        .get(`api/v1/MenuApp/listarPorTitulo?item.Query=${value}&item.ReturnObject=true${id != null ? '&item.IDMenuApp=' + id : ''}`)
        .then((response) => {
          if (mode == 'chart') {
            setChartApps(response.data.data)
          } else if (mode == 'link') {
            setLinkApps(response.data.data)
          }
        })
    }
  }

  const handleOnChangeInput = (mode: string, key: string, event: any) => {
    setChart((prev: CElementStructure) => {
      if (mode == '') {
        prev[key] = event.target.value
      } else {
        prev[mode][key] = event.target.value
      }

      return {...prev}
    })
  }

  const handleOnChangeBoolean = (mode: string, key: string, event: any) => {
    setChart((prev: CElementStructure) => {
      if (mode == '') {
        prev[key] = event.target.checked
      } else {
        prev[mode][key] = event.target.checked
      }

      return {...prev}
    })
  }

  const handleOnOpenAutoComplete = (mode: string, event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete(mode, '')
    }
  }

  const handleOnChangeAutoComplete = (mode: string, key: string, value: any, convertToString: boolean = false) => {
    if (key == '_CHARTAPP_') {
      setChartApp(value)

      if (value != null) {
        handleLoadChartFields(value.accessCode)
      } else {
        setChartFields([])
        setChartOperationFields([])
      }
    }

    if (mode == 'file' && key == 'menuapp') {
      setFileApp(value)

      if (value != null) {
        handleLoadFileFields(value.accessCode)
      } else {
        setFileFields([])
      }
    }

    setChart((prev: CElementStructure) => {
      if (key == '_CHARTAPP_') {
        prev['chart']['datasets'] = []
        prev['chart']['filtro'] = []
        prev['chart']['dimensao'] = 0
        return {...prev}
      }

      if (mode == 'file' && key == 'menuapp') {
        prev['file']['filtro'] = []
        prev['file']['menuapp'] = value?.id ?? 0
        return {...prev}
      }

      if (mode == 'chart' && key == 'tipo') {
        if (value?.id == 'Number') {
          prev['chart']['dimensao'] = 0
          prev['chart']['ordem'] = ''
        }

        if (value != null && value.id != 'Table') {
          prev['chart']['top'] = ''
        }

        if (value?.id == 'Number' || value?.id == 'Table') {
          prev['chart']['datasets'] = [new CChartDatasetStructure()]
        }
      }

      if (mode == 'link' && key == 'tipo') {
        prev[mode]['valor'] = ''
      }

      prev[mode][key] = value != null ? (convertToString ? value.id.toString() : value.id) : ''
      return {...prev}
    })
  }

  const handleOnChangeDataset = (index: number, key: string, value: any) => {
    setChart((prev) => {
      prev.chart.datasets[index][key] = value
      return {...prev}
    })
  }

  const handleDelete = () => {
    let obj = {
      item: {
        ID: desktopChart?.id,
      },
    }

    apiWrapper.delete('api/v1/MenuAppDesktopsChart/excluir', {data: obj, headers: {}}).then((response) => {
      handleCallBack({refresh: true})
    })
  }

  const handleOnUploadImage = (newAzFile: any) => {
    let obj: any = {
      item: {
        AccessCode: 'SYSDASHBOARD',
        Arquivo: newAzFile,
      },
    }

    apiWrapper.post('api/v1/Arquivo/salvarImagemElement', obj, {}).then((response) => {
      if (azImages.length > 0) {
        handleOnDeleteImage(azImages[0], false)
      }

      setAzImages([response.data.data])
      setChart((prev: CElementStructure) => {
        prev['image']['idArquivo'] = response.data.data.id
        return {...prev}
      })
    })
  }

  const handleOnDeleteImage = (azFile: any, clearAzImage: boolean) => {
    let obj: any = {
      item: {
        Nome: azFile.nome,
      },
    }

    apiWrapper.delete('api/v1/Storage/ApagarArquivoAz', {data: obj, headers: {}})

    if (!clearAzImage) {
      return
    }

    setAzImages([])
    setChart((prev: CElementStructure) => {
      prev['image']['idArquivo'] = 0
      return {...prev}
    })
  }

  const isEditable = () => {
    return !saving
  }

  /* GRID & MODAL FILTROS */

  const apiRef_chart = useGridApiRef()
  const apiRef_datasets = useGridApiRef()

  const [selectionModelDatasets, setSelectionModelDatasets] = useState<GridRowSelectionModel>([])
  const [selectionModelChart, setSelectionModelChart] = useState<GridRowSelectionModel>([])
  const [selectionModelFile, setSelectionModelFile] = useState<GridRowSelectionModel>([])

  const [modalSYSCHF, setModalSYSCHF] = useState<boolean>(false)
  const [modalSYSCHFMode, setModalSYSCHFMode] = useState<string>('')
  const [modalSYSCHFAccessCode, setModalSYSCHFAccessCode] = useState<string>('')
  const [modalSYSCHFItem, setModalSYSCHFItem] = useState<CGlobalFilterStructure | undefined>(undefined)
  const [modalSYSCHFItemIndex, setModalSYSCHFItemIndex] = useState<number>(-1)
  const [modalSYSCHFItemOperadorLogico, setModalSYSCHFItemOperadorLogico] = useState<string | undefined>(undefined)

  function BuildGridColumns(mode: string) {
    let columns: Array<any> = [
      {
        field: 'actions',
        width: 25,
        type: 'actions',
        editable: false,
        hideable: false,
        getActions: (params: any) => BuildActions(params, mode),
      },
      {
        field: 'operadorLogico',
        type: 'string',
        headerName: 'Operador Lógico',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'campo',
        type: 'string',
        headerName: 'Campo',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'operador',
        type: 'number',
        headerName: 'Operador',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'valor',
        type: 'number',
        headerName: 'Valor',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
    ]

    columns[4].valueGetter = ({value}) => {
      if (value != null && typeof value == 'string' && value.startsWith('{') && value.endsWith('}')) {
        let parsed = JSON.parse(value)
        return parsed?.label ?? 'N/A'
      }

      return value
    }

    if (mode == 'chart') {
      columns[2].valueGetter = ({value}) => {
        if (value == null) {
          return 'N/A'
        } else {
          return chartFields.find((x: any) => x.id == value)?.label ?? 'N/A'
        }
      }
    } else if (mode == 'file') {
      columns[2].valueGetter = ({value}) => {
        if (value == null) {
          return 'N/A'
        } else {
          return fileFields.find((x: any) => x.id == value)?.label ?? 'N/A'
        }
      }
    }

    return columns
  }

  function BuildActions(params: any, mode: string) {
    let actions: Array<JSX.Element> = [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Editar'
        onClick={() => {
          let rows = apiRef_chart.current.getSortedRowIds()
          let index = rows.findIndex((x) => x == params.row.guid)
          SYSCHFOpen(index, mode, params.row)
        }}
        showInMenu
      />,
      <GridActionsCellItem icon={<DeleteIcon />} label='Excluir' onClick={() => handleDeleteFiltroIndividual(params.row.guid, mode)} showInMenu />,
    ]

    return actions
  }

  const SYSCHFOpen = (index: number, mode: string, item?: CGlobalFilterStructure) => {
    setModalSYSCHF(true)
    setModalSYSCHFMode(mode)
    setModalSYSCHFAccessCode(mode == 'chart' ? chartApp.accessCode : fileApp.accessCode)
    setModalSYSCHFItem(item)
    setModalSYSCHFItemIndex(index)

    if (index == 0) {
      setModalSYSCHFItemOperadorLogico('-')
    } else if (index > 1) {
      if (mode == 'chart') {
        setModalSYSCHFItemOperadorLogico(`${chart.chart?.filtro[1]?.operadorLogico}`)
      } else if (mode == 'file') {
        setModalSYSCHFItemOperadorLogico(`${chart.file?.filtro[1]?.operadorLogico}`)
      }
    } else {
      setModalSYSCHFItemOperadorLogico(undefined)
    }
  }

  const SYSCHFCallback = (response: any = null) => {
    if (response != null) {
      setChart((prev: CElementStructure) => {
        if (modalSYSCHFMode == 'chart') {
          let oldArray = JSON.parse(JSON.stringify(prev.chart.filtro))
          let item = oldArray.find((x: any) => x.guid == response.item.guid)

          if (item == null) {
            oldArray.push(response.item)
          } else {
            let index = oldArray.indexOf(item)
            oldArray[index] = response.item
          }

          if (response.index == 1) {
            for (let i = 2; i < oldArray.length; i++) {
              oldArray[i].operadorLogico = response.item.operadorLogico
            }
          }

          prev.chart.filtro = oldArray
        } else if (modalSYSCHFMode == 'file') {
          let oldArray = JSON.parse(JSON.stringify(prev.file.filtro))
          let item = oldArray.find((x: any) => x.guid == response.item.guid)

          if (item == null) {
            oldArray.push(response.item)
          } else {
            let index = oldArray.indexOf(item)
            oldArray[index] = response.item
          }

          if (response.index == 1) {
            for (let i = 2; i < oldArray.length; i++) {
              oldArray[i].operadorLogico = response.item.operadorLogico
            }
          }

          prev.file.filtro = oldArray
        }

        return {...prev}
      })
    }

    setModalSYSCHF(false)
    setModalSYSCHFItem(undefined)
  }

  const handleDeleteFiltroIndividual = (guid: string, mode: string) => {
    setChart((prev: CElementStructure) => {
      if (mode == 'chart') {
        let oldArray = JSON.parse(JSON.stringify(prev.chart.filtro))
        let newArray = oldArray.filter((x: any) => x.guid != guid)

        if (newArray.length == 1) {
          newArray[0].operadorLogico = '-'
        }

        prev.chart.filtro = newArray
      } else if (mode == 'file') {
        let oldArray = JSON.parse(JSON.stringify(prev.file.filtro))
        let newArray = oldArray.filter((x: any) => x.guid != guid)

        if (newArray.length == 1) {
          newArray[0].operadorLogico = '-'
        }

        prev.file.filtro = newArray
      }

      return {...prev}
    })
  }

  const handleDeleteFiltroSelecionados = (mode: string) => {
    setChart((prev: CElementStructure) => {
      if (mode == 'chart') {
        let oldArray = JSON.parse(JSON.stringify(prev.chart.filtro))
        let newArray = oldArray.filter((x: any) => !selectionModelChart.includes(x.guid))

        if (newArray.length == 1) {
          newArray[0].operadorLogico = '-'
        }

        setSelectionModelChart([])

        prev.chart.filtro = newArray
      } else if (mode == 'file') {
        let oldArray = JSON.parse(JSON.stringify(prev.file.filtro))
        let newArray = oldArray.filter((x: any) => !selectionModelFile.includes(x.guid))

        if (newArray.length == 1) {
          newArray[0].operadorLogico = '-'
        }

        setSelectionModelFile([])

        prev.file.filtro = newArray
      }

      return {...prev}
    })
  }

  const [modalEditDataset, setModalEditDataset] = useState<boolean>(false)
  const [currentDataset, setCurrentDataset] = useState<CChartDatasetStructure>()

  function BuildDatasetsGridColumns() {
    let columns: Array<any> = [
      {
        field: 'actions',
        width: 25,
        type: 'actions',
        editable: false,
        hideable: false,
        getActions: (params: any) => BuildDatasetsActions(params, mode),
      },
      {
        field: 'operacao',
        type: 'string',
        headerName: 'Operação',
        headerAlign: 'left',
        align: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'campo',
        type: 'number',
        headerName: 'Campo',
        headerAlign: 'left',
        align: 'left',
        width: 250,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'cor',
        type: 'string',
        headerName: 'Cor',
        headerAlign: 'left',
        align: 'left',
        width: 200,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
    ]

    columns[1].valueGetter = ({value}) => {
      if (value == null) {
        return 'N/A'
      } else {
        return chartOperators.find((x: any) => x.id == value)?.label ?? 'N/A'
      }
    }

    columns[2].valueGetter = ({value}) => {
      if (value == null) {
        return 'N/A'
      } else {
        return chartFields.find((x: any) => x.id == value)?.label ?? 'N/A'
      }
    }

    return columns
  }

  function BuildDatasetsActions(params: any, mode: string) {
    let actions: Array<JSX.Element> = [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Editar'
        onClick={() => {
          let rows = apiRef_datasets.current.getSortedRowIds()
          let index = rows.findIndex((x) => x == params.row.guid)
          handleOpenModalDatasets(chart.chart.datasets[index])
        }}
        showInMenu
      />,
      <GridActionsCellItem icon={<DeleteIcon />} label='Excluir' onClick={() => handleDeleteDatasetIndividual(params.row.guid)} showInMenu />,
    ]

    return actions
  }

  const handleOpenModalDatasets = (dataset: CChartDatasetStructure) => {
    setModalEditDataset(true)
    setCurrentDataset(dataset)
  }

  const handleCloseModalDatasets = (save: boolean) => {
    setModalEditDataset(false)

    if (save) {
      setChart((prev) => {
        let context = currentDataset as CChartDatasetStructure

        let oldArray = JSON.parse(JSON.stringify(prev.chart.datasets))
        let item = oldArray.find((x: any) => x.guid == context.guid)

        if (item == null) {
          oldArray.push(context)
        } else {
          let index = oldArray.indexOf(item)
          oldArray[index] = context
        }

        prev.chart.datasets = oldArray

        return {...prev}
      })

      return
    }

    setCurrentDataset(undefined)
  }

  const handleDeleteDatasetIndividual = (guid: string) => {
    setChart((prev: CElementStructure) => {
      let oldArray = JSON.parse(JSON.stringify(prev.chart.datasets))
      let newArray = oldArray.filter((x: any) => x.guid != guid)

      prev.chart.datasets = newArray

      return {...prev}
    })
  }

  const handleDeleteDatasetsSelecionados = () => {
    setChart((prev: CElementStructure) => {
      let oldArray = JSON.parse(JSON.stringify(prev.chart.datasets))
      let newArray = oldArray.filter((x: any) => !selectionModelDatasets.includes(x.guid))

      setSelectionModelDatasets([])

      prev.chart.datasets = newArray

      return {...prev}
    })
  }

  //* USEEFFECTS
  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <>
      <StyledDialog open={true} fullScreen={mode != '' && mode != 'SELECT'} fullWidth={mode == 'SELECT' || mode == ''} maxWidth='sm'>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers={true}>
          {loading && (
            <>
              <LinearProgress />
              <Box component='div' className='d-flex flex-column' sx={{justifyContent: 'center', alignItems: 'center'}}>
                {'Aguarde...'}
              </Box>
            </>
          )}
          {mode != '' && (
            <>
              <FormControl hidden={mode != 'SELECT'} sx={{m: 1, height: '100%', width: '100%'}} component='fieldset' variant='standard'>
                <Grid container xs spacing={1}>
                  <Grid item xs={6}>
                    <CardItem>
                      <Grid item xs container spacing={2} direction='column' justifyContent='center' alignItems='flex-start'>
                        <Grid item>
                          <Addchart color='primary' sx={{fontSize: 28}} />
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1, float: 'right'}} className='text-dark fs-3'>
                            Gráficos
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1}} className='text-dark fs-5'>
                            Montar gráficos personalizados a partir de apps e seus campos
                          </Box>
                        </Grid>
                        <Grid item>
                          <Button startIcon={<AddIcon />} color='inherit' onClick={() => handleChangeMode('EDIT-CHART', true)}>
                            Selecionar Modo
                          </Button>
                        </Grid>
                      </Grid>
                    </CardItem>
                  </Grid>
                  <Grid item xs={6}>
                    <CardItem>
                      <Grid item xs container spacing={2} direction='column' justifyContent='center' alignItems='flex-start'>
                        <Grid item>
                          <AddLink color='primary' sx={{fontSize: 28}} />
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1, float: 'right'}} className='text-dark fs-3'>
                            Atalhos
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1}} className='text-dark fs-5'>
                            Criar menu de links para atalhos de acesso externos ou de apps
                          </Box>
                        </Grid>
                        <Grid item>
                          <Button startIcon={<AddIcon />} color='inherit' onClick={() => handleChangeMode('EDIT-LINK', true)}>
                            Selecionar Modo
                          </Button>
                        </Grid>
                      </Grid>
                    </CardItem>
                  </Grid>
                  <Grid item xs={6}>
                    <CardItem>
                      <Grid item xs container spacing={2} direction='column' justifyContent='center' alignItems='flex-start'>
                        <Grid item>
                          <FolderShared color='primary' sx={{fontSize: 28}} />
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1, float: 'right'}} className='text-dark fs-3'>
                            Lista de Documentos
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1}} className='text-dark fs-5'>
                            Gerenciar arquivos enviados por todo o sistema
                          </Box>
                        </Grid>
                        <Grid item>
                          <Button startIcon={<AddIcon />} color='inherit' onClick={() => handleChangeMode('EDIT-FILE', true)}>
                            Selecionar Modo
                          </Button>
                        </Grid>
                      </Grid>
                    </CardItem>
                  </Grid>
                  <Grid item xs={6}>
                    <CardItem>
                      <Grid item xs container spacing={2} direction='column' justifyContent='center' alignItems='flex-start'>
                        <Grid item>
                          <PhotoSizeSelectActualIcon color='primary' sx={{fontSize: 28}} />
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1, float: 'right'}} className='text-dark fs-3'>
                            Imagem Externa
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box component='div' sx={{textAlign: 'left', paddingX: 1}} className='text-dark fs-5'>
                            Exibir imagem a partir de uma URL externa
                          </Box>
                        </Grid>
                        <Grid item>
                          <Button startIcon={<AddIcon />} color='inherit' onClick={() => handleChangeMode('EDIT-IMAGE', true)}>
                            Selecionar Modo
                          </Button>
                        </Grid>
                      </Grid>
                    </CardItem>
                  </Grid>
                </Grid>
              </FormControl>

              <Tabs hidden={mode == 'SELECT'} value={tabIndex} onChange={(event, value) => handleTabIndexChange(value)}>
                <Tab label='Dados Iniciais' value={0} />
                {mode == 'EDIT-CHART' && chart != null && chart.chart.tipo != '' && chart.chart.tipo != 'Number' && chart.chart.tipo != 'Table' && (
                  <Tab label='Categorias' value={1} />
                )}
                {mode == 'EDIT-CHART' && chart != null && <Tab label='Filtros' value={2} />}
                {mode == 'EDIT-FILE' && chart != null && chart.file.menuapp > 0 && <Tab label='Filtros' value={1} />}
                {/*idMenuAppDesktopChart >= 0 && <Tab label='Logs' value={99} />*/}
              </Tabs>

              {/* EDIT CHART */}
              <FormControl hidden={mode != 'EDIT-CHART' || tabIndex != 0} component='fieldset' variant='standard'>
                {chart != null && (
                  <>
                    <Box component='div' sx={{p: 1}} hidden={accessCode != 'SYSDASHBOARD'}>
                      <Autocomplete
                        disablePortal
                        disabled={!isEditable() || accessCode != 'SYSDASHBOARD'}
                        options={chartApps}
                        sx={{width: 300}}
                        size='small'
                        renderInput={(params) => <TextField {...params} label='Menu App' />}
                        getOptionLabel={(option) => option.titulo}
                        value={chartApp != null ? chartApps.filter((op: any) => op.id == chartApp.id)[0] ?? null : null}
                        onOpen={(event) => handleOnOpenAutoComplete('chart', event)}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('chart', '_CHARTAPP_', value)}
                        onInputChange={(event, value) => handleOnInputChangeAutoComplete('chart', value)}
                        filterOptions={(x) => x}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <TextField
                        sx={{width: 300}}
                        disabled={!isEditable()}
                        size='small'
                        label='Nome'
                        value={chart.nome}
                        onChange={(event) => handleOnChangeInput('', 'nome', event)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={chartOptions}
                        sx={{width: 300}}
                        size='small'
                        disabled={!isEditable()}
                        renderInput={(params) => <TextField {...params} label='Tipo' />}
                        value={
                          chart.chart.tipo != null && chart.chart.tipo != ''
                            ? chartOptions.filter((option) => option.id == chart.chart.tipo)[0] ?? null
                            : null
                        }
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('chart', 'tipo', value)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={chartFields}
                        sx={{width: 300}}
                        size='small'
                        disabled={!isEditable()}
                        renderInput={(params) => <TextField {...params} label='Dimensão' />}
                        value={chart.chart.dimensao != null ? chartFields.filter((option) => option.id == chart.chart.dimensao)[0] ?? null : null}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('chart', 'dimensao', value)}
                      />
                    </Box>
                    {(chart.chart.tipo == 'Table' || chart.chart.tipo == 'Number') && (
                      <>
                        <Box component='div' sx={{p: 1}}>
                          <Autocomplete
                            disablePortal
                            options={chartOperators}
                            sx={{width: 300}}
                            size='small'
                            disabled={!isEditable()}
                            renderInput={(params) => <TextField {...params} label='Operação' />}
                            value={
                              chart.chart.datasets[0]?.operacao != null && chart.chart.datasets[0]?.operacao != ''
                                ? chartOperators.filter((option) => option.id == chart.chart.datasets[0]?.operacao)[0] ?? null
                                : null
                            }
                            onChange={(event, value, reason) => handleOnChangeDataset(0, 'operacao', value?.id.toString() ?? '')}
                          />
                        </Box>
                        <Box component='div' sx={{p: 1}}>
                          <Autocomplete
                            disablePortal
                            options={chartOperationFields}
                            sx={{width: 300}}
                            size='small'
                            disabled={!isEditable()}
                            renderInput={(params) => <TextField {...params} label='Campo' />}
                            value={
                              chart.chart.datasets[0]?.campo != 0
                                ? chartFields.filter((option) => option.id == chart.chart.datasets[0]?.campo)[0] ?? null
                                : null
                            }
                            onChange={(event, value, reason) => handleOnChangeDataset(0, 'campo', value?.id ?? 0)}
                          />
                        </Box>
                      </>
                    )}
                    {chart.chart.tipo == 'Table' && (
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          options={chartTop}
                          sx={{width: 300}}
                          size='small'
                          disabled={!isEditable()}
                          renderInput={(params) => <TextField {...params} label='Quantidade' />}
                          value={chart.chart.top != null ? chartTop.filter((option) => option.id == chart.chart.top)[0] ?? null : null}
                          onChange={(event, value, reason) => handleOnChangeAutoComplete('chart', 'top', value)}
                        />
                      </Box>
                    )}
                    {chart.chart.tipo != 'Number' && (
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          options={chartOrdering}
                          sx={{width: 300}}
                          size='small'
                          disabled={!isEditable()}
                          renderInput={(params) => <TextField {...params} label='Ordem' />}
                          value={chart.chart.ordem != null ? chartOrdering.filter((option) => option.id == chart.chart.ordem)[0] ?? null : null}
                          onChange={(event, value, reason) => handleOnChangeAutoComplete('chart', 'ordem', value)}
                        />
                      </Box>
                    )}
                    <Box component='div' sx={{p: 1}}>
                      <MenuAppResourceSelect
                        id='icone-select'
                        label='Ícone'
                        labelId='icone-select-label'
                        type='fontawesome'
                        value={chart.icone}
                        onChange={(event) => handleOnChangeInput('', 'icone', event)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <FormControlLabel
                        label='Mostrar Titulo'
                        control={
                          <Checkbox
                            disabled={!isEditable()}
                            checked={chart.showTitle}
                            onChange={(event, checked) => handleOnChangeBoolean('', 'showTitle', event)}
                          />
                        }
                      />
                    </Box>
                  </>
                )}
              </FormControl>

              {/* EDIT LINK */}
              <FormControl hidden={mode != 'EDIT-LINK' || tabIndex != 0} component='fieldset' variant='standard'>
                {chart != null && (
                  <>
                    <Box component='div' sx={{p: 1}}>
                      <TextField
                        sx={{width: 300}}
                        disabled={!isEditable()}
                        size='small'
                        label='Nome'
                        value={chart.nome}
                        onChange={(event) => handleOnChangeInput('', 'nome', event)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={linkTypes}
                        sx={{width: 300}}
                        size='small'
                        disabled={!isEditable()}
                        renderInput={(params) => <TextField {...params} label='Tipo' />}
                        value={chart.link.tipo != '' ? linkTypes.filter((op: any) => op.id == chart.link.tipo)[0] ?? null : null}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('link', 'tipo', value)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}} hidden={chart.link.tipo != 'menuapp'}>
                      <Autocomplete
                        disablePortal
                        disabled={!isEditable()}
                        options={linkApps}
                        sx={{width: 300}}
                        size='small'
                        renderInput={(params) => <TextField {...params} label='Menu App' />}
                        getOptionLabel={(option) => option.titulo}
                        value={chart.link.valor != '' ? linkApps.filter((op: any) => op.id == chart.link.valor)[0] ?? null : null}
                        onOpen={(event) => handleOnOpenAutoComplete('link', event)}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('link', 'valor', value, true)}
                        onInputChange={(event, value) => handleOnInputChangeAutoComplete('link', value)}
                        filterOptions={(x) => x}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}} hidden={chart.link.tipo != 'externo'}>
                      <TextField
                        sx={{width: 300}}
                        disabled={!isEditable()}
                        size='small'
                        label='URL'
                        value={chart.link.valor}
                        onChange={(event) => handleOnChangeInput('link', 'valor', event)}
                      />
                    </Box>
                  </>
                )}
              </FormControl>

              {/* EDIT FILE */}
              <FormControl hidden={mode != 'EDIT-FILE' || tabIndex != 0} component='fieldset' variant='standard'>
                {chart != null && (
                  <>
                    <Box component='div' sx={{p: 1}}>
                      <TextField
                        sx={{width: 300}}
                        disabled={!isEditable()}
                        size='small'
                        label='Nome'
                        value={chart.nome}
                        onChange={(event) => handleOnChangeInput('', 'nome', event)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        disabled={!isEditable()}
                        options={fileApps}
                        sx={{width: 300}}
                        size='small'
                        renderInput={(params) => <TextField {...params} label='Menu App' />}
                        getOptionLabel={(option) => option.titulo}
                        value={chart.file.menuapp != null ? fileApps.filter((op: any) => op.id == chart.file.menuapp)[0] ?? null : null}
                        onOpen={(event) => handleOnOpenAutoComplete('file', event)}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('file', 'menuapp', value)}
                        onInputChange={(event, value) => handleOnInputChangeAutoComplete('file', value)}
                        filterOptions={(x) => x}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <FormControlLabel
                        label='Mostrar Titulo'
                        control={
                          <Checkbox
                            disabled={!isEditable()}
                            checked={chart.showTitle}
                            onChange={(event, checked) => handleOnChangeBoolean('', 'showTitle', event)}
                          />
                        }
                      />
                    </Box>
                  </>
                )}
              </FormControl>

              {/* EDIT IMAGE */}
              <FormControl hidden={mode != 'EDIT-IMAGE' || tabIndex != 0} component='fieldset' variant='standard'>
                {chart != null && (
                  <>
                    <Box component='div' sx={{p: 1}}>
                      <TextField
                        sx={{width: 300}}
                        disabled={!isEditable()}
                        size='small'
                        label='Nome'
                        value={chart.nome}
                        onChange={(event) => handleOnChangeInput('', 'nome', event)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <UploadCollection
                        azFiles={azImages}
                        onDelete={(azFile) => handleOnDeleteImage(azFile, true)}
                        onUpload={(newAzFile) => handleOnUploadImage(newAzFile)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <FormControlLabel
                        label='Mostrar Titulo'
                        control={
                          <Checkbox
                            disabled={!isEditable()}
                            checked={chart.showTitle}
                            onChange={(event, checked) => handleOnChangeBoolean('', 'showTitle', event)}
                          />
                        }
                      />
                    </Box>
                  </>
                )}
              </FormControl>

              {/* CATEGORIAS CHART */}
              <FormControl
                hidden={mode != 'EDIT-CHART' || tabIndex != 1}
                sx={{m: 1, height: '90%', width: '100%'}}
                component='fieldset'
                variant='standard'
              >
                {!loading && chart?.chart != null && (
                  <>
                    <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
                      <Box component='div' className='page-title d-flex'>
                        <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                          Categorias
                        </Box>
                        <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                        <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                          <Box component='li' className='breadcrumb-item text-muted'>
                            Gerenciamento
                          </Box>
                        </Box>
                      </Box>
                      <Box component='div' className='d-flex align-items-center py-1'>
                        {selectionModelDatasets.length > 0 && (
                          <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                            <Button
                              disableElevation
                              variant='contained'
                              startIcon={<DeleteIcon />}
                              color='error'
                              onClick={() => handleDeleteDatasetsSelecionados()}
                            >
                              Excluir
                            </Button>
                          </Box>
                        )}
                        <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                          <Button
                            disableElevation
                            variant='contained'
                            startIcon={<AddIcon />}
                            color='info'
                            onClick={() => handleOpenModalDatasets(new CChartDatasetStructure())}
                          >
                            Adicionar
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box component='div' sx={{display: 'flex', height: '100%', width: '100%'}}>
                      <StyledDataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        apiRef={apiRef_datasets}
                        components={{
                          NoRowsOverlay: NoRowsOverlay,
                          NoResultsOverlay: NoRowsOverlay,
                          LoadingOverlay: LinearProgress,
                        }}
                        checkboxSelection
                        rows={chart.chart.datasets}
                        columns={BuildDatasetsGridColumns()}
                        rowHeight={38}
                        columnHeaderHeight={38}
                        getRowId={(row: any) => row.guid}
                        rowSelectionModel={selectionModelDatasets}
                        onRowSelectionModelChange={(newSelectionModel) => {
                          setSelectionModelDatasets(newSelectionModel)
                        }}
                      />
                    </Box>
                  </>
                )}
              </FormControl>

              {/* FILTRO CHART */}
              <FormControl
                hidden={mode != 'EDIT-CHART' || tabIndex != 2}
                sx={{m: 1, height: '90%', width: '100%'}}
                component='fieldset'
                variant='standard'
              >
                {!loading && chart?.chart != null && (
                  <>
                    <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
                      <Box component='div' className='page-title d-flex'>
                        <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                          Filtros
                        </Box>
                        <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                        <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                          <Box component='li' className='breadcrumb-item text-muted'>
                            Gerenciamento
                          </Box>
                        </Box>
                      </Box>
                      <Box component='div' className='d-flex align-items-center py-1'>
                        {selectionModelChart.length > 0 && (
                          <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                            <Button
                              disableElevation
                              variant='contained'
                              startIcon={<DeleteIcon />}
                              color='error'
                              onClick={() => handleDeleteFiltroSelecionados('chart')}
                            >
                              Excluir
                            </Button>
                          </Box>
                        )}
                        <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                          <Button
                            disableElevation
                            variant='contained'
                            startIcon={<AddIcon />}
                            color='info'
                            onClick={() => SYSCHFOpen(chart.chart.filtro.length, 'chart')}
                          >
                            Adicionar
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box component='div' sx={{display: 'flex', height: '100%', width: '100%'}}>
                      <StyledDataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        apiRef={apiRef_chart}
                        components={{
                          NoRowsOverlay: NoRowsOverlay,
                          NoResultsOverlay: NoRowsOverlay,
                          LoadingOverlay: LinearProgress,
                        }}
                        checkboxSelection
                        rows={chart.chart.filtro}
                        columns={BuildGridColumns('chart')}
                        rowHeight={38}
                        columnHeaderHeight={38}
                        getRowId={(row: any) => row.guid}
                        rowSelectionModel={selectionModelChart}
                        onRowSelectionModelChange={(newSelectionModel) => {
                          setSelectionModelChart(newSelectionModel)
                        }}
                      />
                    </Box>
                  </>
                )}
              </FormControl>

              {/* FILTRO FILE */}
              <FormControl
                hidden={mode != 'EDIT-FILE' || tabIndex != 1}
                sx={{m: 1, height: '90%', width: '100%'}}
                component='fieldset'
                variant='standard'
              >
                {!loading && chart.file != null && (
                  <>
                    <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
                      <Box component='div' className='page-title d-flex'>
                        <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                          Filtros
                        </Box>
                        <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                        <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                          <Box component='li' className='breadcrumb-item text-muted'>
                            Gerenciamento
                          </Box>
                        </Box>
                      </Box>
                      <Box component='div' className='d-flex align-items-center py-1'>
                        {selectionModelFile.length > 0 && (
                          <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                            <Button
                              disableElevation
                              variant='contained'
                              startIcon={<DeleteIcon />}
                              color='error'
                              onClick={() => handleDeleteFiltroSelecionados('chart')}
                            >
                              Excluir
                            </Button>
                          </Box>
                        )}
                        <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                          <Button
                            disableElevation
                            variant='contained'
                            startIcon={<AddIcon />}
                            color='info'
                            onClick={() => SYSCHFOpen(chart.file.filtro.length, 'file')}
                          >
                            Adicionar
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box component='div' sx={{display: 'flex', height: '100%', width: '100%'}}>
                      <StyledDataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        apiRef={apiRef_chart}
                        components={{
                          NoRowsOverlay: NoRowsOverlay,
                          NoResultsOverlay: NoRowsOverlay,
                          LoadingOverlay: LinearProgress,
                        }}
                        checkboxSelection
                        rows={chart.file.filtro}
                        columns={BuildGridColumns('file')}
                        rowHeight={38}
                        columnHeaderHeight={38}
                        getRowId={(row: any) => row.guid}
                        rowSelectionModel={selectionModelFile}
                        onRowSelectionModelChange={(newSelectionModel) => {
                          setSelectionModelFile(newSelectionModel)
                        }}
                      />
                    </Box>
                  </>
                )}
              </FormControl>

              {/* MODAL FILTRO CHART */}
              {modalSYSCHF && (
                <SYSCHFModal
                  accessCode={modalSYSCHFAccessCode}
                  filterItem={modalSYSCHFItem}
                  filterItemIndex={modalSYSCHFItemIndex}
                  filterItemOperadorLogico={modalSYSCHFItemOperadorLogico}
                  callBack={SYSCHFCallback}
                />
              )}

              {/* MODAL DATASET */}
              <StyledDialog open={modalEditDataset} fullWidth maxWidth='sm'>
                <DialogTitle>Edição de Categoria</DialogTitle>
                <DialogContent dividers sx={{display: 'grid'}}>
                  <FormControl sx={{justifyContent: 'center', alignItems: 'center', minHeight: 400}} component='fieldset' variant='standard'>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={chartOperators}
                        sx={{width: 300}}
                        size='small'
                        disabled={!isEditable()}
                        renderInput={(params) => <TextField {...params} label='Operação' />}
                        value={
                          currentDataset?.operacao != null && currentDataset?.operacao != ''
                            ? chartOperators.filter((option) => option.id == currentDataset?.operacao)[0] ?? null
                            : null
                        }
                        onChange={(event, value, reason) => handleApplyValueToState(setCurrentDataset, 'operacao', value?.id.toString() ?? '')}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={chartOperationFields}
                        sx={{width: 300}}
                        size='small'
                        disabled={!isEditable()}
                        renderInput={(params) => <TextField {...params} label='Campo' />}
                        value={
                          currentDataset?.campo != null
                            ? chartOperationFields.filter((option) => option.id == currentDataset?.campo)[0] ?? null
                            : null
                        }
                        onChange={(event, value, reason) => handleApplyValueToState(setCurrentDataset, 'campo', value?.id ?? 0)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <TextField
                        sx={{width: 300}}
                        disabled={!isEditable()}
                        size='small'
                        label='Cor'
                        value={currentDataset?.cor}
                        onChange={(event) => handleApplyValueToState(setCurrentDataset, 'cor', event.target.value)}
                      />
                    </Box>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <StyledButton variant='contained' color='inherit' startIcon={<CancelIcon />} onClick={(event) => handleCloseModalDatasets(false)}>
                    Fechar
                  </StyledButton>
                  <StyledButton variant='contained' color='success' startIcon={<SaveIcon />} onClick={(event) => handleCloseModalDatasets(true)}>
                    Salvar
                  </StyledButton>
                </DialogActions>
              </StyledDialog>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton variant='contained' color='inherit' onClick={() => handleClose(false)}>
            Fechar
          </StyledButton>
          <StyledButton
            disableElevation
            hidden={mode == '' || mode == 'SELECT' || initialEditMode != null || !isEditable()}
            variant='contained'
            startIcon={<ArrowBackIcon />}
            color='inherit'
            onClick={() => handleChangeMode('SELECT', true)}
          >
            Voltar
          </StyledButton>
          <StyledButton
            disableElevation
            hidden={mode == 'SELECT' || mode == '' || idMenuAppDesktopChart == 0 || !isEditable()}
            variant='contained'
            startIcon={<DeleteIcon />}
            color='error'
            onClick={() => handleDelete()}
          >
            Excluir
          </StyledButton>
          <StyledButton
            disableElevation
            hidden={mode == 'SELECT' || mode == ''}
            disabled={!isEditable()}
            variant='contained'
            startIcon={<SaveIcon />}
            color='success'
            onClick={() => handleClose(true)}
          >
            {(saving && 'Savando...') || 'Salvar'}
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </>
  )
}

export default ChartModal
