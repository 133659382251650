import {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'

import {
  GridFilterModel,
  GridSortModel,
  GridRowSelectionModel,
  GridActionsCellItem,
  GridColumnVisibilityModel,
  useGridApiRef,
  GridPinnedColumns,
  GridPaginationModel,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  MuiEvent,
  useGridApiContext,
  GridRenderEditCellParams,
  GridColDef,
} from '@mui/x-data-grid-pro'
import {GridColumnHeaderParams, GridRenderCellParams, ptBR} from '@mui/x-data-grid'

import DynamicModal from '../../modals/modalDynamicV2'
import SYSMAFModal from '../../modals/modalSYSMAF'
import SYSMAPModal from '../../modals/modalSYSMAP'
import SYSMARModal from '../../modals/modalSYSMAR'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, Dialog, Tooltip } from '@mui/material';

import BoltIcon from '@mui/icons-material/Bolt'

import { Document, Page } from 'react-pdf';

import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopy from '@mui/icons-material/ContentCopy'
import SettingsIcon from '@mui/icons-material/Settings'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PreviewIcon from '@mui/icons-material/Preview'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MailIcon from '@mui/icons-material/Mail'
import ReplyIcon from '@mui/icons-material/Reply'
import ReplyAllIcon from '@mui/icons-material/ReplyAll'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import RefreshIcon from '@mui/icons-material/Refresh'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CallIcon from '@mui/icons-material/Call'
import SearchIcon from '@mui/icons-material/Search'
import GridOnIcon from '@mui/icons-material/GridOn'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { json } from '@codemirror/lang-json'
import LinearProgress from '@mui/material/LinearProgress'
import {NoRowsOverlay} from '../../utility/NoRowsOverlay'
import {StyledDataGrid} from '../../grid/StyledDataGrid'
import {BoxSVGMessage} from '../../utility/BoxSVGMessage'
import {SYSTEMPLATEModal} from '../../modals/modalSYSTEMPLATE'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {writeFileXLSX, utils} from 'xlsx'
import ImportacaoModal from '../../modals/modalImportacao'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {Autocomplete, Box, InputAdornment, TextField} from '@mui/material'
import {GlobalContext} from '../../../../../App'
import {StyledRadioGroup} from '../../styled/StyledRadioButton'
import StyledButton from '../../styled/StyledButton'
import StyledMenu from '../../styled/StyledMenu'
import StyledDialog from '../../styled/StyledDialog'
import {CMenuAppHistoryStructure} from '../../../../../models/classes/CMenuAppHistoryStructure'
import SYSMAAModal from '../../modals/modalSYSMAA'
import HTMLViewer from '../../modals/modalHtmlViewer'
import HTMLSend from '../../modals/modalHtmlSend'
import useStateRef from 'react-usestateref'
import React from 'react'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../../modules/api/ApiWrapper'
import ModalExclusao from '../../modals/modalExclusao'
import {useIntl} from 'react-intl'
import ModalPerformingAction from '../../modals/modalPerformingAction'
import {StyledTextField} from '../../styled/StyledTextField'
import ModalSYSACT from '../../modals/modalSYSACT'
import SYSCHEDULEModal from '../../modals/modalSYSSCHEDULE'
import {DownloadLogico} from '../../../../../models/wrappers/helpers'
import ProgressBar from '../../utility/ProgressBar'
import {useLang} from '../../../../../../_metronic/i18n/Metronici18n'
import {FormatDate} from '../../../../../models/wrappers/format'
import {WhatsAppRedirectToolTip} from '../../grid/CellToolTip'
import PasswordModal from '../../modals/modalPassword'
import {useGlobalAuthentication} from '../../../../../modules/authentication/Authentication'
import ModalTarefa from '../../modals/modalTarefa'
import ModalPdf from '../../modals/modalPdf'
import { MenuAppActionResult } from '../../../../../modules/api/models/Result/MenuAppActionResult'
import { SafeClamp } from '../../../../../models/wrappers/math'
import axios from 'axios'
import MenuAppResourceSelect from '../../utility/menuAppResourceSelect'
import { CampoEstruturadoResult } from '../../../../../modules/api/models/Result/CampoEstruturadoResult'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { LocalizationProvider, DateTimePicker, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { basicLight } from '@uiw/codemirror-theme-basic'
import { Extension } from '@uiw/react-codemirror'
import { id } from 'date-fns/locale'
import { Marker } from 'mapbox-gl'
import moment from 'moment'
import { MuiTelInput } from 'mui-tel-input'
import QRCode from 'qrcode.react'
import uuid from 'react-uuid'
import { ids } from 'webpack'
import { CCalcFieldRule } from '../../../../../models/classes/CCalcFieldRule'
import { CRuleActions } from '../../../../../models/classes/CRuleActions'
import { handleApplyValueToArrayedState } from '../../../../../models/wrappers/handlers'
import { TranslateLocale } from '../../../../../models/wrappers/translator'
import { GetAccessToken } from '../../../../../modules/api/Api'
import { ElapsedFollowUpTimerResult } from '../../../../../modules/api/models/Result/ElapsedFollowUpTimerResult'
import { MenuAppRoleSimpleResult } from '../../../../../modules/api/models/Result/MenuAppRoleSimpleResult'
import { SingleSelectResult } from '../../../../../modules/api/models/Result/SingleSelectResult'
import UploadCollection from '../../upload/UploadCollection'
import { CKEditorZIndexFix } from '../../utility/CKEditorZIndexFix'
import { NumericFormatWithoutDecimal, NumericFormatWithDecimal, GeocodeFormat } from '../../utility/numericFormatDecimal'

import $ from 'jquery'
import 'jquery-mask-plugin'

import { Badge, Theme, ThemeProvider, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import WarningIcon from '@mui/icons-material/Warning'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import RecommendIcon from '@mui/icons-material/Recommend'
import LockResetIcon from '@mui/icons-material/LockReset'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import EditNoteIcon from '@mui/icons-material/EditNote'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { Id, ToastContent, ToastOptions } from 'react-toastify'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Chat from '../../chat/chat'
import EmailSideMenu from '../../email/EmailSideMenu'
import CodeMirror from '@uiw/react-codemirror'

import ReactMapGL, { Marker as MarkerModal } from 'react-map-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import Timer from '../../utility/Timer'
import { NavigateFunction } from 'react-router-dom'

interface IDynamicRequestProps {
  useRelationFilters?: boolean
  idMenuAppRelation?: number
  idRelationItem: number
}

interface IDynamicList {
  readonly?: boolean
  isPrimary?: boolean
  isDisplayed?: boolean
  accessCode?: string
  sysParam?: string
  filters?: Array<any>
  IDMenuAppGenericItem?: string
  IDIntegration?: number
  title?: string
  subtitle?: string
  displayHeader?: boolean
  relationItem?: any
  requestProps?: IDynamicRequestProps
  getDataCallBack?: any
}

export const DynamicList = ({
  readonly,
  isPrimary,
  isDisplayed,
  accessCode,
  sysParam,
  filters,
  IDMenuAppGenericItem,
  IDIntegration,
  title,
  subtitle,
  displayHeader,
  relationItem,
  requestProps,
  getDataCallBack,
}: IDynamicList) => {
  // Variaveis de estado
  const [AccessCodeModal, setAccessCodeModal] = useState<string | undefined>('')
  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Saving, setSaving] = useState<boolean>(false)
  const [Validating, setValidating] = useState<boolean>(false)
  // const [loading, setLoading] = useState<boolean>(false)
  const [DataAccordions, setDataAccordions] = useState<Array<string>>([])
  const [Data, setData, DataRef] = useStateRef<Array<CampoEstruturadoResult>>([])
  const [DataID, setDataID] = useState<number>(0)
  const [DataIDs, setDataIDs] = useState<Array<number>>([])
  const [Relations, setRelations] = useState<any>([])
  const [TabIndex, setTabIndex] = useState<number>(0)
  const [CustomPageFile, setCustomPageFile] = useState<any>(null)
  // const [permissions, setPermissions] = useState<MenuAppRoleSimpleResult | undefined>()
  //const [actions, setActions] = useState<Array<MenuAppActionResult>>([])
  const [elapsedFollowUpTimer, setElapsedFollowUpTimer] = useState<ElapsedFollowUpTimerResult | undefined>(undefined)
  const [fieldLock, setFieldLock] = useState<boolean>(false)
  const Editor: any = ClassicEditor

  const [hideButtons, setHiddenButtons] = useState<{
    enviarPorEmail: boolean
    excluir: boolean
    salvar: boolean
    salvarEFechar: boolean
  }>({
    enviarPorEmail: false,
    excluir: false,
    salvar: false,
    salvarEFechar: false,
  })

  const [sending, setSending] = useState<boolean>(false)
  const [emailProps, setEmailProps] = useState<any>({})

  const [emailGrid, setEmailGrid] = useState<boolean>(false)
  const [emailGridIntegration, setEmailGridIntegration] = useState<number>(0)
  const [emailGridFilters, setEmailGridFilters] = useState<Array<any>>([
    {
      columnField: 'Folder',
      operatorValue: 'equals',
      value: ['INBOX'],
    },
  ])
  const [emailGridTitle, setEmailGridTitle] = useState<string>('Caixa de Entrada')
  const [emailGridNaoLidos, setEmailGridNaoLidos] = useState<Array<any>>([])
  const [appGeneralRules, setAppGeneralRules, appGeneralRulesRef] = useStateRef<Array<any>>([])
  const [codeEditorTheme, setCodeEditorTheme] = useState<Extension>(basicLight)
  const [regraConfirmacao, setRegraConfirmacao] = useState<any>(null)
  const [geocodeIntervalID, setGeocodeIntervalID] = useState<NodeJS.Timer | undefined>(undefined)

  const [MAPTOKEN, setMAPTOKEN] = useState<string>('')
  const MAPDEFAULTCOORDINATES = '{"longitude": -49.2733, "latitude": -25.4284, "zoom": 10, "address": ""}'

  /**
   * Verifica se a função de callback é existente e se for chama a mesma passando o retorno para o componente que esta renderizando este.
   * @param data (Opcional) Objeto de retorno para o componente "Pai"
   * @returns void
   */
  const handleCallBack = (data: any = null) => {
    // Limpamos o objeto Interval do geocoder para evitar azamentos de memória
    if (geocodeIntervalID != null) {
      clearInterval(geocodeIntervalID)
    }

    if (dynamicCallBack != null) {
      dynamicCallBack(data)
    }
  }

  const handleLoadActionsModal = (ignorarValidacoes: boolean = false) => {
    apiWrapper.get(`/api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${accessCode}`).then(async (response) => {
      let _actions: Array<MenuAppActionResult> = response.data.data

      // VALIDAMOS AS EXIBIÇÕES DAS AÇÕES APÓS EXECUÇÃO INICIAL DE REGRAS CASO TENHAM
      // Fazemos isso no back pois o valor que impera para a execução de uma ação É O QUE ESTÁ SALVO NA BASE e não o que está no front sendo renderizado e alterado no modal
      if (!ignorarValidacoes) {
        for (const _action of _actions) {
          if (_action.menuAppFieldsRule?.atributo08 == null || _action.menuAppFieldsRule?.atributo08 == '') {
            continue
          }

          let obj = {
            item: {
              ID: id,
              AccessCode: accessCode,
              Campos: Data,
              EventoRegra: 'Ao carregar app',
              PreValidacaoExibicao: true,
            },
          }

          let ret = await apiWrapper.put('api/v1/Dynamic/executarPreValidacoes', obj)
          let results = ret.data.data as Array<string>

          let result = results.find((x) => x.startsWith(`${_action.menuAppFieldsRule?.id} -`))
          if (!result?.includes('SUCESSO')) {
            _action.biVisible = false
          }
        }
      }

      setActions(_actions)
    })
  }

  const handleLoadTarefaAprovacao = async () => {
    let tarefaRequest = await apiWrapper.get(`api/v1/Tarefa/existeTarefaAprovacaoItemDinamico?item.AccessCode=${accessCode}&item.ID=${id}`)

    if (tarefaRequest.data.data != null) {
      handleOpenModalAvisoTarefa(tarefaRequest.data.data.ID)
    }
  }

  /**
   * Verifica se todos os campos estão válidos, campos não válidps são informados com um erro na tentativa de salvamento.
   * @returns void
   */
  const handleValidate = () => {
    let isValid = true
    let message: Array<string> = []

    for (let index = 0; index < Data.length; index++) {
      let item = Data[index]

      if (
        !modalDynamicAcaoEmMassa &&
        item.obrigatorio &&
        ((item.tipo == 'upload' && item.arquivos.length == 0) || (item.tipo != 'upload' && (item.valor == null || item.valor == '')))
      ) {
        isValid = false
        message.push(`O campo ${item.label} é obrigatório`)

        setData((prev) => {
          prev[index].isValid = false
          prev[index].invalidMessage = 'Campo obrigatório'
          return [...prev]
        })
      }
    }

    if (modalDynamicAcaoEmMassa && Data.find((x) => x.salvarAlteracoes) == null) {
      isValid = false
      message.push(`Você precisa marcar ao menos um campo para ser salvo.`)
    }

    if (!isValid) {
      message.forEach((item: any) => {
        toast.error(item)
      })
    }

    return isValid
  }

  const acessoExterno = null;

  /**
   * Função de inicialização do componente, geralmente chamada no primeiro useEffect.
   * @returns void
   */
  const handleOpen = async (accessCode: string | undefined, id: number) => {
    debugger
    setOpened(true)
    setAccessCodeModal(accessCode)
    setDataID(id)
    setDataIDs(modalDynamicItemIDs ?? [])

    handleClosePerformingAction()
    setLoading(true)

    if (readonly) {
      setFieldLock(true)
    }

    handleLoadTarefaAprovacao()

    // Carregamos os items extras para as propriedades
    const integrationRequest = await apiWrapper.get('api/v1/MenuAppIntegration/CarregarPorIdentificador?item.Identificador=MAPBOX')
    if (integrationRequest.data.data != null) {
      setMAPTOKEN(integrationRequest.data.data.token)
    }

    if (!acessoExterno) {
      if (readonly) {
        setPermissions({ biSelect: true, biInsert: false, biManage: false, biDelete: false, biUpdate: false })
      } else {
        await apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=' + accessCode).then((response) => {
          setPermissions(response.data.data)
        })
      }

      await apiWrapper.get(`/api/v1/ElapsedFollowUpTimer/carregarAtivoPorMenuAppGenericValue?item.IDMenuAppGenericValue=${id}`).then((response) => {
        setElapsedFollowUpTimer(response.data.data)
      })
    } else {
      // Acesso externo tem permissões pré definidas
      setPermissions({ biSelect: true, biInsert: true, biManage: false, biDelete: false, biUpdate: true })
    }

    // Carregamos os campos estruturados
    apiWrapper
      .get(
        `api/v1/Dynamic/carregarCamposEstruturados${acessoExterno ? 'Publico' : ''}?item.AccessCode=${accessCode}&item.ID=${id}&item.AcaoEmMassa=${modalDynamicAcaoEmMassa ? 'true' : 'false'
        }`
      )
      .then(async (response) => {
        let campos: Array<CampoEstruturadoResult> = response.data.data

        // INICAMOS WORKFLOW DIFERENTE APENAS PARA CUSTOM PAGE
        if (response.data.customPage) {
          setCustomPageFile(response.data.data)

          $('#contentbind').load(response.data.data.urlDownload, () => {
            window.APP_Start(accessCode as string, id, process.env.REACT_APP_BST_API_URL, GetAccessToken, {
              jQuery: $,
              navigate: navigate,
              closeModal: handleCallBack,
              successMSG: toast.success,
              errorMSG: toast.error,
              warnMSG: toast.warning,
              hideButtons: setHiddenButtons,
            })
          })

          handleLoadActions(true)
          setLoading(false)

          return
        }

        //MONTAMOS OS ACORDEONS
        let accordions: Array<string> = []

        for (const field of campos) {
          if (field.agrupador != null && field.agrupador != '' && accordions.find((x) => x == field.agrupador) == null) {
            accordions.push(field.agrupador)
          }

          // Lockamos o modal em caso de campos de sistema de items protegidos
          if (
            accessCode == 'SYSROLE' &&
            field.campo.toLowerCase() == 'identificador' &&
            (field.valor == 'SADMIN' || field.valor == 'ADMIN' || field.valor == 'USERALL')
          ) {
            setFieldLock(true)
          }

          //DISPARAMOS AS MENSAGENS DE ERRO QUE ACONTECERAM NA HORA DE CARREGAR OS CAMPOS NO BACK (GERALMENTE ERROS DE REGRAS QUE SÃO EXECUTADAS SOMENTE PELO BACKEND)
          if (field.errosInternos.length > 0) {
            for (const error of field.errosInternos) {
              toast.error(error.response?.data?.mensagem ?? error.message)
            }
          }
        }

        setDataAccordions(accordions.sort())

        if (relationItem != null) {
          let campoDeRelacao = campos.find((x) => x.campo == relationItem.menuAppField.campo)

          if (campoDeRelacao != null) {
            campoDeRelacao.valor = requestProps?.idRelationItem?.toString() ?? ''
          }
        }

        //REGRAS DO APP
        let appRulesReq = await apiWrapper.get(
          `api/v1/MenuAppFieldsRule/listarPorMenuApp${acessoExterno ? 'Publico' : ''}?item.accessCode=${accessCode}`
        )

        let appRules = appRulesReq.data.data

        // Executamos as regras de app
        for (const rule of appRules) {
          if (rule.evento == 'Ao abrir formulario') {
            if (rule.acao == 'Bloquear Campos') {
              if (rule.atributo01 == 'ALL') {
                setFieldLock(true)
              } else if (rule.atributo02 != '') {
                let camposABloquear = JSON.parse(rule.atributo02) as Array<number>

                for (let item of camposABloquear) {
                  let campo = campos.find((x) => x.id == item)

                  if (campo != null) {
                    campo.somenteLeitura = true
                  }
                }
              }
            } else if (rule.acao == 'Ocultar Campos') {
              let aoCriar = rule.atributo02 === 'true'
              let aoEditar = rule.atributo03 === 'true'

              if (rule.atributo01 != '' && ((aoCriar && id == 0) || (aoEditar && id != 0))) {
                let camposAOcultar = JSON.parse(rule.atributo01) as Array<number>

                for (let item of camposAOcultar) {
                  let campo = campos.find((x) => x.id == item)

                  if (campo != null) {
                    campo.exibir = false
                  }
                }
              }
            } else if (rule.acao == 'Executar ação de estrutura') {
              if (rule.atributo04 == '') {
                continue
              }

              let campo: any = null
              let result = campos
              let fields = JSON.parse(rule.atributo04)

              if (fields.length > 1) {
                let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/retornarObjetoRecursivo`, {
                  item: { ID: rule.id, Campos: campos },
                })

                if (ret.data.mensagem != 'SUCCESS') {
                  continue
                }

                result = ret.data.data
                campo = result.find((x) => x.campo.toLowerCase() == ret.data.campo.toLowerCase())
              } else {
                campo = result.find((x) => x.id == fields[0])
              }

              if (result == null || campo == null) {
                continue
              }

              if (rule.atributo05 == 'ACAO') {
                if (
                  (rule.atributo06 == 'Igual' && campo.valor == rule.valor) ||
                  (rule.atributo06 == 'Diferente' && campo.valor != rule.valor) ||
                  (rule.atributo06 == 'Não está preenchido' && campo.valor == '') ||
                  (rule.atributo06 == 'Está preenchido' && campo.valor != '') ||
                  (rule.atributo06 == 'Contém' && campo.valor.contains(rule.valor)) ||
                  (rule.atributo06 == 'Não contém' && !campo.valor.contains(rule.valor)) ||
                  (rule.atributo06 == 'Maior que' && parseFloat(campo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo06 == 'Menor que' && parseFloat(campo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo06 == 'Maior ou igual a' && parseFloat(campo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo06 == 'Menor ou igual a' && parseFloat(campo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo07 == 'OCULTAR') {
                    if (rule.atributo08 != '') {
                      let camposAOcultar = JSON.parse(rule.atributo08) as Array<number>

                      for (let item of camposAOcultar) {
                        let campo = campos.find((x) => x.id == item)

                        if (campo != null) {
                          campo.exibir = false
                        }
                      }
                    }
                  } else if (rule.atributo07 == 'BLOQUEAR') {
                    let camposABloquear = JSON.parse(rule.atributo08) as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.somenteLeitura = true
                      }
                    }
                  } else if (rule.atributo07 == 'LIMPAR') {
                    let camposABloquear = JSON.parse(rule.atributo08) as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.valor = ''
                      }
                    }
                  }
                } else {
                  if (rule.atributo07 == 'OCULTAR') {
                    if (rule.atributo08 != '') {
                      let camposAOcultar = JSON.parse(rule.atributo08) as Array<number>

                      for (let item of camposAOcultar) {
                        let campo = campos.find((x) => x.id == item)

                        if (campo != null) {
                          campo.exibir = true
                        }
                      }
                    }
                  } else if (rule.atributo07 == 'BLOQUEAR') {
                    let camposABloquear = JSON.parse(rule.atributo08) as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.somenteLeitura = false
                      }
                    }
                  }
                }
              } else if (rule.atributo05 == 'APLICAR') {
                let campoParaAplicar = campos.find((x) => x.id == rule.atributo06)

                if (campoParaAplicar != null) {
                  campoParaAplicar.valor = campo.valor
                }
              }
            }
          }

          if (rule.acao == 'Exibir caixa de emails') {
            setEmailGrid(true)
            setEmailGridIntegration(parseInt(rule.atributo01))
          }
        }

        // Executamos regras de campos do inicio do modal
        for (const field of campos) {
          let toConcat = appRules.filter((x) => x.acao == 'Executar ação de estrutura' && x.atributo05 == 'FILTRAR' && x.atributo06 == field.id)
          if (toConcat.length > 0) {
            field.regras = field.regras.concat(toConcat)
          }

          for (const rule of field.regras) {
            if (rule.evento == 'Campo possui valor' && rule.acao == 'Exibir Campos') {
              let campoAlvo = campos.find((item: any) => item.id == (rule.idmenuAppField as number))

              if (campoAlvo != null && campoAlvo.valor != '') {
                if (
                  (rule.atributo01 == 'Igual' && campoAlvo.valor == rule.valor) ||
                  (rule.atributo01 == 'Diferente' && campoAlvo.valor != rule.valor) ||
                  (rule.atributo01 == 'Não está preenchido' && campoAlvo.valor == '') ||
                  (rule.atributo01 == 'Está preenchido' && campoAlvo.valor != '') ||
                  (rule.atributo01 == 'Contém' && campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Não contém' && !campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Maior que' && parseFloat(campoAlvo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor que' && parseFloat(campoAlvo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Maior ou igual a' && parseFloat(campoAlvo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor ou igual a' && parseFloat(campoAlvo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo02 != '') {
                    let camposAExibir = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    for (let item of camposAExibir) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.exibir = true
                      }
                    }
                  }
                }
              }
            }

            if (rule.evento == 'Campo possui valor' && rule.acao == 'Ocultar Campos') {
              let campoAlvo = campos.find((item: any) => item.id == (rule.idmenuAppField as number))

              if (campoAlvo != null && campoAlvo.valor != '') {
                if (
                  (rule.atributo01 == 'Igual' && campoAlvo.valor == rule.valor) ||
                  (rule.atributo01 == 'Diferente' && campoAlvo.valor != rule.valor) ||
                  (rule.atributo01 == 'Não está preenchido' && campoAlvo.valor == '') ||
                  (rule.atributo01 == 'Está preenchido' && campoAlvo.valor != '') ||
                  (rule.atributo01 == 'Contém' && campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Não contém' && !campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Maior que' && parseFloat(campoAlvo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor que' && parseFloat(campoAlvo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Maior ou igual a' && parseFloat(campoAlvo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor ou igual a' && parseFloat(campoAlvo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo02 != '') {
                    let camposAOcultar = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    for (let item of camposAOcultar) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.exibir = false
                      }
                    }
                  }
                }
              }
            }

            if (rule.evento == 'Campo possui valor' && id != 0) {
              if (rule.acao == 'Bloquear Campos') {
                if (
                  field.valor != '' &&
                  ((rule.atributo03 == 'Igual' && field.valor == rule.valor) ||
                    (rule.atributo03 == 'Diferente' && field.valor != rule.valor) ||
                    (rule.atributo03 == 'Não está preenchido' && field.valor == '') ||
                    (rule.atributo03 == 'Está preenchido' && field.valor != '') ||
                    (rule.atributo03 == 'Contém' && field.valor.includes(rule.valor)) ||
                    (rule.atributo03 == 'Não contém' && !field.valor.includes(rule.valor)) ||
                    (rule.atributo03 == 'Maior que' && parseFloat(field.valor) > parseFloat(rule.valor)) ||
                    (rule.atributo03 == 'Menor que' && parseFloat(field.valor) < parseFloat(rule.valor)) ||
                    (rule.atributo03 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(rule.valor)) ||
                    (rule.atributo03 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(rule.valor)))
                ) {
                  if (rule.atributo01 == 'ALL') {
                    setFieldLock(true)
                  } else if (rule.atributo02 != '') {
                    let camposABloquear = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    for (let item of camposABloquear) {
                      let campo = campos.find((x) => x.id == item)

                      if (campo != null) {
                        campo.somenteLeitura = true
                      }
                    }
                  }
                }
              }
            }

            if (rule.evento == 'Campo possui valor' && rule.acao == 'Ocultar custom action') {
              let campoAlvo = campos.find((item: any) => item.id == (rule.idmenuAppField as number))
              if (campoAlvo != null) {
                if (
                  (rule.atributo01 == 'Igual' && campoAlvo.valor == rule.valor) ||
                  (rule.atributo01 == 'Diferente' && campoAlvo.valor != rule.valor) ||
                  (rule.atributo01 == 'Não está preenchido' && campoAlvo.valor == '') ||
                  (rule.atributo01 == 'Está preenchido' && campoAlvo.valor != '') ||
                  (rule.atributo01 == 'Contém' && campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Não contém' && !campoAlvo.valor.includes(rule.valor)) ||
                  (rule.atributo01 == 'Maior que' && parseFloat(campoAlvo.valor) > parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor que' && parseFloat(campoAlvo.valor) < parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Maior ou igual a' && parseFloat(campoAlvo.valor) >= parseFloat(rule.valor)) ||
                  (rule.atributo01 == 'Menor ou igual a' && parseFloat(campoAlvo.valor) <= parseFloat(rule.valor))
                ) {
                  if (rule.atributo02 != '') {
                    let actionsParaOcultar = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    setActions((prev) => {
                      for (let item of actionsParaOcultar) {
                        let itemAlvo = prev.find((x) => x.id == item)
                        if (itemAlvo != null) {
                          itemAlvo.biVisible = false
                        }
                      }

                      return [...prev]
                    })
                  }
                } else {
                  if (rule.atributo02 != '') {
                    let actionsParaExibir = JSON.parse(rule.atributo02 ?? '[]') as Array<number>

                    setActions((prev) => {
                      for (let item of actionsParaExibir) {
                        let itemAlvo = prev.find((x) => x.id == item)
                        if (itemAlvo != null) {
                          itemAlvo.biVisible = true
                        }
                      }

                      return [...prev]
                    })
                  }
                }
              }
            }
          }

          //VARIAVEIS DO FRONT
          field.showDecrypted = false
          field.loading = false
          field.isValid = true
          field.invalidMessage = ''
        }

        setAppGeneralRules(appRules)
        setData(campos)

        if (!acessoExterno) {
          handleLoadActionsModal()
        }

        let tipoIntegracao = campos.find((x) => x.campo.toLowerCase() == 'idtipointegration')
        if (accessCode == 'SYSINTEGRATION' && tipoIntegracao != null && tipoIntegracao.valor == '7') {
          verifyWAInstance(true)
        }

        setTimeout(() => {
          handlePopulateGeolocation(campos)
        }, 500)

        if (relationItem != null) {
          let pos = -1

          campos.map((item: any, index: number) => {
            if (item.campo == relationItem.menuAppField.campo) {
              pos = index
            }
          })

          let ids: Array<number> = []

          if (requestProps?.idRelationItem != null) {
            ids.push(requestProps?.idRelationItem)
          }

          handleOnInputChangeAutoComplete(pos, '', ids, true)
        }

        setTimeout(() => {
          //INCLUSÃO DE FORMATAÇÕES
          //PROCURAMOS TODOS OS COMPONENTES COM BASE NO ID PARA A FORMATAÇÃO

          //CEP
          let cep_element: any = $('input[class*=" cep-"]');
          cep_element.mask('00.000-000')

          //CPF
          let cpf_elements: any = $('input[class*=" cpf-"]');
          cpf_elements.mask('000.000.000-00')

          //CNPJ
          let cnpj_element: any = $('input[class*=" cnpj-"]');
          cnpj_element.mask('00.000.000/0000-00')
        }, 250)
      })
      .finally(() => {
        setLoading(false)
      })

    apiWrapper.get(`api/v1/MenuApp/carregarTituloPorCodigoDeAcesso?item.AccessCode=${accessCode}`).then((response) => {
      if (id == 0) {
        setTitle(response.data.titulo + (modalDynamicAcaoEmMassa ? ' - Edição em massa' : ' - Adicionar'))
      } else {
        setTitle(response.data.titulo + (readonly ? ' - Visualizar' : ' - Editar'))
      }
    })

    if (id != 0) {
      apiWrapper.get('api/v1/MenuAppRelacao/listarParaExibicao?item.AccessCode=' + accessCode).then((response) => {
        setRelations(response.data.data)
      })
    }
  }

  /**
   * Função de fechamento de modal, aqui gerenciamos todos os tratamentos de pré-salvamento e o salvamento em si
   * @param save Salvar dados
   * @param keepOpen Manter o modal aberto após salvar os dados
   * @param eventoDeAcoes (Opcional) Nome do evento de ação customizada
   * @returns void
   */
  const handleClose = async (save: boolean, keepOpen: boolean, eventoDeAcoes?: string) => {
    if (CustomPageFile != null) {
      if (save) {
        setSaving(true)
        let saved = keepOpen ? await window.APP_Save() : await window.APP_SaveAndClose()
        setSaving(false)

        if (saved > 0) {
          handleCallBack({ id: saved, keepOpen: keepOpen, customPage: true })
        }
      } else {
        window.APP_Cancel()
        handleCallBack()
      }

      return
    } else if (!save) {
      handleDeleteAllUploads()

      if (!keepOpen) {
        setOpened(false)
        setData([])
      } else {
        setData([])
        handleOpen(accessCode, DataID)
        return
      }

      handleCallBack()
      return
    }

    if (Data == null || !handleValidate()) {
      return
    }

    let regrasConfirmacao = appGeneralRules.filter(
      (x) =>
        ((x.evento == 'Ao criar registro' && DataID == 0) || (x.evento == 'Ao atualizar registro' && DataID != 0)) && x.acao == 'Exibir confirmacao'
    )

    let _regrasConfirmacao: any = null

    if (regrasConfirmacao.length > 0 && !modalDynamicAcaoEmMassa) {
      handleOpenPerformingAction('Executando regras e salvando registro, aguarde...')

      if (eventoDeAcoes == null) {
        setValidating(true)

        let obj = {
          item: {
            ID: DataID,
            AccessCode: accessCode,
            Campos: Data,
          },
        }

        let ret = await apiWrapper.put('api/v1/Dynamic/executarPreValidacoes', obj)
        let results = ret.data.data as Array<string>

        setValidating(false)

        for (let regra of regrasConfirmacao) {
          let result = results.find((x) => x.startsWith(`${regra.id} -`))

          if (result?.includes('SUCESS')) {
            _regrasConfirmacao = regra
            setRegraConfirmacao(regra)
            break
          }
        }

        if (_regrasConfirmacao != null) {
          handleOpenModalConfirmacao(keepOpen, _regrasConfirmacao.atributo01)
          return
        }
      }

      if (eventoDeAcoes != null && eventoDeAcoes != '' && regraConfirmacao != null) {
        let acoes = JSON.parse(regraConfirmacao.atributo10) as Array<CRuleActions>

        for (const acao of acoes.filter((x) => x.event == eventoDeAcoes)) {
          if (acao.action != 'Atualizar Campo') {
            continue
          }

          let campo = Data.find((x) => x.id == acao.campo)
          if (campo == null) {
            continue
          }

          campo.valor = acao.valor
        }
      }
    }

    setSaving(true)

    let obj: any = {
      item: {
        AccessCode: AccessCode,
        Campos: Data,
        AcaoEmMassa: modalDynamicAcaoEmMassa,
        IDMenuAppFieldRule: regraConfirmacao?.id,
        EventoDeAcoes: eventoDeAcoes ?? '',
      },
    }

    if (modalDynamicAcaoEmMassa) {
      obj.item.IDs = DataIDs
    } else {
      obj.item.IDs = [DataID]
    }

    if (WAInstanceRef.current != null) {
      updateWAInstanceWebhook()
    }

    apiWrapper
      .put(`api/v1/Dynamic/salvarCamposModal${acessoExterno ? 'Publico' : ''}`, obj)
      .then((response) => {
        setSaving(false)

        let erro = false

        if (DataID == 0) {
          for (const item of response.data.resultados) {
            if (item.item2 != 'SUCESSO') {
              toast.error(item.item2)
              erro = true
            } else {
              toast.success('Registro salvo com sucesso!')
            }
          }
        } else {
          for (const item of response.data.resultados) {
            if (item.item2 != 'SUCESSO') {
              toast.error(`Item ${item.item1}: ${item.item2}`)
              erro = true
            } else {
              if (modalDynamicAcaoEmMassa) {
                toast.success(`Item ${item.item1}: Atualizado com sucesso!`)
              } else {
                toast.success('Registro atualizado com sucesso!')
              }
            }
          }
        }

        if (!modalDynamicAcaoEmMassa && erro) {
          handleClosePerformingAction()
          return
        }

        if (!keepOpen) {
          setOpened(false)
          setData([])
        } else {
          setData([])
          setDataID(response.data.id)
          handleOpen(accessCode, response.data.id)
        }

        handleCallBack({ id: response.data.id, keepOpen: keepOpen })
      })
      .catch((error) => {
        setSaving(false)
        toast.error(error.response.data.mensagem)
      })
  }

  /**
   * Função de inicialização do geocode.
   * @returns void
   */
  const handlePopulateGeolocation = (data: any, ignoreTimer?: boolean) => {
    for (const field of data) {
      if (field.tipo != 'geolocation') {
        continue
      }

      navigator.geolocation.getCurrentPosition(
        function (position) {
          setData((prev) => {
            let campo = prev.find((x) => x.id == field.id)

            if (campo != null) {
              campo.valor = JSON.stringify({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            }

            return [...prev]
          })

          if (geocodeIntervalID != null) {
            clearInterval(geocodeIntervalID)
          }

          handleClosePerformingAction()
        },
        function (positionError) {
          if (!field.obrigatorio || ignoreTimer) {
            return
          }

          toast.error('É necessário permitir a localização para este app!')

          let id = setInterval(() => {
            handlePopulateGeolocation(data, true)
          }, 500)

          setGeocodeIntervalID(id)
          handleOpenPerformingAction('Aguardando permissão de localização')
        }
      )
    }
  }

  /**
   * Função que controla o estado das seleções das tabs
   * @param event (Não utilizado) Evento do controle que muda as tabs
   * @param value Valor da tab a ser selecionada
   * @returns void
   */
  const handleTabIndexChange = (event: any, value: number) => {
    setTabIndex(value)

    switch (value) {
      case -1:
        break
      case -2:
        let element = document.getElementById('htmlContentBind')
        if (element != null) {
          element.innerHTML = emailProps.content
        }
        break
    }
  }

  const handleOpenExcluir = (id: number, accessCode?: string) => {
    setModalDataIDExcluir(id)
    setModalAccessCodeExcluir(accessCode)
    setModalOpenedExcluir(true)
    setExclusaoProgresso(0)
  }

  /**
   * Função simples para travar todos os floats do modal.
   * @param value Input para travar
   * @returns number
   */
  const handleClampFloat = (value: number, min: number = -9999999.99, max: number = 9999999.99) => {
    return SafeClamp(value, min, max)
  }

  /**
   * Função simples para travar todos os numeros do modal.
   * @param value Input para travar
   * @returns number
   */
  const handleClampNumber = (value: number) => {
    return SafeClamp(value, -2147483647, 2147483647)
  }

  /**
   * Função geral que aplica um valor final na propriedade do campo estruturado no index informado e logo após executa as regras do campo.
   * @param index Posição do campo estruturado na variável de estado
   * @param value Valor a ser aplicado
   * @returns void
   */
  const handleOnChange = (index: number, value: string) => {
    // Atualizamos o estado com o novo valor
    setData((prev) => {
      let field = prev[index]

      if (DataID != 0 && field.valorAnterior == '') {
        field.valorAnterior = field.valor
      }

      // Aplicamos o valor de latitude e longitude no campo de mapa caso o mesmo existe e esteja com o campo do contexto vinculado
      if (field.tipo == 'geocode') {
        let linkedMapFields = prev.filter((x) => x.configuracao.idmenuAppFieldLat == field.id || x.configuracao.idmenuAppFieldLon == field.id)

        for (const item of linkedMapFields) {
          if (item.configuracao.idmenuAppFieldLat == field.id) {
            let mapJson = JSON.parse(item.valor != '' ? item.valor : MAPDEFAULTCOORDINATES)
            mapJson.latitude = parseFloat(value)
            item.valor = JSON.stringify(mapJson)
          }

          if (item.configuracao.idmenuAppFieldLon == field.id) {
            let mapJson = JSON.parse(item.valor != '' ? item.valor : MAPDEFAULTCOORDINATES)
            mapJson.longitude = parseFloat(value)
            item.valor = JSON.stringify(mapJson)
          }
        }
      }

      field.valor = value

      field.isValid = true
      field.invalidMessage = ''

      return [...prev]
    })

    // Executamos as regras do campo
    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo possui valor')
    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo mudar de valor')
  }

  /**
   * Função que muda o valor de um auto complete e escolhe se é necessário abrir um segundo modal caso o usuário clicou em "Adicionar registro" no drop down.
   * @param index Posição do campo estruturado na variável de estado
   * @param value Valor a ser aplicado
   * @returns void
   */
  const handleOnChangeAutoComplete = (index: any, value: any) => {
    if (value == null || value.id != -1) {
      handleOnChange(index, value != null ? value.id.toString() : '')
    }

    if (value != null && value.id == -1) {
      handleOpenModalSingleSelect(index, DataRef.current[index].loopBackAccessCode as string, 0)
    }
  }

  const handleOnMoveMap = (index, viewState) => {
    updateMapData(index, {mapCenter: viewState})
  }
  
  const mapRefs = useRef({})

  const fetchAddress = (index, lng, lat) => {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAPTOKEN}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.features && data.features.length > 0) {
          updateMapData(index, {address: data.features[0].place_name})
        }
      })
      .catch((error) => console.error('Error fetching address:', error))
  }

  const handleMapClick = (index, lngLat) => {
    const {lng, lat} = lngLat
    updateMapData(index, {longitude: lng, latitude: lat})
    updateRelatedFields(index, 'latitude', lat)
    updateRelatedFields(index, 'longitude', lng)
    fetchAddress(index, lng, lat)
    handleFieldRule(Data, Data[index], 'Campo possui valor')
    handleFieldRule(Data, Data[index], 'Campo mudar de valor')
  }

  const updateMapData = (index, newData) => {
    setData((prev) => {
      const updatedData = [...prev]
      const currentData = JSON.parse(updatedData[index].valor || MAPDEFAULTCOORDINATES)
      updatedData[index].valor = JSON.stringify({...currentData, ...newData})
      return updatedData
    })
  }

  const updateRelatedFields = (index, key, value) => {
    setData((prev) => {
      const updatedData = [...prev]

      if (key === 'latitude' && prev[index].configuracao.idmenuAppFieldLat) {
        const latField = updatedData.find((x) => x.id === prev[index].configuracao.idmenuAppFieldLat)
        if (latField) latField.valor = value.toString()
      }
      if (key === 'longitude' && prev[index].configuracao.idmenuAppFieldLon) {
        const lonField = updatedData.find((x) => x.id === prev[index].configuracao.idmenuAppFieldLon)
        if (lonField) lonField.valor = value.toString()
      }

      return updatedData
    })
  }

  /**
   * Função de update de JSON de campo de mapa.
   * @param index Posição do campo estruturado na variável de estado
   * @param key Propriedade do JSON
   * @param value valor para aplicar ao JSON
   * @returns void
   */
  const handleChangeMapJSONValue = (index: number, key: string, value: any) => {
    setData((prev) => {
      let obj = JSON.parse(prev[index].valor != '' ? prev[index].valor : MAPDEFAULTCOORDINATES)

      if (key === 'mapCenter') {
        obj.mapCenter = value
      } else {
        obj[key] = value
      }

      if (key === 'latitude' || key === 'longitude') {
        // Update related fields if they exist
        if (obj.configuracao) {
          if (key === 'latitude' && obj.configuracao.idmenuAppFieldLat) {
            const latField = prev.find((x) => x.id === obj.configuracao.idmenuAppFieldLat)
            if (latField) {
              latField.valor = value.toString()
            }
          }
          if (key === 'longitude' && obj.configuracao.idmenuAppFieldLon) {
            const lonField = prev.find((x) => x.id === obj.configuracao.idmenuAppFieldLon)
            if (lonField) {
              lonField.valor = value.toString()
            }
          }
        }
      }

      prev[index].valor = JSON.stringify(obj)
      return [...prev]
    })

    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo possui valor')
    handleFieldRule(DataRef.current, DataRef.current[index], 'Campo mudar de valor')
  }

  /**
   * Executa as regras do campo de acordo com o contexto solicitado. NOTA: Essa função deve ser chamada dentro de um setData para evitar enfileiramentos desnecessários de edição de estado.
   * @param fields Array com os campos do estado atual na hora da edição
   * @param field Objeto do campo estruturado que terá as regras executadas
   * @param event Evento da regra a ser executada
   * @returns void
   */
  const handleFieldRule = async (fields: Array<CampoEstruturadoResult>, field: CampoEstruturadoResult, event: string) => {
    let regras = field.regras

    if (regras.length == 0) {
      return
    }

    regras = regras.filter((x) => x.evento == event)

    for (const regra of regras) {
      if (regra.acao == 'Exibir Campos') {
        if (
          regra.atributo02 != '' &&
          ((regra.atributo01 == 'Igual' && field.valor == regra.valor) ||
            (regra.atributo01 == 'Diferente' && field.valor != regra.valor) ||
            (regra.atributo01 == 'Não está preenchido' && field.valor == '') ||
            (regra.atributo01 == 'Está preenchido' && field.valor != '') ||
            (regra.atributo01 == 'Contém' && field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Não contém' && !field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor)))
        ) {
          let camposAExibir = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

          for (let item of camposAExibir) {
            let campo = fields.find((x) => x.id == item)

            if (campo != null) {
              campo.exibir = true
            }
          }
        }
      }

      if (regra.acao == 'Ocultar Campos') {
        if (
          regra.atributo02 != '' &&
          ((regra.atributo01 == 'Igual' && field.valor == regra.valor) ||
            (regra.atributo01 == 'Diferente' && field.valor != regra.valor) ||
            (regra.atributo01 == 'Não está preenchido' && field.valor == '') ||
            (regra.atributo01 == 'Está preenchido' && field.valor != '') ||
            (regra.atributo01 == 'Contém' && field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Não contém' && !field.valor.includes(regra.valor)) ||
            (regra.atributo01 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
            (regra.atributo01 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor)))
        ) {
          let camposAOcultar = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

          for (let item of camposAOcultar) {
            let campo = fields.find((x) => x.id == item)

            if (campo != null) {
              campo.exibir = false
            }
          }
        }
      }

      if (regra.acao == 'Ocultar custom action') {
        if (
          (regra.atributo01 == 'Igual' && field.valor == regra.valor) ||
          (regra.atributo01 == 'Diferente' && field.valor != regra.valor) ||
          (regra.atributo01 == 'Não está preenchido' && field.valor == '') ||
          (regra.atributo01 == 'Está preenchido' && field.valor != '') ||
          (regra.atributo01 == 'Contém' && field.valor.includes(regra.valor)) ||
          (regra.atributo01 == 'Não contém' && !field.valor.includes(regra.valor)) ||
          (regra.atributo01 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
          (regra.atributo01 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
          (regra.atributo01 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
          (regra.atributo01 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor))
        ) {
          if (regra.atributo02 != '') {
            let actionsParaOcultar = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            setActions((prev) => {
              for (let item of actionsParaOcultar) {
                let itemAlvo = prev.find((x) => x.id == item)
                if (itemAlvo != null) {
                  itemAlvo.biVisible = false
                }
              }

              return [...prev]
            })
          }
        } else {
          if (regra.atributo02 != '') {
            let actionsParaExibir = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            setActions((prev) => {
              for (let item of actionsParaExibir) {
                let itemAlvo = prev.find((x) => x.id == item)
                if (itemAlvo != null) {
                  itemAlvo.biVisible = true
                }
              }

              return [...prev]
            })
          }
        }
      }

      if (regra.acao == 'Buscar Valor') {
        let campoAlvo = fields.find((item: any) => item.id == parseInt(regra.atributo03 as string))
        if (campoAlvo == null) {
          continue
        }

        if (field.valor == '') {
          campoAlvo.valor = ''
          continue
        }

        let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/montarRetornoValor`, {
          item: { ID: regra.id, Campos: fields, IDMenuAppGenericItem: DataID },
        })

        let valores = ret.data.data as Array<any>

        if (valores.length > 0) {
          let cIndex = 0
          let cValor = ''

          if (campoAlvo.tipo == 'singleSelect') {
            if (valores.length == 1) {
              campoAlvo.valor = valores[0]
            }

            let parsed: Array<number> = []

            for (const item of valores) {
              parsed.push(parseInt(item))
            }

            handleOnInputChangeAutoComplete(fields.indexOf(campoAlvo), '', parsed, true, true)
          } else if (campoAlvo.tipo == 'decimal') {
            let newValue = parseFloat(valores[0].replace(',', '.'))
            campoAlvo.valor = handleClampFloat(newValue).toFixed(2)
          } else if (campoAlvo.tipo == 'number') {
            let newValue = parseInt(valores[0])
            campoAlvo.valor = isNaN(newValue) ? '' : newValue.toString()
          } else if (campoAlvo.tipo == 'boolean') {
            campoAlvo.valor = valores[0].toLowerCase()
            cValor = campoAlvo.valor
            cIndex = fields.indexOf(campoAlvo)
          } else {
            campoAlvo.valor = valores[0]
          }
          if (cValor != '') {
            handleOnChange(cIndex, cValor == 'true' ? 'true' : 'false')
          }
        }
      }

      if (regra.acao == 'Calcular Campos') {
        let operations: Array<CCalcFieldRule> = JSON.parse(regra.formula)
        let toEval: string = ''

        for (const operation of operations) {
          let campoAlvo = fields.find((item: any) => item.id == operation.campo)
          if (campoAlvo == null || campoAlvo.valor == '') continue

          if (operation.operador == '#') {
            operation.operador = ''
          }

          toEval = `${toEval} ${operation.operador} ${campoAlvo.valor}`
        }

        let result: any = Function(`'use strict'; return (${toEval})`)()

        let campoParaAplicar = fields.find((item: any) => item.id == parseInt(regra.atributo01 as string))

        if (campoParaAplicar != null) {
          if (campoParaAplicar.tipo == 'number') {
            campoParaAplicar.valor = result.toString()
          } else if (campoParaAplicar.tipo == 'decimal') {
            campoParaAplicar.valor = handleClampFloat(result).toFixed(2)
          }
        }
      }

      if (regra.acao == 'Bloquear Campos') {
        if (
          (regra.atributo03 == 'Igual' && field.valor == regra.valor) ||
          (regra.atributo03 == 'Diferente' && field.valor != regra.valor) ||
          (regra.atributo03 == 'Não está preenchido' && field.valor == '') ||
          (regra.atributo03 == 'Está preenchido' && field.valor != '') ||
          (regra.atributo03 == 'Contém' && field.valor.includes(regra.valor)) ||
          (regra.atributo03 == 'Não contém' && !field.valor.includes(regra.valor)) ||
          (regra.atributo03 == 'Maior que' && parseFloat(field.valor) > parseFloat(regra.valor)) ||
          (regra.atributo03 == 'Menor que' && parseFloat(field.valor) < parseFloat(regra.valor)) ||
          (regra.atributo03 == 'Maior ou igual a' && parseFloat(field.valor) >= parseFloat(regra.valor)) ||
          (regra.atributo03 == 'Menor ou igual a' && parseFloat(field.valor) <= parseFloat(regra.valor))
        ) {
          if (regra.atributo01 == 'ALL') {
            setFieldLock(true)
          } else if (regra.atributo02 != '') {
            let camposABloquear = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            for (let item of camposABloquear) {
              let campo = fields.find((x) => x.id == item)

              if (campo != null) {
                campo.somenteLeitura = true
              }
            }
          }
        } else {
          if (regra.atributo01 == 'ALL') {
            setFieldLock(false)
          } else if (regra.atributo02 != '') {
            let camposABloquear = JSON.parse(regra.atributo02 ?? '[]') as Array<number>

            for (let item of camposABloquear) {
              let campo = fields.find((x) => x.id == item)

              if (campo != null) {
                campo.somenteLeitura = false
              }
            }
          }
        }
      }

      if (regra.acao == 'Executar ação de estrutura') {

        if (regra.atributo04 == '') {
          continue
        }

        let campo: any = null
        let result = fields
        let ids = JSON.parse(regra.atributo04 ?? '[]')

        if (ids.length > 1) {
          let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/retornarObjetoRecursivo`, {
            item: { ID: regra.id, Campos: result },
          })

          if (ret.data.mensagem != 'SUCCESS') {
            continue
          }

          result = ret.data.data
          campo = result.find((x) => x.campo.toLowerCase() == ret.data.campo.toLowerCase())
        } else {
          campo = result.find((x) => x.id == ids[0])
        }

        if (result == null || campo == null) {
          continue
        }

        if (regra.atributo05 == 'ACAO') {
          if (
            (regra.atributo06 == 'Igual' && campo.valor == regra.valor) ||
            (regra.atributo06 == 'Diferente' && campo.valor != regra.valor) ||
            (regra.atributo06 == 'Não está preenchido' && campo.valor == '') ||
            (regra.atributo06 == 'Está preenchido' && campo.valor != '') ||
            (regra.atributo06 == 'Contém' && campo.valor.contains(regra.valor)) ||
            (regra.atributo06 == 'Não contém' && !campo.valor.contains(regra.valor)) ||
            (regra.atributo06 == 'Maior que' && parseFloat(campo.valor) > parseFloat(regra.valor)) ||
            (regra.atributo06 == 'Menor que' && parseFloat(campo.valor) < parseFloat(regra.valor)) ||
            (regra.atributo06 == 'Maior ou igual a' && parseFloat(campo.valor) >= parseFloat(regra.valor)) ||
            (regra.atributo06 == 'Menor ou igual a' && parseFloat(campo.valor) <= parseFloat(regra.valor))
          ) {
            if (regra.atributo07 == 'OCULTAR') {
              if (regra.atributo08 != '') {
                let camposAOcultar = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

                for (let item of camposAOcultar) {
                  let campo = fields.find((x) => x.id == item)

                  if (campo != null) {
                    campo.exibir = false
                  }
                }
              }
            } else if (regra.atributo07 == 'BLOQUEAR') {
              let camposABloquear = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

              for (let item of camposABloquear) {
                let campo = fields.find((x) => x.id == item)

                if (campo != null) {
                  campo.somenteLeitura = true
                }
              }
            } else if (regra.atributo07 == 'LIMPAR') {
              let camposABloquear = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

              for (let item of camposABloquear) {
                let campo = fields.find((x) => x.id == item)

                if (campo != null) {
                  campo.valor = ''
                }
              }
            }
          } else {
            if (regra.atributo07 == 'OCULTAR') {
              if (regra.atributo08 != '') {
                let camposAOcultar = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

                for (let item of camposAOcultar) {
                  let campo = fields.find((x) => x.id == item)

                  if (campo != null) {
                    campo.exibir = true
                  }
                }
              }
            } else if (regra.atributo07 == 'BLOQUEAR') {
              let camposABloquear = JSON.parse(regra.atributo08 ?? '[]') as Array<number>

              for (let item of camposABloquear) {
                let campo = fields.find((x) => x.id == item)

                if (campo != null) {
                  campo.somenteLeitura = false
                }
              }
            }
          }
        } else if (regra.atributo05 == 'APLICAR') {
          let campoParaAplicar = fields.find((x) => x.id == parseInt(regra.atributo06 as string))

          if (campoParaAplicar != null) {
            campoParaAplicar.valor = campo.valor
          }
        }
      }

      if (regra.acao == 'Executar procedure validacao') {
        let request = {
          item: {
            AccessCode: accessCode,
            ID: DataID,
            IDMenuAppField: field.id,
            Campos: fields,
          },
        }

        let ret = await apiWrapper.post('api/v1/Dynamic/executarRegrasDeCampo', request)

        if (ret.data.mensagens.length > 0) {
          for (const msg of ret.data.mensagens) {
            if (msg == 'SUCESSO') {
              continue
            }

            toast.error(msg)
          }
        }

        fields = ret.data.data
      }
    }

    setData(fields)
  }

  /**
   * Carrega os items do auto complete baseado nos dados informados
   * @param fields Array com os campos do estado atual na hora da edição
   * @param field Objeto do campo estruturado que terá as regras executadas
   * @param event Evento da regra a ser executada
   * @returns void
   */
  const handleOnInputChangeAutoComplete = async (
    index: number,
    value: any,
    ids: Array<number> = [],
    applySingleValue: boolean = false,
    ignoreFilter: boolean = false,
    ignoreLoading: boolean = false
  ) => {
    // Jogamos o campo num estado de carregamento assim o componente trava e mostra o loading
    if (!ignoreLoading) {
      setData((prev) => {
        prev[index].loading = true
        return [...prev]
      })
    }

    let filter = ''
    let _ids: string = ''

    //TODO: Checar se ainda precisamos desse filtro
    if (!ignoreFilter) {
      filter = (await getFilter(DataRef.current[index].campo)) ?? ''
    }

    if (ids.length > 0) {
      for (const item of ids) {
        if (isNaN(item)) {
          continue
        }

        _ids = `${_ids != '' ? `${_ids},` : ''}${item}`
      }
    }

    let obj: any = {
      item: {
        AccessCode: accessCode,
        IDMenuApp: 0,
        IDMenuAppField: DataRef.current[index].id,
        Campo: DataRef.current[index].campo,
        Query: value,
        Filtro: filter,
        IDs: _ids,
        Campos: DataRef.current,
      },
      PaginaAtual: 0,
      RegistroPorPagina: 15,
    }

    apiWrapper
      .post(`api/v1/Dynamic/listarSingleSelectCampoFormulario${acessoExterno ? 'Publico' : ''}`, obj)
      .then((response) => {
        let opcoes: Array<SingleSelectResult> = response.data.data

        setData((prev) => {
          prev[index].loading = false

          // Caso o valor ja esteja lá então não repopulamos as opções
          let valueFromString = prev[index].opcoes.find((item: any) => item.label == value)
          if (valueFromString != null && prev[index].valor == valueFromString.id.toString()) {
            return [...prev]
          }

          prev[index].opcoes = []

          // Caso tenhamos opções então populamos o estado com elas senão caso o usuário tenha permissão adicionamos a opção de criar um registro
          if (opcoes.length > 0) {
            opcoes.map((item: any) => {
              prev[index].opcoes.push({ id: item.id, label: item.label })
            })
          } else if (prev[index].permissoes.biInsert) {
            prev[index].opcoes.push({ id: -1, label: 'Adicionar Registro' })
          }

          if (prev[index].opcoes.filter((item: any) => item.id == prev[index].valor).length == 0) {
            if (prev[index].valorAnterior == '') {
              prev[index].valorAnterior = prev[index].valor
            }

            prev[index].valor = ''
          }

          return [...prev]
        })

        if (opcoes.length == 1 && applySingleValue) {
          handleOnChangeAutoComplete(index, opcoes[0])
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })
  }

  /**
   * Função de abertura do auto complete.
   * @param index Index do campo estruturado que o auto-complete está vinculado
   * @param value Valor para verificar se é necessário chamar a função de onInputChange
   * @returns void
   */
  const handleOnOpenAutoComplete = (index: number, event: any) => {
    if (event == null || event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete(index, '')
    }
  }

  /**
   * Define o estado inicial do editor de templates
   * @returns void
   */
  const handleOnEditorStartup = (editor: any, disabled?: boolean, initialData: any = null) => {
    editor.editing.view.change((writer) => {
      writer.setStyle('minHeight', '400px', editor.editing.view.document.getRoot() as any)
      writer.setStyle('width', '684px', editor.editing.view.document.getRoot() as any)
    })

    const toolbarElement = editor.ui.view.toolbar.element as HTMLElement

    if (disabled) {
      toolbarElement.style.display = 'none'
    }

    if (initialData != null) {
      editor.setData(initialData)
    }
  }

  /**
   * Função handler chamada quando o componente de upload recebe o arquivo selecionado
   * @param index Posição do campo estruturado no estado atual
   * @param newAzFile Arquivo que acabou de ser enviado para a Azure
   * @returns void
   */
  const handleOnUpload = (index: number, newAzFile: any) => {
    setData((prev) => {
      prev[index].arquivos = [...prev[index].arquivos, newAzFile]
      return [...prev]
    })
  }

  /**
   * Função handler que delete o arquivo no index informado
   * @param index Posição do campo estruturado no estado atual
   * @param AzFile Arquivo existente para ser apagado
   * @returns void
   */
  const handleOnDeleteUpload = (index: number, AzFile: any) => {
    let obj: any = {
      item: {
        Nome: AzFile.nome,
      },
    }

    apiWrapper.delete(`api/v1/Storage/ApagarArquivoAz${acessoExterno ? 'Publico' : ''}`, { data: obj, headers: {} })

    setData((prev) => {
      prev[index].arquivos = prev[index].arquivos.filter((item: any) => item != AzFile)
      return [...prev]
    })
  }

  /**
   * Função handler que deleta todos os arquivos de um campo de upload
   * @returns void
   */
  const handleDeleteAllUploads = () => {
    DataRef.current.map((item: any, index: number) => {
      if (item.tipo != 'upload') {
        return
      }

      item.arquivos.forEach((arquivo) => {
        if (arquivo.id != 0) {
          return
        }

        handleOnDeleteUpload(index, arquivo)
      })
    })
  }

  /**
   * Monta um filtro para listagem de campos singleSelect
   * @param campo Nome do campo de contexto
   * @returns string
   */
  const getFilter = async (campo: string) => {
    let filter = ''

    const contextField = DataRef.current.find((item: any) => item.campo == campo)

    if (contextField == null) {
      return filter
    }

    const fieldsComRegra = DataRef.current.filter((item: any) => item.regras.length > 0)

    for (const field of fieldsComRegra) {
      let regras = field.regras

      for (const regra of regras) {
        if (regra.acao == 'Executar ação de estrutura' && regra.atributo05 == 'FILTRAR' && regra.atributo06 == contextField.id.toString()) {
          if (regra.atributo04 == '') {
            continue
          }

          let campo: any = null
          let result = DataRef.current
          let fields = JSON.parse(regra.atributo04 ?? '[]')

          if (fields.length > 1) {
            let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/retornarObjetoRecursivo`, {
              item: { ID: regra.id, Campos: DataRef.current },
            })

            if (ret.data.mensagem != 'SUCCESS') {
              continue
            }

            result = ret.data.data
            campo = result.find((x) => x.campo.toLowerCase() == ret.data.campo.toLowerCase())
          } else {
            campo = result.find((x) => x.id == fields[0])
          }

          if (result == null || campo == null) {
            continue
          }

          let ret = await apiWrapper.get(`api/v1/MenuAppFields/carregar?item.ID=${regra.atributo07}`)
          return `${ret.data.data.campo} = ${campo.valor}`
        }

        if (regra.acao != 'Buscar Valor' || regra.atributo03 != contextField.id.toString() || field.valor == '') {
          continue
        }

        let ret = await apiWrapper.post(`api/v1/MenuAppFieldsRule/montarRetornoValor`, {
          item: { ID: regra.id, Campos: Data, IDMenuAppGenericItem: DataID },
        })

        let IDs = ret.data.data as Array<any>

        if (IDs.length > 0) {
          let _ids: string = ''

          for (let _id of IDs) {
            if (_id == '') {
              continue
            }

            _ids = `${_ids != '' ? `${_ids},` : ''}${_id}`
          }

          return _ids != '' ? `ID IN (${_ids})` : ''
        }
      }
    }

    return filter
  }

  /**
   * Verifica se algum campo ou o contexto geral do modal está editável
   * @param field (Opcional) Campo estruturado para verificar as propriedades
   * @returns boolean
   */
  const isEditable = (field?: CampoEstruturadoResult) => {
    if (field != null) {
      if (fieldLock) {
        return false
      }

      if (field.somenteLeitura) {
        return false
      }

      if (!field.permissoesCampo.biUpdate) {
        return false
      }

      if (modalDynamicAcaoEmMassa && !field.salvarAlteracoes) {
        return false
      }

      if (relationItem != null && !Saving) {
        return field.campo != relationItem.menuAppField.campo
      }
    }

    return !Saving && !loading
  }

  /* EVOLUTION API */

  const [WAInstance, setWAInstance, WAInstanceRef] = useStateRef<any>(null)
  const [WAInstanceLoading, setWAInstanceLoading] = useState<boolean>(false)
  const [WAInstanceInterval, setWAInstanceInterval, WAInstanceIntervalRef] = useStateRef<any>(null)

  const createWAInstanceInterval = () => {
    let id = setInterval(async () => {
      if (WAInstanceRef.current == null) {
        return
      }

      await verifyWAInstance()
    }, 1500)

    setWAInstanceInterval(id)
  }

  const clearWAInstanceInterval = () => {
    if (WAInstanceIntervalRef.current != null) {
      clearInterval(WAInstanceIntervalRef.current)
      setWAInstanceInterval(null)
    }
  }

  const verifyWAInstance = async (useLoading: boolean = false) => {
    if (useLoading) {
      setWAInstanceLoading(true)
    }

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    let tokenField = DataRef.current.find((x) => x.campo.toLowerCase() == 'token')
    if (tokenField != null) {
      let instances = await axios.get('https://evolutionapi.gym360.com.br/instance/fetchInstances', { headers })
      let instance = (instances.data as Array<any>).find((x) => x.instance.instanceName == tokenField?.valor)

      if (instance != null) {
        setWAInstance((prev) => {
          if (prev == null) {
            prev = instance
          } else {
            prev.instance = instance.instance
          }

          return { ...prev }
        })

        if (
          (instance.instance.status == 'connecting' || instance.instance.status == 'close') &&
          WAInstanceRef.current != null &&
          WAInstanceRef.current.qrcode == null
        ) {
          let instanceConnect = await axios.get(`https://evolutionapi.gym360.com.br/instance/connect/${instance.instance.instanceName}`, { headers })

          setWAInstance((prev) => {
            prev.qrcode = instanceConnect.data
            return { ...prev }
          })
        } else if (
          instance.instance.status != 'connecting' &&
          instance.instance.status != 'close' &&
          WAInstanceRef.current != null &&
          WAInstanceRef.current.qrcode != null
        ) {
          setWAInstance((prev) => {
            prev.qrcode = undefined
            return { ...prev }
          })
        }
      } else {
        setWAInstance(instance)

        if (modalDynamicItemID != 0) {
          setData((prev) => {
            let tokenField = prev.find((x) => x.campo.toLowerCase() == 'token')
            if (tokenField != null) {
              tokenField.valor = ''
            }

            return [...prev]
          })
        }
      }

      if (WAInstanceIntervalRef.current == null) {
        createWAInstanceInterval()
      }
    }

    if (useLoading) {
      setWAInstanceLoading(false)
    }
  }

  const startNewWAInstance = async () => {
    setWAInstanceLoading(true)

    const instanceToken = uuid()
    const instanceName = instanceToken

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    let obj = {
      instanceName: instanceName,
      token: instanceToken,
      qrcode: true,
    }

    let instance = await axios.post('https://evolutionapi.gym360.com.br/instance/create', obj, { headers })
    setWAInstance(instance.data)

    createWAInstanceInterval()

    setData((prev) => {
      let tokenField = prev.find((x) => x.campo.toLowerCase() == 'token')
      if (tokenField != null) {
        tokenField.valor = instanceToken
      }

      return [...prev]
    })

    setWAInstanceLoading(false)
  }

  const closeWAInstance = async () => {
    let tokenField = DataRef.current.find((x) => x.campo.toLowerCase() == 'token')
    if (tokenField == null) {
      return
    }

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    setWAInstanceLoading(true)

    await axios.delete(`https://evolutionapi.gym360.com.br/instance/logout/${tokenField.valor}`, { headers })
    await axios.delete(`https://evolutionapi.gym360.com.br/instance/delete/${tokenField.valor}`, { headers })

    setWAInstance(null)

    clearWAInstanceInterval()

    setData((prev) => {
      let tokenField = prev.find((x) => x.campo.toLowerCase() == 'token')
      if (tokenField != null) {
        tokenField.valor = ''
      }

      return [...prev]
    })

    setWAInstanceLoading(false)
  }

  const updateWAInstanceWebhook = async () => {
    let tokenField = DataRef.current.find((x) => x.campo.toLowerCase() == 'token')
    if (tokenField == null) {
      return
    }

    let urlField = DataRef.current.find((x) => x.campo.toLowerCase() == 'url')
    if (urlField == null) {
      return
    }

    const headers = {
      apikey: 'rEs2rgcvn28GzFjrstxwxLcXoZDGkGBy',
    }

    let obj = {
      url: urlField.valor,
      webhook_by_events: false,
      webhook_base64: false,
      events: ['QRCODE_UPDATED', 'MESSAGES_UPSERT', 'MESSAGES_UPDATE', 'MESSAGES_DELETE', 'SEND_MESSAGE', 'CONNECTION_UPDATE', 'CALL'],
    }

    await axios.post(`https://evolutionapi.gym360.com.br/webhook/set/${tokenField.valor}`, obj, { headers })
  }

  /* END EVOLUTION API */

  /**
   * Escolhe o componente e seus derivados baseado no tipo e formatação do campo
   * @param item Campo estruturado que receberá o componente
   * @param index Posição do campo estruturado no estado atual
   * @returns O componente escolhido
   */
  const getComponent = (item: CampoEstruturadoResult, index: number) => {
    let control: any = <></>

    switch (accessCode) {
      case 'SYSMEN':
      case 'SYSMENSUB':
        if (item.campo == 'Icone') {
          control = (
            <MenuAppResourceSelect
              id='icone-select'
              label='Ícone'
              labelId='icone-select-label'
              type='duotune'
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
            />
          )

          return control
        }
        break
      case 'SYSTASK':
        if (item.campo.toLowerCase() == 'descricao') {
          let disabled = !isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)

          if (disabled) {
            control = (
              <Box
                component='div'
                className='border border-secondary'
                sx={{ width: '100%', px: 2, py: 1 }}
                dangerouslySetInnerHTML={{ __html: item.valor }}
              ></Box>
            )
          } else {
            control = (
              <>
                {CKEditorZIndexFix}
                <CKEditor
                  editor={Editor}
                  data={item.valor}
                  disabled={disabled}
                  onReady={(editor: any) => {
                    handleOnEditorStartup(editor, disabled)
                  }}
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData()
                    handleOnChange(index, data)
                  }}
                  onBlur={(event: any, editor: any) => { }}
                  onFocus={(event: any, editor: any) => { }}
                />
              </>
            )
          }

          return control
        }
        break
      case 'SYSINTEGRATION':
        let tipoIntegracao = DataRef.current.find((x) => x.campo.toLowerCase() == 'idtipointegration')
        if (tipoIntegracao != null && tipoIntegracao.valor == '7' && item.campo.toLowerCase() == 'url') {
          return (
            <Box component='div' sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Box component='div' className='alert alert-dark d-flex align-items-center'>
                <div className='d-flex flex-column'>
                  <h1>Conecte o WhatsApp da sua empresa</h1>
                  <br />
                  <span>1 - Abre o WhatsApp no seu celular</span>
                  <span>
                    2 - Toque em <strong>Mais opções</strong> ou <strong>Ajustes</strong> e selecione <strong>WhatsApp Web</strong>
                  </span>
                  <span>3 - Gere o QR Code e aponte o celular para essa tela para capturar o código</span>
                  <br />
                  {WAInstanceLoading && (
                    <Box component='div' sx={{ alignSelf: 'center' }}>
                      <CircularProgress />
                    </Box>
                  )}
                  {!WAInstanceLoading && WAInstance == null && (
                    <Button variant='contained' startIcon={<FullscreenIcon />} color='secondary' onClick={() => startNewWAInstance()}>
                      Gerar QR Code
                    </Button>
                  )}
                  {!WAInstanceLoading && WAInstance != null && WAInstance.qrcode && <QRCode size={256} value={WAInstance.qrcode.code} />}
                  {!WAInstanceLoading && WAInstance != null && WAInstance.qrcode == null && (
                    <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => closeWAInstance()}>
                      Fechar Conexão
                    </Button>
                  )}
                </div>
              </Box>
              <Box component='div'>
                <TextField
                  required={item.obrigatorio}
                  error={!item.isValid}
                  helperText={item.invalidMessage}
                  sx={{ width: '100%' }}
                  disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
                  size='small'
                  label='URL Webhook'
                  value={item.valor}
                  onChange={(event) => handleOnChange(index, event.target.value)}
                />
              </Box>
            </Box>
          )
        }

        break
    }

    const exibicao = item.jsonformatacao != null ? JSON.parse(item.jsonformatacao) : null
    let startAdornment: any = undefined
    let endAdornment: any = undefined

    if (exibicao != null) {
      if (exibicao.formatacao == 'Moeda (R$)') {
        startAdornment = <InputAdornment position='start'>R$</InputAdornment>
      } else if (exibicao.formatacao == 'Moeda ($)') {
        startAdornment = <InputAdornment position='start'>$</InputAdornment>
      } else if (exibicao.formatacao == 'Percentual (%)' || exibicao.formatacao == 'Progresso (%)') {
        endAdornment = <InputAdornment position='end'>%</InputAdornment>
      }
    }

    switch (item.tipo) {
      case 'number':
        if (item.obrigatorio && item.valor == '') {
          item.valor = '0'
        }

        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{ width: '100%' }}
            disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            InputLabelProps={{ shrink: true }}
            value={item.valor}
            onChange={(event) => {
              let clamped = handleClampNumber(parseInt(event.target.value ?? '0'))
              handleOnChange(index, clamped.toString())
            }}
            InputProps={{
              inputComponent: NumericFormatWithoutDecimal as any,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
            }}
          />
        )
        break
      case 'decimal':
        if (item.obrigatorio && item.valor == '') {
          item.valor = '0'
        }

        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{ width: '100%' }}
            disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            InputLabelProps={{ shrink: true }}
            value={item.valor}
            onChange={(event) => {
              let valueString = event.target.value
              if (valueString == '') {
                return
              }

              let clamped = handleClampFloat(parseFloat(valueString))
              handleOnChange(index, clamped.toFixed(2))
            }}
            InputProps={{
              inputComponent: NumericFormatWithDecimal as any,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
            }}
          />
        )
        break
      case 'geocode':
        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{ width: '100%' }}
            disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            InputLabelProps={{ shrink: true }}
            value={item.valor}
            onChange={(event) => {
              let valueString = event.target.value
              if (valueString == '') {
                return
              }

              let clamped = handleClampFloat(parseFloat(valueString), -999.999999, 999.999999)
              handleOnChange(index, clamped.toFixed(6))
            }}
            InputProps={{
              inputComponent: GeocodeFormat as any,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
            }}
          />
        )
        break
      case 'string':
      case 'geolocation':
        if (item.criptografar) {
          control = (
            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{ width: '100%' }}
              disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              type={item.showDecrypted ? 'text' : 'password'}
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={(event) => handleApplyValueToArrayedState(setData, index, 'showDecrypted', !item.showDecrypted)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge='end'
                    >
                      {item.showDecrypted ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )
        } else {
          control = (
            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{ width: '100%' }}
              disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
            />
          )
        }
        break
      case 'map':
        let isJson = item.valor.startsWith('{') && item.valor.endsWith('}')

        if (!isJson && item.valor != '') {
          if (item.valor != '') {
            let request = new XMLHttpRequest()
            request.open('GET', `https://api.mapbox.com/search/geocode/v6/forward?q=${item.valor}&access_token=${MAPTOKEN}`)
            request.send()
            request.onload = () => {
              if (request.status == 200) {
                let result = JSON.parse(request.response)

                setData((prev) => {
                  let obj = JSON.parse(MAPDEFAULTCOORDINATES)
                  obj.longitude = result.features[0].properties.coordinates.longitude
                  obj.latitude = result.features[0].properties.coordinates.latitude
                  obj.address = prev[index].valor
                  obj.zoom = 17

                  let contextField = prev[index]

                  // Atualizamos o campo selecionado com a latitude
                  if (contextField.configuracao.idmenuAppFieldLat != null) {
                    let latField = prev.find((x) => x.id == contextField.configuracao.idmenuAppFieldLat)
                    if (latField != null) {
                      latField.valorAnterior = latField.valorAnterior == '' ? latField.valor : latField.valorAnterior
                      latField.valor = obj.latitude.toFixed(6)
                    }
                  }

                  // Atualizamos o campo selecionado com a longitude
                  if (contextField.configuracao.idmenuAppFieldLon != null) {
                    let longField = prev.find((x) => x.id == contextField.configuracao.idmenuAppFieldLon)
                    if (longField != null) {
                      longField.valorAnterior = longField.valorAnterior == '' ? longField.valor : longField.valorAnterior
                      longField.valor = obj.longitude.toFixed(6)
                    }
                  }

                  contextField.valor = JSON.stringify(obj)
                  return [...prev]
                })
              } else {
                toast.error(`error ${request.status}: ${request.statusText}`)
              }
            }
          }

          return <></>
        }

        let coordinates = JSON.parse(item.valor != '' ? item.valor : MAPDEFAULTCOORDINATES)

        control = (
          <Box component='div' sx={{ width: '100%' }} className='d-flex flex-column'>
            <Box component='div' sx={{ py: 1, width: '100%' }}>
            <ReactMapGL
              key={item.id}
              ref={(el) => (mapRefs.current[`map-${item.id}`] = el)}
              mapboxAccessToken={MAPTOKEN}
              initialViewState={{
                longitude: coordinates.mapCenter ? coordinates.mapCenter.longitude : coordinates.longitude,
                latitude: coordinates.mapCenter ? coordinates.mapCenter.latitude : coordinates.latitude,
                zoom: coordinates.mapCenter ? coordinates.mapCenter.zoom : coordinates.zoom,
              }}
              onMove={(evt) => handleOnMoveMap(index, evt.viewState)}
              onClick={(evt) => handleMapClick(index, evt.lngLat)}
              dragPan={isEditable(item)}
              scrollZoom={isEditable(item)}
              onLoad={(event) => {
                if (!isEditable(item)) return handleMapClick(index, {lng: coordinates.longitude, lat: coordinates.latitude});
              
                const geocoder = new MapboxGeocoder({
                  accessToken: MAPTOKEN,
                  placeholder: 'Buscar',
                  mapboxgl: ReactMapGL,
                  countries: 'BR',
                  language: 'pt-BR',
                });
              
                geocoder.on('results', (event) => {
                  setTimeout(() => {
                    const suggestionElements = document.querySelectorAll('.mapboxgl-ctrl-geocoder--suggestion');
  
                    if (suggestionElements.length === event.features.length) {
                      suggestionElements.forEach((element, i) => {
                        element.addEventListener('mouseup', () => {
                          const selectedFeature = event.features[i];
                          if (selectedFeature) {
                            handleMapClick(index, {lng: selectedFeature.center[0], lat: selectedFeature.center[1]});
                          }
                        });
                      });
                    } 
                  }, 100)
                });
              
                event.target.addControl(geocoder);
              }}
              style={{width: '100%', height: 400}}
              mapStyle='mapbox://styles/mapbox/streets-v9'
            >
                <MarkerModal color='red' longitude={coordinates.longitude} latitude={coordinates.latitude}></MarkerModal>
            </ReactMapGL>
            </Box>
            <Box component='div' sx={{ py: 1, width: '100%' }}>
              <TextField
                disabled
                required={item.obrigatorio}
                error={!item.isValid}
                helperText={item.invalidMessage}
                fullWidth
                size='small'
                label={item.label}
                value={item.valor != '' ? JSON.parse(item.valor).address : ''}
                onChange={(event) => handleChangeMapJSONValue(index, 'address', event.target.value)}
              />
            </Box>
          </Box>
        )

        break
      case 'textArea':
        control = (
          <TextField
            required={item.obrigatorio}
            error={!item.isValid}
            helperText={item.invalidMessage}
            sx={{ width: '100%' }}
            disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
            size='small'
            label={item.label}
            value={item.valor}
            onChange={(event) => handleOnChange(index, event.target.value)}
            multiline
            maxRows={6}
            minRows={6}
          />
        )
        break
      case 'special':
        if (exibicao != null && exibicao.formatacao == 'Telefone') {
          control = (
            <MuiTelInput
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{ width: '100%' }}
              disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(value) => handleOnChange(index, value)}
            />
          )
        } else if (exibicao != null && exibicao.formatacao == 'JSON') {
          control = (
            <CodeMirror
              readOnly={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              theme={codeEditorTheme}
              value={item.valor}
              height='150px'
              width='683px'
              placeholder={item.label}
              extensions={[json()]}
              onChange={(value, viewUpdate) => handleOnChange(index, value)}
            />
          )
        } else if (exibicao != null && exibicao.formatacao != 'Telefone' && exibicao.formatacao != '') {
          control = (

            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{ width: '100%' }}
              disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
              inputProps={{
                className: `${exibicao.formatacao.toLowerCase()}-${index}`,
              }}
            />

          )
        } else {
          control = (
            <TextField
              required={item.obrigatorio}
              error={!item.isValid}
              helperText={item.invalidMessage}
              sx={{ width: '100%' }}
              disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              size='small'
              label={item.label}
              value={item.valor}
              onChange={(event) => handleOnChange(index, event.target.value)}
              
            />
          )
        }
        break
      case 'singleSelect':
        if (item.valor != '' && item.opcoes.find((x) => x.id == parseInt(item.valor)) == null) {
          handleOnInputChangeAutoComplete(index, '', [parseInt(item.valor)], true, false, true)
          return <></>
        }

        control = (
          <Box component='div' sx={{ display: 'flex', width: '100%' }}>
            <Autocomplete
              disablePortal
              disabled={!isEditable(item) || !item.permissoes.biSelect || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              options={item.opcoes != null ? item.opcoes : []}
              sx={{ width: '100%' }}
              size='small'
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={item.obrigatorio}
                  error={!item.isValid}
                  helperText={item.invalidMessage}
                  label={item.label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {item.loading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              value={item.valor != null && item.valor != '' ? item.opcoes.find((op: any) => op.id == item.valor) ?? null : null}
              onOpen={(event) => handleOnOpenAutoComplete(index, event)}
              onChange={(event, value, reason) => handleOnChangeAutoComplete(index, value)}
              onInputChange={(event, value) => handleOnInputChangeAutoComplete(index, value)}
              filterOptions={(x) => x}
              loading={item.loading}
              loadingText='Buscando opções...'
              noOptionsText='Nenhuma opção encontrada, digite para buscar'
            />
            {item.loopBackAccessCode != null && (
              <IconButton
                disabled={item.valor == null || item.valor == '' || item.somenteLeitura || !item.permissoes.biUpdate}
                aria-label='Edit single select option'
                size='small'
                title='Editar Registro'
                sx={{ pl: 1 }}
                onClick={() => {
                  let valor = item.valor != null && item.valor != '' ? parseInt(item.valor) : 0
                  handleOpenModalSingleSelect(index, item.loopBackAccessCode as string, valor)
                }}
              >
                <EditNoteIcon />
              </IconButton>
            )}
          </Box>
        )
        break
      case 'dateTime':
        control = (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={TranslateLocale(locale)}>
            <DateTimePicker
              slotProps={{ textField: { size: 'small', error: !item.isValid, helperText: item.invalidMessage, required: item.obrigatorio } }}
              label={item.label}
              value={item.valor != '' ? new Date(item.valor) : null}
              onChange={(date) => {
                let parsed = moment(date).format('YYYY-MM-DDTHH:mm:ss').toString()

                if (parsed == 'Invalid date') {
                  parsed = ''
                }

                handleOnChange(index, parsed)
              }}
              disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        )
        break
      case 'date':
        control = (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={TranslateLocale(locale)}>
            <DesktopDatePicker
              slotProps={{ textField: { size: 'small', error: !item.isValid, helperText: item.invalidMessage, required: item.obrigatorio } }}
              label={item.label}
              value={item.valor != '' ? new Date(item.valor) : null}
              onChange={(date) => {
                let parsed = moment(date).format('YYYY-MM-DDTHH:mm:ss').toString()

                if (parsed == 'Invalid date') {
                  parsed = ''
                }

                handleOnChange(index, parsed)
              }}
              disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        )
        break
      case 'boolean':
        control = (
          <FormControlLabel
            label={item.label}
            control={
              <Checkbox
                disabled={!isEditable(item) || (modalDynamicAcaoEmMassa && !item.salvarAlteracoes)}
                checked={item.valor != '' ? item.valor == 'true' : false}
                onChange={(event, checked) => handleOnChange(index, event.target.checked ? 'true' : 'false')}
              />
            }
          />
        )
        break
      case 'upload':
        control = (
          <UploadCollection
            label={item.label}
            readonly={!isEditable(item) || readonly}
            azFiles={item.arquivos}
            onDelete={(azFile) => handleOnDeleteUpload(index, azFile)}
            onUpload={(newAzFile) => handleOnUpload(index, newAzFile)}
          />
        )
        break
    }

    return control
  }

  /**
   * Função que monta a primeira tab onde vem os campos do formulário
   * @returns void
   */
  const getFirstTab = () => {
    return (
      <>
        {loading && (
          <Box component='div' sx={{ alignItems: 'center', justifyContent: 'center' }}>
            {acessoExterno && <CircularProgress />}
            {!acessoExterno && <LinearProgress />}
          </Box>
        )}
        {Data && Data.length > 0 && (
          <>
            {/*MAPEAMOS OS CAMPOS COM AGRUPADORES*/}
            {DataAccordions && DataAccordions.map((accordion: any, index: number) => {
              return (
                <Accordion sx={{ border: 0 }} defaultExpanded={index == 0} variant='outlined'>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`accordion-content-${accordion}-${index}`}
                    id={`accordion-${accordion}-${index}`}
                  >
                    {accordion}
                  </AccordionSummary>
                  <AccordionDetails>
                    {Data && Data.map((item: any, index: number) => {
                      if (item.agrupador != accordion) {
                        return <></>
                      }

                      let control: any = getComponent(item, index)

                      return (
                        <Box
                          hidden={!item.exibir}
                          component='div'
                          sx={{ p: 1, display: 'flex', width: '95%', maxWidth: '700px' }}
                          key={`box-accordion-${index}`}
                          title={item.info ?? undefined}
                        >
                          {modalDynamicAcaoEmMassa && (
                            <Checkbox
                              //disabled={!isEditable(item)}
                              checked={item.salvarAlteracoes}
                              onChange={(event, checked) => handleApplyValueToArrayedState(setData, index, 'salvarAlteracoes', checked)}
                            />
                          )}
                          {control}
                          {item.tipo == 'singleSelect' && !item.permissoes.biSelect && (
                            <Box
                              component='div'
                              sx={{
                                height: '100%',
                                justifyContent: 'center',
                                paddingTop: '7px',
                                paddingLeft: '5px',
                              }}
                            >
                              <Tooltip title='Você não tem permissão para editar este campo.'>
                                <WarningIcon color='disabled' />
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}

            <br></br>

            {/*MAPEAMOS OS CAMPOS SEM AGRUPADORES*/}
            {Data && Data.map((item: any, index: number) => {
              if (item.agrupador != null && item.agrupador != '') {
                return <></>
              }

              let control: any = getComponent(item, index)

              return (
                <Box
                  hidden={!item.exibir}
                  component='div'
                  sx={{ p: 1, display: 'flex', width: '95%', maxWidth: '700px' }}
                  key={`box-noaccordion-${index}`}
                  title={item.info ?? undefined}
                >
                  {modalDynamicAcaoEmMassa && (
                    <Checkbox
                      //disabled={!isEditable(item)}
                      checked={item.salvarAlteracoes}
                      onChange={(event, checked) => handleApplyValueToArrayedState(setData, index, 'salvarAlteracoes', checked)}
                    />
                  )}
                  {control}
                  {item.tipo == 'singleSelect' && !item.permissoes.biSelect && (
                    <Box
                      component='div'
                      sx={{
                        height: '100%',
                        justifyContent: 'center',
                        paddingTop: '7px',
                        paddingLeft: '5px',
                      }}
                    >
                      <Tooltip title='Você não tem permissão para editar este campo.'>
                        <WarningIcon color='disabled' />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              )
            })}
          </>
        )}

        {acessoExterno && !loading && (
          <Box component='div' sx={{ p: 1 }}>
            <Button disabled={Saving} variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, true)}>
              Salvar
            </Button>
          </Box>
        )}
      </>
    )
  }

  /**
   * Função que identifica campos protegidos de edição de certos Apps TODO: Passar tudo isso para as regras
   * @returns void
   */
  const isProtectedItem = () => {
    if (DataRef.current != null && accessCode == 'SYSROLE') {
      if (
        DataRef.current.find(
          (x) =>
            x.campo.toLowerCase() == 'identificador' &&
            (x.valor.toLowerCase() == 'admin' || x.valor.toLowerCase() == 'sadmin' || x.valor.toLowerCase() == 'userall')
        )
      ) {
        return true
      }
    }

    return false
  }

  /**
   * Função call back para o menu lateral do painel de emails
   * @param selectedTab Nome da tab selecionada
   * @returns void
   */
  const emailSideMenuCallBack = (selectedTab: string) => {
    switch (selectedTab) {
      case 'INBOX':
        setEmailGridTitle('Caixa de Entrada')
        break
      case 'SEND':
        setEmailGridTitle('Caixa de Saída')
        break
      case 'SENT':
        setEmailGridTitle('Items Enviados')
        break
      case 'DRAFT':
        setEmailGridTitle('Rascunhos')
        break
    }

    let filter: Array<any> = []
    filter.push({
      columnField: 'Folder',
      operatorValue: 'equals',
      value: [selectedTab],
    })

    setEmailGridFilters(filter)
  }

  /**
   * Função que atualiza a badge de emails não lidos
   * @returns void
   */
  const handleUpdateBadge = () => {
    apiWrapper.get(`api/v1/Email/listarNaoLidos?item.AccessCode=${accessCode}&item.IDMenuAppGenericItem=${id}`).then((response) => {
      setEmailGridNaoLidos(response.data.data)
    })
  }

  /**
   * Função de execução de ação customizada
   * @param item Objeto da ação customizada
   * @param validate (Opcional) Necessário validação?
   * @param acaoConfirmacao (Opcional) Nome da ação de confirmação
   * @param preConfirmacao (Opcional) É uma pré confirmação?
   * @returns void
   */
  const handleExecuteCustomAction = async (item: MenuAppActionResult, validate?: boolean, acaoConfirmacao?: string, preConfirmacao?: boolean) => {
    setValidating(true)

    if (validate) {
      handleOpenPerformingAction('Executando validações, aguarde...')

      let obj = {
        item: {
          ID: DataID,
          AccessCode: accessCode,
          Campos: Data,
          EventoRegra: 'Ao carregar app',
        },
      }

      let ret = await apiWrapper.put('api/v1/Dynamic/executarPreValidacoes', obj)
      let results = ret.data.data as Array<string>

      let result = results.find((x) => x.startsWith(`${item.menuAppFieldsRule?.id} -`))
      if (result?.includes('SUCESSO')) {
        handleOpenModalConfirmacao(true, item.menuAppFieldsRule?.atributo01 ?? '', item)
      } else if (result?.includes('CONFIRMACAO')) {
        let split = result.split(':')
        handleOpenModalConfirmacao(true, split[1], item, true)
      } else {
        toast.error('Os critérios de execução para esta ação não foram atingidos!')
      }

      handleClosePerformingAction()
      setValidating(false)
      return
    }

    if (preConfirmacao) {
      handleOpenModalConfirmacao(true, item.menuAppFieldsRule?.atributo01 ?? '', item)
      return
    }

    handleOpenPerformingAction('Executando ação customizada, aguarde...')

    let acoes = JSON.parse(item.menuAppFieldsRule?.atributo10 ?? '[]') as Array<CRuleActions>
    let camposAtualizados = 0

    for (const acao of acoes.filter((x) => x.event == acaoConfirmacao)) {
      if (acao.action != 'Atualizar Campo') {
        continue
      }

      let campo = Data.find((x) => x.id == acao.campo)
      if (campo == null) {
        continue
      }

      campo.valorAnterior = campo.valor
      campo.valor = acao.valor

      camposAtualizados++
    }

    let obj = {
      item: {
        ID: DataID,
        AccessCode: accessCode,
        Campos: Data,
        IDMenuAppFieldRule: item.menuAppFieldsRule?.id,
        EventoDeAcoes: acaoConfirmacao,
      },
    }

    await apiWrapper.put('api/v1/Dynamic/executarCustomAction', obj)
    toast.success('Ação customizada executada com sucesso!')
    handleClosePerformingAction()

    setValidating(false)

    if (camposAtualizados > 0) {
      handleClose(true, true)
    } else {
      handleClose(false, true)
    }
  }

  // --<< MODAIS >>-- \\

  /* SingleSelect (ou secundario dependendo do caso) */

  const [modalDynamicSingleSelect, setModalDynamicSingleSelect] = useState<boolean>(false)
  const [modalDynamicSingleSelectFieldIndex, setModalDynamicSingleSelectFieldIndex] = useState<number>(0)
  const [modalDynamicSingleSelectAccessCode, setModalDynamicSingleSelectAccessCode] = useState<string>('')
  const [modalDynamicSingleSelectID, setModalDynamicSingleSelectID] = useState<number>(0)

  const handleOpenModalSingleSelect = (fieldIndex: number, AccessCode: string, Id: number) => {
    setModalDynamicSingleSelect(true)
    setModalDynamicSingleSelectFieldIndex(fieldIndex)
    setModalDynamicSingleSelectAccessCode(AccessCode)
    setModalDynamicSingleSelectID(Id)
  }

  const handleModalSingleSelectCallback = (response: any = null) => {
    setModalDynamicSingleSelect(false)

    // Recarregamos as opções existentes do campo singleSelect
    if (response != null) {
      handleOnInputChangeAutoComplete(modalDynamicSingleSelectFieldIndex, '', [response.id ?? modalDynamicSingleSelectID], true)
    }

    setModalDynamicSingleSelectFieldIndex(0)
    setModalDynamicSingleSelectAccessCode('')
    setModalDynamicSingleSelectID(0)
  }

  /* AVISO TAREFA */

  const [modalAvisoTarefa, setModalAvisoTarefa] = useState<boolean>(false)
  const [modalAvisoTarefaIDTarefa, setModalAvisoTarefaIDTarefa] = useState<number>(0)

  const handleOpenModalAvisoTarefa = (idTarefa: number) => {
    setModalAvisoTarefa(true)
    setModalAvisoTarefaIDTarefa(idTarefa)
  }

  const handleCloseModalAvisoTarefa = (redirect: boolean = false) => {
    setModalAvisoTarefa(false)
    setModalAvisoTarefaIDTarefa(0)

    if (redirect) {
      window.open(`/pages/_dynamicTabs/SYSTASK/${modalAvisoTarefaIDTarefa}`, '_blank')
    }
  }

  /* SYSTASK */

  const [modalObservacaoTarefa, setModalObservacaoTarefa] = useState<boolean>(false)
  const [modalObservacaoTarefaAcao, setModalObservacaoTarefaAcao] = useState<string>('')
  const [observacaoTarefa, setObservacaoTarefa] = useState<string>('')

  /**
   * Função que checa se estamos num ambiente de uma tarefa de aprovação
   * @returns boolean
   */
  const getTarefaAprovacao = () => {
    if (accessCode != 'SYSTASK' || Data == null || DataID == 0) {
      return false
    }

    let campoIdTipoTarefa = DataRef.current.find((x) => x.campo.toLowerCase() == 'idtipotarefa')
    if (campoIdTipoTarefa == null) {
      return false
    }

    let campoIdTipoStatus = DataRef.current.find((x) => x.campo.toLowerCase() == 'idtipostatus')
    if (campoIdTipoTarefa == null) {
      return false
    }

    return campoIdTipoTarefa.valor == '2' && (campoIdTipoStatus?.valor == '1' || campoIdTipoStatus?.valor == '2')
  }

  /**
   * Função que executa uma tarefa, aprovar, reprovar ou aprovar com ressalva
   * @param evento O evento da tarefa
   * @param observacao Observação a ser salva na tarefa ao realizar a execução
   * @returns void
   */
  const handleExecutarTarefa = (evento: string, observacao: string, necessitaObservacao: boolean = false) => {
    if (necessitaObservacao && observacao == '') {
      toast.error('É necessário informar a observação!')
      return
    }

    setModalObservacaoTarefa(false)

    let campoStatusTarefa = Data.find((x) => x.campo.toLowerCase() == 'idtipostatus')
    if (campoStatusTarefa == null) {
      toast.error('Campo de status de tarefa não foi encontrado no formulário, verifique o mapeamento do app.')
      return
    }

    handleOpenPerformingAction('Executando ações e salvando tarefa, aguarde...')

    let novoStatus = campoStatusTarefa.valor
    let mensagem = ''

    switch (evento) {
      case 'APROVACAO':
        novoStatus = '12'
        mensagem = 'Tarefa aprovada!'
        break
      case 'REPROVACAO':
        novoStatus = '13'
        mensagem = 'Tarefa reprovada!'
        break
      case 'APROVACAORESSALVA':
        novoStatus = '14'
        mensagem = 'Tarefa aprovada com ressalva!'
        break
    }

    campoStatusTarefa.valor = novoStatus //SANITY CHECK

    setData(Data)

    // LOCKAMOS O MODAL PARA EXECUÇÃO E SALVAMENTO DA TAREFA
    setFieldLock(true)
    setSaving(true)

    apiWrapper
      .post('api/v1/Tarefa/executarTarefa', { item: { IDTarefa: DataID, Evento: evento, Observacao: observacao, Campos: Data } })
      .then((response) => {
        for (const message of response.data.data) {
          if (message != 'SUCESSO') {
            toast.error(message)
          }
        }
      })
      .catch((error) => {
        alert(error)
      })
      .finally(() => {
        setFieldLock(false)
        setSaving(false)
        handleClosePerformingAction()
        handleClose(false, false)
        handleCallBack({ id: DataID, keepOpen: false })
      })
  }

  /* CONFIRMAÇÃO */

  const [modalConfirmacao, setModalConfirmacao] = useState<boolean>(false)
  const [modalConfirmacaoPreConfirmacao, setModalConfirmacaoPreConfirmacao] = useState<boolean>(false)
  const [modalConfirmacaoCustomAction, setModalConfirmacaoCustomAction] = useState<MenuAppActionResult | undefined>(undefined)
  const [modalConfirmacaoKeepOpen, setModalConfirmacaoKeepOpen] = useState<boolean>(false)
  const [modalConfirmacaoMensagem, setModalConfirmacaoMensagem] = useState<string>('')

  /**
   * Função que abre o modal de confirmação da regra de validação
   * @param keepOpen Mander modal aberto?
   * @param mensagem Mensagem para mostrar no modal de confirmação
   * @param customAction (Opcional) Objeto da ação customizada
   * @param preConfirmacao (Opcional) É uma pré confirmação?
   * @returns void
   */
  const handleOpenModalConfirmacao = (keepOpen: boolean, mensagem: string, customAction?: MenuAppActionResult, preConfirmacao?: boolean) => {
    setModalConfirmacao(true)
    setModalConfirmacaoCustomAction(customAction)
    setModalConfirmacaoKeepOpen(keepOpen)
    setModalConfirmacaoMensagem(mensagem)
    setModalConfirmacaoPreConfirmacao(preConfirmacao ?? false)
  }

  /**
   * Função que gerencia o que acontece quando o modal de confirmaçào fecha dependendo do que foi pressionado
   * @param evento Nome do evento
   * @param customAction (Opcional) Objeto da ação customizada
   * @param preConfirmacao (Opcional) É uma pré confirmação?
   * @returns void
   */
  const handleCloseModalConfirmacao = (evento: string, customAction?: MenuAppActionResult, preConfirmacao?: boolean) => {
    if (evento != '' && (evento == 'SIM' || evento == 'NAO')) {
      if (customAction == null) {
        handleClose(true, modalConfirmacaoKeepOpen, evento)
      } else if (preConfirmacao) {
        handleExecuteCustomAction(customAction, false, evento, true)
      } else {
        handleExecuteCustomAction(customAction, false, evento)
      }
    }

    handleClosePerformingAction()
    setModalConfirmacao(false)
  }

  /* AGUARDE */

  const [modalPerformingAction, setModalPerformingAction] = useState<boolean>(false)
  const [modalPerformingActionMessage, setModalPerformingActionMessage] = useState<string>('')

  /**
   * Função que abre o modal de aguarde
   * @param message Mensagem para mostrar no modal de aguarde
   * @returns void
   */
  const handleOpenPerformingAction = (message: string) => {
    setModalPerformingAction(true)
    setModalPerformingActionMessage(message)
  }

  /**
   * Função que fecha o modal de aguarde
   * @returns void
   */
  const handleClosePerformingAction = () => {
    setModalPerformingAction(false)
    setModalPerformingActionMessage('')
  }
  const intl = useIntl()
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [actions, setActions] = useState<Array<MenuAppActionResult>>([])

  const {loggedUser} = useGlobalAuthentication()

  const locale = useLang()

  //* CONTEXTO GLOBAL PARA APLICAR TITULO E BOTÕES AO TOOLBAR
  const globalContext: any = useContext(GlobalContext)

  const apiRef = useGridApiRef()
  const navigate = useNavigate()

  //* VARIAVEIS GLOBAIS
  var gridSelection: Array<number> = []

  //* REGIÃO: PARÂMETROS DE ROTA *//

  // Recuperamos os parametos da rota
  const RouteParams = useParams()

  /**
   * Escolhe quais parametros da rota iremos usar no contexto atual
   * @returns object
   */
  const BuildParams = () => {
    if (!isPrimary) {
      return {AccessCode: accessCode, SYSParam: sysParam, IDRouteItem: undefined}
    } else {
      // Possuimos ja duas rotas para o lista dinamica e, por as duas terem pelo menos 2 parametros uma sobrepoe a outra
      // Sendo assim o react route adiciona o que poderia ou não ser o proximo parametro de rota na chave * para evitar que parametros se percam
      // Utilizamos o split para recuperar-lo

      let idrouteitem = RouteParams['*'] as string
      if (idrouteitem != null) {
        let split = idrouteitem.split('/')
        idrouteitem = split[1]
      }

      return {AccessCode: RouteParams.accesscode, SYSParam: RouteParams.sysparam, IDRouteItem: idrouteitem}
    }
  }

  const handleLoadActions = (AccessCode) => {
    apiWrapper.get(`/api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${AccessCode}`).then(async (response) => {
      let _actions: Array<MenuAppActionResult> = response.data.data

      setActions(_actions)
    })
  }

  // Construímos os parametros gerais
  const {AccessCode, SYSParam, IDRouteItem} = BuildParams()

  // Variaveis de estado de código de acesso
  const [AccessCodeMaster, setAccessCodeMaster] = useState<string>('')
  const [AccessCodeInherited, setAccessCodeInherited] = useState<string>('')

  
  const [AcaoAdicionar, setAcaoAdicionar] = useState<any>({})

  /**
   * Carregamos o objeto do app e verificamos se ele é herdado de algum app pai, caso seja então carregamos o pai e guardamos o access code na variavel de estado para uso posterior
   * @returns void
   */
  const VerifyAccessCode = async () => {
    // Caso o código de acesso da rota seja nulo então não temos o que carregar, retornamos
    if (AccessCode == null || AccessCode == '') {
      return
    }

    setLoading(true)

    let _accessCodeMaster = AccessCode ?? ''
    let _accessCodeInherited = AccessCode ?? ''

    // Carregamos o objeto do app da rota
    const baseAccessCodeRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${AccessCode}`)
    const baseMenuApp = baseAccessCodeRequest.data.data

    if (baseMenuApp == null) {
      setAccessCodeMaster('')
      setAccessCodeInherited('')
      return
    }

    // Verificamos a existencia de um app pai para o app da rota CASO o app filho não seja uma página customizada
    if (!baseMenuApp.biCustomPage && baseMenuApp.idmenuAppPai != null) {
      // Carregamos o app pai
      const masterAccessCodeRequest = await apiWrapper.get(`api/v1/MenuApp/carregar?item.ID=${baseMenuApp.idmenuAppPai}`)
      const masterMenuApp = masterAccessCodeRequest.data.data

      // Guardamos o access code do app pai
      _accessCodeMaster = masterMenuApp.accessCode
    }

    setLoading(false)

    // Aplicamos ambos os códigos de acesso nas variáveis de estado
    setAccessCodeMaster(_accessCodeMaster)
    setAccessCodeInherited(_accessCodeInherited)
  }

  //* FIM REGIÃO: PARÂMETROS DE ROTA *//

  //* VARIAVEIS DE ESTADO DO APP
  const [menuApp, setMenuApp] = useState<any>(null)
  const [menuAppHistory, setMenuAppHistory] = useState<any>(null)

  //* VARIAVEIS DE ESTADO DE ACESSO
  const [permissions, setPermissions] = useState<any>(null)
  const [userRoles, setUserRoles] = useState<Array<any>>([])

  //* VARIAVEIS DE ESTADO DO GRID
  const [loading, setLoading] = useState<boolean>(false)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [rows, setRows, rowsRef] = useStateRef<Array<any>>([])
  const [columns, setColumns] = useState<Array<any>>([])
  const [rowsCount, setRowsCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({pageSize: 25, page: 0})
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  const [invisibleColumns, setInvisibleColumns] = useState<GridColumnVisibilityModel>({id: false})
  const [gridFilter, setGridFilter] = useState<GridFilterModel | undefined>()
  const [gridSort, setGridSort] = useState<GridSortModel | undefined>()
  const [controls, setControls] = useState<Array<any>>([])
  const [filtro, setFiltro] = useState<string>('')
  const [hideDelete, setHideDelete] = useState<boolean>(false)

  const [requestFilters, setRequestFilters] = useState<Array<any>>([])
  const [requestOrders, setRequestOrders] = useState<Array<any>>([])

  //* VARIAVEIS DE ESTADO EXTRA
  const popperRefObject = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElCM, setAnchorElCM] = useState<null | HTMLElement>(null)
  const contextMenuOpen = Boolean(anchorElCM)
  const [appMode, setAppMode] = useState<string>('2') // 1 = CORE | 2 = DATA
  const [integration, setIntegration] = useState<any>()
  const [filterPanelOpened, setFilterModelOpened, filterPanelOpenedRef] = useStateRef<boolean>(false)


  //* VARIAVEIS DE ESTADO PDF
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [secondPdfUrl, setSecondPdfUrl] = useState<string | null>(null);

  const handleOpenPdfModal = (event: any, url: string) => {
    event.preventDefault();
    setLoading(true);
    setPdfUrl(url);
    setSecondPdfUrl(null); 
  };

  const handleOpenDualPdfModal = (event: any, url1: string, url2: string) => {
    event.preventDefault();
    setLoading(true);
    setPdfUrl(url1);
    setSecondPdfUrl(url2);
  };

  const handleClosePdfModal = () => {
    setPdfUrl(null);
    setSecondPdfUrl(null);
    setLoading(false);
  };
  
  const handlePdfLoaded = () => {
    setLoading(false);
  };
  
  // Create a function to find PDF URLs in the row
  const findPdfUrlsInRow = async (params: GridRenderCellParams<any>) => {
    const pdfUrls: string[] = [];
    
    // Get all URL format columns from the current row
    let columnsRequest = await apiWrapper.get(`/api/v1/Dynamic/carregar?item.AccessCode=${AccessCodeInherited}`)
    
    const urlColumns = columnsRequest.data.data.fields.filter(item => {
      const exibicao = JSON.parse(item.jsonformatacao);
      return exibicao?.formatacao === 'Url';
    });
  
    // Check each URL column in this row for PDFs
    urlColumns.forEach(column => {
      const value = params.row[column.campo];
      if (value) {
        pdfUrls.push(value);
      }
    });
  
    return pdfUrls;
  };

  //* COMPONENTES - RENDER CELL

  function CustomTextEditRenderComponent(props: GridRenderCellParams<any>) {
    const {value} = props
    const [showEncrypted, setShowEncrypted] = useState<boolean>(false)

    return (
      <>
        <span>{showEncrypted ? value : <>••••••••••••</>}</span>
        <IconButton
          aria-label='toggle password visibility'
          onClick={(event) => setShowEncrypted((prev) => !prev)}
          onMouseDown={(e) => e.preventDefault()}
          edge='end'
        >
          {showEncrypted ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </>
    )
  }

  const renderTextInputCell: GridColDef['renderCell'] = (params) => {
    return <CustomTextEditRenderComponent {...params} />
  }

  //* COMPONENTES - RENDER EDIT CELL

  function CustomSingleSelectEditComponent(props: GridRenderEditCellParams) {
    const {id, value, field, hasFocus} = props
    const [options, setOptions] = useState<Array<any>>([])
    const apiRef = useGridApiContext()
    const ref = useRef<any>()

    const handleOnInit = () => {
      if (IsSystemAccessCode()) {
        let ids = value != null && value != 'N/A' ? (value as number).toString() : ''
        getOptions('', ids, 15)
      } else {
        let query = value != null ? value : ''
        getOptions(query, '', 15)
      }
        
    }

    const handleOnChange = (value: any) => {
      apiRef.current.setEditCellValue({id, field, value: IsSystemAccessCode() ? value?.id : value?.label ?? null})
    }

    const handleOnOpen = (event: any) => {
      if (event == null || event.target.value == '' || event.target.value == null) {
        handleOnInputChange(null, '')
      }
    }

    const handleOnInputChange = (event: any, query: string) => {
      getOptions(query, '', 15)
    }

    const getOptions = (query: string, ids: string, limit: number) => {
      apiWrapper
        .get(
          `api/v1/Dynamic/listarSingleSelect?item.AccessCode=${AccessCodeInherited}&item.Campo=${field}&item.Query=${query}&item.IDs=${ids}&RegistroPorPagina=${limit}`
        )
        .then((response) => {
          setOptions(response.data.data)
        })
    }

    useEffect(() => {
      if (options.length == 0) {
        handleOnInit()
      }
    }, [])

    useLayoutEffect(() => {
      if (hasFocus && ref.current != null) {
        ref.current.focus()
      }
    }, [hasFocus])

    return (
      <>
        <Autocomplete
          ref={ref}
          disablePortal
          options={options}
          sx={{width: '100%'}}
          size='small'
          renderInput={(params) => <TextField {...params} label='' />}
          value={
            IsSystemAccessCode()
              ? value != null && value != 'N/A'
                ? options.filter((op: any) => op.id == value)[0] ?? null
                : null
              : value != null
              ? options.filter((op: any) => op.label == value)[0] ?? null
              : null
          }
          onChange={(event, value, reason) => handleOnChange(value)}
          onOpen={(event) => handleOnOpen(event)}
          onInputChange={(event, value) => handleOnInputChange(event, value)}
          filterOptions={(x) => x}
        />
      </>
    )
  }

  const renderSingleSelectEditInputCell: GridColDef['renderEditCell'] = (params) => {
    return <CustomSingleSelectEditComponent {...params} />
  }

  function CustomTextEditComponent(props: GridRenderEditCellParams) {
    const {id, value, field, hasFocus, headerName} = props
    const apiRef = useGridApiContext()
    const ref = useRef<any>()

    const [showEncrypted, setShowEncrypted] = useState<boolean>(false)

    const handleOnChange = (value: any) => {
      apiRef.current.setEditCellValue({id, field, value: value ?? null})
    }

    useLayoutEffect(() => {
      if (hasFocus && ref.current != null) {
        ref.current.focus()
      }
    }, [hasFocus])

    return (
      <>
        <TextField
          sx={{width: '100%'}}
          size='small'
          type={showEncrypted ? 'text' : 'password'}
          label={headerName}
          value={value}
          onChange={(event) => handleOnChange(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={(event) => setShowEncrypted((prev) => !prev)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge='end'
                >
                  {showEncrypted ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </>
    )
  }

  const renderTextEditInputCell: GridColDef['renderEditCell'] = (params) => {
    return <CustomTextEditComponent {...params} />
  }

  //* CONSTRUTORES
  const BuildActions = (params: any, goToApp: boolean, _permissions: any, isReadOnly: boolean = false) => {
    if (AccessCodeInherited == 'SYSUSERAUDIT') {
      return []
    }

    if (AccessCodeInherited == 'SYSMAA') {
      return [<GridActionsCellItem icon={<PreviewIcon />} label='Visualizar' onClick={() => chooseModalToOpen('SYSMAA', params.id)} showInMenu />]
    }

    if (AccessCodeInherited == 'ZPLUGMAILBOX') {
      return [
        <GridActionsCellItem icon={<PreviewIcon />} label='Visualizar' onClick={() => HTMLViewerOpen(params.row)} showInMenu />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biInsert) && !IsSystemAccessCode()}
          icon={<ReplyIcon />}
          label='Responder'
          onClick={() => HTMLSendOpen(params.id, 'Responder', params.row.De, undefined, `Res: ${params.row.Nome}`)}
          showInMenu
        />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biInsert) && !IsSystemAccessCode()}
          icon={<ReplyAllIcon />}
          label='Responder a Todos'
          onClick={() => HTMLSendOpen(params.id, 'Responder a Todos', undefined, `${params.row.De}, ${params.row.Cc}`, `Res: ${params.row.Nome}`)}
          showInMenu
        />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biInsert) && !IsSystemAccessCode()}
          icon={<DoubleArrowIcon />}
          label='Encaminhar'
          onClick={() => HTMLSendOpen(params.id, 'Encaminhar', undefined, undefined, `Enc: ${params.row.Nome}`)}
          showInMenu
        />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biDelete || hideDelete) && !IsSystemAccessCode()}
          icon={<DeleteIcon />}
          label='Excluir'
          onClick={() => handleOpenExcluirModal(params.id, AccessCodeMaster)}
          showInMenu
        />,
      ]
    }

    let actions: Array<JSX.Element> = [
      <GridActionsCellItem
        hidden={(_permissions == null || (!isReadOnly && !_permissions.biSelect && !_permissions.biUpdate)) && !IsSystemAccessCode()}
        icon={<EditIcon />}
        label={isReadOnly || (_permissions.biSelect && !_permissions.biUpdate) ? 'Visualizar' : 'Editar'}
        onClick={() => chooseModalToOpen(AccessCodeMaster, params.id)}
        showInMenu
      />,
      <GridActionsCellItem
        hidden={(_permissions == null || !_permissions.biInsert || isReadOnly) && !IsSystemAccessCode()}
        icon={<ContentCopy />}
        label='Duplicar'
        onClick={() => Duplicar(params.id)}
        showInMenu
      />,
      <GridActionsCellItem
        hidden={(_permissions == null || !_permissions.biDelete || isReadOnly || hideDelete) && !IsSystemAccessCode()}
        icon={<DeleteIcon />}
        label='Excluir'
        onClick={() => handleOpenExcluirModal(params.id, AccessCodeMaster)}
        showInMenu
      />,
      <GridActionsCellItem
        hidden={IsSystemAccessCode()}
        icon={<MailIcon />}
        label='Enviar por Email'
        onClick={() => {
          HTMLSendOpenWithSelectedItemAsContent([params.id])
        }}
        showInMenu
      />,
    ]

    if (goToApp) {
      actions.push(
        <GridActionsCellItem
          icon={<ExitToAppIcon />}
          label='Ir para o App'
          onClick={() => {
            apiWrapper.get('/api/v1/MenuApp/carregar?item.ID=' + params.id).then((response) => {
              let accessCode: string = response.data.data.accessCode

              if (accessCode.toLowerCase().startsWith('sys')) {
                navigate(`/pages/_dynamic/${accessCode}`, {replace: true})
              } else {
                navigate(`/pages/_dynamicTabs/${accessCode}`, {replace: true})
              }
            })
          }}
          showInMenu
        />
      )

      actions.push(
        <GridActionsCellItem
          icon={<ExitToAppIcon />}
          label='Abrir em uma nova aba'
          onClick={() => {
            apiWrapper.get('/api/v1/MenuApp/carregar?item.ID=' + params.id).then((response) => {
              let accessCode: string = response.data.data.accessCode
              window.open(`/pages/_dynamicTabs/${accessCode}`, '_blank')
            })
          }}
          showInMenu
        />
      )
    }

    return actions
  }

  const BuildControls = (selection: boolean = false, force: boolean = false) => {
    if (!isDisplayed && !force) {
      return
    }

    let permitedActions = actions.filter(x => x.acao == "Custom");


    let controls: Array<any> = []

    if (AccessCodeInherited == 'SYSUSERAUDIT') {
      if (permissions != null && permissions.biSelect) {
        controls.push(
          <StyledButton variant='contained' startIcon={<FileDownloadIcon />} color='inherit' onClick={() => Exportar()}>
            Exportar
          </StyledButton>
        )
      }
    } else if (AccessCodeInherited == 'ZPLUGMAILBOX') {
      if (selection && permissions != null && permissions.biDelete && !hideDelete) {
        controls.push(
          <StyledButton variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluirModal(0, AccessCodeMaster)}>
            Excluir
          </StyledButton>
        )
      }

      if (permissions != null && permissions.biSelect) {
        controls.push(
          <StyledButton variant='contained' startIcon={<RefreshIcon />} color='inherit' onClick={async () => GetData(true, true)}>
            Atualizar
          </StyledButton>
        )
      }

      if (permissions != null && permissions.biInsert) {
        controls.push(
          <StyledButton variant='contained' startIcon={<AddIcon />} color='info' onClick={() => HTMLSendOpen(0, 'Novo Email')}>
            Novo Email
          </StyledButton>
        )
      }
    } else {
      if (AccessCodeInherited == 'SYSMAP' && HasSuperAdmin()) {
        controls.push(
          <FormControl>
            <StyledRadioGroup value={appMode} onChange={handleOnChangeAppMode} row>
              <FormControlLabel value='1' control={<Radio />} label='Apps Core' />
              <FormControlLabel value='2' control={<Radio />} label='Apps Usuário' />
            </StyledRadioGroup>
          </FormControl>
        )
      }

      //* TODO: Refactor para componente de filtros
      if (AccessCodeInherited == 'SYSINTEGRATION' && HasSuperAdmin()) {
        controls.push(
          <FormControl>
            <StyledRadioGroup value={appMode} onChange={handleOnChangeAppMode} row>
              <FormControlLabel value='1' control={<Radio />} label='Integrações Core' />
              <FormControlLabel value='2' control={<Radio />} label='Integrações Usuário' />
            </StyledRadioGroup>
          </FormControl>
        )
      }

      //* Caso o item de relação tenha a flag biOcultarAcoes então ocultamos as abaixo
      //? Note que nem todas as aÇões entram nessa regra pois algumas não tem há ver com items de relação
      let ocultarAcoes = relationItem != null && relationItem.biOcultarAcoes

      if (!ocultarAcoes && selection && !IsSystemAccessCode()) {
        controls.push(
          <StyledButton
            variant='contained'
            startIcon={<AppRegistrationIcon />}
            color='info'
            onClick={() => HTMLSendOpenWithSelectedItemAsContent(gridSelection)}
          >
            Enviar por email
          </StyledButton>
        )
      }

      if (!ocultarAcoes && !readOnly && selection && ((permissions != null && permissions.biUpdate) || IsSystemAccessCode())) {
        controls.push(
          <StyledButton variant='contained' startIcon={<AppRegistrationIcon />} color='inherit' onClick={() => handleOpenModalDynamic('0', true)}>
            Edição em Massa
          </StyledButton>
        )

        if (AccessCode == 'SYSTASK') {
          controls.push(
            <StyledButton variant='contained' startIcon={<PlaylistAddCheckIcon />} color='inherit' onClick={() => handleOpenModalTarefa()}>
              Aprovar/Reprovar
            </StyledButton>
          )
        }
      }

      if (!ocultarAcoes && !hideDelete && !readOnly && selection && ((permissions != null && permissions.biDelete) || IsSystemAccessCode())) {
        controls.push(
          <StyledButton variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluirModal(0, AccessCodeMaster)}>
            Excluir
          </StyledButton>
        )
      }

      if (AccessCodeInherited == 'SYSMAF' && permissions != null && permissions.biManage) {
        controls.push(
          <StyledButton variant='contained' color='inherit' onClick={() => remapFields()}>
            Remapear Campos
          </StyledButton>
        )
      }

      // PadraoModalCustom antes do adicionar
      const padraoModalActions = permitedActions.filter(x => x.tipo.trim() === 'PadraoModalCustom');
        padraoModalActions.forEach(action => {
        controls.push(
          <StyledButton
            hidden={action.biVisible === false}
            variant='contained'
            startIcon={<BoltIcon />}
            color='warning'
            title={action.descricao}
            onClick={() => handleExecuteCustomAction(action, true)}
          >
            {action.nome}
          </StyledButton>
        );
      });
      
      // Deixar o adicionar por ultimo
      if (permitedActions.filter(x => x.acao == "Add")) {
        controls.push(
          <StyledButton
            hidden={(permissions == null || !permissions.biInsert) && !IsSystemAccessCode()}
            variant='contained'
            startIcon={<AddIcon />}
            color='info'
            onClick={() => chooseModalToOpen(AccessCodeMaster, '0')}>

            {AcaoAdicionar.nome ?? 'Adicionar'}

          </StyledButton>
        )
      }
    
      // Adicionar anterior
      // if (!ocultarAcoes && !readOnly) {
      //   controls.push(
      //     <StyledButton
      //       hidden={(permissions == null || !permissions.biInsert) && !IsSystemAccessCode()}
      //       variant='contained'
      //       startIcon={<AddIcon />}
      //       color='info'
      //       onClick={() => chooseModalToOpen(AccessCodeMaster, '0')}>

      //       {AcaoAdicionar.nome ?? 'Adicionar'}

      //     </StyledButton>
      //   )
      // }

      if (
        !IsSystemAccessCode() ||
        (AccessCodeInherited == 'SYSMAF' && SYSParam != null) ||
        (permissions != null && permissions.biSelect && !IsSystemAccessCode(true)) ||
        (permissions != null && permissions.biInsert && !IsSystemAccessCode(true))
      ) {
        controls.push(
          <IconButton color='inherit' onClick={handleClickOpenContextMenu}>
            <MoreVertIcon />
          </IconButton>
        )
      }
    }

    if (!isPrimary) {
      if (displayHeader) {
        setControls(controls)
      }

      return
    }

    globalContext.ReactState.Toolbar2.setControls(controls)
  }

  //* FUNÇÕES DE AJUDA
  const HasAdmin = () => {
    if (userRoles.length == 0) {
      return false
    }

    return userRoles.find((x: any) => x.identificador == 'ADMIN') != null
  }

  const HasSuperAdmin = () => {
    if (userRoles.length == 0) {
      return false
    }

    return userRoles.find((x: any) => x.identificador == 'SADMIN') != null
  }

  const IsSystemAccessCode = (actions: boolean = false) => {
    if (actions) {
      return AccessCodeInherited == 'SYSMAP' || AccessCodeInherited == 'SYSMAPVISOES' || AccessCodeInherited == 'SYSMAF'
    }

    return (
      AccessCodeInherited != 'SYSTASK' &&
      (AccessCodeInherited?.toLowerCase().startsWith('sys') || AccessCodeInherited?.toLowerCase().startsWith('zplug'))
    )
  }

  const IsPluginAccessCode = () => {
    return AccessCodeInherited?.toLowerCase().startsWith('zplug')
  }

  const isProtectedItemSelected = (newSelectionModel: GridRowSelectionModel) => {
    for (const itemId of newSelectionModel) {
      let fromData = rows.find((x) => x.ID == itemId)

      if (fromData != null) {
        if (AccessCodeInherited == 'SYSROLE') {
          if (
            fromData.Identificador.toLowerCase() == 'admin' ||
            fromData.Identificador.toLowerCase() == 'sadmin' ||
            fromData.Identificador.toLowerCase() == 'userall'
          ) {
            return true
          }
        }
      }
    }

    return false
  }

  const isEmpty = (obj: any) => {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }

    return JSON.stringify(obj) === JSON.stringify({})
  }

  const GetHideableNames = (fieldName: string) => {
    return true
  }

  const GetIgnorableTypes = (fieldType: string) => {
    switch (fieldType) {
      case 'upload':
        return true
    }

    return false
  }

  const GetFilterableTypes = (fieldType: string) => {
    switch (fieldType) {
      case 'formulaSQL':
        return false
    }

    return true
  }

  const TranslateFieldType = (fieldType: string) => {
    switch (fieldType) {
      case 'decimal':
      case 'geocode':
        return 'number'
      case 'special':
      case 'textArea':
      case 'geolocation':
        return 'string'
      case 'singleSelect':
        if (!IsSystemAccessCode()) {
          return 'string'
        }
        break
    }

    return fieldType
  }

  //* FUNÇÕES GRID
  const StartGrid = async () => {
    let isReadOnly = false

    //* Resetamos o grid por completo caso ele esteja sendo re-renderizado de uma outra rota
    setRows([])
    setColumns([])
    setFiltro('')
    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })

    if (AccessCodeMaster == '' || AccessCodeInherited == '') {
      return
    }

    //* Verificamos se existe a necessidade de abrir o modal com o ID parametro da rota
    if (IDRouteItem != null && parseInt(IDRouteItem) > 0) {
      chooseModalToOpen(AccessCodeMaster, IDRouteItem)
    }

    setLoading(true)

    document.addEventListener('keydown', (event) => {
      handleOnKeyDown(null, event, null)
    })

    //* REQUEST DAS VARIAVEIS BÁSICAS DE ESTADO
    const appRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${AccessCodeInherited}`)
    setMenuApp(appRequest.data.data)

    const appHistoryRequest = await apiWrapper.get(`api/v1/MenuAppHistory/carregarPorMenuApp?item.AccessCode=${AccessCodeInherited}`)
    setMenuAppHistory(appHistoryRequest.data.data)

    const permissionsRequest = await apiWrapper.get(`api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=${AccessCodeInherited}`)
    setPermissions(permissionsRequest.data.data)

    const userRolesRequest = await apiWrapper.get('api/v1/Role/listarRolesPessoaLogada')
    setUserRoles(userRolesRequest.data.data)

    const appActions = await apiWrapper.get(`/api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${AccessCodeInherited}`)

    let acaoAdicionar = appActions.data.data.find((x) => x.acao == 'Add')
    setAcaoAdicionar(acaoAdicionar)

    let acaoExcluir = appActions.data.data.find((x) => x.acao == 'Delete')
    setHideDelete(acaoExcluir != null && !acaoExcluir.biVisible)

    if (
      readonly ||
      (permissionsRequest.data.data.biSelect && !permissionsRequest.data.data.biUpdate) ||
      appRequest.data.data.sqlType.trim() == 'VIEW'
    ) {
      setReadOnly(true)
      isReadOnly = true
    } else {
      setReadOnly(false)
    }

    if (IDIntegration != null) {
      const integrationRequest = await apiWrapper.get(`api/v1/MenuAppIntegration/Carregar?item.ID=${IDIntegration}`)
      setIntegration(integrationRequest.data.data)
    }

    if (isPrimary) {
      globalContext.ReactState.Toolbar2.setTitle(appRequest.data.data.titulo)
      globalContext.ReactState.Toolbar2.setSubtitle(appRequest.data.data.descricao)
    }

    let historico =
      appHistoryRequest.data.data.historico != null ? (JSON.parse(appHistoryRequest.data.data.historico) as CMenuAppHistoryStructure) : undefined

    if (permissionsRequest.data.data == null || !permissionsRequest.data.data.biSelect) {
      setLoading(false)
      return
    }

    //* CONSTRUÇÃO DAS COLUNAS
    let columnsRequest = await apiWrapper.get(`/api/v1/Dynamic/carregar?item.AccessCode=${AccessCodeInherited}`)

    let colunas: Array<any> = []
    let colunasParaOcultar: string = ''
    let colunasOcultas: GridColumnVisibilityModel

    let left: string[] = []
    let right: string[] = []

    if (
      AccessCodeInherited != 'SYSUSERAUDIT' &&
      permissionsRequest.data.data != null &&
      (permissionsRequest.data.data.biSelect ||
        permissionsRequest.data.data.biDelete ||
        permissionsRequest.data.data.biInsert ||
        permissionsRequest.data.data.biUpdate)
    ) {
      colunas.push({
        field: 'actions',
        width: 25,
        type: 'actions',
        editable: false,
        hideable: false,
        getActions: (params: any) =>
          BuildActions(params, AccessCodeInherited == 'SYSMAP' || AccessCodeInherited == 'SYSMAPVISOES', permissionsRequest.data.data, isReadOnly),
      })
    }

    for (const item of columnsRequest.data.data.fields) {
      if (!item.ativo || GetIgnorableTypes(item.tipo)) continue
      if (relationItem != null && relationItem.menuAppField.id == item.id) continue

      const exibicao = JSON.parse(item.jsonformatacao)
      const usaExibicao = exibicao != null && (exibicao.formatacao != '' || exibicao.exibicao.length > 0)

      let isID = item.campo.toUpperCase() == 'ID'
      let realType = TranslateFieldType(item.tipo)
      let alignment = realType == 'number' ? 'right' : usaExibicao || item.tipo == 'boolean' ? 'center' : 'left'

      let cx: any = {
        field: item.campo,
        type: realType,
        headerName: item.label,
        headerAlign: isID ? 'left' : alignment,
        align: isID ? 'left' : alignment,
        width: isID ? 125 : undefined,
        flex: isID ? undefined : 0.25,
        hideable: !IsSystemAccessCode() && GetHideableNames(item.campo.toUpperCase()),
        filterable: GetFilterableTypes(item.tipo),
        disableReorder: IsSystemAccessCode(),
        pinnable: !IsSystemAccessCode(),
      }

      if (item.criptografar) {
        cx.renderCell = renderTextInputCell
        cx.renderEditCell = renderTextEditInputCell
      } else {
        if (usaExibicao) {
          cx.renderCell = (params: GridRenderCellParams<any>) => {
            let value = <>{params.formattedValue}</>

            if (params.value == null || params.value == 'N/A') {
              return value
            }

            switch (exibicao.formatacao) {
              case 'Telefone':
                return (
                  <Box component='div' sx={{cursor: 'pointer'}} onClick={(event) => handleOpenTelefone(params.value)}>
                    {params.value}
                  </Box>
                )
              case 'CPF':
                let formated_cpf = params.value.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                value = <>{formated_cpf}</>
                break
              case 'CNPJ':
                let formated_cnpj = params.value.replace(/[^\d]/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
                value = <>{formated_cnpj}</>
                break
              case 'Moeda (R$)':
                let formatted_brl = parseFloat(params.value).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                value = <>{formatted_brl}</>
                break
              case 'Moeda ($)':
                let formatted_usd = parseFloat(params.value).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
                value = <>{formatted_usd}</>
                break
              case 'Percentual (%)':
                let formatted_percent = `${parseFloat(params.value).toLocaleString()}%`
                value = <>{formatted_percent}</>
                break
              case 'Progresso (%)':
                return <ProgressBar value={parseFloat(params.value)} />
              case 'Tudo em caixa alta':
                value = <>{params.value.toString().toUpperCase()}</>
                break
              case 'Tudo em caixa baixa':
                value = <>{params.value.toString().toLowerCase()}</>
                break


                case 'Url':
                  return (
                    <a 
                      href={params.value} 
                      target='_blank' 
                      onClick={(e) => {
                        e.preventDefault();
                        findPdfUrlsInRow(params).then((pdfUrls) => {
                          if (pdfUrls.length === 2) {
                            // Show toast with option to open side by side
                            toast.info(
                              <Box>
                                Abrir PDFs lado a lado?
                                <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                                  <Button 
                                    size="small" 
                                    variant="contained" 
                                    onClick={() => handleOpenDualPdfModal(e, pdfUrls[0], pdfUrls[1])}
                                  >
                                    Sim
                                  </Button>
                                  <Button 
                                    size="small" 
                                    variant="outlined" 
                                    onClick={() => handleOpenPdfModal(e, params.value)}
                                  >
                                    Não
                                  </Button>
                                </Box>
                              </Box>,
                              {
                                autoClose: 5000,
                                closeOnClick: false,
                              }
                            );
                          } else {
                            // Regular single PDF opening
                            handleOpenPdfModal(e, params.value);
                          }
                        });
                      }}
                    >
                      {exibicao?.exibeCampoUrl 
                        ? exibicao?.exibeCampoUrl.replace("Baixar", "Visualizar") 
                        : 'Acessar Url'}
                    </a>
                  );
              case 'Email':
                return <a href={`mailto:${params.value}`}>{params.value}</a>
            }

            let sorted = exibicao.exibicao.sort((a, b) => {
              if (a.ordem < b.ordem) {
                return -1
              }
              if (a.ordem > b.ordem) {
                return 1
              }
              return 0
            })

            for (const rule of sorted) {
              rule.valor = rule.valor.trim()

              if (typeof params.value == 'string') {
                params.value = (params.value as string).trim()
              }

              if (
                (rule.operador == 'Valor nulo' && (params.value == '' || params.value == 'N/A')) ||
                (rule.operador == 'Valor igual a' &&
                  (params.value == rule.valor ||
                    params.formattedValue == rule.valor ||
                    (item.tipo == 'boolean' &&
                      ((rule.valor == 'Verdadeiro' && params.value == true) || (rule.valor == 'Falso' && params.value == false))))) ||
                (rule.operador == 'Valor diferente de' &&
                  (params.value != rule.valor ||
                    (item.tipo == 'boolean' &&
                      ((rule.valor == 'Verdadeiro' && params.value == false) || (rule.valor == 'Falso' && params.value == true))))) ||
                (rule.operador == 'Valor contém' && params.value.includes(rule.valor)) ||
                (rule.operador == 'Valor não contém' && !params.value.includes(rule.valor)) ||
                (rule.operador == 'Valor maior que' && params.value > rule.valor) ||
                (rule.operador == 'Valor menor que' && params.value < rule.valor) ||
                (rule.operador == 'Valor maior ou igual a' && params.value >= rule.valor) ||
                (rule.operador == 'Valor menor ou igual a' && params.value <= rule.valor) ||
                (rule.operador == 'Valor entre' && params.value >= rule.valor && params.value <= rule.extra)
              ) {
                switch (rule.acao) {
                  case 'Exibir badge':
                  case 'Alterar a cor do texto':
                    return <span className={rule.cor}>{value}</span>
                  case 'Exibir ícone':
                    return (
                      <>
                        <i className={rule.icone} aria-hidden='true' title={params.value}></i>
                      </>
                    )
                }
              }
            }

            if (item.tipo == 'boolean') {
              switch (params.value) {
                case true:
                  return <i title={params.formattedValue} className='fa fa-check'></i>
                case false:
                  return <i title={params.formattedValue} className='fa fa-x'></i>
              }
            }

            return <>{value}</>
          }
        } else {
          if (item.tipo == 'decimal') {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              if (params.value == null) {
                return params.value
              }

              let formated = (params.value as number).toLocaleString(intl.formatMessage({id: 'NUMBER.FORMAT'}))
              return <>{formated}</>
            }
          } else if (item.tipo == 'geocode') {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              if (params.value == null) {
                return params.value
              }

              let formated = (params.value as number).toFixed(6)
              return <>{formated}</>
            }
          } else if (item.tipo == 'map') {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              if (params.value == null || typeof params.value !== 'string') {
                return params.value
              }

              let isJson = (params.value as string).startsWith('{') && (params.value as string).endsWith('}')

              let formated = isJson ? JSON.parse(params.value).address : params.value
              return <>{formated}</>
            }
          }
        }
      }

      if ((AccessCodeInherited == 'SYSMAP' || AccessCodeInherited == 'SYSMAPVISOES') && item.campo == 'Icone') {
        cx.renderCell = (params: GridRenderCellParams<any>) => {
          let value = <>{params.value}</>

          if (params.value == null || params.value == '' || params.value == 'N/A') {
            return value
          }

          return (
            <>
              <i className={params.value} aria-hidden='true' title={params.value}></i>
            </>
          )
        }
      }

      if ((AccessCodeInherited == 'SYSMEN' || AccessCodeInherited == 'SYSMENSUB') && item.campo == 'Icone') {
        cx.renderCell = (params: GridRenderCellParams<any>) => {
          let value = <>{params.value}</>

          if (params.value == null || params.value == '' || params.value == 'N/A') {
            return value
          }

          return (
            <>
              <img src={toAbsoluteUrl('/media/icons/duotune' + params.value)} className='h-15px' />
            </>
          )
        }
      }

      if (
        (AccessCodeInherited == 'ZPLUGPESSOATEL' && item.campo == 'Numero') ||
        (item.tipo == 'special' && usaExibicao && exibicao.formatacao == 'Telefone')
      ) {
        cx.renderCell = (params: GridRenderCellParams<any>) => {
          if (params.value == null || params.value == '') return <></>

          return <WhatsAppRedirectToolTip number={params.value} />
        }
      }

      switch (item.fixar) {
        case 'left':
          left.push(item.campo)
          break
        case 'right':
          right.push(item.campo)
          break
      }

      if (item.tipo == 'singleSelect') {
        if (IsSystemAccessCode()) {
          await apiWrapper
            .get(`/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${AccessCodeInherited}&item.Campo=${item.campo}`)
            .then((response) => {
              let options: any[] = []

              for (const obj of response.data.data) {
                options.push({value: obj.id, label: obj.label})
              }

              cx.valueOptions = options
            })

          if (cx.renderCell == null) {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              return <>{params.formattedValue}</>
            }
          }
        }

        cx.renderEditCell = renderSingleSelectEditInputCell

        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return IsSystemAccessCode() ? 'N/A' : null
          } else {
            return value
          }
        }
      } else if (item.tipo == 'date' || item.tipo == 'dateTime') {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return null
          } else {
            return new Date(value)
          }
        }

        cx.renderCell = (params: GridRenderCellParams<any>) => {
          if (params.value == null) {
            return params.value
          }

          return <>{FormatDate(new Date(params.value), locale, item.tipo == 'dateTime')}</>
        }
      } else if (item.tipo == 'boolean') {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return false
          } else {
            return value
          }
        }
      } else if (item.tipo == 'number' || item.tipo == 'decimal' || item.tipo == 'geocode') {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return 'N/A'
          } else {
            return value
          }
        }
      } else {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return null
          } else {
            return value
          }
        }
      }

      colunas.push(cx)
      colunasParaOcultar += '"' + item.campo + '"' + ':' + (item.exibirGrid ? 'true' : 'false') + ', '
    }

    colunasOcultas = JSON.parse('{ ' + colunasParaOcultar.substring(0, colunasParaOcultar.length - 2) + ' }')

    if (!readOnly && !IsSystemAccessCode() && permissionsRequest.data.data.biManage) {
      colunas.push({
        field: 'addColumn',
        type: 'actions',
        editable: false,
        hideable: false,
        pinable: false,
        width: 75,
        getActions: (params: any) => [],
        renderHeader: (params: GridColumnHeaderParams) => (
          <IconButton aria-label='add' onClick={() => SYSMAFExternal(AccessCodeInherited, 0)}>
            <AddIcon fontSize='small' />
          </IconButton>
        ),
      })
    }

    if (isPrimary && historico != null && historico.grid != null && historico.grid.columns != null && historico.grid.columns.length > 0) {
      let reordered: Array<any> = []

      for (const item of historico.grid.columns) {
        let column = colunas.find((x) => x.field == item)

        if (column != null) {
          reordered.push(column)
        }
      }

      setColumns(reordered.concat(colunas.filter((x) => reordered.find((y) => x.field == y.field) == null)))
    } else {
      setColumns(colunas)
    }

    setInvisibleColumns(colunasOcultas)

    let colunasFixadas: GridPinnedColumns = {
      left: ['__check__', 'actions'].concat(left),
      right: right,
    }

    //TODO: Identificar o porque o apiRef esta vindo nulo em alguns casos de re-render
    if (apiRef.current != null) {
      apiRef.current.setPinnedColumns(colunasFixadas)

      let columns: any = apiRef.current.getAllColumns()
      if (columns[0] != null) {
        columns[0].hideable = false
      }
    }

    let queryString = window.location.search
    let params = new URLSearchParams(queryString)
    let urlFiltro = params.get('f')

    if (urlFiltro != null) {
      // O replace serve para limpar espaços não tratados pela função "btoa"
      // TODO: Verificar se isto esta resolvido na nova função não depreciada de codificação base64
      let obj = JSON.parse(atob(urlFiltro.replaceAll(' ', '+')))

      let urlFilterObject = {
        items: [
          {
            field: obj.columnField,
            operator: obj.operatorValue,
            value: obj.value[0],
          },
        ],
        logicOperator: 'and',
        quickFilterValues: [],
        quickFilterLogicOperator: 'and',
      }

      onFilterChange(urlFilterObject as any, true)
      return
    }

    if (!IsSystemAccessCode() && isPrimary && historico != null && historico.grid != null) {
      if (historico.grid.filter != null) {
        onFilterChange(historico.grid.filter as any, true)
      } else {
        setGridFilter(undefined)
      }
      if (historico.grid.order != null) {
        onOrderChange(historico.grid.order as any, true)
      } else {
        setGridSort(undefined)
      }

      if (historico.grid.filter == null && historico.grid.order == null) {
        setTimeout(() => {
          GetData(true, AccessCodeInherited == 'ZPLUGMAILBOX')
        }, 1)
      }
    } else {
      setGridFilter(undefined)
      setGridSort(undefined)

      setTimeout(() => {
        GetData(true, AccessCodeInherited == 'ZPLUGMAILBOX')
      }, 1)
    }

    //setLoading(false)
  }

  const GetData = async (useLoading?: boolean, fetchZPLUGMAIL?: boolean, _filtro?: string) => {
    if (AccessCodeMaster == '' || AccessCodeInherited == '') {
      return
    }

    if (useLoading) setLoading(true)
    if (fetchZPLUGMAIL) await fetchZPLUGMAILBOX()

    let request = {
      item: {
        AccessCode: AccessCodeInherited,
        SysParam: SYSParam,
        IDMenuAppGenericItem: IDMenuAppGenericItem,
        UseRelationFilter: requestProps?.useRelationFilters,
        IDMenuAppRelation: requestProps?.idMenuAppRelation,
        IDRelationItem: requestProps?.idRelationItem,
        DescricoesAoInvesDeIDs: IsSystemAccessCode() ? false : true,
        AppFactoryType: parseInt(appMode),
        Filters: requestFilters.concat(filters ?? []),
        Orders: requestOrders,
        Busca: _filtro ?? filtro,
      },
      PaginaAtual: paginationModel.page,
      RegistroPorPagina: paginationModel.pageSize,
    }

    apiWrapper
      .post('/api/v1/Dynamic/listarComFiltros', request)
      .then((response) => {
        setRowsCount(response.data.totalRegistros)
        setRows(response.data.data)
      })
      .catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })
      .finally(() => {
        if (useLoading) setLoading(false)

        if (getDataCallBack != null) {
          getDataCallBack()
        }
      })
  }

  const handleOnKeyDown = (params, event, details) => {
    if (event.key == 'F3' && !filterPanelOpened && isPrimary) {
      event.preventDefault()
      setFilterModelOpened(true)
      apiRef.current.showFilterPanel()
    }
  }

  const handleCellDoubleClick = (params, event, details) => {
    if (permissions != null && (permissions.biSelect || permissions.biUpdate)) {
      if (AccessCodeInherited == 'ZPLUGMAILBOX') {
        HTMLViewerOpen(params.row)
      } else {
        chooseModalToOpen(AccessCodeInherited, params.id)
      }
    }
  }

  const handleOnChangeAppMode = (event) => {
    setAppMode(event.target.value)
    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })
  }

  const handleClickOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCM(event.currentTarget)
  }

  const onMouseEnterRow = (event) => {
    const id = event.currentTarget.dataset.id
    setAnchorEl(event.currentTarget)
  }

  const onMouseLeaveRow = (event) => {
    if (anchorEl == null || (popperRefObject?.current ?? false)) return
    setAnchorEl(null)
  }

  const onFilterChange = (filterModel?: GridFilterModel, skipSave?: boolean) => {
    if (filterModel == null || (menuAppHistory == null && skipSave == null) || loading) {
      return
    }

    setGridFilter(filterModel)

    setMenuAppHistory((prev) => {
      let historico =
        prev.historico != null && prev.historico != '' ? (JSON.parse(prev.historico) as CMenuAppHistoryStructure) : new CMenuAppHistoryStructure()

      if (historico.grid == null) {
        historico.grid = {filter: undefined, order: undefined, columns: []}
      }

      historico.grid.filter = filterModel
      prev.historico = JSON.stringify(historico)

      if (skipSave != true) {
        apiWrapper.put('api/v1/MenuAppHistory/salvar', {item: {AccessCode: AccessCodeInherited, MenuAppHistory: prev}})
      }

      return {...prev}
    })

    let newFilters: Array<any> = []

    for (const item of filterModel.items) {
      if (item.value == null || item.value == '') {
        continue
      }

      let obj: any[] = []
      obj[0] = item.value.toString()

      newFilters.push({
        columnField: item.field,
        operatorValue: item.operator != null ? item.operator : 'INVALID',
        value: obj,
      })
    }

    setRequestFilters(newFilters)

    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })
  }

  const onOrderChange = (sortModel: GridSortModel, skipSave?: boolean) => {
    if (sortModel == null || (menuAppHistory == null && skipSave == null) || loading) {
      return
    }

    setGridSort(sortModel)

    setMenuAppHistory((prev) => {
      let historico =
        prev.historico != null && prev.historico != '' ? (JSON.parse(prev.historico) as CMenuAppHistoryStructure) : new CMenuAppHistoryStructure()

      if (historico.grid == null) {
        historico.grid = {filter: undefined, order: undefined, columns: []}
      }

      historico.grid.order = sortModel
      prev.historico = JSON.stringify(historico)

      if (skipSave != true) {
        apiWrapper.put('api/v1/MenuAppHistory/salvar', {item: {AccessCode: AccessCodeInherited, MenuAppHistory: prev}})
      }

      return {...prev}
    })

    let newOrders: Array<any> = []

    for (const item of sortModel) {
      newOrders.push(item)
    }

    setRequestOrders(newOrders)

    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })
  }

  const onVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    if (IsSystemAccessCode()) {
      return
    }

    let hiddenFields: any = []
    let visibleFields: any = []

    for (let key in newModel) {
      if (newModel[key] === false) {
        hiddenFields.push(key)
      }

      if (newModel[key] === true) {
        visibleFields.push(key)
      }
    }

    let obj: any = {
      item: {
        AccessCode: AccessCodeInherited,
        HiddenFields: hiddenFields,
        VisibleFields: visibleFields,
      },
    }

    apiWrapper.post('/api/v1/Dynamic/salvarExibicaoGrid', obj, {}).then((response) => {
      setInvisibleColumns((col) => newModel)
    })
  }

  const onSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    if (newSelectionModel.length > 0 && !isProtectedItemSelected(newSelectionModel)) {
      BuildControls(true)
    } else {
      BuildControls()
    }

    gridSelection = newSelectionModel as Array<number>
    setSelectionModel(newSelectionModel)
    setModalToDeleteItemsExcluir(newSelectionModel)
    setModalTarefaIDs(newSelectionModel.map((x) => x as number))
  }

  const onColumnHeaderDragEnd = (params: GridColumnOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails) => {
    let order: Array<string> = []

    for (const item of apiRef.current.getAllColumns()) {
      order.push(item.field)
    }

    setMenuAppHistory((prev) => {
      let historico =
        prev.historico != null && prev.historico != '' ? (JSON.parse(prev.historico) as CMenuAppHistoryStructure) : new CMenuAppHistoryStructure()

      if (historico.grid == null) {
        historico.grid = {filter: undefined, order: undefined, columns: []}
      }

      historico.grid.columns = order
      prev.historico = JSON.stringify(historico)

      apiWrapper.put('api/v1/MenuAppHistory/salvar', {item: {AccessCode: AccessCodeInherited, MenuAppHistory: prev}})

      return {...prev}
    })
  }

  const handleClearFilters = () => {
    setFiltro('')
    onFilterChange({items: []})
  }

  //* ROTINAS
  const Exportar = async () => {
    let request = {
      item: {
        AccessCode: AccessCodeInherited,
        SysParam: SYSParam,
        DescricoesAoInvesDeIDs: true,
        Filters: requestFilters,
        Orders: requestOrders,
        Busca: filtro,
      },
      PaginaAtual: 0,
      RegistroPorPagina: 999999,
    }

    let fields = await apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${AccessCodeInherited}`)
    let list = await apiWrapper.post('api/v1/Dynamic/listarComFiltros', request, {})

    let newArray: Array<any> = []

    for (const item of list.data.data) {
      let newEntry: any = {}

      for (const [key, value] of Object.entries(item)) {
        if (key == 'RowNum' || key == 'TotalRecord') {
          continue
        }

        let mappedField = fields.data.data.find((x) => x.campo == key)

        if (mappedField != null) {
          newEntry[mappedField.label] = value
        }
      }

      newArray.push(newEntry)
    }

    const worksheet = utils.json_to_sheet(newArray)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Dados')
    writeFileXLSX(workbook, `${menuApp.titulo} - Export - ${new Date().toLocaleDateString()}.xlsx`)

    //SALVAR LOG
    apiWrapper.put('api/v1/Dynamic/salvarLogImportacaoExportacao', {
      item: {
        AccessCode: AccessCodeInherited,
        Tipo: 'EXPORTACAO',
        TotalRegistros: list.data.data.length,
      },
    })
  }

  const ExportarPlanilhaConectada = async () => {
    if (rowsCount > 100000) {
      toast.error(
        'A exportação de planilhas conectadas está limitada a 50.000 registros, considere o uso de filtros no cabeçalho do grid para exportar uma quantidade menor de registros.'
      )
      return
    }

    let passwordRequest = await apiWrapper.get(`api/v1/User/verificarSenha?item.IDPessoa=${loggedUser?.id}`)
    if (!passwordRequest.data.data) {
      setModalPassword(true)
      return
    }

    let obj = {
      AccessCode: AccessCodeInherited,
      SysParam: SYSParam,
      Filters: requestFilters.concat(filters ?? []),
      RegistrosPorPagina: 50000,
      PaginaAtual: 0,
    }

    let requestParams = btoa(JSON.stringify(obj))
    let fileText = `WEB\n1\n${process.env.REACT_APP_BST_API_URL}/api/v1/Dynamic/Export?q=${requestParams}`

    DownloadLogico('Planilha de Dados.iqy', fileText)
  }

  const Duplicar = async (id: string) => {
    handleOpenModalPerformingAction('Realizando duplicação de registro, aguarde...')

    let duplicarRequest = await apiWrapper.post('api/v1/Dynamic/duplicarRegistro', {
      item: {AccessCode: AccessCodeMaster, ID: id},
    })

    handleCloseModalPerformingAction()

    if (duplicarRequest.data.mensagem != 'SUCESSO') {
      toast.error(duplicarRequest.data.mensagem)
      return
    }

    toast.success('Registro duplicado com sucesso!')
    GetData(true, false, filtro)

    setModalDynamicItemID(duplicarRequest.data.id)
    setModalSYSMAFItemID(duplicarRequest.data.id)
    setModalSYSMAPItemID(duplicarRequest.data.id)
    setModalSYSMARItemID(duplicarRequest.data.id)
    setModalSYSTEMPLATEItemID(duplicarRequest.data.id)

    setTimeout(() => {
      chooseModalToOpen(AccessCodeMaster, duplicarRequest.data.id)
    }, 100)
  }

  const remapFields = () => {
    apiWrapper
      .get(`api/v1/MenuAppFields/remapearCamposApp?item.AccessCode=${SYSParam}`)
      .then((response) => {
        if (response.data.mensagem == 'SUCESSO') {
          toast.success('Campos validados e remapeados com sucesso!')
          GetData(true)
        } else {
          toast.error(response.data.mensagem)
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })
  }

  const fetchZPLUGMAILBOX = () => {
    return apiWrapper.get(
      `api/v1/Email/fetchZPLUGMAILBOX?item.AccessCode=${sysParam}&item.IDIntegration=${IDIntegration}&item.IDMenuAppGenericItem=${IDMenuAppGenericItem}`
    )
  }

  const getEnderecoMailBox = (integrationUsername: string) => {
    let split = integrationUsername.split('@')
    return `${split[0]}+id${IDMenuAppGenericItem}@${split[1]}`
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    toast.success('Copiado para a área de transferência!')
  }

  //* MODALS
  const chooseModalToOpen = (accessCode: string | undefined, id: string) => {
    switch (accessCode) {
      case 'SYSMAF':
        setModalSYSMAF(true)
        setModalSYSMAFItemID(parseInt(id))
        setModalSYSMAFAccessCode(SYSParam)
        break
      case 'SYSMAP':
      case 'SYSMAPVISOES':
        setModalSYSMAP(true)
        setModalSYSMAPItemID(parseInt(id))
        break
      case 'SYSMAR':
        setModalSYSMAR(true)
        setModalSYSMARItemID(parseInt(id))
        break
      case 'SYSMAA':
        setModalSYSMAA(true)
        setModalSYSMAAItemID(parseInt(id))
        break
      case 'SYSTEMPLATE':
        setModalSYSTEMPLATE(true)
        setModalSYSTEMPLATEItemID(parseInt(id))
        break
      case 'SYSACT':
        setModalSYSACT(true)
        setModalSYSACTItemID(parseInt(id))
        break
      case 'SYSSCHEDULE':
        setModalSYSCHEDULE(true)
        setModalSYSSCHEDULEItemID(parseInt(id))
        break
      default:
        setModalDynamic(true)
        setModalDynamicItemID(parseInt(id))
        break
    }
  }

  const chooseModalToClose = () => {
    if (modalDynamic) {
      setModalDynamic(false)
      setModalDynamicItemID(0)
    }

    if (modalSYSMAF) {
      setModalSYSMAF(false)
      setModalSYSMAFItemID(0)
    }

    if (modalSYSMAP) {
      setModalSYSMAP(false)
      setModalSYSMAPItemID(0)
    }

    if (modalSYSMAR) {
      setModalSYSMAR(false)
      setModalSYSMARItemID(0)
    }

    if (modalSYSMAA) {
      setModalSYSMAA(false)
      setModalSYSMAAItemID(0)
    }

    if (modalSYSTEMPLATE) {
      setModalSYSTEMPLATE(false)
      setModalSYSTEMPLATEItemID(0)
    }

    if (modalSYSACT) {
      setModalDynamic(false)
      setModalDynamicItemID(0)
    }

    if (modalSYSSCHEDULE) {
      setModalSYSCHEDULE(false)
      setModalSYSSCHEDULEItemID(0)
    }
  }

  // TAREFA APROVAÇÃO/REPROVAÇÃO

  const [modalTarefa, setModalTarefa] = useState<boolean>(false)
  const [modalTarefaIDs, setModalTarefaIDs] = useState<Array<number>>([])

  const handleOpenModalTarefa = () => {
    setModalTarefa(true)
  }

  const handleCloseModalTarefa = () => {
    setModalTarefa(false)
    setModalTarefaIDs([])
  }

  const handleModalTarefaCallback = (response: any = null) => {
    if (response?.refresh) {
      GetData()
    }

    handleCloseModalTarefa()
  }

  // TELEFONE

  const [modalTelefone, setModalTelefone] = useState<boolean>(false)
  const [modalTelefoneHTMLFragment, setModalTelefoneHTMLFragment] = useState<JSX.Element>(<></>)

  const handleOpenTelefone = (telefone: string) => {
    let formatted = telefone.replaceAll('+', '').replaceAll(' ', '')

    setModalTelefone(true)
    setModalTelefoneHTMLFragment(
      <Box component='div' className='d-flex flex-column'>
        <Box component='div' className='p-1'>
          <a href={`https://api.whatsapp.com/send?phone=${formatted}`} target='_blank'>
            <WhatsAppIcon />
            {telefone}
          </a>
        </Box>
        <Box component='div' className='p-1'>
          <a href={`tel://+${formatted}`} target='_blank'>
            <CallIcon />
            {telefone}
          </a>
        </Box>
      </Box>
    )
  }

  const handleCloseTelefone = () => {
    setModalTelefone(false)
    setModalTelefoneHTMLFragment(<></>)
  }

  // EXCLUSÃO

  const normalise = (value, min, max) => ((value - min) * 100) / (max - min)

  const [exclusaoProgresso, setExclusaoProgresso] = useState<number>(0)

  const [modalAccessCodeExcluir, setModalAccessCodeExcluir] = useState<string | undefined>('')
  const [modalToDeleteItemsExcluir, setModalToDeleteItemsExcluir] = useState<GridRowSelectionModel>([])
  const [modalOpenedExcluir, setModalOpenedExcluir] = useState<boolean>(false)
  const [modalDataIDExcluir, setModalDataIDExcluir] = useState<number>(0)

  const [showExclusaoWait, setShowExclusaoWait] = useState<boolean>(false)

  const handleOpenExcluirModal = (id: number, accessCode?: string) => {
    setModalDataIDExcluir(id)
    setModalAccessCodeExcluir(accessCode)
    setModalOpenedExcluir(true)
    setExclusaoProgresso(0)
  }

  const handleCloseExcluir = async (excluir: boolean) => {
    setModalOpenedExcluir(false)

    if (!excluir) {
      return
    }

    chooseModalToClose()

    let items: Array<number> = []
    let index: number = 0
    let errorCount: number = 0

    if (modalToDeleteItemsExcluir.length > 0) {
      items = modalToDeleteItemsExcluir.map((item, index) => {
        return item as number
      })
    } else {
      items = [modalDataIDExcluir]
    }

    setShowExclusaoWait(true)

    for (let id of items) {
      let url = ''
      let obj: any = {
        item: {
          ID: id,
        },
      }

      if (modalAccessCodeExcluir == 'SYSMAP' || modalAccessCodeExcluir == 'SYSMAPVISOES') {
        url = 'api/v1/MenuApp/excluir'
      } else if (modalAccessCodeExcluir == 'SYSMAR') {
        url = 'api/v1/MenuAppRelacao/excluir'
      } else {
        obj.item.AccessCode = modalAccessCodeExcluir
        url = 'api/v1/Dynamic/excluir'
      }

      let ret = await apiWrapper.delete(url, {data: obj, headers: {}})
      index++

      setExclusaoProgresso(normalise(index, 0, items.length))

      if (url == 'api/v1/Dynamic/excluir') {
        for (const item of ret.data.data) {
          if (item.item2 != 'SUCESSO') {
            toast.error(`Registro ${item.item1}: ${item.item2}`)
            errorCount++
          }
        }
      } else {
        if (ret.data.mensagem == 'ERRO') {
          toast.error('Não é possível excluir o registro devido ao mesmo estar sendo usado em outras partes do sistema!')
          errorCount++
        }
      }

      if (index == items.length) {
        setShowExclusaoWait(false)
        GetData(true)

        if (errorCount > 0) {
          if (errorCount < items.length) {
            toast.warning('Exclusão realizada parcialmente.')
          }
        } else {
          toast.success('Exclusão realizada com sucesso!')
        }
      }
    }

    chooseModalToClose()
  }

  // DYNAMIC

  const [modalDynamic, setModalDynamic] = useState<boolean>(false)
  const [modalDynamicItemID, setModalDynamicItemID] = useState<number>(0)
  const [modalDynamicItemIDs, setModalDynamicItemIDs] = useState<Array<number>>([])
  const [modalDynamicAcaoEmMassa, setModalDynamicAcaoEmMassa] = useState<boolean>(false)

  const handleOpenModalDynamic = (id: string, acaoEmMassa?: boolean) => {
    setModalDynamic(true)
    setModalDynamicItemID(parseInt(id))
    setModalDynamicItemIDs(gridSelection)
    setModalDynamicAcaoEmMassa(acaoEmMassa ?? false)
  }

  const dynamicCallBack = (response: any = null) => {

    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalDynamicItemID(response.id)

        if (response.customPage) {
          setModalDynamic(false)

          setTimeout(() => {
            setModalDynamic(true)
          }, 1)
        }

        return
      }

      if (response.reopenAsEmailSender) {
        //TODO: Abrir o HTMLSend com os dados do grid como no fluxo padrão de seleção
        //return
      }
    }

    setModalDynamic(false)
    setModalDynamicItemID(0)
    setModalDynamicAcaoEmMassa(false)
  }

  // DYNAMIC READ

  const [modalDynamicRead, setModalDynamicRead] = useState<boolean>(false)
  const [modalDynamicReadAccessCode, setModalDynamicReadAccessCode] = useState<string>('')
  const [modalDynamicReadItemID, setModalDynamicReadItemID] = useState<number>(0)

  const handleOpenDynamicRead = (ac: string, id: number) => {
    setModalDynamicRead(true)
    setModalDynamicReadAccessCode(ac)
    setModalDynamicReadItemID(id)
  }

  const dynamicReadCallBack = (response: any = null) => {
    window['openModalDynamicRead'] = (ac: string, id: number) => {
      handleOpenDynamicRead(ac, id)
    }

    setModalDynamicRead(false)
  }

  // SYSMAF

  const [modalSYSMAF, setModalSYSMAF] = useState<boolean>(false)
  const [modalSYSMAFItemID, setModalSYSMAFItemID] = useState<number>(0)
  const [modalSYSMAFAccessCode, setModalSYSMAFAccessCode] = useState<string | undefined>('')

  const SYSMAFCallBack = (response: any = null) => {
    if (response != null) {
      if (AccessCodeInherited != 'SYSMAF') {
        StartGrid()
      } else {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSMAFItemID(response.id)
        return
      }
    }

    setModalSYSMAF(false)
    setModalSYSMAFItemID(0)
  }

  const SYSMAFExternal = (accessCode: string | undefined, id: number) => {
    setModalSYSMAF(true)
    setModalSYSMAFItemID(id)
    setModalSYSMAFAccessCode(accessCode)
  }

  // SYSMAP

  const [modalSYSMAP, setModalSYSMAP] = useState<boolean>(false)
  const [modalSYSMAPItemID, setModalSYSMAPItemID] = useState<number>(0)

  const SYSMAPCallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSMAPItemID(response.id)
        return
      }
    }

    setModalSYSMAP(false)
    setModalSYSMAPItemID(0)
  }

  // SYSMAR

  const [modalSYSMAR, setModalSYSMAR] = useState<boolean>(false)
  const [modalSYSMARItemID, setModalSYSMARItemID] = useState<number>(0)

  const SYSMARCallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSMARItemID(response.id)
        return
      }
    }

    setModalSYSMAR(false)
    setModalSYSMARItemID(0)
  }

  // SYSMAA

  const [modalSYSMAA, setModalSYSMAA] = useState<boolean>(false)
  const [modalSYSMAAItemID, setModalSYSMAAItemID] = useState<number>(0)

  const SYSMAACallBack = (response: any = null) => {
    setModalSYSMAA(false)
    setModalSYSMAAItemID(0)
  }

  // SYSTEMPLATE

  const [modalSYSTEMPLATE, setModalSYSTEMPLATE] = useState<boolean>(false)
  const [modalSYSTEMPLATEItemID, setModalSYSTEMPLATEItemID] = useState<number>(0)

  const SYSTEMPLATECallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSTEMPLATEItemID(response.id)
        return
      }
    }

    setModalSYSTEMPLATE(false)
    setModalSYSTEMPLATEItemID(0)
  }

  /* SYSACT */

  const [modalSYSACT, setModalSYSACT] = useState<boolean>(false)
  const [modalSYSACTItemID, setModalSYSACTItemID] = useState<number>(0)

  const handleModalSYSACTCallBack = (response: any) => {
    if (response?.save) {
      GetData(true)
    }

    setModalSYSACT(false)
    setModalSYSACTItemID(0)
  }

  /* SYSSCHEDULE */

  const [modalSYSSCHEDULE, setModalSYSCHEDULE] = useState<boolean>(false)
  const [modalSYSSCHEDULEItemID, setModalSYSSCHEDULEItemID] = useState<number>(0)

  const handleModalSYSSCHEDULECallBack = (response: any) => {
    GetData(true)

    setModalSYSCHEDULE(false)
    setModalSYSSCHEDULEItemID(0)
  }

  // ENVIO HTML

  const [modalHTMLSend, setModalHTMLSend] = useState<boolean>(false)
  const [modalHTMLSendAccessCode, setModalHTMLSendAccessCode] = useState<string>(SYSParam as string)
  const [modalHTMLSendIDIntegration, setModalHTMLSendIDIntegration] = useState<number>(IDIntegration as number)
  const [modalHTMLSendId, setModalHTMLSendId] = useState<number>(0)
  const [modalHTMLSendIdMenuAppGenericItem, setModalHTMLSendIdMenuAppGenericItem] = useState<number>(0)
  const [modalHTMLSendTitle, setModalHTMLSendTitle] = useState<string>('')
  const [modalHTMLSendTo, setModalHTMLSendTo] = useState<string>('')
  const [modalHTMLSendCc, setModalHTMLSendCc] = useState<string>('')
  const [modalHTMLSendSubject, setModalHTMLSendSubject] = useState<string>('')
  const [modalHTMLSendContent, setModalHTMLSendContent] = useState<string>('')

  const HTMLSendCallBack = (response: any = null) => {
    if (response != null) {
      if (response.reload) {
        GetData(true, true)
      }

      if (response.keepOpen) {
        return
      }
    }

    setModalHTMLSend(false)
    setModalHTMLSendId(0)
    setModalHTMLSendTitle('')
    setModalHTMLSendTo('')
    setModalHTMLSendCc('')
    setModalHTMLSendSubject('')
    setModalHTMLSendIdMenuAppGenericItem(0)
  }

  const HTMLSendOpen = (id: number, title: string, to?: string, cc?: string, subject?: string, content?: string) => {
    setModalHTMLSend(true)
    setModalHTMLSendId(id)
    setModalHTMLSendTitle(title)
    setModalHTMLSendTo(to ?? '')
    setModalHTMLSendCc(cc ?? '')
    setModalHTMLSendSubject(subject ?? '')
    setModalHTMLSendContent(content ?? '')
  }

  const HTMLSendOpenWithSelectedItemAsContent = (items: Array<number>) => {
    let content: string = '<table border="1" style="border-collapse: collapse; width: 100%;">'
    content += '<thead>'

    //Recuperamos as colunas ocultas do grid
    let hiddenColumns = Object.keys(invisibleColumns).filter((x) => invisibleColumns[x] == false)

    //Montamos o cabeçalho da tabela
    for (const column of columns) {
      if (hiddenColumns.includes(column.field) || column.field == 'actions' || column.field == 'addColumn') {
        continue
      }

      content += `<th>${column.headerName}</th>`
    }

    content += '</thead>'
    content += '<tbody>'

    //Montamos o corpo da tabela
    for (const item of items) {
      content += '<tr>'

      for (const column of columns) {
        if (hiddenColumns.includes(column.field) || column.field == 'actions' || column.field == 'addColumn') {
          continue
        }

        //Recuperamos o valor do item formatado pelo grid
        let value = apiRef.current.getCellValue(item, column.field)

        //Formatamos o valor em caso de data ou booleano
        if ((column.type == 'date' || column.type == 'dateTime') && value != null && value != '') {
          value = new Date(value).toLocaleDateString()
        } else if (column.type == 'boolean') {
          value = value ? 'Sim' : 'Não'
        }

        content += `<td>${value}</td>`
      }

      content += '</tr>'
    }

    content += '</tbody>'
    content += '</table>'

    setModalHTMLSendAccessCode(AccessCodeInherited)
    setModalHTMLSendIDIntegration(0)
    setModalHTMLSendIdMenuAppGenericItem(items.length == 1 ? items[0] : 0)

    HTMLSendOpen(0, 'Enviar Email', '', '', 'Assunto', content)
  }

  // VISUALIZAÇÃO HTML

  const [modalHTMLViewer, setModalHTMLViewer] = useState<boolean>(false)
  const [modalHTMLViewerContent, setModalHTMLViewerContent] = useState<string>('')

  const HTMLViewerCallBack = (response: any = null) => {
    if (response.action != null) {
      switch (response.action) {
        case 'RES':
          HTMLSendOpen(response.id, 'Responder', response.content.De, undefined, `Res: ${response.content.Nome}`)
          break
        case 'RESALL':
          HTMLSendOpen(response.id, 'Responder a Todos', undefined, `${response.content.De}, ${response.content.Cc}`, `Res: ${response.content.Nome}`)
          break
        case 'ENC':
          HTMLSendOpen(response.id, 'Encaminhar', undefined, undefined, `Enc: ${response.content.Nome}`)
          break
        case 'RELOAD':
          GetData()
          return
      }
    }

    setModalHTMLViewer(false)
    setModalHTMLViewerContent('')
  }

  const HTMLViewerOpen = (content: string) => {
    setModalHTMLViewer(true)
    setModalHTMLViewerContent(content)
  }

  // IMPORTACAO

  const [modalImportacao, setModalImportacao] = useState<boolean>(false)

  const ImportacaoCallBack = (response: any = null) => {
    if (response != null) {
      if (response.refresh) {
        GetData(true)
      }
    }

    setModalImportacao(false)
  }

  // PASSWORD

  const [modalPassword, setModalPassword] = useState<boolean>(false)

  const PasswordCallback = (data: any) => {
    setModalPassword(false)

    if (data.save) {
      ExportarPlanilhaConectada()
    }
  }

  // PERFOMING ACTION

  // const [modalPerformingAction, setModalPerformingAction] = useState<boolean>(false)
  // const [modalPerformingActionMessage, setModalPerformingActionMessage] = useState<string>('')

  const handleOpenModalPerformingAction = (message: string) => {
    setModalPerformingAction(true)
    setModalPerformingActionMessage(message)
  }

  const handleCloseModalPerformingAction = () => {
    setModalPerformingAction(false)
    setModalPerformingActionMessage('')
  }

  // BUSCA

  let timeoutId: NodeJS.Timeout

  const handleOnChangeFiltro = (filtro: string) => {
    setFiltro(filtro)

    // Cancela o temporizador anterior
    clearTimeout(timeoutId)

    // Inicia um novo temporizador de 3 segundos
    timeoutId = setTimeout(() => {
      if (filtro.length > 0 && filtro.length < 3) {
        return
      }

      // Executa sua lógica após o atraso de 3 segundos

      if (paginationModel.page > 0) {
        setPaginationModel((prev) => {
          prev.page = 0
          return {...prev}
        })
      } else {
        GetData(true, undefined, filtro)
      }
    }, 1000)
  }

  //* USEEFFECTS
  useEffect(() => {
    VerifyAccessCode()
    handleLoadActions(AccessCode);
  }, [AccessCode])

  useEffect(() => {
    StartGrid()
  }, [AccessCodeMaster, AccessCodeInherited])

  useEffect(() => {
    if (columns.length == 0) {
      return
    }

    GetData(true, false, filtro)
  }, [paginationModel.page, appMode, requestFilters, requestOrders, filters])

  useEffect(() => {
    BuildControls()
  }, [userRoles, appMode])

  useEffect(() => {
    toast.dismiss()

    if (!isDisplayed) {
      return
    }

    if (!isPrimary) {
      GetData(true)
    }

    BuildControls()
  }, [isDisplayed])

  useEffect(() => {
    window['openModalDynamicRead'] = (ac: string, id: number) => {
      handleOpenDynamicRead(ac, id)
    }
  }, [])

  return (
    <>
      {displayHeader && (
        <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
          <Box component='div' className='page-title d-flex'>
            <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
              {title}
            </Box>
            {accessCode == 'ZPLUGMAILBOX' && IDMenuAppGenericItem != null && integration != null && (
              <>
                <Box component='span' className='h-40px border-gray-200 border-start mx-4'></Box>
                <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                  <Box component='li' className='breadcrumb-item'>
                    Email de comunicação:
                  </Box>
                  <Box component='li' className='breadcrumb-item text-muted'>
                    {getEnderecoMailBox(integration.username)}
                  </Box>
                  <Box component='li' className='breadcrumb-item'>
                    <IconButton onClick={() => copyToClipboard(getEnderecoMailBox(integration.username))}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                </Box>
              </>
            )}
            {subtitle != null && subtitle != '' && (
              <>
                <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                  <Box component='li' className='breadcrumb-item text-muted'>
                    {subtitle}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box component='div' className='d-flex align-items-center py-1'>
            {controls.map((control: any, index: number) => {
              return (
                <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}} key={`ctrl-grid-${index}`}>
                  {control}
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
      {isPrimary && !IsSystemAccessCode() && (
        <Box component='div' style={{position: 'absolute', top: '-72px', right: '1px'}} className='d-flex flex-stack' sx={{pt: 2, pr: 2}}>
          <Box component='div' className='page-title d-flex'></Box>
          <Box component='div' className='d-flex align-items-center py-1'>
            <StyledTextField
              sx={{width: 487}}
              //disabled={loading}
              size='small'
              label='Busca'
              value={filtro}
              onChange={(event) => handleOnChangeFiltro(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          height: !isPrimary ? '100%' : window.location.href.includes('_dynamicTabs') ? 'calc(100vh - 200px)' : 'calc(100vh - 130px)',
          width: '100%',
        }}
      >
        <StyledDataGrid
          sx={{
            display: permissions != null && permissions.biSelect ? '' : 'none',
            minHeight: window.location.href.includes('_dynamicTabs') ? 'calc(100vh - 200px)' : 'calc(100vh - 130px)',
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          apiRef={apiRef}
          components={{
            NoRowsOverlay: () => (
              <>
                <NoRowsOverlay
                  openModal={AccessCode != 'SYSMAA' && permissions != null && permissions.biInsert ? () => handleOpenModalDynamic('0') : undefined}
                />
              </>
            ),
            NoResultsOverlay: NoRowsOverlay,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            row: {
              onMouseEnter: onMouseEnterRow,
              onMouseLeave: onMouseLeaveRow,
            },
            filterPanel: {
              onKeyDown: (event) => {
                if (event.key == 'Escape' || event.key == 'F3') {
                  event.preventDefault()
                  apiRef.current.hideFilterPanel()
                }
              },
            },
          }}
          editMode={undefined} //'row'
          disableRowSelectionOnClick
          onPreferencePanelClose={(args) => {
            if (args.openedPanelValue == 'filters') {
              setFilterModelOpened(false)
            }
          }}
          onCellDoubleClick={handleCellDoubleClick}
          getRowId={(row: any) => row.ID}
          rows={rows && rows.length > 0 ? rows : []}
          columns={columns}
          checkboxSelection={AccessCodeInherited != 'SYSUSERAUDIT' && AccessCodeInherited != 'SYSMAA'}
          pagination
          rowHeight={38}
          columnHeaderHeight={38}
          columnVisibilityModel={invisibleColumns}
          onColumnVisibilityModelChange={(newModel) => {
            onVisibilityChange(newModel)
          }}
          paginationModel={paginationModel}
          pageSizeOptions={[25]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel)
            setSelectionModel([])
            BuildControls(false)
          }}
          rowCount={rowsCount}
          paginationMode='server'
          onRowSelectionModelChange={(newSelectionModel) => {
            onSelectionChange(newSelectionModel)
          }}
          filterMode='server'
          filterModel={gridFilter}
          onFilterModelChange={(newFilter, details) => {
            if (details == null) {
              return
            }

            onFilterChange(newFilter, IsSystemAccessCode() || !isPrimary)
          }}
          sortingMode='server'
          sortModel={gridSort}
          onSortModelChange={(newOrder, details) => {
            if (details == null) {
              return
            }

            onOrderChange(newOrder, IsSystemAccessCode())
          }}
          onColumnOrderChange={onColumnHeaderDragEnd}
          rowSelectionModel={selectionModel}
          loading={loading}
        />
        {permissions != null && !IsSystemAccessCode() && !permissions.biSelect && <BoxSVGMessage message='Usuário sem permissão de visualização' />}
      </Box>

      {/* MODAL TAREFA */}
      {modalTarefa && <ModalTarefa ids={modalTarefaIDs} callback={handleModalTarefaCallback} />}

      {/* MODAL TELEFONE */}
      {modalTelefone && (
        <StyledDialog open={modalTelefone}>
          <DialogTitle>Telefone(s)</DialogTitle>
          <DialogContent>{modalTelefoneHTMLFragment}</DialogContent>
          <DialogActions>
            <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleCloseTelefone()}>
              Fechar
            </Button>
          </DialogActions>
        </StyledDialog>
      )}

      {/* MODAL DYNAMIC */}
      {modalDynamic && (
        <DynamicModal
          accessCode={AccessCodeMaster}
          accessCodeMaster={AccessCodeMaster}
          id={modalDynamicItemID}
          ids={modalDynamicItemIDs}
          relationItem={relationItem}
          relationItemID={requestProps?.idRelationItem}
          acaoEmMassa={modalDynamicAcaoEmMassa}
          readonly={readOnly}
          callBack={dynamicCallBack}
          deleteFunc={handleOpenExcluir}
          actions={actions}
          setActions={setActions}
        />
      )}

      {/* MODAL DYNAMIC READ */}
      {modalDynamicRead && (
        <DynamicModal
          readonly={true}
          showClose={true}
          accessCode={modalDynamicReadAccessCode}
          id={modalDynamicReadItemID}
          callBack={dynamicReadCallBack}
        />
      )}

      {/* MODAL SYSMAF */}
      {modalSYSMAF && (
        <SYSMAFModal
          accessCode={modalSYSMAFAccessCode}
          id={modalSYSMAFItemID}
          disableEditType={readOnly}
          callBack={SYSMAFCallBack}
          deleteFunc={handleOpenExcluir}
        />
      )}

      {/* MODAL SYSMAP */}
      {modalSYSMAP && (
        <SYSMAPModal
          id={modalSYSMAPItemID}
          idmenuAppPai={IDMenuAppGenericItem != null ? parseInt(IDMenuAppGenericItem) : undefined}
          callBack={SYSMAPCallBack}
          deleteFunc={handleOpenExcluir}
        />
      )}

      {/* MODAL SYSMAR */}
      {modalSYSMAR && <SYSMARModal accessCode={SYSParam} id={modalSYSMARItemID} callBack={SYSMARCallBack} deleteFunc={handleOpenExcluir} />}

      {/* MODAL SYSMAA */}
      {modalSYSMAA && <SYSMAAModal id={modalSYSMAAItemID} callBack={SYSMAACallBack} />}

      {/* MODAL SYSTEMPLATE */}
      {modalSYSTEMPLATE && <SYSTEMPLATEModal id={modalSYSTEMPLATEItemID} callBack={SYSTEMPLATECallBack} deleteFunc={handleOpenExcluir} />}

      {/* MODAL SYSACT */}
      {modalSYSACT && <ModalSYSACT id={modalSYSACTItemID} accessCode={SYSParam as string} callBack={handleModalSYSACTCallBack} />}

      {/* MODAL SYSSCHEDULE */}
      {modalSYSSCHEDULE && <SYSCHEDULEModal id={modalSYSSCHEDULEItemID} callBack={handleModalSYSSCHEDULECallBack} />}

      {/* MODAL HTMLSEND */}
      {modalHTMLSend && (
        <HTMLSend
          id={modalHTMLSendId}
          accessCode={modalHTMLSendAccessCode}
          idIntegration={modalHTMLSendIDIntegration}
          idMenuAppGenericItem={IDMenuAppGenericItem != null ? IDMenuAppGenericItem : modalHTMLSendIdMenuAppGenericItem.toString()}
          callBack={HTMLSendCallBack}
          title={modalHTMLSendTitle}
          to={modalHTMLSendTo}
          cc={modalHTMLSendCc}
          subject={modalHTMLSendSubject}
          content={modalHTMLSendContent}
          maxWidth='xl'
        />
      )}

      {/* MODAL IMPORTACAO */}
      {modalImportacao && <ImportacaoModal accessCode={AccessCodeMaster} callBack={ImportacaoCallBack} />}

      {/* MODAL PASSWORD */}
      {modalPassword && (
        <PasswordModal
          idPessoa={loggedUser?.id ?? 0}
          content={
            <Box component='div' className='alert alert-warning d-flex align-items-center'>
              <div className='d-flex flex-column'>
                <span>
                  <strong>ATENÇÃO</strong>: É necessário ter uma senha cadastrada no sistema para acessar planilhas conectadas!
                </span>
                <br />
                <span>Defina uma senha que tenha no mínimo 6 caracteres.</span>
              </div>
            </Box>
          }
          callback={PasswordCallback}
        />
      )}

      {/* MODAL HTML */}
      {modalHTMLViewer && <HTMLViewer content={modalHTMLViewerContent} permissions={permissions} callBack={HTMLViewerCallBack} />}

      {/* MODAL EXCLUSÃO */}
      <ModalExclusao open={modalOpenedExcluir} title='Excluir' message='Esta ação não pode ser desfeita, confirma?' callBack={handleCloseExcluir} />

      {/* MODAL EXCLUSÃO PROGRESS */}
      {showExclusaoWait && <ModalPerformingAction message='Realizando exclusão...' progress={exclusaoProgresso} />}

      {/* MODAL PERFORMING ACTION */}
      {modalPerformingAction && <ModalPerformingAction message={modalPerformingActionMessage} />}

      <StyledMenu
        anchorEl={anchorElCM}
        open={contextMenuOpen}
        onClose={() => {
          setAnchorElCM(null)
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            GetData(true)
            setAnchorElCM(null)
          }}
        >
          <ListItemIcon>
            <RefreshIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Recarregar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClearFilters()
            setAnchorElCM(null)
          }}
        >
          <ListItemIcon>
            <FilterAltOffIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Limpar filtros</ListItemText>
        </MenuItem>
        {!readOnly && permissions != null && permissions.biInsert && !IsSystemAccessCode(true) && !IsPluginAccessCode() && (
          <MenuItem
            onClick={() => {
              setModalImportacao(true)
              setAnchorElCM(null)
            }}
          >
            <ListItemIcon>
              <FileUploadIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Importar</ListItemText>
          </MenuItem>
        )}
        {permissions != null && permissions.biSelect && !IsSystemAccessCode(true) && (
          <>
            <MenuItem
              onClick={() => {
                Exportar()
                setAnchorElCM(null)
              }}
            >
              <ListItemIcon>
                <FileDownloadIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>Exportar</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                ExportarPlanilhaConectada()
                setAnchorElCM(null)
              }}
            >
              <ListItemIcon>
                <GridOnIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText title='Geração de arquivo de leitura para consulta de dados do App.'>Gerar planilha de leitura</ListItemText>
            </MenuItem>
          </>
        )}
        {isPrimary && (!IsSystemAccessCode() || (AccessCodeInherited == 'SYSMAF' && SYSParam != null)) && (
          <MenuItem
            onClick={() => {
              setAnchorElCM(null)

              let cfgRoute = IsSystemAccessCode() ? '/pages/_dynamicTabs/' + SYSParam : '/pages/_dynamic/SYSMAF/' + AccessCodeInherited

              navigate(cfgRoute, {replace: true})
            }}
          >
            <ListItemIcon>
              {!IsSystemAccessCode() && <SettingsIcon fontSize='small' />}
              {IsSystemAccessCode() && <ArrowBackIcon fontSize='small' />}
            </ListItemIcon>
            <ListItemText>
              {!IsSystemAccessCode() && <>Gerenciar Campos</>}
              {IsSystemAccessCode() && <>Retornar ao App</>}
            </ListItemText>
          </MenuItem>
        )}
      </StyledMenu>
      {pdfUrl && (
        <ModalPdf
          pdfUrl={pdfUrl ?? ""}
          secondPdfUrl={secondPdfUrl ?? undefined}
          callback={(data) => {
            if (data.closed) {
              handleClosePdfModal();
            }
            if (data.loaded) {
              handlePdfLoaded();
            }
          }}
        />
      )}
    </>
  )
}

export default DynamicList
