import React, {useState, useRef, useEffect} from 'react'
import StyledDialog from '../styled/StyledDialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import StyledButton from '../styled/StyledButton'
import CancelIcon from '@mui/icons-material/Cancel'
import DownloadIcon from '@mui/icons-material/Download'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import Grid from '@mui/material/Grid'
import {Document, Page} from 'react-pdf'
import {pdfjs} from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = '/library/pdf.worker.min.mjs'

interface IModalPdf {
  pdfUrl: string
  secondPdfUrl?: string
  callback(data: any): void
}

interface PDFControlState {
  numPages: number | null
  pageNumber: number
  scale: number
  isDownloading: boolean
}

const ModalPdf = ({pdfUrl, secondPdfUrl, callback}: IModalPdf) => {
  const [firstPdfState, setFirstPdfState] = useState<PDFControlState>({
    numPages: null,
    pageNumber: 1,
    scale: 1,
    isDownloading: false,
  })
  const [secondPdfState, setSecondPdfState] = useState<PDFControlState>({
    numPages: null,
    pageNumber: 1,
    scale: 1,
    isDownloading: false,
  })
  const contentRef = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    callback({closed: true})
  }

  const handleZoomIn = (isPrimaryPdf: boolean) => {
    const setState = isPrimaryPdf ? setFirstPdfState : setSecondPdfState
    setState((prev) => ({...prev, scale: Math.min(prev.scale + 0.2, 3)}))
  }

  const handleZoomOut = (isPrimaryPdf: boolean) => {
    const setState = isPrimaryPdf ? setFirstPdfState : setSecondPdfState
    setState((prev) => ({...prev, scale: Math.max(prev.scale - 0.2, 0.5)}))
  }

  const handleDownload = async (url: string, isPrimaryPdf: boolean) => {
    const setState = isPrimaryPdf ? setFirstPdfState : setSecondPdfState
    try {
      setState((prev) => ({...prev, isDownloading: true}))
      const response = await fetch(url)
      const blob = await response.blob()
      const blobUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = `documento${isPrimaryPdf ? '1' : '2'}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.error('Erro ao baixar o PDF:', error)
    } finally {
      setState((prev) => ({...prev, isDownloading: false}))
    }
  }

  const onDocumentLoadSuccess =
    (isPrimaryPdf: boolean) =>
    ({numPages}: {numPages: number}) => {
      const setState = isPrimaryPdf ? setFirstPdfState : setSecondPdfState
      setState((prev) => ({...prev, numPages}))
      callback({loaded: true})
    }

  const renderPDFControls = (isPrimaryPdf: boolean) => {
    const state = isPrimaryPdf ? firstPdfState : secondPdfState
    const setState = isPrimaryPdf ? setFirstPdfState : setSecondPdfState
    const url = isPrimaryPdf ? pdfUrl : secondPdfUrl

    return (
      <Box sx={{display: 'flex', gap: 1}}>
        <StyledButton
          variant='contained'
          color='primary'
          startIcon={state.isDownloading ? <CircularProgress size={20} color='inherit' /> : <DownloadIcon />}
          onClick={() => handleDownload(url!, isPrimaryPdf)}
          disabled={state.isDownloading}
        >
          {state.isDownloading ? 'Baixando...' : 'Baixar'}
        </StyledButton>
        <StyledButton
          variant='contained'
          color='inherit'
          disabled={state.pageNumber <= 1}
          onClick={() => setState((prev) => ({...prev, pageNumber: prev.pageNumber - 1}))}
        >
          Anterior
        </StyledButton>
        <StyledButton
          variant='contained'
          color='inherit'
          disabled={state.pageNumber >= (state.numPages || 1)}
          onClick={() => setState((prev) => ({...prev, pageNumber: prev.pageNumber + 1}))}
        >
          Próximo
        </StyledButton>
      </Box>
    )
  }

  const renderDualPDFViewer = (url: string, isPrimaryPdf: boolean) => {
    const state = isPrimaryPdf ? firstPdfState : secondPdfState
    const pageWidth = contentRef.current ? contentRef.current.offsetWidth / (secondPdfUrl ? 2 : 1) - 38 : 0

    return (
      <Box
        sx={{
          overflowX: 'auto',
          overflowY: 'auto',
          height: '100%',
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess(isPrimaryPdf)}
          onLoadError={(error) => console.error('Error loading PDF:', error)}
          loading={
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <CircularProgress />
            </Box>
          }
        >
          {pageWidth > 0 && (
            <Page
              key={`page_${state.pageNumber}`}
              pageNumber={state.pageNumber}
              width={pageWidth * state.scale} // Dynamically set width based on container
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          )}
        </Document>
      </Box>
    )
  }

  const renderSinglePDFViewer = (url: string) => {
    const state = firstPdfState
    const pageWidth = contentRef.current ? contentRef.current.offsetWidth - 42 : 0

    return (
      <Box
        sx={{
          overflowX: 'auto',
          overflowY: 'auto',
          height: '100%',
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess(true)}
          onLoadError={(error) => console.error('Error loading PDF:', error)}
          loading={
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <CircularProgress />
            </Box>
          }
        >
          {pageWidth > 0 && (
            <Page
              key={`page_${state.pageNumber}`}
              pageNumber={state.pageNumber}
              width={pageWidth * state.scale} 
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          )}
        </Document>
      </Box>
    )
  }

  const renderZoomControls = (isPrimaryPdf: boolean) => {
    const state = isPrimaryPdf ? firstPdfState : secondPdfState

    return (
      <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
        <StyledButton
          variant='contained'
          color='inherit'
          startIcon={<ZoomOutIcon />}
          onClick={() => handleZoomOut(isPrimaryPdf)}
          disabled={state.scale <= 0.5}
          size='small'
        >
          Zoom -
        </StyledButton>
        <Box sx={{minWidth: '60px', textAlign: 'center'}}>{Math.round(state.scale * 100)}%</Box>
        <StyledButton
          variant='contained'
          color='inherit'
          startIcon={<ZoomInIcon />}
          onClick={() => handleZoomIn(isPrimaryPdf)}
          disabled={state.scale >= 3}
          size='small'
        >
          Zoom +
        </StyledButton>
      </Box>
    )
  }

  return (
    <StyledDialog open fullWidth maxWidth='lg'>
      <DialogTitle
        sx={{
          display: 'grid',
          gridTemplateColumns: secondPdfUrl ? '1fr 1fr 1fr' : '1fr auto 1fr',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {secondPdfUrl ? (
          <>
            <Box sx={{display: 'flex', gap: 4, justifyContent: 'left'}}>{renderPDFControls(true)}</Box>
            <Box sx={{display: 'flex', gap: 4, justifyContent: 'center'}}>Visualização de PDF</Box>
            <Box sx={{display: 'flex', gap: 4, justifyContent: 'right'}}>{renderPDFControls(false)}</Box>
          </>
        ) : (
          <>
            <Box />
            <Box sx={{display: 'flex', gap: 4, justifyContent: 'left'}}>Visualização de PDF</Box>
            <Box sx={{display: 'flex', gap: 4, justifyContent: 'right'}}>{renderPDFControls(true)}</Box>
          </>
        )}
      </DialogTitle>
      <DialogContent ref={contentRef} sx={{height: 'calc(100vh - 200px)', padding: '16px', display: 'flex'}}>
        <Grid container spacing={2} sx={{flexGrow: 1}}>
          {secondPdfUrl ? (
            <>
              <Grid item xs={6} sx={{display: 'flex', height: '100%'}}>
                {renderDualPDFViewer(pdfUrl, true)}
              </Grid>
              <Grid item xs={6} sx={{display: 'flex', height: '100%'}}>
                {renderDualPDFViewer(secondPdfUrl, false)}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sx={{display: 'flex', height: '100%', justifyContent: 'center'}}>
                {renderSinglePDFViewer(pdfUrl)}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{padding: '8px 24px'}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          {secondPdfUrl ? (
            <>
              {renderZoomControls(true)}
              <StyledButton variant='contained' color='inherit' startIcon={<CancelIcon />} onClick={handleClose}>
                Fechar
              </StyledButton>
              {renderZoomControls(false)}
            </>
          ) : (
            <>
              {renderZoomControls(true)}
              <StyledButton variant='contained' color='inherit' startIcon={<CancelIcon />} onClick={handleClose}>
                Fechar
              </StyledButton>
            </>
          )}
        </Box>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalPdf
